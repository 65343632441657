import MosaiqClient from "../clients/MosaiqClient";
import {
  BestSellers, ContentPage,
  Featured, FeaturedHome, HomeCategories, ListCategory,
  MarketingCategory,
  MarketingHome, MarketingSection, Promotions,
  Seo, Slider,
  Sliders,
  Universe
} from '@/models/marketingHome';
import { AxiosRequestConfig } from "axios";
import { i18n } from "vue-lang-router";
export default class MarketingService {
  static of(): MarketingService {
    return new MarketingService();
  }

  getFaq() {
    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        attrRef: "sku",
      },
    };
    return MosaiqClient.of().get("products/marketing/faq", axiosRequestConfig).then(
        (data) => {
          return data.values;
        },
        err => {
          throw err;
        },
    );
  }

  getCategory(categoryPath) {
    /*
    *  "attrId":"marketing_landing_category",
            "attrValue": "/ART DE VIVRE/ARTS DE VIVRE/",
            "section": "...",
            * */


    const indexPremierSlash = categoryPath.indexOf('/');
    const resultat = categoryPath.substring(indexPremierSlash);


    const params = {
      attrId: "categ_path",
      attrValue: resultat,
      "categoryUp": false

    };

    return MosaiqClient.of().post("products/category/findOne", params).then(
        (data) => {
          return MarketingCategory.of(data.values);
        },
        err => {
          throw err;
        },
    );
  }
  getListCategories(categoryPath) {
    /*
    *  "attrId":"marketing_landing_category",
            "attrValue": "/ART DE VIVRE/ARTS DE VIVRE/",
            "section": "...",
            * */


    const result = categoryPath.map(item => {
      const index = item.indexOf('/');
      return item.substring(index);
    });


    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        columnFilters: JSON.stringify({categ_path:result}),
      }
    }
    return MosaiqClient.of().get("products/category", axiosRequestConfig).then(
        (data) => {
          const categoriesMarketing = data.data;
          const categoriesMarketingMap:ListCategory[] = []

          categoriesMarketing.forEach(function (category) {
            categoriesMarketingMap.push(ListCategory.of(category));
          });

          return categoriesMarketingMap;
        },
        err => {
          throw err;
        },
    );
  }

  getHomePage(section) {
    /*
    marketing_homepage_slider
    marketing_homepage_bestsellers
    marketing_homepage_featured
    marketing_homepage_universe
    marketing_homepage_seo
    */

    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        section: section,
        attrRef: "sku",
      },
    };

    return MosaiqClient.of().get("products/marketing/homepage", axiosRequestConfig).then(
        (data) => {

          return MarketingHome.of(data.values);
        },
        err => {
          throw err;
        },
    );
  }

  getHomeSlider() {
    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        section: "homepage_slider",
        attrRef: "sku",
      },
    };
    return MosaiqClient.of().get("products/marketing/homepage", axiosRequestConfig).then(
        (data) => {
          return Sliders.of(data.values);
        },
        err => {
          throw err;
        },
    );
  }

  getHomeCategories() {
    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        section: "homepage_categories",
        attrRef: "sku",
      },
    };
    return MosaiqClient.of().get("products/marketing/homepage", axiosRequestConfig).then(
        (data) => {

          return HomeCategories.of(data.values);
        },
        err => {
          throw err;
        },
    );
  }

  getBestSellers() {
    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        section: "homepage_bestsellers",
        attrRef: "sku",
        replaceIdBy: "sku",
      },
    };
    return MosaiqClient.of().get("products/marketing/homepage", axiosRequestConfig).then(
        (data) => {
          return BestSellers.of(data.values);
        },
        err => {
          throw err;
        },
    );
  }

  getFeatured() {
    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        section: "homepage_featured_products",
        attrRef: "sku",
        replaceIdBy: "sku",
      },
    };
    return MosaiqClient.of().get("products/marketing/homepage", axiosRequestConfig).then(
        (data) => {
          return FeaturedHome.of(data.values);
        },
        err => {
          throw err;
        },
    );
  }

  getPromotions() {
    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        section: "homepage_promotions",
        attrRef: "sku",
        replaceIdBy: "sku",
      },
    };
    return MosaiqClient.of().get("products/marketing/homepage", axiosRequestConfig).then(
        (data) => {
          return Promotions.of(data.values);
        },
        err => {
          throw err;
        },
    );
  }

  getMarketingBlock(section) {
    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        section: "homepage_section_"+section,
        attrRef: "sku",
        replaceIdBy: "sku",
      },
    };
    return MosaiqClient.of().get("products/marketing/homepage", axiosRequestConfig).then(
        (data) => {
          return MarketingSection.of(data.values, section);
        },
        err => {
          console.log(err)
          throw err;
        },
    );
  }

  getHomeSeo() {
    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        section: "homepage_seo",
        attrRef: "sku",
      },
    };
    return MosaiqClient.of().get("products/marketing/homepage", axiosRequestConfig).then(
        (data) => {
          return Seo.of(data.values);
        },
        err => {
          throw err;
        },
    );
  }

  getDelivery() {
    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        attrRef: "sku",
      },
    };
    return MosaiqClient.of().get("products/marketing/delivery", axiosRequestConfig).then(
        (data) => {
          return data.values;
        },
        err => {
          throw err;
        },
    );
  }


  getContentPage(pageId) {
    // VSX 2024-07-18 Do not cache content as it prevents from getting updates
    // if(localStorage.key(pageId)){
    //   const content = localStorage.getItem(pageId)
    //   if(content){
    //     return Promise.resolve(JSON.parse(content))
    //   }
    // }

    const axiosRequestConfig: AxiosRequestConfig = {
        params: {
          attrRef: "sku",
        },
      };

    return MosaiqClient.of().get("products/marketing/"+pageId, axiosRequestConfig).then(
        (data) => {
          localStorage.setItem(pageId, JSON.stringify(data.values))
          return data.values;
        },
        err => {
          throw err;
        },
    );
  }

  setSeo(content){

    const descEl = document.querySelector('head meta[name="description"]');
    const titleEl = document.querySelector('head title');
    if(descEl)
      descEl.setAttribute('content', content.seo.description?content.seo.description:i18n.t('seo.defaultDescription'));
    if(titleEl)
      titleEl.textContent = content.seo.title ? content.seo.title : i18n.t('seo.defaultPageTitle', {pageName: content.title});
  }

  getPageSeller() {
    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        attrRef: "sku",
      },
    };
    return MosaiqClient.of().get("products/marketing/become_seller", axiosRequestConfig).then(
        (data) => {
          return data.values;
        },
        err => {
          throw err;
        },
    );
  }

  getUniverse() {
    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        section: "homepage_universe",
        attrRef: "sku",
      },
    };
    return MosaiqClient.of().get("products/marketing/homepage", axiosRequestConfig).then(
        (data) => {
          return Universe.of(data.values);
        },
        err => {
          console.log(err)
          throw err;
        },
    );
  }

  getHomeFeatured() {
    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        section: "homepage_featured_products",
        attrRef: "sku",
      },
    };
    return MosaiqClient.of().get("products/marketing//omepage", axiosRequestConfig).then(
        (data) => {
          return Featured.of(data.values);
        },
        err => {
          throw err;
        },
    );
  }

  getTopPromotional() {
    /*const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        section: "homepage_promotions",
        attrRef: "sku",
        replaceIdBy: "sku",
      },
    };*/
    return Promise.resolve([
      {
        title: "profiter de&nbsp;<b>10%</b>&nbsp;supplémentaire sur la catégorie electroménager avec le code&nbsp;<b>ELECTRO10</b>",
        btn: {
          label: "J'y vais !",
          url: "/categorie/electromenager/appareils-de-cuisson",
        },
        startDate: new Date(Date.now()- (3600 * 1000 * 24)),
        endDate: new Date(Date.now()+ (3600 * 1000 * 24)),
        isEvent: true,
        isActive: true
      },
      {
        title: "L'entreprise sera présente au salon&nbsp;<b>Maison & Travaux</b>&nbsp;du&nbsp;<b>22/02 au 27/02</b>&nbsp;venez vite nous retrouver",
        btn: {
          label: "Plus d'informations",
          url: "/categorie/electromenager/appareils-de-cuisson",
        },
        startDate: new Date(Date.now()- (3600 * 1000 * 24)),
        endDate: new Date(Date.now()+ (3600 * 1000 * 24)),
        isEvent: false,
        isActive: true
      }
    ])
    /*return MosaiqClient.of().get("products/marketing/homepage", axiosRequestConfig).then(
        (data) => {
          return Promotions.of(data.values);
        },
        err => {
          throw err;
        },
    );*/
  }
  getFranco() {
    /*const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        section: "homepage_promotions",
        attrRef: "sku",
        replaceIdBy: "sku",
      },
    };*/
    try {
      return Promise.resolve(parseFloat(process.env.VUE_APP_FRANCO))
    }catch {
      return Promise.resolve(0)
    }
  }
}
