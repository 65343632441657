import {DbType, DisplayType, FilterType} from "@/enum/filters";

export class Attribute {
  value?: string | Record<string, Attribute>;
  label?: string;
  labelText?: string;
  unit?: string | null;
  displayType?: DisplayType | null;
  filterType?: FilterType | null;
  index?: number;
  dbType?:DbType | null

  [x: string]: unknown;

  public static of(data: any): Attribute {
    const a: Attribute = new Attribute();
    let value:string | null=null;
    let unit:string | null=null
    try {
      if (data.dbType === "MEASURE") {
        value = data.value[0].value
        unit = data.value[0].unit
      }
    }
    catch (e){ }

    a.value = value ? [value] : typeof data.value==='string'?[data.value]:data.value;
    a.label = data.label;
    a.labelText = data.label;
    a.unit = unit? unit : null;
    a.displayType = data.displayType;
    a.dbType = data.dbType;
    a.filterType = data.filterType;
    a.index = data.index;

    return a;
  }
}

export class FilterableAttribute {
  value?: string | Record<string, Attribute> | Array<string | any>;
  label?: string;
  unit?: string | null;
  filterType?: FilterType | null;
  index?: number;
  dbType?:DbType | null
  min?:number;
  max?:number;
  filterName?: string;


  public static of(data: any): FilterableAttribute {
    const f: FilterableAttribute = new FilterableAttribute();

    if(data.dbType === "SWATCH"){
      if(!f.value) {
        f.value = []
        f.value.push({
          "label": "Pierre Cardin",
          "value": "pierre-cardin",
          "color": null,
          "image": "https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcSVGfkQ9KZaxVicRcbXmDYCqzkwuerfME7j8sbz7-SzM08ndYtka8TOjJwa8o187DV_ThsU42LZghl_MnbNl70rSA1_L8JjhwVhzkV7P0o&usqp=CAc"
        }, {
          "label": "color2",
          "value": "color2",
          "color": null,
          "image": null
        })
        for (const value of data.values) {
          f.value.push(value)
        }
      }
    }else{
      f.value = data.values || [];
    }
    f.label = data.label;
    f.unit = data.currencySymbol ? data.currencySymbol : data.unit;
    f.dbType = data.dbType;
    f.filterType = data.filterType;
    f.index = data.index;
    f.min = data.min;
    f.max = data.max;
    f.filterName = data.name
    return f;
  }
}