import { Customer } from "@/models/customer";
import store from "~/core/store";
import { MarketingEventHandler, ViewPageData } from "./MarketingEventHandler";
import { OrderMarketingtEventData, ProductMarketingEventData } from "../MarketingEventService";

export default class BrevoEventHandler implements MarketingEventHandler {

    handlerName(): string {
        return "brevo";
    }

    private getCustomerProperties() {
        const customer: Customer = store.getters.getProfile;

        return !customer?.id ? null : {  
            "email": customer?.email || null,
            "FIRSTNAME": customer?.firstName,
            "LASTNAME": customer?.lastName
         };
    }

    private trackEvent(eventName, eventData) {
        window['sendinblue']?.track(eventName, this.getCustomerProperties(), eventData);
    }

    private trackPage(pageName) {
        window['sendinblue']?.page(pageName);
    }

    private identify(customer) {

        if (!customer?.id) return;

        const customerProperties = {  
            EXT_ID: customer.email || null,
            LASTNAME: customer.lastName,
            FIRSTNAME: customer.firstName,
            GENDER: customer.gender,
            BIRTH_DATE: customer.birthDate,
            LOCALE: customer.locale,
            GROUP: customer.customerGroup
        };

        const billingAddressName = customer.defaultBillingAddress;
        const billingAddress = customer.addresses?.find(a => a.name === billingAddressName);
        const billingAddressProperties = !billingAddress ? {} : {
            COMPANY: billingAddress.company,
            STREET: billingAddress.street,
            CITY: billingAddress.city,
            POSTCODE: billingAddress.postCode,
            COUNTRY: billingAddress.country,
            SMS: billingAddress.phone,
        }

        const identifyProperties = { ...customerProperties, ...billingAddressProperties };

        window['sendinblue']?.identify(customer.email, identifyProperties);
    }

    private mapCartEvent(cartData) {
        return {
            id: cartData.id,
            data: cartData
        };
    }

    private mapProfileEvent(profileData) {
        return {
            id: profileData.id,
            data: profileData
        };
    }

    private mapGenericEvent(cartData) {
        return {
            data: cartData
        };
    }

    viewCart = (cartData: OrderMarketingtEventData): void => {
        this.trackEvent("view_cart", this.mapCartEvent(cartData));
    };

    viewItem = (cartData: OrderMarketingtEventData): void => {
        this.trackEvent("view_product", this.mapCartEvent(cartData));
    };

    viewCategory = (eventData): void => {
        this.trackEvent("view_category", this.mapGenericEvent(eventData));
    };

    viewPage = (viewPageData: ViewPageData): void => {
        this.trackPage(this.mapGenericEvent(viewPageData.title));
    };

    addToCart = (cartData: OrderMarketingtEventData, productData: ProductMarketingEventData): void => {
        this.trackEvent("cart_updated", this.mapCartEvent(cartData));
    };

    removeFromCart = (cartData: OrderMarketingtEventData, productData: ProductMarketingEventData): void => {
        this.trackEvent("cart_updated", this.mapCartEvent(cartData));
    };

    beginCheckout = (cartData: OrderMarketingtEventData): void => {
        this.trackEvent("begin_checkout", this.mapCartEvent(cartData));
    };

    purchase = (orderData: OrderMarketingtEventData): void => {
        this.trackEvent("order_completed", this.mapCartEvent(orderData));
    };

    search = (searchTerm: string): void => {
        this.trackEvent("search", this.mapGenericEvent(searchTerm));
    };

    signUp = (customerData: Customer): void => {
        this.identify(this.mapProfileEvent(customerData));
    };

    login = (customerData: Customer): void => {
        this.identify(this.mapProfileEvent(customerData));
    };

    updateProfile = (customerData: Customer): void => {
        this.identify(this.mapProfileEvent(customerData));
    };
}
