import Vue from "vue";
import Locales from "@/utils/locales";
import countries from "@/models/country.json";
import {i18n} from "vue-lang-router";

Vue.filter("toCurrency", function (value: number) {
    if (isNaN(value) || value === null || value === undefined) {
        return null;
    }

    const integerPart = Math.trunc(value);
    const decimalPart = (value % 1).toFixed(2).substring(2);

    if (Locales.getLocaleCode() === "fr_FR") {
        return integerPart + "<span class='cent'>," + decimalPart + "</span> <span class='currency'>€</span> ";
    } else {
        return "€" + integerPart + "<span class='cent'>." + decimalPart + "</span>";
    }
});

Vue.filter("toCurrencyHT", function (value: number) {
    if (isNaN(value) || value === null || value === undefined) {
        return null;
    }

    const integerPart = Math.trunc(value);
    const decimalPart = (value % 1).toFixed(2).substring(2);

    if (Locales.getLocaleCode() === "fr_FR") {
        return integerPart + "<span class='cent'>," + decimalPart + "</span> <span class='currency'>€</span><span class='tax'> HT</span> ";
    } else {
        return "€" + integerPart + "<span class='cent'>." + decimalPart + "</span> <span class='tax'> excl tax</span>";
    }
});

Vue.filter("toCurrencyTTC", function (value: number) {
    if (isNaN(value) || value === null || value === undefined) {
        return null;
    }

    const integerPart = Math.trunc(value);
    const decimalPart = (value % 1).toFixed(2).substring(2);

    if (Locales.getLocaleCode() === "fr_FR") {
        return integerPart + "<span class='cent'>," + decimalPart + "</span> €<span class='tax'> TTC</span> ";
    } else {
        return "€" + integerPart + "<span class='cent'>." + decimalPart + "</span> <span class='tax'> Incl tax</span>";
    }
});

Vue.filter("shortDate", function (date) {
    if (date) {
        return new Date(date).toLocaleDateString();
    }
});

Vue.filter("toInt", function (qty) {
    // console.log(qty)
    if (qty) {
        return +qty;
        //return Math.round(qty);
    }
});

Vue.filter("longDate", function (date) {
    if (date) {
        return new Intl.DateTimeFormat("fr-FR", {
            year: "numeric",
            month: "long",
            day: "numeric",
        }).format(new Date(date));
    }
});

Vue.filter("convertTimestamp", function (timestamp) {
    return new Date(timestamp * 1000).toLocaleDateString();
});

Vue.filter("toCountryName", function (value) {
    return countries.filter(country => country.code === value)[0]['label_' + i18n.locale]

});
