import icons from "@/utils/icons";
import store from "~/core/store";
import {i18n} from "vue-lang-router";

export default class Notifications {
    static dispatchNotification = (msg: string, type = 'info', icon = icons.info) => {
        store.dispatch('sendNotification', {
            type:type,
            message: msg,
            icon: icon
        });
    }
    static createNotifications = (action) => {
        switch (action.name) {
            // ADD TO CART
            case "addToCart.add":
                Notifications.dispatchNotification(i18n.t('notifications.addToCart.add', {productName: action.value.productName}));
                break;
            case "addToCart.update":
                Notifications.dispatchNotification(i18n.t('notifications.addToCart.update', {productName: action.value.productName}));
                break;
            case "addToCart.maxQty":
                Notifications.dispatchNotification(i18n.t('notifications.addToCart.max', {
                    stock: action.value.stock,
                    productName: action.value.productName
                }));
                break;
            case "addToCart.remove":
                Notifications.dispatchNotification(i18n.t('notifications.addToCart.remove', {productName: action.value.productName}), 'danger');
                break;
            //address
            case "address.billing":
                Notifications.dispatchNotification(i18n.t('notifications.address.billing'), 'info', icons.check);
                break;
            case "address.shipping":
                Notifications.dispatchNotification(i18n.t('notifications.address.shipping'), 'info', icons.check);
                break
            case "address.created":
                Notifications.dispatchNotification(i18n.t('notifications.address.shipping'), 'info', icons.check);
                break;
            case "address.updated":
                Notifications.dispatchNotification(i18n.t('notifications.address.updated'), 'info', icons.check);
                break
            case "address.removed":
                Notifications.dispatchNotification(i18n.t('notifications.address.removed'), 'info', icons.check);
                break
            // coupon
            case "coupon.add":
                Notifications.dispatchNotification(i18n.t('notifications.coupon.add'), 'info', icons.check);
                break
            case "coupon.allReady":
                Notifications.dispatchNotification(i18n.t('notifications.coupon.allReady'), 'danger', icons.error);
                break
            case "coupon.not":
                Notifications.dispatchNotification(i18n.t('notifications.coupon.not'), 'danger', icons.error);
                break
            //account
            case  'errors.login.unauthorized':
                Notifications.dispatchNotification(i18n.t('errors.login.unauthorized'), 'danger', icons.error);
                break
            case  'errors.login.allReadyExist':
                Notifications.dispatchNotification(i18n.t('errors.login.allReadyExist'), 'danger', icons.error);
                break
            case  'errors.login.notFound':
                Notifications.dispatchNotification(i18n.t('errors.login.notFound'), 'danger', icons.error);
                break
            // ERRORS
            case 'errors.server.access':
                Notifications.dispatchNotification(i18n.t('errors.server.access'), 'danger', icons.error);
                break;
            case 'errors.address.unauthorized':
                Notifications.dispatchNotification(i18n.t('errors.address.unauthorized'), 'danger' , icons.error);
                break;
            case 'errors.address.allReadyExist':
                Notifications.dispatchNotification(i18n.t('errors.address.allReadyExist'), 'danger', icons.error);
                break;
            case 'errors.address.notFound':
                Notifications.dispatchNotification(i18n.t('errors.address.notFound'), 'danger', icons.error);
                break;
            case "errors.notFound":
                Notifications.dispatchNotification(i18n.t('errors.notFound'), 'danger', icons.error);
                break;
            case "errors.recaptcha":
                Notifications.dispatchNotification(i18n.t('errors.recaptcha'), 'danger', icons.error);
                break;
            case "errors.unauthorized":
                Notifications.dispatchNotification(i18n.t('errors.unauthorized'), 'danger', icons.error);
                break;
            case "errors.server.connexion":
                Notifications.dispatchNotification(i18n.t('errors.server.connexion'), 'danger', icons.error);
                break;
            case "errors.categories":
                Notifications.dispatchNotification(i18n.t('errors.categories'), 'danger', icons.error);
                break;
            case "errors.rootCategories":
                Notifications.dispatchNotification(i18n.t('errors.rootCategories'), 'danger', icons.error);
                break;
        }
    };
}

