<template src="@/components/AccountForm/ForgotPassword.html"/>
<style lang="scss" scoped src="@/components/AccountForm/ForgotPassword.scss"/>


<script>

import {SfAlert, SfButton, SfInput, SfLoader, SfHeading} from '@storefront-ui/vue';
import OrkRecaptcha from "@/components/Ork/OrkRecaptcha";
import Validation from "@/utils/validation";

export default {
  name: "ForgotPassword",
  components: {
    OrkRecaptcha,
    SfAlert,
    SfInput,
    SfButton,
    SfLoader,
    SfHeading
  },
  data() {
    return {
      Validation,
      loading: false,
      error: null,
      valid: false,
      submitted: false,
      email: "",
      emailBlur: true
    }
  },
  watch: {
    isLogIn() {
      this.email = ""

    },
  },
  methods: {
    executeRecaptcha() {
      this.$refs.recaptchaForgot.execute()
    },
    validate() {

      this.emailBlur = false;

      if (
          Validation.validateEmail(this.email)
      ) {
        this.valid = true;
      }else{
        this.valid=false
      }
    },
    submitForgotForm(recaptchaData) {
      const onComplete = () => {
        if (!this.error || this.error.status === 200) {
          this.loading = false;
        } else {
          this.loading = false;
        }
      };

      const onError = (e) => {
        this.error = e;
        this.loading = false;
      };
      this.validate()
      if (this.valid) {
        this.loading = true;
        this.$emit('submit', {form: {email: this.email, password: this.password, recaptchaData}, onComplete, onError});

      }
    }
  }
}
</script>

