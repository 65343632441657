<template>
  <div class="orkImgGrid">
    <div class="orkImgGrid--title">{{ data.name }}</div>
    <div class="orkImgGrid--reference">{{ data.reference }}</div>
    <SfImage :alt="data.name?data.name:''" :src="data.img" class="orkImgGrid--image" height="auto" width="90"/>
  </div>

</template>
<script>


import {

  SfImage,
} from '@storefront-ui/vue';

export default {
  name: "OrkImgGrid",
  components: {
    SfImage
  },
  data() {
    return {
      data: null,
      name: null,
    }
  },
  beforeMount() {
    this.data = this.params.value
  },
  methods: {
    /* btnClickedHandler() {
       this.params.clicked(this.params.value);
     }*/
  },
};
</script>
<style lang="scss" scoped>

.orkImgGrid {
  display:flex;
  flex-direction:column;

  ::v-deep{
    .sf-image{
      &--wrapper{
        margin: auto;
        width: 90px;
        height: auto;
      }
      img {
        width: 90px;
        height: auto;
      }
    }
  }


  &--title{
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;

  }
  &--reference{

  }
}
</style>

