<template src="@/components/ProductsActionModal.html"/>
<style lang="scss" scoped src="@/components/ProductsActionModal.scss"/>

<script>

import {
  SfButton,
  SfModal
} from '@storefront-ui/vue';


export default {
  name: 'ProductsActionModal',
  components: {
    SfButton,
    SfModal
  },

  data() {
    return {};
  },
  computed: {
    isProductsActionModal: {
      get() {
        return this.$store.getters.isProductsActionModal
      }
    },
  },


  methods: {
    toggleProductsActionModal() {
      this.$store.dispatch('toggleProductsActionModal', {type: null, product: null})
    },
    passOrder() {
      this.$store.dispatch('toggleProductsActionModal', {type: null, product: null})
      this.$router.push(this.$localizePath('/mon-panier'));
    }
  }
};
</script>


