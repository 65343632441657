<template>

  <form
      id='shipping-details-form'
      class='form'
      @submit.prevent='submitForm'
  >

    <div class='form__horizontal'>
      <SfInput
          v-model="addressComp.lastName"
          :error-message="addressComp.lastName ? $t('form.errors.lastName') : $t('form.required.lastName') "
          :label="$t('form.label.lastName')"
          :valid="lastNameBlur || Validation.validateText(addressComp.lastName,2)"
          class="form__element"
          name="last-name"
          required
          @blur="lastNameBlur = false"
      />
      <SfInput
          v-model="addressComp.firstName"
          :error-message="addressComp.firstName? $t('form.errors.firstName') :$t('form.required.firstName') "
          :label="$t('form.label.firstName')"
          :valid="firstNameBlur || Validation.validateText(addressComp.firstName,2)"
          class="form__element"
          name="first-name"
          required
          @blur="firstNameBlur = false"
      />
    </div>
    <div class='form__horizontal'>
      <SfInput
          v-model='addressComp.company'
          :label="$t('form.label.company')"
          class="form__element"
          name='company'
      />
    </div>
    <div class='form__horizontal'>
      <SfInput
          v-model='addressComp.street'
          :errorMessage="addressComp.street ? $t('form.errors.street'):$t('form.required.street')"
          :label="$t('form.label.street')"
          :valid='streetBlur || Validation.validateText(addressComp.street,2)'
          class="form__element"
          name='street'
          required
          @blur="streetBlur=false"
      />
    </div>
    <div class='form__horizontal'>

      <SfInput
          v-model='addressComp.city'
          :errorMessage="addressComp.city ? $t('form.errors.city'): $t('form.required.city')"
          :label="$t('form.label.city')"
          :valid='cityBlur || Validation.validateText(addressComp.city,2)'
          class="form__element"
          name='city'
          required
          @blur="cityBlur=false"
      />


      <SfInput
          v-model='addressComp.postCode'
          :errorMessage="addressComp.postCode ? $t('form.errors.postCode') : $t('form.required.postCode')"
          :label="$t('form.label.postCode')"
          :valid="postCodeBlur || Validation.validatePostalCode(addressComp.country,addressComp.postCode)"
          class="form__element"
          name='postCode'
          required
          @blur="postCodeBlur = false"
      />
    </div>
    <div class='form__horizontal'>
      <SfSelect
          v-model='addressComp.country'
          :disabled="countries.length<2"
          :errorMessage="addressComp.country ? $t('form.errors.country') : $t('form.required.country')"
          :label="$t('form.label.country')"
          :placeholder="$t('form.placeholder.country')"
          :valid="countryBlur || Validation.validateCountry(addressComp.country)"
          class='form__element sf-select--underlined'
          name='country'
          required
          @blur="countryBlur = false"

      >
        <SfSelectOption
            v-for='country in countries'
            :key='country.code'
            :value='country.code'
        >
          {{ country[countryLang] }}
        </SfSelectOption>
      </SfSelect>


    </div>

    <div class='form__horizontal'>
      <SfInput
          v-model='addressComp.phone'
          :errorMessage="addressComp.phone ? $t('form.errors.phone') : $t('form.required.phone')"
          :label="$t('form.label.phone')"
          :valid="phoneBlur || validatePhoneNumber().isValid"
          class="form__element"
          name='phone'
          required
          @blur="phoneBlur = false"
      />
    </div>
    <div class='form__default__address'>

      <OrkToggle v-if="!isShipping"
                 v-model='addressComp.defaultShipping'
                 :description="$t('form.account.address.defaultShipping')"
                 class='form__toggle'
                 name='shipping'
                 size="medium"></OrkToggle>

      <OrkToggle v-if="!isShipping"
                 v-model='addressComp.defaultBilling'
                 :description="$t('form.account.address.defaultBilling')"
                 class='form__toggle'
                 name='billing'
                 size="medium"></OrkToggle>

    </div>
    <div class='form__actions'>
      <SfButton :disabled="loading || valid " class='sf-button color-white text-secondary-color' type="submit">
        <div v-if="loading">
          <SfLoader :class="{ loader: loading }" :loading="loading"></SfLoader>
          {{ $t('loading.default') }}
        </div>
        <div v-else> {{ isNew ? $t('form.account.address.submitNew') : $t('form.account.address.saveChange') }}</div>
      </SfButton>
    </div>
  </form>

</template>

<script>
import {SfButton, SfInput, SfLoader, SfSelect} from '@storefront-ui/vue';
import countries from "@/models/country.json";
import {OrkToggle} from "@/components/Ork";
import Validation from "@/utils/validation";
import {Address} from "@/models/address";

export default {
  name: 'AddressForm',

  components: {
    SfInput,
    SfLoader,
    SfButton,
    SfSelect,
    OrkToggle
  },
  data() {
    return {
      Validation,
      countryLang: "label_" + this.$i18n.locale,
      authorizedCountryList: process.env.VUE_APP_AUTHORIZED_COUNTRY_LIST,
      countries: countries,
      countryBlur: true,
      companyBlur: true,
      firstNameBlur: true,
      lastNameBlur: true,
      streetBlur: true,
      cityBlur: true,
      postCodeBlur: true,
      phoneBlur: true,
      defaultShipping: false,
      defaultBilling: false,
      errors: null,
      loading: false,
      valid: false,
      submitted: false,
    }
  },
  props: {
    address: {
      type: Address,
      default: () => ({
        name: '',
        company: '',
        firstName: '',
        lastName: '',
        street: '',
        city: '',
        postCode: '',
        country: '',
        phone: '',
        defaultShipping: true,
        defaultBilling: true
      })
    },
    isNew: {
      type: Boolean,
      required: true
    },
    isShipping: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    addressComp() {
      return {...this.address}
    },
  },
  mounted() {
    if (this.countries.length === 1) {
      this.addressComp.country = this.countries[0].code
    }
    this.countries.sort((a, b) => {
      return a[this.countryLang] > b[this.countryLang] ? 1 : -1;
    })
  },
  methods: {
    makeid(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
            charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },

    validate() {
      this.firstNameBlur = false;
      this.lastNameBlur = false;
      this.cityBlur = false;
      this.phoneBlur = false;
      this.postCodeBlur = false;
      this.countryBlur = false;
      this.streetBlur = false;

      this.valid = this.validatePhoneNumber() &&
        Validation.validatePostalCode(this.addressComp.country, this.addressComp.postCode) &&
        Validation.validateText(this.addressComp.city, 2) &&
        Validation.validateCountry(this.addressComp.country) &&
        Validation.validateText(this.addressComp.street, 2) &&
        Validation.validateText(this.addressComp.firstName, 2) &&
        Validation.validateText(this.addressComp.lastName, 2);
    },
    validatePhoneNumber(){
      const validation = Validation.validatePhoneNumber(this.addressComp.phone,this.addressComp.country)
      if(validation.isValid){
        this.addressComp.phone =validation.phoneNumber
      }
      return validation
    },
    submitForm() {
      const onComplete = () => {
        if (!this.error || this.error.status === 200) {
          this.loading = false;
        } else {
          this.loading = false;
        }
      };

      const onError = (e) => {
        this.error = e;
        this.loading = false;
      };
      this.validate()
      if (this.valid) {
        if (!this.addressComp.name) {
          this.addressComp.name = this.addressComp.street.normalize("NFKD").replace(/[^a-zA-Z]+/g, '').replace(/\s/g, '') + this.addressComp.postCode.replace(/[^0-9]+/g, '') + this.makeid(8)
        }
        this.loading = true;
        this.$emit('submit', {address: this.addressComp, onComplete, onError});
      }
    },
  }
};
</script>

<style lang='scss' scoped>
@import "@/assets/css/variables/siteConfig";

form {
  padding: 0 var(--spacer-base);
  margin-bottom: var(--spacer-base);
}

.form__toggle {
  &:first-of-type {
    margin-bottom: var(--spacer-sm);
  }

  .title {
    display: none;
  }
}

.form__horizontal {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .form__element {
    flex: 1;
    margin: var(--spacer-sm);
    @include for-mobile {
      width: 100%;
      flex: auto;
    }
  }
}


.form__default__address {
  margin: var(--spacer-base) 0;
  padding: 0 0 0 var(--spacer-sm);
}

.form__actions {
  margin-top: var(--spacer-lg);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

::v-deep {
  .will-change {
    line-height: 14px !important;
    font-size: 14px !important;
    transform: translate3d(0, -200%, 0) !important;
  }

  .sf-select .will-change {
    color: var(--c-gray);
    transform: translate3d(0, 0, 0) !important;
  }
}
</style>
