import Vue from "vue";
import VueRouter from "vue-router";
import {RouteConfig} from "vue-router/types/router";
import LangRouter from "vue-lang-router";
import translations from "@/locales/translations";
import localizedURLs from "~/client/locales/localized-urls/index";
// VueLangRouter conf translation file / route file and i18n Options
import store from "~/core/store";
import Rgpd from "@/utils/rgpd";
import clientRoute from "~/client/router/overrides";
import Api from "@/api/Api";
import scriptLoader from "@/utils/loadScript";
import {CustomerActions} from "~/core/store/customer/actions";

//Vue.use(Meta);
Vue.use(LangRouter, {
    defaultLanguage: "fr",
    defaultLocale: "fr",
    locale: "fr",
    translations,
    localizedURLs,
    i18nOptions: {
        defaultLocale: "fr",
        locale: process.env.VUE_APP_I18N_LOCALE,
        fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
        numberFormats: {
            en: {
                percent: {
                    style: "percent", useGrouping: false,
                },
                currency: {
                    style: "currency",
                    currency: "EUR",
                    currencyDisplay: "symbol",
                },
                currencyNoCent: {
                    style: "currency",
                    minimumFractionDigits: 0, maximumFractionDigits: 0,
                    currency: "EUR",
                    currencyDisplay: "symbol",
                },
                decimal: {
                    style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2,
                },
            },
            fr: {
                percent: {
                    style: "percent", useGrouping: false,
                },
                currency: {
                    style: "currency", currency: "EUR", currencyDisplay: "symbol",
                },
                decimal: {
                    style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2,
                },
                currencyNoCent: {
                    style: "currency",
                    minimumFractionDigits: 0, maximumFractionDigits: 0,
                    currency: "EUR",
                    currencyDisplay: "symbol",
                },
            },
        },
        dateTimeFormats: {
            en: {
                short: {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                },
                long: {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                },
            },
            fr: {
                short: {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                },
                long: {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                },
            },
        },
    },
});

Vue.use(VueRouter);

const defaultRoutes: RouteConfig[] = [
    {
        path: "/",
        name: "Home",
        component: () => import("@/pages/Home.vue"),
    },
    {
        path: "/categorie/:categorySlugs(.*)",
        name: "Category",
        component: () => import("@/pages/Category.vue"),
    },
    {
        path: "/categorie",
        name: "Categories",
        component: () => import(/* webpackPrefetch: true */  "@/pages/Categories.vue"),
    },
    {
        path: "/produit/:productSlug",
        name: "Product",
        component: () => import(  "@/pages/Product.vue"
            ),
    },
    {
        path: "/reinitialisation-mot-de-passe",
        name: "ResetPassword",
        component: () => import(/* webpackPrefetch: true */  "@/pages/ResetPassword.vue"),
    },
    {
        name: "My-account",
        path: "/mon-compte",
        component: () => import(/* webpackPrefetch: true */  "@/pages/MyAccount.vue"),
        redirect: "/mon-compte/profil",
        children: [
            {
                path: "profil",
                component: () => import(/* webpackPrefetch: true */  "@/pages/MyAccount/MyProfile.vue"),
            },
            {
                path: "carnet-d-adresses",
                component: () => import(/* webpackPrefetch: true */  "@/pages/MyAccount/Addresses.vue"),
            },
            {
                path: "commandes",
                component: () => import(/* webpackPrefetch: true */  "@/pages/MyAccount/MyOrders.vue")
            },
            {
                path: "commandes/:orderReference?",
                component: () => import(/* webpackPrefetch: true */  "@/pages/MyAccount/MyOrder.vue")
            },
            {
                path: "deconnexion",
                component: () => import(/* webpackPrefetch: true */  "@/pages/MyAccount/Logout.vue")
            }
        ]
    },
    {
        path: "/commande/merci/:orderReference?",
        name: "CheckoutThankYou",
        component: () => import(/* webpackPrefetch: true */  "@/pages/Checkout/ThankYou.vue"),
    },
    {
        path: "/contact",
        name: "Contact",
        component: () => import(/* webpackPrefetch: true */  "@/pages/Contact.vue"),
    },
    {
        path: "/recherche",
        name: "Search",
        component: () => import(/* webpackPrefetch: true */  "@/pages/Search.vue"),
    },
    {
        path: "/mon-panier",
        name: "Cart",
        component: () => import(/* webpackPrefetch: true */  "@/pages/Cart.vue"),
    },
    {
        path: "/mentions-legales",
        name: "LegalMentions",
        component: () => import(/* webpackPrefetch: true */  "@/pages/Legales/LegalMentions.vue"),
    },
    {
        path: "/conditions-generales-de-vente",
        name: "TermsOfUse",
        component: () => import(/* webpackPrefetch: true */  "@/pages/Legales/TermsOfUse.vue"),
    },
    {
        path: "/gestion-des-cookies",
        name: "Cookies",
        component: () => import(/* webpackPrefetch: true */  "@/pages/Legales/Cookies.vue"),
    },
    {
        path: "/prestataires",
        name: "Prestataires",
        component: () => import(/* webpackPrefetch: true */  "@/pages/MarketPlace/Prestataires.vue"),
    },
    {
        path: "/politique-de-protection-des-donnees-personnelles",
        name: "Rgpd",
        component: () => import(/* webpackPrefetch: true */  "@/pages/Legales/Rgpd.vue"),
    },
    {
        path: "/livraison",
        name: "Livraison",
        component: () => import(/* webpackPrefetch: true */  "@/pages/Legales/Delivery.vue"),
    },
    {
        path: "/politique-de-retours",
        name: "Echange et retour",
        component: () => import(/* webpackPrefetch: true */  "@/pages/Legales/EchangeAndReturn.vue"),
    },

    {
        path: "/404",
        name: "Notfound",
        meta: {title: "not found", decription: {"content": "not found"}},
        component: () => import(/* webpackPrefetch: true */  "@/pages/Errors/NotFound.vue"),
    },
    {
        path: "/401",
        name: "Notauthorized",
        component: () => import(/* webpackPrefetch: true */  "@/pages/Errors/NotAuthorized.vue"),
    },
    {
        path: "*",
        redirect: "/404",
    },
];

const paymentRoute: RouteConfig[] = [
    {
        name: "Checkout",
        path: "/commande/",
        meta: {layout: "checkout"},
        component: () => import(/* webpackPrefetch: true */  "@/pages/Checkout.vue"),
        redirect: "/commande/livraison",

        children: [
            {
                path: "connexion",
                name: "CheckoutLogin",
                meta: {layout: "checkout"},
                components: {checkout: () => import(/* webpackPrefetch: true */  "@/pages/Checkout/Login.vue")},
                beforeEnter: requireAuth,
            },
            {
                path: "livraison",
                name: "CheckoutShipping",
                meta: {layout: "checkout"},
                components: {checkout: () => import(/* webpackPrefetch: true */  "@/pages/Checkout/Shipping.vue")},
                beforeEnter: requireAuth,
            },
            {
                path: "paiement",
                name: "CheckoutPayment",
                meta: {layout: "checkout"},
                components: {checkout: () => import(/* webpackPrefetch: true */  "@/pages/Checkout/Payment.vue")},
                beforeEnter: requireAuth,
            }
        ],
    },
    {
        path: "/commande/paiement/resultat",
        name: "CheckoutPaymentResult",
        meta: {layout: "blank"},
        component: () => import(/* webpackPrefetch: true */  "@/payment/" + process.env.VUE_APP_PAYMENT_OPERATOR + "/pages/Payment-Result.vue"),
        beforeEnter: requireAuth
    },
    {
        path: "/commande/paiement/retour",
        name: "CheckoutPaymentReturn",
        meta: {layout: "blank"},
        component: () => import(/* webpackPrefetch: true */  "@/payment/" + process.env.VUE_APP_PAYMENT_OPERATOR + "/pages/Payment-Return.vue"),
        beforeEnter: requireAuth
    }]

let routes: RouteConfig[] = [];

routes = defaultRoutes;
routes = routes.concat(paymentRoute);

const MergedRoutes = scriptLoader.mergeArray(routes, clientRoute)
routes = MergedRoutes

export async function requireAuth(to, from, next) {
    // VSX 2024-07-19
    // Don't trust local data as it may be out of date with server state,
    // We don't want to allow a user to access a page that requires to be authentified
    // if he actually is not authenticated anymore on server side du to expired token
    
    //if (!store.getters.isAuthenticated || !store.getters.getCart) {
        await store.dispatch(CustomerActions.getMe);
    //}

    const cart = store.getters.getCart;

    if (store.getters.isAuthenticated) {
        // only paiement result and paiement return may be accessed with an empty cart,
        // as the previous cart was just transformed into a validated order
        if (!cart?.cartItems.length && to.name != "CheckoutPaymentResult" && to.name != "CheckoutPaymentReturn") {
            router.push(router.app.$localizePath("/mon-panier")).catch();
            return;
        }
    } else if (to.name != "CheckoutLogin") {
        // If not authenticated and trying to access a page that is not the connection screen itself,
        // redirect to the connection screen
        router.push(router.app.$localizePath("/commande/connexion")).catch();
        return;
    }

    // All exceptions were handled before, continue with normal processing
    next();
}

function checkDisplaySize() {
    const hasInfiniteScroll = process.env.VUE_APP_CATEGORIES_INFINITESCROLL !== 'false',
        desktopInfiniteScroll = process.env.VUE_APP_DESKTOP_INFINITESCROLL !== 'false',
        desktopWidth = Number(process.env.VUE_APP_DESKTOP_WIDTH);
    const mql = window.matchMedia("(max-width :" + desktopWidth + "px)");
    if (mql.matches) {
        if (hasInfiniteScroll) {
            sessionStorage.setItem('isMobile', 'true')

        } else {
            sessionStorage.setItem('isMobile', 'false')
        }
    } else {
        if (desktopInfiniteScroll) {
            sessionStorage.setItem('isMobile', 'true')
        } else {
            sessionStorage.setItem('isMobile', 'false')
        }
    }
    if (!desktopInfiniteScroll && hasInfiniteScroll) {
        mql.addEventListener("change", (e) => {
            sessionStorage.setItem('isMobile', e.matches.toString())

        });
    }
}


const router = new LangRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        //&& to.name != "category"
        checkDisplaySize();
        Rgpd.initRgpd();
        if (to.name === from.name) {
            to.meta?.scrollPos && (to.meta.scrollPos.top = 0)
            if (to.hash) {
                return {
                    behaviors: "smooth",
                    selector: to.hash,
                };
            } else {
                return {left: 0, top: 0}
            }
        }
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    },
});

router.beforeEach(async (to, from, next) => {
    if (process.env.VUE_APP_DEBUG_PRINT_ROUTER) {
        console.log(`START Routing from '${from.path}' to '${to.path}'`, from, to);
    }
    // FIXME VSX 2024-07-19
    // What is this abort thing??? Is it usefull? If so then it needs a comment as it is not trivial
    // The same goes for the scroll position line
    const category = Api.product.categoryController
    if (category) {
        category.abort()
    }
    from.meta?.scrollPos && (from.meta.scrollPos.top = document.documentElement.scrollTop)

    const rootCategories = store.getters.getRootCategories || await store.dispatch("getRootCategories");

    const rootCategory = await store.dispatch("getRootCategory");

    // only make a remote call if there's a mismatch
    if (rootCategories.length === 1 && rootCategories[0].path !== rootCategory?.path) {
        await store.dispatch('setRootCategoryPath', rootCategories[0].path || '/');
    }
    else if (rootCategories?.length && !rootCategory) {
        // Vérifier si le chemin de la route actuelle est dans le tableau des chemins à protéger
        store.dispatch('toggleRootCategoryModal', {open: true, rootCategory: null}).then();
    }
    
    next();
});

router.afterEach((to, from, failure) => {
    if (process.env.VUE_APP_DEBUG_PRINT_ROUTER) {
        console.log(`DONE Routing from '${from.path}' to '${to.path}'`);
    }

    if (failure) return;

    Api.marketingEvent.viewPage({
        routeName: to.name,
        url: window.location.href,
        path: to.path,
        title: window.document.title,
        referer: from.fullPath
    })
})

export default router;
