import QorusClient from "@/api/clients/QorusClient";
import { Product, ProductsWithFilters } from "@/models/product";
import { AxiosRequestConfig } from "axios";
import qs from "qs";

/**
 *  Qorus API about products
 */
export default class ProductService {
    private qorusClient = QorusClient.of();

    static of(): ProductService {
        return new ProductService();
    }

    public categoryController;

    /**
     * Gets all products according to a given pagination
     * @param categoryId category Id
     * @param page Page to retrieve
     * @param pageSize Number of products by page
     * @param sort Attribute used for sorting
     * @param asc True (ascending) / False (descending)
     * @return Page<LightProductDTO> Paginated products
     */
    getByCategory(categoryId: string, query: any): Promise<ProductsWithFilters> {
        //v1/products?category=<category>
        //v1/products/categories/<category>
        // page = 0, pageSize = 10, sort = "price", asc = true;

        const signal = this.abortController();
        // A new instance of the AbortController is created before making the API request


        const defaultSort = process.env.VUE_APP_DEFAULT_SORT;
        const sort = query.sort?.split(".")[0] || defaultSort.split(".")[0];
        const sortOrder = query.sort?.split(".")[1] || defaultSort.split(".")[1];
        const filters = query.filters || null;

        return this.qorusClient.get("products/category", {
            signal,
            paramsSerializer: {
                serialize: (params) => {
                    return qs.stringify(params, { indices: false })
                },
                indexes: null
            },
            params: {
                categoryPath: categoryId,
                size: query.itemsPerPage || process.env.VUE_APP_DEFAULT_DESKTOP_ITEMSPERPAGE,
                page: query.page - 1 || 0,
                sort: sort + "," + sortOrder,
                filters,
            },
        }).then(
            (data: ProductsWithFilters) => {
                return data;
            },
            data => {
                throw data;
            },
        );

    }

    abortController() {
        if (this.categoryController) this.categoryController.abort();
        this.categoryController = new AbortController();
        return this.categoryController.signal;
    }

    getProductByCategoryForMobile(categoryId: string, query: any, page: number, changeViewMode: boolean): Promise<ProductsWithFilters> {

        const signal = this.abortController();
        //v1/products?category=<category>
        //v1/products/categories/<category>
        // page = 0, pageSize = 10, sort = "price", asc = true;
        const defaultSort = process.env.VUE_APP_DEFAULT_SORT;
        const sort = query.sort?.split(".")[0] || defaultSort.split(".")[0];
        const sortOrder = query.sort?.split(".")[1] || defaultSort.split(".")[1];
        const filters = query.filters || null;
        let params = {}
        if (changeViewMode) {
            params = {
                categoryPath: categoryId,
                size: process.env.VUE_APP_DEFAULT_MOBILE_ITEMSPERPAGE * page,
                page: 0,
                sort: sort + "," + sortOrder,
                filters,
            };
        } else {
            params = {
                categoryPath: categoryId,
                size: process.env.VUE_APP_DEFAULT_MOBILE_ITEMSPERPAGE,
                page: page - 1,
                sort: sort + "," + sortOrder,
                filters,
            };
        }
        return this.qorusClient.get("products/category", {
            signal,
            paramsSerializer: {
                serialize: (params) => {
                    return qs.stringify(params, { indices: false })
                },
                indexes: null
            },
            params
        }).then(
            (data: ProductsWithFilters) => {
                return data;
            },
            data => {
                throw data;
            },
        );

    }

    getProductByMerchantId(merchantId: string, query: any): Promise<ProductsWithFilters> {
        //v1/products?category=<category>
        //v1/products/categories/<category>
        // page = 0, pageSize = 10, sort = "price", asc = true;

        //console.log(query)
        const signal = this.abortController();
        const defaultSort = process.env.VUE_APP_DEFAULT_SORT;
        const sort = query.sort?.split(".")[0] || defaultSort.split(".")[0];
        const sortOrder = query.sort?.split(".")[1] || defaultSort.split(".")[1];
        const filters = query.filters || null;

        return this.qorusClient.get("products/seller", {
            signal,
            paramsSerializer: {
                serialize: (params) => {
                    return qs.stringify(params, { indices: false })
                },
                indexes: null
            },
            params: {
                sellerId: merchantId,
                size: query.itemsPerPage || process.env.VUE_APP_DEFAULT_DESKTOP_ITEMSPERPAGE,
                page: query.page - 1 || 0,
                sort: sort + "," + sortOrder,
                filters,
            },

        }).then(
            (data: ProductsWithFilters) => {
                return data;
            },
            data => {
                throw data;
            },
        );
    }

    getProductByMerchantIdForMobile(merchantId: string, query: any, page: number, changeViewMode: boolean): Promise<ProductsWithFilters> {

        //v1/products?category=<category>
        //v1/products/categories/<category>
        // page = 0, pageSize = 10, sort = "price", asc = true;
        const signal = this.abortController();
        const defaultSort = process.env.VUE_APP_DEFAULT_SORT;
        const sort = query.sort?.split(".")[0] || defaultSort.split(".")[0];
        const sortOrder = query.sort?.split(".")[1] || defaultSort.split(".")[1];
        const filters = query.filters || null;
        let params = {}
        if (changeViewMode) {
            params = {
                sellerId: merchantId,
                size: process.env.VUE_APP_DEFAULT_MOBILE_ITEMSPERPAGE * page,
                page: 0,
                sort: sort + "," + sortOrder,
                filters,
            };
        } else {
            params = {
                sellerId: merchantId,
                size: process.env.VUE_APP_DEFAULT_MOBILE_ITEMSPERPAGE,
                page: page - 1,
                sort: sort + "," + sortOrder,
                filters,
            };
        }
        return this.qorusClient.get("products/seller", {
            signal,
            paramsSerializer: {
                serialize: (params) => {
                    return qs.stringify(params, { indices: false })
                },
                indexes: null
            },
            params
        }).then(
            (data: ProductsWithFilters) => {
                return data;
            },
            data => {
                throw data;
            },
        );
    }

    getProductByType(type: string, query: any): Promise<ProductsWithFilters> {
        //v1/products?category=<category>
        //v1/products/categories/<category>
        // page = 0, pageSize = 10, sort = "price", asc = true;

        //console.log(query)
        const signal = this.abortController();
        const defaultSort = process.env.VUE_APP_DEFAULT_SORT;
        const sort = query.sort?.split(".")[0] || defaultSort.split(".")[0];
        const sortOrder = query.sort?.split(".")[1] || defaultSort.split(".")[1];
        let filters = query.filters;
        filters = filters ? filters + '&' + type + ':true' : type + ':true';
        return this.qorusClient.get("products/category", {
            signal,
            paramsSerializer: {
                serialize: (params) => {
                    return qs.stringify(params, { indices: false })
                },
                indexes: null
            },
            params: {
                categoryPath: '/',
                size: query.itemsPerPage || process.env.VUE_APP_DEFAULT_DESKTOP_ITEMSPERPAGE,
                page: query.page - 1 || 0,
                sort: sort + "," + sortOrder,
                filters,
            },

        }).then(
            (data: ProductsWithFilters) => {
                return data;
            },
            data => {
                throw data;
            },
        );
    }

    getProductByTypeForMobile(type: string, query: any, page: number, changeViewMode: boolean): Promise<ProductsWithFilters> {
        const signal = this.abortController();
        //v1/products?category=<category>
        //v1/products/categories/<category>
        // page = 0, pageSize = 10, sort = "price", asc = true;
        const defaultSort = process.env.VUE_APP_DEFAULT_SORT;
        const sort = query.sort?.split(".")[0] || defaultSort.split(".")[0];
        const sortOrder = query.sort?.split(".")[1] || defaultSort.split(".")[1];
        let filters = query.filters;
        filters = filters ? filters + '&' + type + ':true' : type + ':true';

        let params = {}
        if (changeViewMode) {
            params = {
                categoryPath: '/',
                size: process.env.VUE_APP_DEFAULT_MOBILE_ITEMSPERPAGE * page,
                page: 0,
                sort: sort + "," + sortOrder,
                filters,
            };
        } else {
            params = {
                type,
                size: process.env.VUE_APP_DEFAULT_MOBILE_ITEMSPERPAGE,
                page: page - 1,
                sort: sort + "," + sortOrder,
                filters,
            };
        }
        return this.qorusClient.get("products/category", {
            signal,
            paramsSerializer: {
                serialize: (params) => {
                    return qs.stringify(params, { indices: false })
                },
                indexes: null
            },
            params
        }).then(
            (data: ProductsWithFilters) => {
                return data;
            },
            data => {
                throw data;
            },
        );
    }

    getBestSeller(categoryId: string): Promise<ProductsWithFilters> {
        //v1/products?category=<category>
        //v1/products/categories/<category>
        // page = 0, pageSize = 10, sort = "price", asc = true;
        const signal = this.abortController();
        return this.qorusClient.get("products/category", {
            signal,
            params: {
                categoryPath: categoryId,
                size: 10,
                page: 0,
                sort: "relevance,asc",
            },
        }).then(
            (data: ProductsWithFilters) => {
                return data;
            },
            data => {
                throw data;
            },
        );
    }

    getBySlug(slug: string): Promise<Product> {
        return this.qorusClient.get(`products/slug/` + slug).then(
            (data: Product) => {
                return data;
            },
            data => {
                throw data;
            },
        );
    }

    search(searchTerm: string): Promise<ProductsWithFilters> {
        const axiosRequestConfig: AxiosRequestConfig = {
            params: {
                searchTerm,
                size: 4,
            },
        };
        return this.qorusClient.get(`products/search`, axiosRequestConfig).then(
            (data: ProductsWithFilters) => {
                return data;
            },
            data => {
                throw data;
            },
        );
    }

    searchPage(query: any): Promise<ProductsWithFilters> {

        const defaultSort = process.env.VUE_APP_DEFAULT_SORT;
        const sort = query.sort?.split(".")[0] || defaultSort.split(".")[0];
        const sortOrder = query.sort?.split(".")[1] || defaultSort.split(".")[1];
        const filters = query.filters || null;

        const axiosRequestConfig: AxiosRequestConfig = {
            paramsSerializer: {
                serialize: (params) => {
                    return qs.stringify(params, { indices: false })
                },
                indexes: null
            },
            params: {
                searchTerm: query.searchTerm,
                size: query.itemsPerPage || process.env.VUE_APP_DEFAULT_DESKTOP_ITEMSPERPAGE,
                page: query.page - 1 || 0,
                sort: sort + "," + sortOrder,
                filters,
            },
        };
        return this.qorusClient.get(`products/search`, axiosRequestConfig).then(
            (data: ProductsWithFilters) => {
                return data;
            },
            data => {
                throw data;
            },
        );
    }

    searchPageMobile(query: any, page: number, changeViewMode: boolean): Promise<ProductsWithFilters> {

        const defaultSort = process.env.VUE_APP_DEFAULT_SORT;
        const sort = query.sort?.split(".")[0] || defaultSort.split(".")[0];
        const sortOrder = query.sort?.split(".")[1] || defaultSort.split(".")[1];
        const filters = query.filters || null;
        let params = {}
        if (changeViewMode) {
            params = {
                searchTerm: query.searchTerm,
                size: process.env.VUE_APP_DEFAULT_MOBILE_ITEMSPERPAGE * page,
                page: 0,
                sort: sort + "," + sortOrder,
                filters,
            };
        } else {
            params = {
                searchTerm: query.searchTerm,
                size: process.env.VUE_APP_DEFAULT_MOBILE_ITEMSPERPAGE,
                page: page - 1,
                sort: sort + "," + sortOrder,
                filters,
            };
        }

        const axiosRequestConfig: AxiosRequestConfig = {
            paramsSerializer: {
                serialize: (params) => {
                    return qs.stringify(params, { indices: false })
                },
                indexes: null
            },
            params
        };
        return this.qorusClient.get(`products/search`, axiosRequestConfig).then(
            (data: ProductsWithFilters) => {
                return data;
            },
            data => {
                throw data;
            },
        );
    }

    searchAutocomplete(searchTerm: string): Promise<Array<string>> {
        const axiosRequestConfig: AxiosRequestConfig = {
            params: {
                searchTerm,
                size: 10,
            },
        };
        return this.qorusClient.get(`products/search-autocomplete`, axiosRequestConfig).then(
            (data) => {
                return data;
            },
            data => {
                throw data;
            },
        );
    }

    getBySkuList(referenceList: string): Promise<any> {
        const axiosRequestConfig: AxiosRequestConfig = {
            params: {
                referenceList,
            },
        };
        return this.qorusClient.get(`products/references`, axiosRequestConfig).then(
            (data) => {
                return data;
            },
            err => {
                throw err;
            },
        );
    }

    restockAlert(form): Promise<any> {
        return this.qorusClient.post("products/offer/restock-alert", form).then(
            (data: any) => {
                return data;
            },
            data => {
                throw data;
            },
        );
    }
}