import Orejime from "orejime/dist/orejime";
import { i18n } from "vue-lang-router";

export default class Rgpd {

  static initRgpd = () => {

    const orejimeConfig = {
      appElement: "#app",
      cookieName: "AFS_RGPD_OREJIME",
      cookieExpiresAfterDays: 182,
      privacyPolicy: "/gestion-des-cookies",
      lang: i18n.locale || "fr",
      default: !0,
      mustConsent: !1,
      mustNotice: !1,
      implicitConsent: !1,
      poweredBy: !1,
      translations: {
        fr: {
          poweredBy: false,
          consentNotice: {
            description: i18n.t("cookie.notice.description"),
            learnMore: i18n.t("cookie.learnMore"),
          },
          consentModal: {
            title: i18n.t("cookie.noticeModal.title"),
            description: i18n.t("cookie.noticeModal.description"),
            privacyPolicy: {
              name: i18n.t("cookie.pageTitle"),
              text: i18n.t("cookie.privacy"),
            },
          },
          internalTracker: {
            title: i18n.t("cookie.internalTracker.title"),
            description: i18n.t("cookie.internalTracker.description"),
          },
          app: { optOut: { title: "" }, required: { title: "" }, purposes: "Utilisations", purpose: "Utilisation" },
        },
        en: {
          poweredBy: false,
          consentNotice: {
            description: i18n.t("cookie.notice.description"),
            learnMore: i18n.t("cookie.learnMore"),
          },
          consentModal: {
            title: i18n.t("cookie.noticeModal.title"),
            description: i18n.t("cookie.noticeModal.description"),
            privacyPolicy: {
              name: i18n.t("cookie.pageTitle"),
              text: i18n.t("cookie.privacy"),
            },
          },
          internalTracker: {
            title: i18n.t("cookie.internalTracker.title"),
            description: i18n.t("cookie.internalTracker.description"),
          },

          app: {
            optOut: { title: "" }, required: { title: "" }, purposes: {}, purpose: "Utilisation",
          },
        },
      },
      apps: [{
        name: "internal-tracker",
        title: i18n.t("cookie.internalTracker.title"),
        description: i18n.t("cookie.internalTracker.description"),
        required: true,
      }, {
        name: "google-analytics",
        title: i18n.t("cookie.googleAnalytic.title"),
        description: i18n.t("cookie.googleAnalytic.description"),
        required: false,
        cookies: [
          "_GRECAPTCHA",
          "_ga",
          "_gat",
          "_gcl_au",
          "_gid",
          "__utma",
          "__utmb",
          "__utmc",
          "__utmt",
          "__utmz",
          "_gat_gtag_"+process.env.VUE_APP_GTAG,
          "_ga_"+process.env.VUE_APP_GTAG,
          "_gat_"+process.env.VUE_APP_GTAG,
          "_gat_gtag_"+process.env.VUE_APP_ANALYTICS,
          "_ga_"+process.env.VUE_APP_ANALYTICS,
          "_gat_"+process.env.VUE_APP_ANALYTICS],
      },
      {
        name: "brevo",
        title: i18n.t("cookie.brevo.title"),
        description: i18n.t("cookie.brevo.description"),
        required: false,
        cookies: [
          "visitor_id"
        ],
      },
        {
          name: "facebook",
          title: i18n.t("cookie.social.title"),
          description: i18n.t("cookie.social.description"),
          cookies: ["_fbq"],
          required: false,
          callback: function (e) {
            const i = "disable-facebook";
            window[i] = !e;
          },
        },
      ],
      /*categories: [
        {
          name: "example",
          title: "Example category",
          apps: ["internal-tracker", "google-analytics"],
        },
        {
          name: "third-party",
          title: "Third-party applications",
          apps: ["recapcha", "facebook"],
        },
      ],*/
    };

    const t = Orejime.init(orejimeConfig);

    const cookieShowModal = document.getElementById("setCookie");
    if (cookieShowModal) {
      cookieShowModal.onclick = function (e) {
        e.preventDefault();
        //your handler here
        t.show();
      };
    }

  };

}
