<template>
  <div
      :class="{
       'small':size==='small',
       'medium':size==='medium',
      'is-active': isChecked,
      'is-disabled': disabled,
      'has-error': !valid,
      'is-required': required,
    }"
      class="ork-toggle"
  >
    <label>
      <span :class="[inverse?'switch inverse':'switch']">
        <input
            v-focus
            :checked="isChecked"
            :disabled="disabled"
            :name="name"
            :value="value"
            type="checkbox"
            @change="inputHandler"/>
      <span v-if="round" class="slider round"></span>
      <span v-else class="slider"></span></span>


      <span class="text">
        <span v-if="title" class="title" v-html="title"></span>
        <span v-if="description" class="description" v-html="description"> </span>
      </span>
    </label>

  </div>
</template>
<script>

import {focus} from "@storefront-ui/vue/src/utilities/directives";

export default {
  name: "OrkToggle",
  directives: {
    focus,
  },
  components: {},
  model: {
    prop: "selected",
    event: "change",
  },
  props: {

    size: {
      type: String,
      default: 'medium'
    },
    round: {
      type: Boolean,
      default: true,
    },
    inverse: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Boolean],
      default: "false",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: [String, Boolean],
      default: "false",
    },
  },
  computed: {
    isChecked() {
      if (typeof this.selected === "boolean") {
        return this.selected;
      } else {
        return this.selected.includes(this.value);
      }
    },

  },
  methods: {
    inputHandler() {

      if (typeof this.selected === "boolean") {
        this.$emit("change", !this.selected);
      } else {
        let selected = [...this.selected];
        if (selected.includes(this.value)) {
          selected = selected.filter((value) => value !== this.value);
        } else {
          selected.push(this.value);
        }
        this.$emit("change", selected);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/components/molecules/SfCheckbox.scss";

.ork-toggle {

  label {
    display: flex;
    align-items: center;
  }

  .text {
    margin-left: var(--spacer-sm);
    flex: 1;

    .title {
      display: block;
      font-weight: bold;
      font-size: var(--font-size--lg);
    }

    .description {
      display: block;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    &.inverse {
      order: +1;
    }
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--_c-primary-background);
    box-shadow: 0 0 10px -5px inset rgba(0, 0, 0, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider, input:checked:focus + .slider {
    background-color: var(--c-primary);
  }

  input:checked:hover + .slider {
    box-shadow: 0 0 10px -5px inset rgba(0, 0, 0, 0.5);
  }

  input:focus + .slider, input:hover + .slider {

    box-shadow: 0 0 10px -5px inset rgba(0, 0, 0, 0.5);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  &.small {
    .switch {
      position: relative;
      display: inline-block;
      width: 32px;
      height: 20px;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(13px);
      -ms-transform: translateX(13px);
      transform: translateX(13px);
    }

    .slider.round {
      border-radius: 20px;
    }
  }

  &.medium {
    .switch {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 24px;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }

    .slider.round {
      border-radius: 20px;
    }
  }
}
</style>

