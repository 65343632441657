<template>
  <div style="padding: 4px; width: 200px;">
    <div style="font-weight: bold;">Custom Athlete Filter</div>
    <div>

      <OrkMultiselect v-model="currentValue"  v-if="filter.values.length > 1" :key="filter.name" :label="filter.name" :options="filter.values" :currentValue="currentValue" @selectChange="changeValue"></OrkMultiselect>

    </div>
    <div>



    </div>
  </div>

</template>
<script>


import OrkMultiselect from "@/components/Ork/OrkMultiSelect";

export default {
  name: "OrkFilterSelectGrid",
  components: {
    OrkMultiselect
  },
  data() {
    return {
      currentValue:null,
      filter:null,
    }
  },
  created() {

    this.filter = this.params.filterValue
  },
  watch:{

  },
  methods: {
    changeValue(event) {

      this.$emit("changeValue", {value:event.value})
      this.currentValue = event.value
      if (this.currentValue === '') {
        // Remove the filter
        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged(null, null);
        });
        return;
      }

      this.params.parentFilterInstance((instance) => {

        if(this.currentValue.length>1){
        //  this.currentValue.forEach(value=>{})
            instance.onFloatingFilterChanged('equal', this.currentValue);


          instance.onFloatingFilterChanged('equal', this.currentValue);
        }else {
          instance.onFloatingFilterChanged('equal', this.currentValue);
        }
      });
    },

    onParentModelChanged(parentModel) {
      // When the filter is empty we will receive a null value here
      if (!parentModel) {
        this.currentValue = '';
      } else {
        this.currentValue = parentModel.filter;
      }
    },
  },
};
</script>
<style lang="scss" scoped>

</style>

