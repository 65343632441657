import { GetterTree } from "vuex";
import { CategoryState } from "~/core/store/category/type";
import { RootState } from "~/core/store/type";

// Définissez la fonction activeCategory en dehors de l'objet getters
function findActiveCategory(state, categorySlug, categoryTree) {
  // Vérifiez si categoryTree est défini

  if (!categoryTree) {
    sessionStorage.setItem("activeCategory", "");
    return undefined;
  }

  // Si le slug correspond, définissez la catégorie active et retournez-la
  if (categoryTree.slug === categorySlug) {
    sessionStorage.setItem("activeCategory", categoryTree.path || '');
    return categoryTree;
  }

  // Si categoryTree a des enfants, recherchez récursivement
  if (categoryTree.children && categoryTree.children.length > 0) {
    for (const child of categoryTree.children) {
      const result = findActiveCategory(state, categorySlug, child);
      if (result) {
        return result;
      }
    }
  }

  // Si aucune correspondance n'est trouvée, définissez la catégorie active comme vide
  sessionStorage.setItem("activeCategory", "");
  return undefined;
}

export const getters: GetterTree<CategoryState, RootState> = {
  activeCategory: (state) => (categorySlug: string) => {
    return findActiveCategory(state, categorySlug, state.categoryTree);
  },

  getCategoryTree: (state) => {
    return state.categoryTree;
  },

  getRootCategories: (state) => {
    return state.rootCategories;
  },

  getRootCategory: (state) => {
    return state.rootCategory;
  },

  getRootCategoryPath: (state) => {
    return state.rootCategory?.path || null;
  },

  getRootCategoryLabel: (state) => {
    return state.rootCategory?.label || null;
  },

  getPage: (state) => {
    return state.page;
  },
};
