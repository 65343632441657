import { Module } from "vuex";
import { RootState } from "~/core/store/type";
import { UiState } from "~/core/store/ui/type";
import { getters } from "~/core/store/ui/getters";
import { actions } from "~/core/store/ui/actions";
import { mutations } from "~/core/store/ui/mutations";

const state: UiState = {
  isLoginModalOpen: {open:false,isReloaded:false},
  isQuickOrderModal: {open:false},
  isCartSidebarOpen: false,
  isProductSidebarOpen: { open: false, productSlug: null },
  isProductsActionModal: { open: false, type: null, product: null },
  isNoStockActionModal: { open: false, products: [] },
  isStockAlertModal: { open: false, product: null },
  isWelcomeModal: { open: false, customer: null },
  isCgvModal: { open: false, shopName: null, cgvContent: null },
  isResetModal: { open: false, email: null, status: null },
  relayModal: { open: false, address: null, selectedRelais: null, shipmentWeight: 0 },
  isMenuModalOpen: false,
  isAddressModalOpen: { open: false, address: null, isShipping:false, isBilling:false},
  isAddressListModalOpen: false,
  rootCategoryModalOpen: { open: false, rootCategory: null},
  notifications: [],
  isSearchOpen: false,
  indexGallery: null,
  productGallery: [],
  currentLocal: '',
  franco: null,
  topPromotional : null,
  filtersValues:[]
};

export const ui: Module<UiState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
