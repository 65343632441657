<template src="@/components/Menu/MenuDesktopInline.html"/>
<style lang="scss" scoped src="@/components/Menu/MenuDesktopInline.scss"/>
<script>

import {
  SfSidebar,
  SfList,
  SfLink,
  SfBar,
  SfIcon,
  SfInput,
  SfButton
} from '@storefront-ui/vue';


import icons from "@/utils/icons";
import MenuModalLevel from "@/components/Menu/MenuModalLevel";


export default {
  name: 'MenuInline',
  components: {
    MenuModalLevel,
    SfButton,
    SfInput,

    SfSidebar,
    SfBar,
    SfLink,
    SfList,
    SfIcon
  },
  data() {
    return {
      activeMenu: '',
      icons,
      desktopMin: 768,
      subCategoryOpened: false
    }
  },

  computed: {
    locale: {
      get() {
        return this.$root.$i18n.locale
      }
    },
    isMenuDesktopModalOpen: {
      get() {
        return this.$store.getters.isMenuDesktopModalOpen
      }
    },
    categoryTree: {
      get() {
        return this.$store.getters.getCategoryTree
      }
    },
  },
  watch: {},

  methods: {
    isActive(catSlug, level) {
      return this.$router.history.current.params[level] === catSlug
    },
    toggleMenuDesktopModal() {
      this.$store.dispatch('toggleMenuDesktopModal')
    }
  }
};
</script>
