import QorusClient from "@/api/clients/QorusClient";
import DefaultClient from "@/api/clients/DefaultClient";
import qs from "qs";
import { hereAddress } from "@/models/relais";
import Api from "@/api/Api";
import { AxiosRequestConfig } from "axios";
import MosaiqClient from "@/api/clients/MosaiqClient";

/**
 *  Qorus API about orders
 */
export default class PlatformService {
    private qorusClient = QorusClient.of();

    static of(): PlatformService {
        return new PlatformService();
    }

    getApiColissimo(info): Promise<any> {
        const date = new Date();

        const shippingDate = new Date(date.setDate(date.getDate() + 2)).toLocaleDateString("fr-FR");

        const params = {
            address: info.address.street,
            countryCode: info.address.country,
            zipCode: info.address.postCode,
            city: info.address.city,
            weight: info.weight,
            shippingDate,
        };

        return this.qorusClient.post("colissimo/relays", params).then(
            (data) => {
                return data;
            },
            (data) => {
                throw data;
            },
        );
    }

    checkRecaptcha(token): Promise<any> {
        return Promise.resolve()
        /*  return this.qorusClient.post("google/recaptcha", {
            "response": token,
            "remoteip": Api.payment.getIP(),
          }).then(
              (data) => {
                return data;
              },
              (data) => {
                throw data;
              },
          );*/
    }

    locationId(token): Promise<any> {

        return this.qorusClient.post("google/recaptcha", {
            "response": token,
            "remoteip": Api.payment.getIP(),
        }).then(
            (data) => {
                return data;
            },
            (data) => {
                throw data;
            },
        );
    }

    reverseGeocoding(geocode): Promise<any> {

        return DefaultClient.of().get("https://revgeocode.search.hereapi.com/v1/revgeocode", {
            paramsSerializer: {
                serialize: (params) => {
                    return qs.stringify(params, { indices: false })
                },
                indexes: null
            },
            params: {
                "at": geocode.lat + "," + geocode.lng,
                "lang": "fr-FR",
                "apiKey": process.env.VUE_APP_HERE_API,
            },
        }).then(
            (data) => {
                return hereAddress.of(data.items[0].address);
            },
            (data) => {
                throw data;
            },
        );
    }

    formatAddress(address): Promise<any> {
        return DefaultClient.of().get("https://places.cit.api.here.com/places/v1/suggest", {
            paramsSerializer: {
                serialize: (params) => {
                    return qs.stringify(params, { indices: false })
                },
                indexes: null
            },
            params: {
                "q": address.street + " " + address.postCode + " " + address.city + " " + address.country,
                "X-Map-Viewport": "13.3704,52.5122,13.4194,52.5262",
                "apiKey": process.env.VUE_APP_HERE_API,
            },
        }).then(
            (data) => {
                return data.suggestions[0];
            },
            (data) => {
                throw data;
            },
        );
    }

    geocode(address): Promise<any> {
        return DefaultClient.of().get("https://geocode.search.hereapi.com/v1/geocode", {
            paramsSerializer: {
                serialize: (params) => {
                    return qs.stringify(params, { indices: false })
                },
                indexes: null
            },
            params: {
                "q": address.street + " " + address.postCode + " " + address.city + " " + address.country,
                "limite": 1,
                "apiKey": process.env.VUE_APP_HERE_API,
            },
        }).then(
            (data) => {
                return { lat: data.items[0].position.lat, lng: data.items[0].position.lng };
            },
            (data) => {
                throw data;
            },
        );
    }

    submitContactForm(form): Promise<any> {
        return this.qorusClient.post("platform/contact", form).then(
            (data) => {
                return data;
            },
            (data) => {
                throw data;
            },
        );
    }

    getLocations() {
        const axiosRequestConfig: AxiosRequestConfig = {
            params: {
                attrRef: "sku",
            },
        };
        return MosaiqClient.of().get("locations/location_hotel", axiosRequestConfig).then(
            (data) => {
                return data.data;
            },
            err => {
                throw err;
            },
        );
    }

    getMerchants() {
        const axiosRequestConfig: AxiosRequestConfig = {
            params: {
                attrRef: "sku",
            },
        };
        return MosaiqClient.of().get("locations/merchants", axiosRequestConfig).then(
            (data) => {
                return data.data;
            },
            err => {
                throw err;
            },
        );
    }

    getMerchantMosaiq(merchantId) {
        const axiosRequestConfig: AxiosRequestConfig = {
            params: {
                attrRef: "sku",
            },
        };
        return MosaiqClient.of().get("locations/merchants/" + merchantId, axiosRequestConfig).then(
            (data) => {
                return data.values;
            },
            err => {
                throw err;
            },
        );
    }

    getMerchant(merchantId) {
        const axiosRequestConfig: AxiosRequestConfig = {
            params: {
                attrRef: "sku",
            },
        };
        return this.qorusClient.get("shops/" + merchantId, axiosRequestConfig).then(
            (data) => {
                return data;
            },
            err => {
                throw err;
            },
        );
    }

    getTermsOfUse(): Promise<any> {
        return this.qorusClient.get("platform/terms-of-use").then(
            (data) => {
                return data;
            },
            (data) => {
                throw data;
            },
        );
    }

    getLegalMentions(): Promise<any> {
        return this.qorusClient.get("platform/legal-mentions").then(
            (data) => {
                return data;
            },
            (data) => {
                throw data;
            },
        );
    }
}