<template functional src="@/components/Ork/OrkLoader.html"></template>
<style lang="scss" scoped src="@/components/Ork/OrkLoader.scss"></style>

<script>
export default {
  name: "OrkLoader",
  props: {
    /**
     * Shows the loader on top of the existing content
     */
    loading: {
      default: true,
      type: Boolean,
    },
    message: {
      default: null,
    }
  },
}
</script>
