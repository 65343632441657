<template src="@/components/Ork/OrkFranco.html"></template>
<style lang="scss" scoped src="@/components/Ork/OrkFranco.scss"></style>

<script>
import {
  SfDivider,
} from '@storefront-ui/vue';

export default {
  name: 'OrkFranco',
  components: {
    SfDivider
  },
  data() {
    return {
      ecommerceMode: process.env.VUE_APP_ECOMMERCE_MODE,
      francoPercent: null,
      articlesPrice : null
    }
  },
  computed: {
    currentCart: {
      get() {
        return this.$store.getters.getCart
      },
    },
    franco: {
      get() {
        return this.$store.getters.getFranco;
      }
    }
  },
  watch: {
    currentCart() {
      this.getFrancoPercent()
      this.getArticlesPrice()
    }
  },
  mounted() {
    this.getFrancoPercent()
    this.getArticlesPrice()
  },
  methods: {
    getArticlesPrice(){
      if(this.currentCart){
        if (this.ecommerceMode !== 'B2B') {
          this.articlesPrice = this.currentCart.totalPrice.taxIncluded - this.currentCart.shippingPrice.taxIncluded
        } else{
          this.articlesPrice = this.currentCart.totalPrice.taxFree - this.currentCart.shippingPrice.taxFree
        }
      }
    },
    getFrancoPercent() {
      setTimeout(() => {
          this.francoPercent = ( this.articlesPrice / this.franco) * 100
      }, 250)
    }
  }
};
</script>
