<template src="@/components/Cart/OrderSummary.html"/>
<style lang="scss" scoped src="@/components/Cart/OrderSummary.scss"/>
<script>
import {
  SfButton,
  SfProperty,
  SfHeading,
  SfPrice,
  SfDivider,
  SfIcon,
  SfCircleIcon,
  SfCharacteristic

} from '@storefront-ui/vue';
import Api from "@/api/Api";
import icons from "@/utils/icons";
import Notifications from "@/utils/notifications";
import {OrkFranco} from "@/components/Ork";

export default {
  name: 'OrderSummary',
  components: {
    SfHeading,
    SfDivider,
    SfCircleIcon,
    SfIcon,
    SfCharacteristic,
    SfPrice,
    SfButton,
    SfProperty,
    OrkFranco
  },
  data() {
    return {
      ecommerceMode: process.env.VUE_APP_ECOMMERCE_MODE,
      icons: icons,
    }
  },
  computed: {
    currentCart: {
      get() {
        return this.$store.getters.getCart
      },
    },
  },

  methods: {
    removeCoupon() {
      Api.cart.removeCoupons()
          .then((data) => {
            this.$store.dispatch('setCart', data);
            Notifications.createNotifications({name: 'coupon.removed'})
          })
          .catch((e) => {

            switch (e.status) {
              case 409:
                Notifications.createNotifications({name: 'coupon.allReady'})
                break;
              case 404:
                Notifications.createNotifications({name: 'coupon.not'})
                break;
              default:
                break
            }

          })
          .finally();

    },
  }

};
</script>


