<template src="@/components/Ork/OrkProductPreview.html"/>
<style lang="scss" scoped src="@/components/Ork/OrkProductPreview.scss"/>

<script>

import {
  SfButton,
  SfImage,
  SfLink,
  SfModal,
  SfHeading,
} from '@storefront-ui/vue';
import Api from "@/api/Api";
import icons from "@/utils/icons";
import {OrkPrice,OrkAddToCart, OrkStock,OrkLoader } from "@/components/Ork";
import {filterData} from "@/utils/categoryFilter";
import Locales from "@/utils/locales";
import Notifications from "@/utils/notifications";
import {Product} from "@/models/product";
import Vue from "vue";

export default {
  name: 'OrkProductPreview',
  components: {
    OrkLoader,
    OrkAddToCart,
    OrkPrice,
    SfModal,
    SfLink,
    SfButton,
    SfHeading,
    SfImage,
    OrkStock,
  },

  data() {
    return {
      ecommerceStyle:process.env.VUE_APP_ECOMMERCE_STYLE,
      ecommerceMode:process.env.VUE_APP_ECOMMERCE_MODE,
      showQty : process.env.VUE_APP_SHOWQTY === 'true',
      showStockAlert: process.env.VUE_APP_SHOW_STOCK_ALERT === 'true',
      showWishList: process.env.VUE_APP_WISHLIST === 'true',
      showAddToCartModal: process.env.VUE_APP_SHOW_ADD_TO_CART_MODAL === 'true',
      icons,
      categ : null,
      loading: false,
      quantity: 1,
      AddToCartLoading: false,
      selectedProduct:null,
      locale: Locales.getLocaleCode(),
      index: null,
      fromCategory: null,
      urlEncode: encodeURI(window.location.href),
      product: null,
      productIsInCart:null,
      error: false,
      configurables: null,

    };


  },
  computed: {
    isProductSidebarOpen: {
      get() {
        return this.$store.getters.isProductSidebarOpen.open;
      }
    },
    productSlug:{
      get() {
        return this.$store.getters.isProductSidebarOpen.productSlug;
      }
    },
    customer() {
      return this.$store.getters.getProfile;
    },
    sortedAttribute: {
      get() {

        const sort = Object.fromEntries(
            Object.entries({...this.selectedProduct.attributeValuesByAttributeName})
                .sort(([KeyA, a], [KeyB, b]) => {

                  if (!a.value) {
                    delete this[KeyA]
                  }
                  if (!b.value) {
                    delete this.selectedProduct.attributeValuesByAttributeName[KeyB]
                  }
                  return a.label < b.label ? -1 : 1;
                })
        );
        for (const [key, value] of Object.entries(sort)) {
          if (!value.value) {
            delete sort[key]
          }
        }
        return sort
      }
    },
    currentCart: {
      get() {
        return this.$store.getters.getCart;
      }
    },
    categoryTree: {
      get() {
        return this.$store.getters.getCategoryTree
      }
    },
    activeCategory: {

      get() {
        return sessionStorage.getItem('activeCategory')
      }
    },
  },

  watch: {
    productSlug(newVal,oldVal){
      if(newVal && newVal!== oldVal && this.isProductSidebarOpen) {
        this.selectedProduct=null
        this.product=null
        this.loadProduct(newVal)
      }
    },
    currentCart(newCart){
      if( newCart) {
        if(this.isProductSidebarOpen) {
          this.productIsInCart = this.currentCart.cartItems.find(item => item.product.offer.id === this.selectedProduct.defaultOfferId)
        } else {
          this.productIsInCart =null
        }
      }
      else {
        this.productIsInCart = null
      }
        if (this.productIsInCart) {
          this.quantity = this.productIsInCart.quantity
        }
      }

  },
  methods: {
    changeQty(quantity){
      if(this.ecommerceStyle==="market") {
        this.addToCart({
          offerId: this.product.defaultOfferId,
          quantity: Number(quantity),
          stock: this.product.stockQuantity,
          currentCart: this.currentCart
        })
      }
    },
    getProductCategory() {
      const productCategory = this.product.categoryPaths;

      let splitPath = null
      // get only the first category

      if (this.activeCategory) {
        splitPath = this.product.categoryPaths.find(categ => {
          if (categ.includes(this.activeCategory)) {
            return true;
          }
        }) || null;

        if (splitPath) {
          splitPath = splitPath.split('/');
        } else {
          splitPath = productCategory.length ? productCategory[0].split('/') : null;
        }
      } else {
        splitPath = productCategory.length ? productCategory[0].split('/') : null;
      }
      const tempArr = [];
      splitPath.shift();
      if (splitPath) {
        for (let i of splitPath)
          i && tempArr.push(i); // copy each non-empty value to the 'temp' array

        splitPath = tempArr;

        const categoriesPath = []
// get all path for categories child
        for (let i = 0, n = splitPath.length; i + 1 <= n; i++) {
          if (i === 0)
            categoriesPath.push('/' + splitPath[i]);
          else categoriesPath.push(categoriesPath[i - 1] + '/' + splitPath[i])
        }

//create categories breadcrumbs
        const categoriesBreadcrumbs = [];

        for (let i = 0, n = categoriesPath.length; i + 1 <= n; i++) {
          //get data for the category with categoryTree
          let categ = filterData(this.categoryTree.children, function (item) {
            const categoryPath = item.path;
            const indexPremierSlash = categoryPath.indexOf('/');
            const categoryPathWithoutRootCategory = categoryPath.substring(indexPremierSlash);
            return categoryPathWithoutRootCategory === categoriesPath[i]
          })[0];


          if (categ) {

            // add categories to breadcrumbs
            // if is the first entry
            if (i === 0) {
              categoriesBreadcrumbs.push({
                link: '/categorie/' + categ.slug,
                text: categ.label
              })
            }
            //for other
            else {
              categoriesBreadcrumbs.push({
                link: categoriesBreadcrumbs[i - 1]['link'] + '/' + categ.slug,
                text: categ.label
              })
            }
          }
        }
        this.categ= categoriesBreadcrumbs.slice(-1);
      }
    },
    toggleProductSidebar(){
      this.$store.dispatch('toggleProductSidebar',{open:false,productSlug:null})
    },
    getSelectedProduct(product) {
      if (product.children) {
        return product.children.find((p) => {
          if (p.offers && product.defaultOffer) {
            return p.offers.find(o => o.id === product.defaultOffer?.id) !== undefined
          }
        });
      }
      return product
    },
    loadProduct(productSlug) {

      this.loading = true;
      Api.product.getBySlug(productSlug)
          .then(data => {
            this.configurables = {};
            this.product = Product.of(data);
            this.selectedProduct = this.getSelectedProduct(this.product);
            this.getProductCategory()
            if (this.product.configurableAttributes) {
              this.getConfigurable(this.product);
            }
            if( this.currentCart) {
              this.productIsInCart = this.currentCart.cartItems.find(item => item.product.offer.id === this.selectedProduct.defaultOfferId)
            }else{
              this.productIsInCart = null;
            }
            if(this.productIsInCart){
              this.quantity= this.productIsInCart.quantity
            }
          })
          .catch((e) => {
            console.log(e);
            if (e.status === 404) {
             // this.$router.push(this.$localizePath('/404')).catch();
              this.$store.dispatch('sendNotification', {
                type: 'danger',
                message: this.$i18n.t('errors.notfound'),
                icon: icons.error
              });
            } else {
              this.$store.dispatch('sendNotification', {
                type: 'danger',
                message: this.$i18n.t('errors.server.access'),
                icon: icons.error
              });
              this.error = e;
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    getConfigurable(product) {
      this.configurables = {};
      if (product.configurableAttributes?.length > 0 && product.children?.length > 0) {
        const defaultChild = product.children.find((child) => child?.defaultOffer?.id === product.defaultOfferId);

        if (!defaultChild) return;

        for (const attr of product.configurableAttributes) {
          const defaultAttr = defaultChild.attributeValuesByAttributeName[attr];
          const attrValue = {
            label: defaultAttr?.label || this.$i18n.t('Choose an option'),
            options: {},
            selected: defaultAttr?.value.toString(),
            type: product.attributeValuesByAttributeName[attr]?.attributeType
          };

          // Find difference between all children for a configurable attribute
          for (const p of product.children) {
            const value = p.attributeValuesByAttributeName[attr]?.value.toString();

            if (Object.keys(attrValue.options).indexOf(value) === -1 && value) {
              attrValue.options[value] = 'enabled';
            }
          }

          // set only if has more than 1 option  in attrValue.options
          if (Object.keys(attrValue.options).length > 1) {
            // Need to set new value with Vue.set method to preserve reactivity
            attrValue.options = Object.keys(attrValue.options).sort().reduce((r, k) => (r[k] = attrValue.options[k], r), {})
            this.$set(this.configurables, attr, attrValue);
          }
        }

        // Foreach key select default option
        Object.keys(this.configurables).forEach(key => {
          this.selectOption(key, this.configurables[key]?.selected);
        });
      }
    },
    selectOption(key, option) {
      // Set new value with Vue.set method to preserve reactivity
      this.$set(this.configurables[key], 'selected', option);

      Object.keys(this.configurables).forEach(k => {
        if (k && k != key) {
          const enabledOptions = [];

          for (const p of this.product.children) {
            // For all children, check if attribute value is same than option
            if (p.attributeValuesByAttributeName[key]?.value.toString() === option) enabledOptions.push(p.attributeValuesByAttributeName[k]?.value.toString());
          }
          Object.keys(this.configurables[k].options).forEach(option => {
            // Disabled all configurables button that don't contain the same option
            if (!enabledOptions.includes(option)) this.configurables[k].options[option] = 'disabled';
          });
        }
      });
    },
    selectConfigurableOption(key, option, remove) {

      const resetAllOptions = () => {
        Object.keys(this.configurables).forEach(k => {
          Object.keys(this.configurables[k].options).forEach(option => {
            this.configurables[k].options[option] = 'enabled';
          });
        });
      };

      const selectDisabledOption = (key, option) => {
        resetAllOptions();

        // Unselect all except current key
        Object.keys(this.configurables).forEach(k => {
          if (k != key) this.$set(this.configurables[k], 'selected', null);
          else if (this.configurables[key].selected !== 'selected') this.selectOption(key, option);
        });
      }

      const selectProduct = () => {
        let matchingProducts = [...this.product.children];

        Object.keys(this.configurables).forEach(k => {
          const selected = this.configurables[k].selected;
          if (selected) matchingProducts = matchingProducts.filter(p => p.attributeValuesByAttributeName[k]?.value === selected);
        });

        if (matchingProducts.length !== 0) {
          this.selectedProduct = matchingProducts[0];
        } else {

        }
      };

      const unSelectOption = (key) => {
        this.$set(this.configurables[key], 'selected', null);
        resetAllOptions();

        // Replay all selected options
        Object.keys(this.configurables).forEach(k => {
          if (this.configurables[k].selected && k !== key) this.selectConfigurableOption(k, this.configurables[k].selected, true);
        });
      };

      if (this.configurables[key].options[option] === 'disabled') selectDisabledOption(key, option);
      else if (this.configurables[key].selected == null || remove) this.selectOption(key, option);
      else if (this.configurables[key].selected === option && !remove) unSelectOption(key);
      else if (this.configurables[key].selected !== option) {
        unSelectOption(key);
        this.selectOption(key, option);
      }
      selectProduct();
    },

    addToCart(params) {
      let cart = params.currentCart,
          itemIsInCart = null,
          qty = Vue.filter('toInt')(params.quantity),
          prevQty = 0,
          stock = params.stock;

      if (cart && cart.cartItems && cart.cartItems.length) {
        itemIsInCart = cart.cartItems.find(item => item.product?.offer?.id === params.offerId);

        if (itemIsInCart) {
          prevQty = itemIsInCart?.quantity || 0;
          stock = itemIsInCart?.product.offer.stockQuantity || 0;
        }
      }
      if(this.productIsInCart){
        params.method= 'update'
      }else{
        params.method= 'add'
      }
      if (prevQty + qty > stock && params.method==='add') {
        Notifications.createNotifications({name:'addToCart.maxQty',value:{stock:stock, productName:params.product}})

      } else {
        this.AddToCartLoading = true;
        Api.cart.setOfferQuantity(params)
            .then(data => {
                if (params.method==="add" && this.showAddToCartModal ) {
                    this.$store.dispatch('toggleProductsActionModal', {
                        open: true,
                        type: params.method,
                        product: this.product.name
                    });
                }
                if(params.method==="remove"){
                    Api.marketingEvent.removeFromCart(this.product,this.currentCart)
                    Notifications.createNotifications({name: 'addToCart.remove', value: {productName: this.product.name}})
                } else {
                    Api.marketingEvent.addToCart(this.product, qty, this.currentCart)
                    if (this.productIsInCart) {
                        Notifications.createNotifications({name: 'addToCart.update', value: {productName: this.product.name}})
                    } else {
                        Notifications.createNotifications({name: 'addToCart.add', value: {productName: this.product.name}})
                    }
                }

            })
            .catch((e) => {
                console.error(e)
                this.$store.dispatch('sendNotification', {
                    type: 'danger',
                    message: this.$i18n.t('errors.server.access'),
                    icon: icons.error
                });
                this.error = e;
            })
            .finally(() => {
                this.loading = false;
                this.AddToCartLoading = false;
            });
      }
      if(!this.productIsInCart) {
        this.quantity = 1;
      }
    },
    goToProductPage() {
      if (this.activeCategory) {
        sessionStorage.setItem("activeCategory", this.activeCategory);
      }
      this.toggleProductSidebar();
      this.$router.push(this.$localizePath('/produit/' + this.product.slug));
    },
  }
};
</script>
