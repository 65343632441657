<template>
  <SfButton v-if="hasAction" :class="[
  'ork-swatch',
  'label-'+value.label,
   { 'label': value.label },
  { 'is-disabled': disabled },
  { 'is-active': selected }]" @click="$emit('click', isSelected)">
    <SfImage v-if="value.image" :alt="value.label" :src="value.image" class="ork-swatch__img "></SfImage>
    <span v-else-if="value.color" :style="'background-color:'+value.color" class="ork-swatch__color"></span>
    <span v-else class="ork-swatch__label">{{ value.label }}</span>
    <div class="ork-swatch__tooltip">{{ value.label }}</div>
  </SfButton>
  <span v-else :class="[
  'ork-swatch',
  'attribute',
  'label-'+value.label,
  { 'is-disabled': disabled },
  { 'is-active': selected }]" @click="$emit('click', isSelected)">
    <SfImage v-if="value.image" :alt="value.label" :src="value.image" class="ork-swatch__img "></SfImage>
    <span v-else-if="value.color" :style="'background-color:'+value.color" class="ork-swatch__color"></span>
    <span v-else class="ork-swatch__label">{{ value.label }}</span>
  </span>
</template>
<script>

import {focus} from "@storefront-ui/vue/src/utilities/directives";
import {
  SfButton,
  SfImage,
} from '@storefront-ui/vue';

export default {
  name: "OrkSwatch",
  directives: {
    focus,
  },
  components: {
    SfButton,
    SfImage,
  },
  model: {
    prop: "selected",
    event: "change",
  },
  props: {
    hasAction:{
      default:true
    },
    value: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSelected() {
      if (typeof this.selected === "boolean") {
        return this.selected;
      }
      return false;
    },

  },
  methods: {},
};
</script>
<style lang="scss" scoped>

.ork-swatch {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--swatchSizeAttr);
  height: var(--swatchSizeAttr);
  border-radius: var(--swatchBorderRadius);
  padding: 0;
  margin: 5px;
  transition: 0.25s ease all;
  overflow: visible;
  font-weight: normal;
  font-size: var(--font-size--xs);
  position: relative;
  border: 2px solid;

  &.attribute{
    border-color: var(--c-white);
  }
  &:not(.attribute){
    width: var(--swatchSize);
    height: var(--swatchSize);
    opacity: 0.8;
    filter: grayscale(0.1);
    border-color: var(--c-sidebar-background);

    &:hover {
      border: 2px solid var(--c-primary-variant);
      .ork-swatch__tooltip {
        opacity: 1;
        transform: translateY(calc(100% + 8px));
      }
    }
  }
  &.label{
    background-color: var(--swatchBackgroundColor);
    &.color, &.image{

      background-color: transparent !important;
    }
  }
&.attribute{
  .ork-swatch__tooltip{
    display: none;
  }
}
  &__tooltip {

    text-transform: capitalize;
    position: absolute;
    z-index: -1;
    bottom: 0;
    border-radius: 2px;
    background-color: #fff;
    padding: 4px;
    transform: translateY(100%);
    color: var(--c-text);
    left: auto;
    right: auto;
    margin: 0 auto;
    opacity: 0;
    transition: 0.25s ease all;
    font-weight: normal;
    font-size: var(--font-size--xs);
    display: flex;
    justify-content: center;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.2);

    &::after {
      position: absolute;
      top: -5px;
      width: 0;
      left: auto;
      right: auto;
      margin: 0 auto;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      content: '';
      border-bottom: 5px solid #fff;
    }
  }

  &.is-active, &:hover {
    &:not(.attribute) {
      opacity: 1;
      box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }
  }



  &.is-active {
    border: 2px solid var(--c-primary);
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.2);
  }

  &__img {
    object-fit: cover;

    img {
      object-fit: cover;
      width: fit-content;

    }
  }
::v-deep {
  span.ork-swatch__label {
    padding: 5px;
    font-weight: normal;
    font-size: var(--font-size--xs);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--swatchTextColor);
  }
}
  &__color {
    display: block;
    width: 100%;
    height: 100%;

  }
}

</style>

