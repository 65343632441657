import { MutationTree } from "vuex";
import { ClientState } from "@/store/type";
import UiClientCookie from '@/utils/cookie';
import { UiMutations } from '@/store/ui/mutations';

export enum ClientMutations {
  IS_TOGGLE_MENU_DESKTOP = "IS_TOGGLE_MENU_DESKTOP",
}

export const mutations: MutationTree<ClientState> = {
  [ClientMutations.IS_TOGGLE_MENU_DESKTOP](state): void {
    state.isMenuDesktopModalOpen = !state.isMenuDesktopModalOpen;
  },
};