import store from "@/store";

type Territory = {
    slug: string,
    countryCode: string,
    hereMap: HereMapData
};

type HereMapData = {
    zoom: number,
    coords: {lat: number, lng: number}
}

const DATA_BY_TERRITORY: Territory[] = [
    {
        slug: "guadeloupe",
        countryCode: "GP",
        hereMap: {zoom: 10, coords: {lat: 46.50, lng: 2.00}}
    },
    {
        slug: "guyane",
        countryCode: "GF",
        hereMap: {zoom: 10, coords: {lat: 46.50, lng: 2.00}}
    },
    {
        slug: "martinique",
        countryCode: "MQ",
        hereMap: {zoom: 10, coords: {lat: 46.50, lng: 2.00}}
    },
    {
        slug: "reunion",
        countryCode: "RE",
        hereMap: {zoom: 10, coords: {lat: 46.50, lng: 2.00}}
    },
    {
        slug: "st-barthelemy",
        countryCode: "BL",
        hereMap: {zoom: 10, coords: {lat: 17.896865761147613, lng: -62.84741356710102}}
    },
    {
        slug: "st-martin",
        countryCode: "MF",
        hereMap: {zoom: 10, coords: {lat: 18.068131711975344, lng: -63.083317987537846}}
    }
];

const ROOT_CATEGORY_BY_COUNTRY_CODE = DATA_BY_TERRITORY.reduce((acc, val)=> {
    acc[val.countryCode] = val.slug + '/';
    return acc;
}, {});
const HERE_MAP_PARAMS_BY_TERRITORY = DATA_BY_TERRITORY.reduce((acc, val) => {
    acc[val.slug] = val.hereMap;
    return acc;
}, {});

export default class SedService {

    static of(): SedService {
        return new SedService();
    }

    getRootCategoryByCountryCode(countryCode: string): string {
        return ROOT_CATEGORY_BY_COUNTRY_CODE[countryCode] || null;
    }

    getHereMapParamsByTerritorySlug(territorySlug: string): HereMapData {
        const params = HERE_MAP_PARAMS_BY_TERRITORY[territorySlug];

        if (!params) throw new Error("unknown territory slug " + territorySlug);

        return params;
    }

    isWithDockingFeesInsteadOfVat(): boolean {
        return store.getters.getRootCategoryPath === 'st-barthelemy/';
    }
}
