import {Attribute} from "./attribute";
import {Offer} from "./offer";
import {CategoryPicture, LightPicture, Picture} from "./picture";
import {Page} from "@/models/page";

import {i18n} from "vue-lang-router";
import {ShoppingCart} from "@/models/shopping";

export class Product {
    id!: string;
    reference!: string;
    slug!: string;
    name!: string;
    description!: string;
    gallery!: Picture[];
    lightGallery!: [];
    pictureCover!: LightPicture;
    categoryPaths!: string[];
    attributeValuesByAttributeName!: Record<string, Attribute>;
    descriptionShort!: Attribute;
    descriptionLong!: Attribute;
    focus!: FocusMarketing[] | any;
    relatedProduct!: string | null;
    children!: Product[];
    configurableAttributes!: any;
    offers!: Offer[];
    percentPromo!: number;
    stockQuantity!: number;
    shopName!: string | null;
    currency!: string;
    taxIncludedPrice!: number | null;
    taxFreePrice!: number | null;
    discountPrice!: number | null;
    taxFreeDiscountPrice!: number | null;
    minimalOrderQuantity!: number;
    maximalOrderQuantity!: number;
    isNew!: Attribute | null;
    seoTitle!: Attribute | null;
    seoDescription!: Attribute | null;
    defaultOfferId!: string;
    notices!: Notices[];
    brand!: Attribute;
    restock_date!: Attribute;
    private defaultOffer!: Offer | null;
    practice!: Attribute;
    plus!: Attribute;
    information!: Attribute;
    packSize!: number
    isPromotion!: Attribute | null;
    isBestseller!: Attribute | null;


    public static of(dto: any): Product {
        const p: Product = new Product();
        p.id = dto.id;
        p.reference = dto.reference;
        p.slug = dto.slug;
        p.name = dto.name;

        //remove first entry is rootCategory
        const categoryPath = dto?.categoryPaths

        const categoryPathsWithoutRootCategory = categoryPath.map(item => {
            const index = item.indexOf('/');
            return item.substring(index);
        });

        p.categoryPaths = categoryPathsWithoutRootCategory.sort();
//
        p.packSize = dto.packSize || 1;
        Object.keys(dto.attributeValuesByAttributeName).forEach(function (key, index) {
            dto.attributeValuesByAttributeName[key].index = index;
            dto.attributeValuesByAttributeName[key] = Attribute.of(dto.attributeValuesByAttributeName[key]);
        });

        p.attributeValuesByAttributeName = dto.attributeValuesByAttributeName;
        p.description = dto.description;
        p.configurableAttributes = dto?.configurableAttributes;
        p.defaultOfferId = dto?.defaultOfferId;

        // Set description short & long and delete reference in attributes list
        // FIXME VSX 2024-06-27 Never used
        p.descriptionShort = p.attributeValuesByAttributeName?.["description_short"];
        delete p?.attributeValuesByAttributeName?.["description_short"];

        p.descriptionLong = p.attributeValuesByAttributeName?.["description_long"];
        delete p?.attributeValuesByAttributeName?.["description_long"];

        p.relatedProduct = p.attributeValuesByAttributeName?.["product_associated_products"]?.value?.toString() || null;
        delete p?.attributeValuesByAttributeName?.["product_associated_products"];

        // remove shipping attribute
        delete p?.attributeValuesByAttributeName?.["logistic_transport_width"];
        delete p?.attributeValuesByAttributeName?.["logistic_transport_height"];
        delete p?.attributeValuesByAttributeName?.["logistic_transport_length"];
        delete p?.attributeValuesByAttributeName?.["logistic_transport_weight"];
        //remove ean  / logisticClass / taille / entretient
        delete p?.attributeValuesByAttributeName?.["ean"];
        delete p?.attributeValuesByAttributeName?.["product_logistic_class"];

        // FIXME VSX 2024-06-27 Never used
        p.isBestseller = p?.attributeValuesByAttributeName?.["product_bestseller"] || null;
        delete p?.attributeValuesByAttributeName?.["product_bestseller"];

        // FIXME VSX 2024-06-27 Never used
        p.isPromotion = p?.attributeValuesByAttributeName?.["product_promotion"] || null;
        delete p?.attributeValuesByAttributeName?.["product_promotion"];

        // FIXME VSX 2024-06-27 Never used
        p.isNew = p?.attributeValuesByAttributeName?.["marketing_new"] || null;
        delete p?.attributeValuesByAttributeName?.["marketing_new"];

        p.seoTitle = p?.attributeValuesByAttributeName?.["product_seo_title"] || null;
        delete p?.attributeValuesByAttributeName?.["product_seo_title"];
        p.seoDescription = p?.attributeValuesByAttributeName?.["product_seo_description"] || null;
        delete p?.attributeValuesByAttributeName?.["product_seo_description"];

        p.brand = p?.attributeValuesByAttributeName?.["details_brand"];
        p.practice = p?.attributeValuesByAttributeName?.["product_in_practice"];
        delete p?.attributeValuesByAttributeName?.["product_in_practice"];

        p.information = p?.attributeValuesByAttributeName?.["product_info"];
        delete p?.attributeValuesByAttributeName?.["product_info"];

        p.plus = p?.attributeValuesByAttributeName?.["product_plus"];
        delete p?.attributeValuesByAttributeName?.["product_plus"];
        
        //remove delay
        delete p?.attributeValuesByAttributeName?.["product_picking_delay"];


        p.notices = [];
        if (dto?.attributeValuesByAttributeName?.["docs"]) {
            const tempFocus: any[] = JSON.parse(dto?.attributeValuesByAttributeName?.["docs"]?.value);
            if (tempFocus) {
                tempFocus.forEach(b => p?.notices?.push(Notices.of(b)));
            }
            delete p?.attributeValuesByAttributeName?.["docs"];
        }
        p.focus = [];
        p.offers = [];

        if (dto?.attributeValuesByAttributeName?.["product_marketing_focus"]) {

            const tempFocus: any[] = dto?.attributeValuesByAttributeName?.["product_marketing_focus"]?.value;
            if (tempFocus) {
                tempFocus.forEach(b => { p.focus.push(FocusMarketing.of(JSON.parse(b)))});
            }
            delete p?.attributeValuesByAttributeName?.["product_marketing_focus"];
        }

        if (dto?.pictures?.length > 0) {

            p.gallery = dto.pictures.map(p => {
                return Picture.of(p, dto.name);
            });
            p.lightGallery = dto.pictures.map(p => {
                return LightPicture.of(p, dto.name);
            });
            const cover = dto.pictures.find(p => p.isCover === true);
            p.pictureCover = cover && LightPicture.of(cover, dto.name);
        }

        if (dto.children && dto.children.length > 0) {
            p.children = [];
            dto.children.forEach((child: Product) => {
                p.children?.push(Product.of(child));
                if (!p.defaultOffer) {
                    p.defaultOffer = child?.offers?.find(o => o.id === dto?.defaultOfferId) || null;
                }
            });
        } else if (dto.offers && dto.offers.length > 0) {

            dto.offers.forEach((offer: Offer) => {
                p.offers?.push(Offer.of(offer));
            });
            if (dto?.defaultOfferId && !p.defaultOffer) {
                p.defaultOffer = Offer.of(dto.offers.find(o => o.id === dto?.defaultOfferId));
            }
        }

        p.stockQuantity = p?.defaultOffer?.stockQuantity || 0;
        p.minimalOrderQuantity = p?.defaultOffer?.minimalOrderQuantity || 0;
        p.maximalOrderQuantity = p?.defaultOffer?.maximalOrderQuantity || p.stockQuantity;
        p.shopName = p?.defaultOffer?.shopName || null;
        p.currency = p?.defaultOffer?.price?.currency || 'EUR';
        p.taxIncludedPrice = p?.defaultOffer?.price?.taxIncluded || null;
        p.taxFreePrice = p?.defaultOffer?.price?.taxFree || null;
        p.discountPrice = p?.defaultOffer?.discountPrice?.taxIncluded || null;
        p.taxFreeDiscountPrice = p?.defaultOffer?.discountPrice?.taxFree || null;
        if (p.discountPrice && p.taxIncludedPrice) {
            p.percentPromo = ((p.discountPrice - p.taxIncludedPrice) / p.taxIncludedPrice);
        }


        return p;
    }
}

export class FocusMarketing {
    title:  string ='' ;
    description:  string= '' ;
    image: any;

    static of(data: any) {
        const f: FocusMarketing = new FocusMarketing();
        f.description = data.product_marketing_focus_description || "";
        f.title = data.product_marketing_focus_title || "";
        f.image = data.product_marketing_focus_image ? Picture.of(data.product_marketing_focus_image[0],f.title) : null;

        return f;
    }
}

export class Notices {
    name?: string;
    url?: string;

    static of(data: any) {
        const n: Notices = new Notices();

        n.name = data.name;
        n.url = data.url;

        return n;
    }
}

export class LightProduct {
    defaultOfferId?: string;
    percentPromo?: number;
    defaultChildSlug?: string;
    taxFreePrice?: number;
    taxIncludedPrice?: number;
    taxFreeDiscountPrice?: number;
    taxIncludedDiscountPrice?: number;
    stockQuantity?: number;
    reference?: string;
    slug?: string;
    brand?: string;
    name?: string;
    description?: string;
    pictureCover?: CategoryPicture;
    categoryPaths?: string[];
    isConfigurable?: boolean;
    minimalOrderQuantity?: number;
    maximalOrderQuantity?: number;
    packSize?: number

    public static of(data: any): LightProduct {
        const l: LightProduct = new LightProduct();
        l.defaultOfferId = data?.defaultOfferId;
        l.defaultChildSlug = data?.defaultChildSlug;
        l.taxFreePrice = data?.taxFreePrice;
        l.taxIncludedPrice = data?.taxIncludedPrice;
        l.brand = data?.brand;
        l.taxFreeDiscountPrice = data?.taxFreeDiscountPrice || null;
        l.taxIncludedDiscountPrice = data?.taxIncludedDiscountPrice || null;
        l.stockQuantity = data?.stockQuantity || null;
        l.reference = data?.reference;
        l.slug = data?.slug;
        l.name = data?.name;
        if (data?.discountPrice) {
            l.percentPromo = ((data?.discountPrice - data?.price) / data?.price);
        }
        l.description = data?.description;
        const categoryPath = data?.categoryPaths
        const categoryPathsWithoutRootCategory = categoryPath.map(item => {
            const index = item.indexOf('/');
            return item.substring(index);
        });
        l.categoryPaths = categoryPathsWithoutRootCategory.sort();
        l.isConfigurable = data?.isConfigurable;
        l.pictureCover = data?.pictures?.filter(picture => (picture.isCover === true)).map(picture => picture ? CategoryPicture.of(picture, data?.name) : CategoryPicture.of([], data?.name))[0] || "";
        l.packSize = data?.packSize || 1;
        l.minimalOrderQuantity = data.minimalOrderQuantity || 0;
        l.maximalOrderQuantity = data.maximalOrderQuantity || l.stockQuantity;
        return l;
    }
}

export class ProductsWithFilters {
    products?: Page<LightProduct>;
    filterableAttributes?: unknown;
    currentFilterableAttributes?: unknown;
}


export class ConfigurableProductsGrid {
    id?: string;
    reference?: string;
    name?: string;
    pictureCover?: LightPicture | "";
    offers?: Offer[];
    columnDefs?: any;
    rowData?: any;
    defaultOffer?: Offer;
    filters?: any;


    public static of(dto: Product[], currentCart: ShoppingCart): ConfigurableProductsGrid {
        const p: ConfigurableProductsGrid = new ConfigurableProductsGrid();


        const filters: any = [];
        p.rowData = []

        dto.forEach(function (product: any) {
            p.pictureCover = product.pictureCover.srcThumb;
            p.id = product.id
            p.name = product.name
            p.reference = product.reference
            p.offers = product.offers
            p.defaultOffer = product.defaultOffer

            const rowData = {}
            p.columnDefs = [
                {"field": "reference", pinned: 'left'},
                {"field": i18n.t("price"), pinned: 'right'},
                {"field": i18n.t("stock"), pinned: 'right'},
                {"field": "addToCart", pinned: 'right'},
                {"field": "mobile"}
            ];
            rowData["addToCart"] = {
                quantity: 1,
                packSize: product.packSize,
                defaultOffer: product.defaultOffer,
                defaultOfferId: product.defaultOfferId,
                minimalOrderQuantity: product.minimalOrderQuantity,
                maximalOrderQuantity: product.maximalOrderQuantity,
                stockQuantity: product.stockQuantity,
                name: product.name,
                reference: product.reference
            }
            rowData["mobile"] = {
                quantity: 1,
                packSize: product.packSize,
                defaultOffer: product.defaultOffer,
                defaultOfferId: product.defaultOfferId,
                minimalOrderQuantity: product.minimalOrderQuantity,
                maximalOrderQuantity: product.maximalOrderQuantity,
                stockQuantity: product.stockQuantity,
                name: product.name,
                reference: product.reference,
                img: p.pictureCover,
                attributes: {},
                discountPrice: product.defaultOffer ? product.defaultOffer.discountPrice : null,
                regular: product.defaultOffer ? product.defaultOffer.price : null

            }
            rowData["reference"] = {
                img: p.pictureCover,
                name: p.name,
                reference: p.reference
            };
            // rowData["reference"] = p.reference;
            rowData[i18n.t("price")] = {
                discountPrice: product.defaultOffer ? product.defaultOffer.discountPrice : null,
                regular: product.defaultOffer ? product.defaultOffer.price : null
            };
            rowData[i18n.t("stock")] =  {
                stockQuantity:product.stockQuantity,
                defaultOfferId:product.defaultOfferId
            }

            Object.keys(product.attributeValuesByAttributeName).forEach(function (key, index) {
                product.attributeValuesByAttributeName[key].index = index;
                product.attributeValuesByAttributeName[key] = Attribute.of(product.attributeValuesByAttributeName[key]);
                const field = product.attributeValuesByAttributeName[key].label
                const value = product.attributeValuesByAttributeName[key].value[0]
                const displayType = product.attributeValuesByAttributeName[key].displayType
                const unit = product.attributeValuesByAttributeName[key].unit
                p.columnDefs.push({
                    "field": product.attributeValuesByAttributeName[key].label,
                    "id": key,
                    "filterType": product.attributeValuesByAttributeName[key].filterType
                })
                rowData[field] = value
                rowData["mobile"].attributes[key] = {"id":key, name: field, value: [value], displayType: displayType, unit:unit};
                const tempFilter = filters.find(filter => filter.name == field);
                if (!tempFilter) filters.push({id:key, name: field, values: [value]})
                else if (!tempFilter.values.includes(value)) tempFilter.values.push(value)
            });

            p.rowData.push(rowData);
        });
        p.filters = filters.map((filter) => {
            if (filter.values.length === 1) {
                // remove col has no multiple value
                p.columnDefs.forEach(function (entry, index) {
                    if(entry.field === filter.name){
                        p.columnDefs.splice(index,1)
                    }
                })
                // remove rowData has no multiple value
                p.rowData.filter((entry)=> {
                    if(entry[filter.name]){
                        delete entry[filter.name]

                    }})

                return null
            } else {
                // remove attributes has variable attribute
                dto.forEach(function (product: any) {
                    product.attributeValuesByAttributeName[filter.id].displayType="NONE"
                })
                return {
                    id:filter.id,name: filter.name, values: filter.values.map((value) => {
                        return {'value': value}
                    })
                }
            }
        }).filter(entry=>entry);


        p.columnDefs.rowHeight = 120;
        p.columnDefs.defaultColDef = {}

        return p;
    }
}
