import { render, staticRenderFns } from "@/components/Ork/OrkProductCard.html?vue&type=template&id=2ff67c9a&scoped=true&external"
import script from "./OrkProductCard.vue?vue&type=script&lang=js"
export * from "./OrkProductCard.vue?vue&type=script&lang=js"
import style0 from "@/components/Ork/OrkProductCard.scss?vue&type=style&index=0&id=2ff67c9a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ff67c9a",
  null
  
)

export default component.exports