import coreTranslationFR from "./fr.json"
import clientTranslationFR from "~/client/locales/translations/fr.json"
import scriptLoader from "@/utils/loadScript";


const FrMerged = scriptLoader.mergeObjects (coreTranslationFR, clientTranslationFR);

export default {

  /*en: {
    name: "English",
    iso: "en_US",
    load: () => {
      return import("./en_US.json");
    },
  },*/
  fr: {
    name: "Français",
    iso: "fr_FR",
    messages: FrMerged,
  },
};