export const icons = {
  viewBoxDefault: '0 0 42 42',
  cart: {
    viewBox: '0 -960 960 960',
    path: 'M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z'
  },
  cartRefresh: {
    viewBox: '0 0 32 32',
    path: ['M14.5 10.1c0 .4-.4.8-.8.8s-.8-.4-.8-.8c0-3.2 2.6-5.8 5.9-5.8 1.4 0 2.7.5 3.8 1.4V5c0-.4.4-.8.8-.8s.8.4.8.8v2.5c0 .4-.4.8-.8.8l-2.4.2c-.4 0-.8-.3-.9-.7 0-.4.3-.8.7-.9l.8-.1c-1.8-1.5-4.5-1.4-6 .4-.7.9-1.1 1.8-1.1 2.9zm2.3 1.9c.4 0 .8.3.9.7 0 .4-.3.8-.7.9l-1 .1c1.7 1.6 4.4 1.4 6-.3.7-.8 1.1-1.8 1.1-2.9 0-.4.4-.8.8-.8s.8.4.8.8c0 3.2-2.6 5.8-5.8 5.8-1.3 0-2.6-.4-3.6-1.3v.4c0 .4-.4.8-.8.8s-.8-.4-.8-.8V13c0-.4.3-.8.7-.8l2.4-.2z',
      'M28.8 8.2h-2.7c.2.6.4 1.2.4 1.8h2.7c-.3 1.2-.6 2.4-.9 3.7 0 .1-.1.1-.2.2H26c-.6 1.4-1.6 2.6-2.9 3.5-.1.6-.2 1.3-.3 1.9 0 .1-.1.2-.2.2h-2.4v-.9c-.5.1-.9.1-1.4.1h-.4v.8H16c-.2 0-.2 0-.2-.2-.1-.4-.1-.8-.2-1.3-.7-.3-1.4-.8-2-1.3l.3 2.4c.1.1.1.2.1.3h-2.3c-.2 0-.3-.1-.4-.3-.3-1.2-.7-2.4-1-3.6h2.2c-.4-.5-.7-1.1-1-1.7h-.8c-.2-.1-.6 0-.8-.1-.2-.2-.2-.6-.3-.8l-.8-3H11c.1-.6.2-1.2.4-1.8H8.6c-.2 0-.3-.1-.4-.3-.3-1.1-.7-2.4-1-3.6-.1-.8-.9-1.5-1.7-1.5H1.8c-.3 0-.6.2-.8.6-.2.5.1 1 .5 1.1.1 0 .2.1.3 0H5c.4 0 .5 0 .6.5L9 17.4l.6 2.1h-.2c-1.5.1-2.6 1.4-2.5 2.9.1 1.2 1 2.2 2.2 2.5.5.1 1 .1 1.5.1h.3c-.5 1.7-.2 2.9.9 3.8 1.4 1 3.3.7 4.3-.6.2-.2.3-.4.4-.7.3-.8.3-1.7 0-2.5H22c-.4 1.9-.1 3 1.1 3.8 1 .7 2.4.7 3.5 0 .5-.3.9-.8 1.1-1.4.3-.8.3-1.7-.1-2.5l.1-.1c.3-.2.5-.5.5-.9-.1-.4-.5-.6-.9-.7H9.6c-.5 0-1-.4-1-1s.4-1 1-1h16.9c1.1.1 2.1-.7 2.3-1.8l1.5-6.3c.2-.9.5-2 .7-2.9v-.6c-.2-.9-1.2-1.6-2.2-1.4zm-4 16.8c.7 0 1.3.6 1.3 1.3s-.6 1.3-1.3 1.3c-.7-.1-1.2-.7-1.2-1.4.1-.6.6-1.1 1.2-1.2zm-11.1 0c.7 0 1.3.6 1.3 1.3s-.6 1.3-1.3 1.3-1.3-.6-1.3-1.3.6-1.3 1.3-1.3zm13.8-8.1c0 .8-.2 1.5-.4 2.2-.1.2-.2.4-.4.4h-2.2l.5-3.9h2.8c-.1.5-.2.9-.3 1.3z'
    ]
  },
  userFill: {
    viewBox: '0 0 42 42',
    path: 'M11.5 10.2a9.5 10 0 1 0 19 0 9.5 10 0 1 0-19 0m15.7 15L21 31.5l-6.2-6.3h-4.5C4.7 25.2.2 30 .2 35.9v5.9h41.6v-5.9c0-5.9-4.5-10.7-10.1-10.7h-4.5z'
  },
  userStroke: {
    viewBox: '0 -960 960 960',
    path: 'M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z'
  },
  userStrokeMiddle: {
    viewBox: '0 0 42 42',
    path: 'M36.7 33.8c-.3-3.7-1.8-7.2-4.5-9.9-.1-.1-.4-.3-.7-.3-.3 0-.6.1-.7.3-.1.1-.3.4-.3.7 0 .3.1.6.3.7 2.3 2.3 3.7 5.4 4 8.7.1.7-.1 1.3-.9 1.7-.9.6-1.7 1-2.7 1.4-2.6.9-5.4 1.4-8.2 1.6-4 .3-8.1-.3-11.9-1.6-1.1-.3-2.1-.9-3-1.7-.3-.1-.4-.6-.4-.9s0-.6.1-.9c0-.3 0-.6.1-.7.4-2.8 1.7-5.4 3.7-7.4l.1-.1c.4-.4.3-1-.1-1.4-.4-.4-1.1-.4-1.7 0-2.3 2.4-3.8 5.4-4.4 8.7 0 .3-.1.7-.1 1v.7c-.1 1.1.4 2.1 1.3 2.8 1 .9 2.3 1.6 3.5 2.1 3.4 1.1 7 1.7 10.5 1.7.7 0 1.6 0 2.3-.1 3-.1 6-.6 8.9-1.7 1-.4 2-.9 3-1.4 1.3-1.1 2-2.6 1.8-4zM21.2 23.1c6.1 0 11.1-5 11.1-11.1s-5-11-11.1-11c-6.1.1-11.1 5-11.1 11.1s5 11 11.1 11zm-8.9-11c0-4.8 4-8.8 8.9-8.8s8.9 4 8.9 8.9-4 8.9-8.9 8.9c-5-.1-8.9-4.1-8.9-9 0 .1 0 .1 0 0z'
  },
  barcode: {
    viewBox: '0 0 42 42',
    path: 'M12.7 1c.5 0 1 .4 1 1s-.4 1-1 1H5.3C4 2.9 2.9 4 2.9 5.3v7.5c0 .5-.4 1-1 1s-1-.4-1-1V5.3C1 2.9 2.9 1 5.3 1h7.4zm16.6 1.9c-.5 0-1-.4-1-1s.4-1 1-1h7.4c2.4.1 4.3 2 4.3 4.4v7.5c0 .5-.4 1-1 1-.5 0-1-.4-1-1V5.3c0-1.3-1.1-2.4-2.4-2.4h-7.3zm0 38.1c-.5 0-1-.4-1-1 0-.5.4-1 1-1h7.4c1.3 0 2.4-1.1 2.4-2.4v-7.5c0-.5.4-1 1-1s1 .4 1 1v7.5c0 2.4-1.9 4.3-4.3 4.3h-7.5zm-16.6-1.9c.5 0 1 .4 1 1 0 .5-.4 1-1 1H5.3C2.9 41 1 39.1 1 36.7v-7.5c0-.5.4-1 1-1s1 .4 1 1v7.5c0 1.3 1.1 2.4 2.4 2.4h7.3zm21.8-16.9c0 .3-.2.5-.5.5H8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h26c.3 0 .5.2.5.5zm-5.8 7.4V24h2.7v5.6h-2.7zm-5.9 0V24h4.1v5.6h-4.1zm-4.1 0V24h2.7v5.6h-2.7zm-3.1 0V24H17v5.6h-1.4zm-4.5 0V24h2.7v5.6h-2.7zm17.6-8.8v-8.4h2.7v8.4h-2.7zm-5.9 0v-8.4h4.1v8.4h-4.1zm-4.1 0v-8.4h2.7v8.4h-2.7zm-3.1 0v-8.4H17v8.4h-1.4zm-4.5 0v-8.4h2.7v8.4h-2.7z'
  },
  shop: {
    viewBox: '0 0 32 32',
    path: [
      'M30.2 30.3H30V17.4c1.2-.9 1.9-2.3 2-3.8 0-.2 0-.3-.1-.4l-3.6-6.3c-.3-.6-1-.9-1.6-.9H5.4c-.7 0-1.3.4-1.6.9L.2 13.2c-.2.1-.2.3-.2.4 0 1.5.8 2.9 2 3.8v12.9h-.1c-.5 0-.9.4-.9.9 0 .2.1.5.3.6.2.2.4.3.6.2h28.2c.2 0 .5-.1.6-.3.2-.2.2-.4.2-.6.1-.4-.3-.8-.7-.8zm-4.4 0h-4.1v-.6h4.1v.6zm0-2.4h-4.1v-7.5h4.1v7.5zm2.5 2.4h-.7V19.5c0-.2-.1-.5-.3-.6-.2-.2-.4-.2-.6-.2h-5.9c-.5 0-.9.4-.9.9v10.8H3.7V18.2c1.8.4 3.6-.4 4.7-1.8 1.5 2 4.3 2.5 6.4 1.1.5-.3.9-.7 1.2-1.1 1.5 2 4.3 2.5 6.4 1.1.5-.3.9-.7 1.2-1.1 1.1 1.5 2.9 2.2 4.7 1.8v12.1zM9.5 14.5H15c-.5 1.5-2.1 2.3-3.7 1.8-.8-.2-1.5-.9-1.8-1.8zm7.5 0h5.5c-.5 1.5-2.2 2.3-3.7 1.8-.8-.2-1.5-.9-1.8-1.8zm11.8 1.6c-.7.4-1.5.5-2.2.3-.9-.3-1.7-1-2-1.9h1.6c.5.1.9-.3 1-.8.1-.5-.3-.9-.8-1H5.8c-.5.1-.9.5-.8 1 0 .4.4.8.8.8h1.6c-.5 1.5-2.1 2.3-3.6 1.8-.9-.3-1.6-1-1.8-1.8h.9c.2 0 .5-.1.6-.3.3-.3.3-.9 0-1.3-.2-.2-.4-.3-.6-.3h-.5l2.8-5H26.7l2.9 5h-.4c-.5.1-.9.5-.8 1 0 .4.4.8.8.8h.9c-.3.8-.7 1.4-1.3 1.7z',
      'M18.4 18.6H5.3c-.5 0-.9.4-.9.9v9.3c0 .5.4.9.9.9h13.2c.5 0 .9-.4.9-.9v-9.3c-.1-.5-.5-.9-1-.9zm-.9 9.2H6.2v-7.5h11.4v7.5z'
    ]
  },
  help: {
    viewBox: '0 0 32 32',
    path: [
      'M32 14.5V13.9c-.6-4.3-4.3-7.5-8.6-7.5H8.3C4.1 6.4.5 9.6.1 13.8v4.5c0 4.6 3.7 8.4 8.4 8.4H9s.1 0 .1.1v4.7s0 .1.1.1l7.7-4.9h6.8c4.6 0 8.3-3.7 8.3-8.3V14.5zm-1.6 3.9c0 3.7-3 6.7-6.7 6.7h-7.2l-5.7 3.8h-.1v-3.7s0-.1-.1-.1H8.4c-3.7 0-6.7-3-6.7-6.7v-3.6C1.6 11 4.6 8 8.3 8h15.3c3.7 0 6.7 3 6.7 6.7l.1 3.7z',
      'M25.8 12.8H6.2c-.6 0-1 .4-1 1s.4 1 1 1h19.5c.6 0 1-.4 1-1s-.4-1-.9-1zM25.5 18.3H6.6c-.6 0-1 .4-1 1s.4 1 1 1h18.9c.6 0 1-.4 1-1s-.5-1-1-1z'
    ]
  },
  promo: {
    viewBox: '0 0 32 32',
    path: [
      'M31.13,18.81a7.43,7.43,0,0,0-2.41-3,6.47,6.47,0,0,0-7.22,0,7.52,7.52,0,0,0-2.45,3,9.69,9.69,0,0,0-.89,4.2,9.13,9.13,0,0,0,.93,4.14,7.76,7.76,0,0,0,2.5,3,6,6,0,0,0,3.51,1.1,5.89,5.89,0,0,0,3.49-1.1,7.89,7.89,0,0,0,2.48-3A9.13,9.13,0,0,0,32,23,9.83,9.83,0,0,0,31.13,18.81Zm-4.06,7.37a2.16,2.16,0,0,1-2,1.27,2.19,2.19,0,0,1-2-1.27,6.46,6.46,0,0,1-.7-3.17,6.39,6.39,0,0,1,.72-3.18,2.2,2.2,0,0,1,2-1.27,2.15,2.15,0,0,1,2,1.27A6.74,6.74,0,0,1,27.77,23,6.58,6.58,0,0,1,27.07,26.18Z',
      'M10.43,16.15a7.94,7.94,0,0,0,2.48-3A9.2,9.2,0,0,0,13.84,9,9.78,9.78,0,0,0,13,4.85a7.46,7.46,0,0,0-2.41-3A6.11,6.11,0,0,0,6.94.78a6.16,6.16,0,0,0-3.6,1.1,7.55,7.55,0,0,0-2.45,3A9.64,9.64,0,0,0,0,9a9.59,9.59,0,0,0,.89,4.15,7.52,7.52,0,0,0,2.45,3,6.16,6.16,0,0,0,3.6,1.1A5.89,5.89,0,0,0,10.43,16.15Zm-5.5-3.94A6.55,6.55,0,0,1,4.23,9,6.39,6.39,0,0,1,5,5.86a2.23,2.23,0,0,1,2-1.27,2.15,2.15,0,0,1,2,1.27A6.74,6.74,0,0,1,9.61,9a6.44,6.44,0,0,1-.7,3.17,2.16,2.16,0,0,1-2,1.27A2.19,2.19,0,0,1,4.93,12.21Z',
      'M26.46,3.15a1.78,1.78,0,0,0-.75-1.52,2.42,2.42,0,0,0-1.46-.55,2.16,2.16,0,0,0-1.73,1L5.67,27.53a2.62,2.62,0,0,0-.42,1.27A1.78,1.78,0,0,0,6,30.31a2.46,2.46,0,0,0,1.44.57,2.17,2.17,0,0,0,1.74-1L26,4.42A2.62,2.62,0,0,0,26.46,3.15Z'
    ]
  },
  bestseller: {
    viewBox: '0 0 32 32',
    path: 'M23,6.18c-.9-.89-1.79-1.79-2.69-2.69L17.49.7a2.42,2.42,0,0,0-.56-.44,1.9,1.9,0,0,0-2.37.39c-1.82,1.81-3.62,3.63-5.45,5.43A4.83,4.83,0,0,0,7.6,9.72q0,9.81,0,19.61A2.45,2.45,0,0,0,10.27,32H21.74a2.5,2.5,0,0,0,2.66-2.65V9.62A4.63,4.63,0,0,0,23,6.18Zm-12,20h-.4V16.9H11Zm1.17,0V16.9h.16v9.26Zm1.61,0h-.4V16.9h.4Zm1.33,0H15V16.9h.16Zm1.65,0H16.6V16.9h.16Zm.59-17.47h0a1.9,1.9,0,0,1-2.68,0l0,0a1.92,1.92,0,0,1,0-2.69,1.91,1.91,0,1,1,2.7,2.71Zm.86,17.47h-.16V16.9h.16Zm1.63,0h-.4V16.9h.4Zm1.56,0H21V16.9h.4Z'
  },
  wishlistFill: {
    viewBox: '0 0 42 42',
    path: 'M30.32 3.93c-4.45 0-8.2 3.01-9.32 7.11-1.13-4.1-4.87-7.11-9.32-7.11C6.33 3.93 2 8.26 2 13.61 2 26.5 21 38.07 21 38.07S40 26.5 40 13.61c0-5.35-4.33-9.68-9.68-9.68'
  },
  wishlistStroke: {
    viewBox: '0 0 42 42',
    path: 'm21 39.24-.52-.32C19.68 38.44 1 26.9 1 13.61 1 7.72 5.79 2.93 11.68 2.93c3.92 0 7.47 2.17 9.32 5.49 1.85-3.32 5.4-5.49 9.32-5.49C36.21 2.93 41 7.72 41 13.61c0 13.29-18.68 24.83-19.48 25.32l-.52.31zM11.68 4.93C6.89 4.93 3 8.82 3 13.61c0 11.01 14.99 21.32 18 23.28 3.01-1.96 18-12.27 18-23.28 0-4.79-3.89-8.68-8.68-8.68a8.69 8.69 0 0 0-8.36 6.38L21 14.82l-.96-3.51C19 7.55 15.57 4.93 11.68 4.93z'
  },
  search: {
    viewBox: '0 0 42 42',
    path: [
      'M28.8 14.7c-1.1-3.4-3.8-6.1-7.2-7.2-.8-.2-1.5.3-1.7 1-.2.7.2 1.4.9 1.7 2.5.8 4.5 2.8 5.4 5.3.2.8 1.1 1.2 1.8.9.7-.2 1.1-1 .8-1.7z',
      'm40.7 38.6-9.4-9.4c6.1-7.2 5.2-18.1-2-24.2S11.2-.2 5.1 7s-5.2 18.1 2 24.2c6.4 5.4 15.8 5.4 22.2 0l9.4 9.4c.6.5 1.5.4 2-.2.4-.4.4-1.2 0-1.8zM3.9 18.2c0-7.9 6.4-14.3 14.3-14.3s14.3 6.4 14.3 14.3-6.4 14.3-14.3 14.3S3.9 26.1 3.9 18.2z'
    ]
  },
  refound: {
    viewBox: '0 0 42 42',
    path: [
      'M37.91 30.44h-1.83v-9.71c-.05-4.45-1.84-8.6-5.04-11.69a16.375 16.375 0 0 0-11.88-4.61c-8.8.23-15.84 7.29-16.05 16.08-.11 4.48 1.56 8.71 4.68 11.92 3.13 3.2 7.32 4.97 11.79 4.97.21 0 .42 0 .63-.01l-.18-4.04c-.15.01-.3.01-.45.01-3.38 0-6.54-1.33-8.9-3.75a12.366 12.366 0 0 1-3.54-9c.16-6.63 5.47-11.96 12.1-12.13 3.37-.08 6.55 1.15 8.97 3.48 2.42 2.33 3.77 5.47 3.81 8.8v9.68h-1.86c-.68 0-.99.43-.99.83 0 .21.08.43.23.64l3.71 5.16c.23.32.56.5.91.5s.68-.18.91-.5l3.71-5.17c.15-.21.23-.43.23-.64.02-.39-.29-.82-.96-.82z',
      'M22.46 24.51h2.48c.28 0 .54-.19.6-.47.07-.37-.21-.7-.57-.7h-2.48a.61.61 0 0 0-.6.47c-.08.36.21.7.57.7z',
      'M26.13 15.12H12.95c-1.04 0-1.88.84-1.88 1.88v7.81c0 1.04.84 1.88 1.88 1.88h13.18c1.04 0 1.88-.84 1.88-1.88V17c0-1.04-.85-1.88-1.88-1.88zM12.09 17c0-.48.39-.86.86-.86h13.18c.48 0 .86.39.86.86v.83h-14.9V17zm14.9 7.81c0 .48-.39.86-.86.86H12.95a.86.86 0 0 1-.86-.86v-4.73h14.9v4.73z'
    ]
  },
  share: {
    viewBox: '0 0 42 42',
    path: 'M31.52 26.11c-2 0-3.79.88-5.02 2.28l-9.97-5.8c.2-.64.33-1.32.33-2.03 0-.71-.12-1.39-.33-2.03l10.02-5.83c1.23 1.36 3 2.22 4.97 2.22a6.7 6.7 0 1 0-6.7-6.7c0 .71.12 1.39.33 2.03l-10.02 5.83c-1.23-1.36-3-2.22-4.97-2.22a6.7 6.7 0 0 0 0 13.4c1.98 0 3.75-.86 4.97-2.22l10 5.81c-.19.62-.3 1.28-.3 1.97a6.7 6.7 0 0 0 13.4 0c0-3.7-3.01-6.71-6.71-6.71z'
  },
  up: {
    viewBox: '0 0 42 42',
    path: 'M7.23 25.16a2.601 2.601 0 0 0 0 3.67 2.601 2.601 0 0 0 3.67 0l9.8-9.8 9.8 9.8a2.601 2.601 0 0 0 3.67 0c.51-.51.76-1.17.76-1.83 0-.66-.25-1.33-.76-1.83L20.71 11.69 7.23 25.16z'
  },
  down: {
    viewBox: '0 0 42 42',
    path: 'M16.18 7.17a2.601 2.601 0 0 0-3.67 0 2.601 2.601 0 0 0 0 3.67l9.8 9.8-9.8 9.8a2.601 2.601 0 0 0 0 3.67c.51.51 1.17.76 1.83.76s1.33-.25 1.83-.76l13.47-13.47L16.18 7.17z'
  },
  left: {
    viewBox: '0 0 42 42',
    path: 'M25.23 34.11a2.601 2.601 0 0 0 3.67 0 2.601 2.601 0 0 0 0-3.67l-9.8-9.8 9.8-9.8a2.601 2.601 0 0 0 0-3.67c-.51-.51-1.17-.76-1.83-.76-.66 0-1.33.25-1.83.76L11.75 20.64l13.48 13.47z'
  },
  right: {
    viewBox: '0 0 42 42',
    path: 'M16.18 7.17a2.601 2.601 0 0 0-3.67 0 2.601 2.601 0 0 0 0 3.67l9.8 9.8-9.8 9.8a2.601 2.601 0 0 0 0 3.67c.51.51 1.17.76 1.83.76s1.33-.25 1.83-.76l13.47-13.47L16.18 7.17z'
  },
  filter: {
    viewBox: '0 0 42 42',
    path: 'M36.84 8.9H22.81c-1.1-2.8-3.82-4.79-7.01-4.79S9.88 6.1 8.78 8.9H4.34c-1.52 0-2.75 1.23-2.75 2.75s1.23 2.75 2.75 2.75h4.44c1.1 2.8 3.82 4.79 7.01 4.79s5.91-1.99 7.01-4.79h14.04c1.52 0 2.75-1.23 2.75-2.75S38.36 8.9 36.84 8.9zm-17.62 2.74c0 1.89-1.53 3.42-3.42 3.42s-3.42-1.53-3.42-3.42 1.53-3.42 3.42-3.42 3.42 1.53 3.42 3.42zM1.59 29.38c0 1.52 1.23 2.75 2.75 2.75h14.04c1.1 2.8 3.82 4.79 7.01 4.79s5.91-1.99 7.01-4.79h4.44c1.52 0 2.75-1.23 2.75-2.75s-1.23-2.75-2.75-2.75H32.4c-1.1-2.8-3.82-4.79-7.01-4.79s-5.91 1.99-7.01 4.79H4.34c-1.52 0-2.75 1.23-2.75 2.75zm23.8-3.43c1.89 0 3.42 1.53 3.42 3.42s-1.53 3.42-3.42 3.42-3.42-1.53-3.42-3.42 1.53-3.42 3.42-3.42z'
  },
  trash: {
    viewBox: '0 0 42 42',
    path: 'M33.95 9.54a3.352 3.352 0 0 0-3.35-3.33l-6.77.02-.23-1.57c-.06-.43-.47-.78-.91-.78l-4.23.01c-.44 0-.84.36-.9.79l-.22 1.58-6.78.02c-1.84.01-3.33 1.5-3.33 3.35v1.11l26.71-.08.01-1.12zM31.17 12.67H10.02c-1.22 0-2.14 1-2.02 2.22l1.82 20.05c.11 1.22 1.2 2.22 2.43 2.22h16.69c1.22 0 2.32-1 2.43-2.22l1.82-20.05c.11-1.22-.8-2.22-2.02-2.22zM16.13 32.89c0 .94-.76 1.7-1.7 1.7s-1.7-.76-1.7-1.7V16.93c0-.94.76-1.7 1.7-1.7s1.7.76 1.7 1.7v15.96zm6.17 0c0 .94-.76 1.7-1.7 1.7s-1.7-.76-1.7-1.7V16.93c0-.94.76-1.7 1.7-1.7s1.7.76 1.7 1.7v15.96zm6.17 0c0 .94-.76 1.7-1.7 1.7s-1.7-.76-1.7-1.7V16.93c0-.94.76-1.7 1.7-1.7s1.7.76 1.7 1.7v15.96z'
  },
  check: {
    viewBox: '0 0 42 42',
    path: 'M38.37 9.22a2.74 2.74 0 0 0-3.86 0L18.36 25.38 6.67 13.69a2.74 2.74 0 0 0-3.86 0 2.74 2.74 0 0 0 0 3.86l15.54 15.54 20.01-20.01c1.07-1.07 1.07-2.8.01-3.86'
  },
  payment: {
    viewBox: '0 0 42 42',
    path: 'M30.28 13.25h-2.26v-2.93c0-4.11-3.33-7.46-7.43-7.46s-7.43 3.34-7.43 7.46v2.93H10.9a4.04 4.04 0 0 0-3.98 4.03v16.86c0 2.22 1.81 4.03 4.03 4.03h19.29c2.22 0 4.03-1.82 4.03-4.03V17.28c0-2.21-1.79-4.01-3.99-4.03zM15.6 10.31c0-2.77 2.24-5.02 5-5.02 2.75 0 5 2.25 5 5.02v2.93h-10v-2.93zm12.49 10.53c.45.45.45 1.18 0 1.63l-8.44 8.44-6.55-6.55a1.153 1.153 0 0 1 1.63-1.63l4.93 4.93 6.81-6.81a1.15 1.15 0 0 1 1.62-.01'
  },
  miles: {
    viewBox: '0 0 42 42',
    path: 'M39.35 21.03 33.7 7.11a3.824 3.824 0 0 0-3.56-2.4c-.5 0-.98.1-1.44.28l-24.67 10c-.95.39-1.7 1.12-2.1 2.06-.4.95-.41 1.99-.02 2.94l5.64 13.93c.59 1.46 1.99 2.4 3.56 2.4.49 0 .98-.1 1.44-.28l24.67-10c1.97-.8 2.92-3.04 2.13-5.01zm-12.02 3.82-3.46-.38c-.59-.06-1.4.26-1.78.71l-2.24 2.66c-.39.46-.81.35-.93-.24l-.74-3.43c-.13-.59-.69-1.26-1.24-1.49l-3.22-1.35c-.55-.23-.58-.67-.07-.97l3-1.74c.52-.3.97-1.04 1.01-1.64l.25-3.5c.04-.6.44-.76.89-.36l2.6 2.35c.45.4 1.29.62 1.87.48l3.38-.81c.58-.14.86.2.62.75l-1.39 3.2c-.24.55-.18 1.42.14 1.94l1.83 3c.3.52.07.89-.52.82z'
  },
  bagage: {
    viewBox: '0 0 42 42',
    path: 'M34.38 10.06h-6.83V7.72c0-2.1-1.71-3.8-3.8-3.8h-5.82c-2.1 0-3.8 1.71-3.8 3.8v2.34H7.29c-2.38.03-4.33 1.99-4.33 4.38v18.3c0 2.41 1.97 4.38 4.38 4.38h26.98c2.41 0 4.38-1.97 4.38-4.38v-18.3c0-2.4-1.94-4.35-4.32-4.38zM16.15 7.72c0-.98.79-1.77 1.77-1.77h5.82c.98 0 1.77.8 1.77 1.77v2.34h-9.36V7.72z'
  },
  clock: {
    viewBox: '0 0 42 42',
    path: 'M20.83 1.51c-10.49 0-19 8.51-19 19s8.51 19 19 19 19-8.51 19-19-8.5-19-19-19zm9.99 16.64-9.23 5.33c-.03.02-.06.03-.09.04-.04.02-.08.04-.13.06-.05.02-.09.03-.14.04l-.14.03c-.05.01-.09.01-.14.01-.03 0-.06.01-.1.01-.02 0-.04-.01-.06-.01-.04 0-.08-.01-.12-.01-.06-.01-.11-.01-.16-.03l-.11-.03c-.05-.02-.1-.03-.15-.06l-.12-.06a.746.746 0 0 1-.12-.07c-.04-.03-.08-.07-.13-.1-.03-.03-.06-.05-.08-.08-.04-.04-.07-.08-.11-.13l-.07-.1c-.01-.02-.02-.03-.03-.05-.02-.03-.03-.06-.04-.09-.02-.04-.04-.08-.06-.13a.737.737 0 0 1-.04-.14l-.03-.14c-.01-.05-.01-.09-.01-.14 0-.03-.01-.06-.01-.1V7.93c0-.8.66-1.46 1.46-1.46.8 0 1.46.66 1.46 1.46v11.75l7.04-4.07a1.465 1.465 0 0 1 1.46 2.54z'
  },
  question: {
    viewBox: '0 0 42 42',
    path: 'M20.82 37.51c-1.95 0-3.48-1.37-3.48-3.43 0-1.9 1.53-3.38 3.48-3.38 1.9 0 3.43 1.48 3.43 3.38-.01 2.06-1.54 3.43-3.43 3.43zM25.83 21.63c-1.9 1.79-2.48 2.9-2.48 4.43v1.95h-5.12v-1.9c0-2.69.69-4.27 2.95-6.49 2.27-2.27 4.85-4.27 4.85-6.49 0-2.37-1.58-4.01-5.33-4.01-2.64 0-5.38 1.64-7.23 3.59l-3.01-3.48c2.22-2.37 6.12-4.75 10.39-4.75 6.49 0 10.66 3.59 10.66 8.28.02 3.6-2.94 6.18-5.68 8.87z'
  },
  contact: {
    viewBox: '0 0 42 42',
    path: [
      'M19.04 8c0-1.01.82-1.84 1.84-1.84H34c1.01 0 1.84.82 1.84 1.84l.01 8.1c0 1.02-.83 1.84-1.84 1.84H25.4c-.41 0-.8.14-1.11.4l-1.66 1.39v-.06c0-.96-.74-1.61-1.73-1.7-1.14-.1-1.84-.86-1.84-1.87L19.04 8zm18.53 0a3.59 3.59 0 0 0-3.58-3.58H20.88A3.59 3.59 0 0 0 17.3 8v8.1a3.59 3.59 0 0 0 3.58 3.58v3.76l4.51-3.76H34a3.59 3.59 0 0 0 3.58-3.58L37.57 8z',
      'M11.52 30.48c3.12 3.12 6.67 5.26 9.5 6.3 1.37.51 2.77.86 4.26.77.92-.06 1.68-.44 2.35-1.11l2.13-2.13c.92-.92.92-2.12-.01-3.05-.57-.57-2.83-2.86-3.4-3.4-.98-.98-2.14-.98-3.12.01l-2.1 2.1c-.18.18-.32.22-.56.13-1.13-.48-3.08-1.71-5.02-3.66-1.95-1.95-3.18-3.89-3.66-5.02-.09-.24-.05-.38.13-.56l2.1-2.1c.98-.98.99-2.14.01-3.12-.54-.57-2.83-2.83-3.4-3.4-.92-.92-2.12-.93-3.05-.01l-2.13 2.13c-.67.67-1.05 1.42-1.11 2.35-.09 1.49.27 2.89.77 4.26 1.05 2.84 3.19 6.39 6.31 9.51z',
      'M24.22 12.11c0 .8-.64 1.44-1.44 1.44-.8 0-1.44-.64-1.44-1.44a1.44 1.44 0 0 1 2.88 0zM28.88 12.11c0 .8-.64 1.44-1.44 1.44-.8 0-1.44-.64-1.44-1.44 0-.8.64-1.44 1.44-1.44.79 0 1.44.65 1.44 1.44zM33.53 12.11c0 .8-.64 1.44-1.44 1.44s-1.44-.64-1.44-1.44a1.44 1.44 0 0 1 2.88 0z'
    ]
  },
  info: {
    viewBox: '0 0 42 42',
    path: 'M21 2C10.51 2 2 10.51 2 21s8.51 19 19 19 19-8.51 19-19S31.49 2 21 2m-.02 5.49c1.82 0 3.28 1.42 3.28 3.24a3.27 3.27 0 0 1-3.28 3.28c-1.82 0-3.24-1.46-3.24-3.28 0-1.81 1.42-3.24 3.24-3.24m4.96 25.6h-9.89v-3.7h2.35v-8.66h-2.35v-3.7h7.54V29.4h2.34v3.69z'
  },
  error: {
    viewBox: '0 0 42 42',
    path: 'M21.18 2.16c-10.47 0-18.96 8.49-18.96 18.96s8.49 18.96 18.96 18.96 18.96-8.49 18.96-18.96S31.65 2.16 21.18 2.16zm0 4.02c2.85 0 5.52.8 7.79 2.19L8.42 28.9a14.925 14.925 0 0 1-2.18-7.78c0-8.25 6.69-14.94 14.94-14.94zm0 29.88c-2.85 0-5.52-.8-7.79-2.19l20.55-20.53c1.38 2.27 2.18 4.93 2.18 7.78 0 8.25-6.69 14.94-14.94 14.94z'
  },
  cross: {
    viewBox: '0 0 42 42',
    path: 'M26.02 21.03 39.13 7.92a3.47 3.47 0 0 0 0-4.9 3.47 3.47 0 0 0-4.9 0l-13.11 13.1L8.01 3.02a3.483 3.483 0 0 0-4.91 0 3.47 3.47 0 0 0 0 4.9l13.11 13.11L3.1 34.13a3.47 3.47 0 0 0 2.45 5.92c.89 0 1.77-.34 2.45-1.02l13.11-13.11 13.11 13.11c.68.68 1.56 1.02 2.45 1.02a3.47 3.47 0 0 0 2.45-5.92l-13.1-13.1z'
  },
  delete: {
    viewBox: '0 0 42 42',
    path: 'M33.95 9.54a3.352 3.352 0 0 0-3.35-3.33l-6.77.02-.23-1.57c-.06-.43-.47-.78-.91-.78l-4.23.01c-.44 0-.84.36-.9.79l-.22 1.58-6.78.02c-1.84.01-3.33 1.5-3.33 3.35v1.11l26.71-.08.01-1.12zM31.17 12.67H10.02c-1.22 0-2.14 1-2.02 2.22l1.82 20.05c.11 1.22 1.2 2.22 2.43 2.22h16.69c1.22 0 2.32-1 2.43-2.22l1.82-20.05c.11-1.22-.8-2.22-2.02-2.22zM16.13 32.89c0 .94-.76 1.7-1.7 1.7s-1.7-.76-1.7-1.7V16.93c0-.94.76-1.7 1.7-1.7s1.7.76 1.7 1.7v15.96zm6.17 0c0 .94-.76 1.7-1.7 1.7s-1.7-.76-1.7-1.7V16.93c0-.94.76-1.7 1.7-1.7s1.7.76 1.7 1.7v15.96zm6.17 0c0 .94-.76 1.7-1.7 1.7s-1.7-.76-1.7-1.7V16.93c0-.94.76-1.7 1.7-1.7s1.7.76 1.7 1.7v15.96z'
  },
  print: {
    viewBox: '0 0 42 42',
    path: 'M34.7 10.4h-2.5V6.9c0-2.2-1.8-3.9-3.9-3.9H13.8c-2.2 0-3.9 1.8-3.9 3.9v3.5H7.3c-2.9 0-5.3 2.4-5.3 5.3v8.4c0 2.9 2.4 5.3 5.3 5.3h2.5v5.7c0 2.2 1.8 3.9 3.9 3.9h14.5c2.2 0 3.9-1.8 3.9-3.9v-5.7h2.5c2.9 0 5.3-2.4 5.3-5.3v-8.4c.1-2.9-2.3-5.3-5.2-5.3zM7.2 16.7c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5c-.1.9-.7 1.5-1.5 1.5zm22.7 18.4c0 .9-.7 1.6-1.6 1.6H13.8c-.9 0-1.6-.7-1.6-1.6v-12h17.7v12zm0-24.7H12.2V6.9c0-.9.7-1.6 1.6-1.6h14.5c.9 0 1.6.7 1.6 1.6v3.5z'
  },
  twitter: {
    viewBox: '0 0 42 42',
    path: 'M36.2 13.2v1c0 10.3-7.9 22.2-22.2 22.2-4.4 0-8.5-1.3-12-3.5.6.1 1.2.1 1.9.1 3.7 0 7-1.2 9.7-3.4-3.4-.1-6.3-2.3-7.3-5.4.5.1 1 .1 1.5.1.7 0 1.4-.1 2.1-.3-3.6-.7-6.3-3.9-6.3-7.7v-.1c1 .6 2.3.9 3.5 1-2.1-1.4-3.5-3.8-3.5-6.5 0-1.4.4-2.8 1.1-3.9 3.9 4.7 9.6 7.8 16.1 8.2-.1-.6-.2-1.2-.2-1.8 0-4.3 3.5-7.8 7.8-7.8 2.2 0 4.3.9 5.7 2.5 1.8-.3 3.5-1 5-1.9-.6 1.8-1.8 3.4-3.4 4.3 1.6-.2 3.1-.6 4.5-1.2-1.2 1.7-2.5 3-4 4.1'
  },
  youtube: {
    viewBox: '0 0 42 42',
    path: 'M39.2 11.8c-.4-1.6-1.7-2.9-3.4-3.4-2.9-.8-14.8-.8-14.8-.8s-11.9 0-14.8.8c-1.6.4-2.9 1.7-3.4 3.4C2 14.8 2 21 2 21s0 6.2.8 9.2c.4 1.6 1.7 2.9 3.4 3.4 3 .8 14.8.8 14.8.8s11.9 0 14.8-.8c1.6-.4 2.9-1.7 3.4-3.4.8-3 .8-9.2.8-9.2s0-6.2-.8-9.2zM16 26.6V15.4L26 21l-10 5.6z'
  },
  facebook: {
    viewBox: '0 0 42 42',
    path: 'M19.3 39.3c-1.2 0-2.1-1-2.1-2.1V22.3h-3.8c-1.2 0-2.1-.9-2.1-2.1v-2.6c0-.6.2-1.1.6-1.5.4-.4.9-.6 1.5-.6h3.8v-3.8c0-5.8 2.9-8.9 8.3-8.9 2.1 0 4 .1 4 .2.8.1 1.4.8 1.4 1.6v3.9c0 .9-.7 1.6-1.6 1.6h-2.5c-1 0-1.9.8-1.9 1.9v3.7h3.8c.6 0 1.2.3 1.6.7.4.5.6 1.1.5 1.7l-.3 2.6c-.1 1.1-1 1.9-2.1 1.9h-3.5v14.9c0 1.2-1 2.1-2.1 2.1h-3.5z'
  },
  tiktok: {
    viewBox: '0 0 256 256',
    path: 'M232,84v40a7.99977,7.99977,0,0,1-8,8,103.32406,103.32406,0,0,1-48.00049-11.70752L176,156A76,76,0,1,1,86.59766,81.17971,7.99952,7.99952,0,0,1,96,89.05569l-.00049,41.63916a7.99971,7.99971,0,0,1-4.56689,7.22607A20.00272,20.00272,0,1,0,120,156V28a7.99977,7.99977,0,0,1,8-8h40a7.99977,7.99977,0,0,1,8,8,48.05436,48.05436,0,0,0,48,48A7.99977,7.99977,0,0,1,232,84Z'
  },
  pinterest: {
    viewBox: '0 0 42 42',
    path: 'M21.8 3.1C14.6 3.1 7.4 8 7.4 15.8c0 5 2.8 7.8 4.5 7.8.7 0 1.1-2 1.1-2.5 0-.7-1.7-2.1-1.7-4.8 0-5.7 4.3-9.7 9.9-9.7 4.8 0 8.4 2.7 8.4 7.8 0 3.8-1.5 10.8-6.4 10.8-1.8 0-3.3-1.3-3.3-3.1 0-2.7 1.7-5.3 1.7-8 0-4.7-6.5-3.8-6.5 1.8 0 1.2.1 2.5.7 3.6-1 4.2-2.8 10.9-2.8 15.2 0 1.3.2 2.7.3 4 .2.3.1.2.5.1 3.6-4.9 3.2-6.3 4.8-12.7.9 1.7 3.1 2.5 4.9 2.5 7.5 0 10.9-7.3 10.9-13.9.2-7-5.9-11.6-12.6-11.6z'
  },
  instagram: {
    viewBox: '0 0 42 42',
    path: 'M13.6 3C7.8 3 3 7.8 3 13.6v14.8C3 34.2 7.8 39 13.6 39h14.8C34.2 39 39 34.2 39 28.4V13.6C39 7.8 34.2 3 28.4 3H13.6zm0 3.3h14.8c4 0 7.3 3.3 7.3 7.3v14.8c0 4-3.3 7.3-7.3 7.3H13.6c-4 0-7.3-3.3-7.3-7.3V13.6c0-4.1 3.2-7.3 7.3-7.3zm17.1 3.6c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5c-.1-.9-.7-1.5-1.5-1.5zM21 11.2c-5.4 0-9.8 4.4-9.8 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8-4.4-9.8-9.8-9.8zm0 3.3c3.6 0 6.5 2.9 6.5 6.5s-2.9 6.5-6.5 6.5-6.5-2.9-6.5-6.5 2.9-6.5 6.5-6.5z'
  },
  linkedIn: {
    viewBox: '0 0 42 42',
    path: 'M36.8 36.1V24.2c0-6.3-3.4-9.3-7.9-9.3-3.6 0-5.3 2-6.2 3.4v-2.9h-6.9c.1 1.9 0 20.6 0 20.6h6.9V24.6c0-.6 0-1.2.2-1.7.5-1.2 1.6-2.5 3.5-2.5 2.5 0 3.5 1.9 3.5 4.7v11h6.9zM8.6 12.6c2.4 0 3.9-1.6 3.9-3.6S11 5.4 8.7 5.4 4.8 7 4.8 9.1c0 1.9 1.5 3.5 3.8 3.5zm3.5 23.5V15.4H5.2V36c0 .1 6.9.1 6.9.1z'
  },
  show: {
    viewBox: '0 0 42 42',
    path: 'M1.9 21.7c.2.5 6.3 10.4 19.1 10.4s18.9-9.9 19.1-10.4l.4-.7-.4-.7c-.2-.5-6.3-10.4-19.1-10.4S2.1 19.8 1.9 20.3l-.5.7.5.7zM21 17.2c2.2 0 4 1.7 4 3.8 0 2.1-1.8 3.8-4 3.8s-4-1.7-4-3.8c0-2.1 1.8-3.8 4-3.8zM38 21c-1 1.5-4.7 6.3-11.6 8.2 3-1.7 5-4.7 5-8.2s-2-6.5-5-8.2C33.2 14.7 37 19.5 38 21zm-22.4-8.2c-3 1.7-5 4.7-5 8.2s2 6.5 5 8.2C8.8 27.3 5 22.5 4 21c1-1.5 4.8-6.3 11.6-8.2z'
  },
  emptyCart: {
    viewBox: '0 0 42 42',
    path: [
      'M12.18 17.04c.58 0 1.03-.46 1.03-1.03v-7.4c0-2.36 1.9-4.28 4.26-4.28 2.34 0 4.26 1.92 4.26 4.28v7.38c0 .58.46 1.03 1.03 1.03.58 0 1.03-.46 1.03-1.03V8.62c0-3.51-2.84-6.35-6.33-6.35s-6.33 2.86-6.33 6.35V16c.01.57.47 1.04 1.05 1.04z',
      'M33.26 23.09c.16 0 .34 0 .5.02V10.8H25.3v5.22c0 1.41-1.15 2.56-2.56 2.56s-2.56-1.15-2.56-2.56V10.8h-5.45v5.22c0 1.41-1.15 2.56-2.56 2.56s-2.56-1.15-2.56-2.56V10.8H1.17v21.44h23.04v-.08c0-5 4.07-9.07 9.05-9.07z',
      'M33.26 24.58c-4.18 0-7.58 3.39-7.58 7.58s3.39 7.58 7.58 7.58 7.58-3.39 7.58-7.58-3.4-7.58-7.58-7.58zm3.03 9.78c.22.24.22.59 0 .83-.12.12-.26.18-.42.18-.16 0-.3-.06-.42-.18l-2.2-2.2-2.2 2.2c-.12.12-.26.18-.42.18s-.3-.06-.42-.18a.604.604 0 0 1 0-.83l2.2-2.2-2.2-2.2a.604.604 0 0 1 0-.83c.24-.22.59-.22.83 0l2.2 2.2 2.22-2.2c.22-.22.59-.22.83 0 .22.22.22.59 0 .83l-2.22 2.18 2.22 2.22z'
    ]
  },
  payment_card: {
    viewBox: '0 0 42 42',
    path: [
      'M39.4 21.03 33.75 7.1a3.824 3.824 0 0 0-3.56-2.4c-.5 0-.98.1-1.44.28l-24.67 10c-.95.39-1.7 1.12-2.1 2.06-.4.95-.41 1.99-.02 2.94L7.6 33.91c.59 1.46 1.99 2.4 3.56 2.4.49 0 .98-.1 1.44-.28l24.67-10a3.846 3.846 0 0 0 2.13-5zm-35.57-1.8c-.18-.45-.18-.95.01-1.4.19-.45.54-.8 1-.98l24.67-10a1.83 1.83 0 0 1 2.39 1L32.37 9 4.3 20.38l-.47-1.15zm32.7 4.94-24.67 10a1.83 1.83 0 0 1-2.39-1l-3.33-8.2L34.2 13.6l3.33 8.2c.38.92-.07 1.99-1 2.37z',
      'm29.01 22.95 5.11-2.07c.54-.22 1.16-.01 1.38.53.22.54-.04 1.1-.58 1.32l-5.11 2.07c-.54.22-1.16.01-1.38-.53-.23-.54.04-1.11.58-1.32'
    ]
  },
  home: {
    viewBox: '0 0 42 42',
    path: [
      'M40.7 19.6 23.1 1.9c-.6-.6-1.3-.9-2.1-.9s-1.6.3-2.2.9L1.3 19.5c-.2.2-.3.5-.3.8 0 .3.1.5.3.6.2.2.4.3.7.3.3 0 .5-.1.7-.3L20.2 3.3c.3-.2.5-.3.8-.3.3 0 .6.1.8.3L39.3 21c.2.2.4.3.7.3.2 0 .4-.1.6-.2.2-.2.4-.4.4-.7 0-.4-.1-.6-.3-.8z',
      'M36.1 19.8c-.6 0-1 .4-1 1V38c0 .6-.5 1.1-1.1 1.1h-7.2v-1.9c0-3.2-2.6-5.8-5.8-5.8s-5.8 2.6-5.8 5.8V39H8c-.6 0-1.1-.5-1.1-1.1V20.8c0-.6-.4-1-1-1s-1 .4-1 1V38c0 1.7 1.4 3 3 3h9.2v-3.9c0-2.1 1.7-3.8 3.8-3.8 2.1 0 3.8 1.7 3.8 3.8V41H34c1.7 0 3-1.4 3-3V20.8c.1-.6-.4-1-.9-1z'
    ]
  },
  download: {
    viewBox: '0 0 42 42',
    path: 'M33.26 7.86H8.23c-.54 0-.98-.44-.98-.98s.44-.98.98-.98h25.04c.54 0 .98.44.98.98s-.44.98-.99.98zM30.51 3.35H10.98c-.42 0-.77-.44-.77-.98s.34-.98.77-.98h19.53c.42 0 .77.44.77.98s-.35.98-.77.98zM37.82 14.38v21.4c0 2.2-1.78 3.98-3.98 3.98H7.65c-2.2 0-3.98-1.78-3.98-3.98v-21.4c0-2.2 1.78-3.98 3.98-3.98h26.19c2.19.01 3.98 1.79 3.98 3.98zm-9.17 13.07c.54-.54.54-1.41 0-1.95s-1.41-.54-1.95 0l-4.57 4.57V16.19c0-.76-.62-1.38-1.38-1.38s-1.38.62-1.38 1.38v13.88L14.8 25.5a1.376 1.376 0 0 0-2.35.98c0 .36.13.71.4.98l7.9 7.9 7.9-7.91z'
  },
  twoFactor: {
    viewBox: '0 0 42 42',
    path: 'M34.78 8.83c-2.8-.06-8.04-.8-12.35-6.14a1.86 1.86 0 0 0-2.88 0C15.24 8.04 10 8.77 7.2 8.83c-.96.02-1.75.85-1.75 1.81v3.05h.01c.1 5.26 1.2 18.56 14.54 26.03.03.02.06.03.09.05.27.15.58.23.91.23.33 0 .64-.08.91-.23.03-.02.05-.03.08-.05 13.35-7.47 14.45-20.78 14.53-26.04h.01v-3.04c.01-.96-.78-1.79-1.75-1.81zM28.4 19.09l-8.01 7.96-6.13-6.14c-.39-.39-.39-1.02 0-1.42a.996.996 0 0 1 1.41 0l4.74 4.74L27 17.66a.996.996 0 0 1 1.41.01c.38.39.38 1.03-.01 1.42z'
  },
  destinationArrow: {
    viewBox: '0 0 42 42',
    path: 'M25.38 6.38c-1-1-2.61-1-3.61 0s-1 2.61 0 3.61l8.46 8.46H4.55C3.14 18.45 2 19.59 2 21s1.14 2.55 2.55 2.55h25.68l-8.46 8.46c-1 1-1 2.61 0 3.61a2.538 2.538 0 0 0 3.6 0L40 21 25.38 6.38z'
  },
  email: {
    viewBox: '0 0 42 42',
    path: ['M23.9 22.23 38.85 8.95H3.15L18.1 22.23c1.6 1.42 4.2 1.42 5.8 0M27.91 21l12.26 10.88V10.12z', 'M38.85 33.05 26.6 22.17l-1.54 1.37A6.119 6.119 0 0 1 21 25.06c-1.46 0-2.92-.5-4.06-1.52l-1.54-1.37L3.15 33.05h35.7zM14.09 21 1.83 10.12v21.76z']
  },
  passager: {
    viewBox: '0 0 42 42',
    path: ['M20.94 27.35c-1.25 0-3.36.28-4.45.4 0 0 1.46-2.53 7.23-2.54 1.19 0 1.55-2.4-.04-2.76-1.83-.41-5.64-.49-9.86.32-1.56-4.21-2.01-8.56-2.01-8.56s2.34-.75 2.22-4.41c-.17-4.99-5.84-4.15-5.84-4.15-1.47 11.76.28 26.05 3.14 32.38.76 1.67 1.97 2.52 4.22 2.52h10.38s3.13-3.3 3.13-9.82c.01-2.25-2.82-3.38-8.12-3.38z', 'M15.62 9.75c.09 2.76-.99 4.42-2.09 5.3.19 1.26.6 3.55 1.36 5.93 2.19-.33 4.02-.41 5.25-.41 1.55 0 2.89.12 3.89.34.48.11.89.32 1.23.61.98-.26 2.2-.45 3.73-.45 1.19 0 1.55-2.4-.04-2.76-1.83-.41-5.64-.49-9.86.32-1.56-4.21-2.01-8.56-2.01-8.56s2.34-.75 2.22-4.41c-.17-4.99-5.84-4.15-5.84-4.15-.15 1.17-.26 2.36-.34 3.57 1.53.97 2.43 2.59 2.5 4.67zM26.26 23.2c.07.33.09.67.05 1.03-.08.77-.41 1.43-.89 1.89 3.27.61 5 2.02 5.2 4.19v.04c.01.13.04.25.04.39 0 2.23-.34 4.12-.81 5.67h1.36s3.13-3.3 3.13-9.82c0-2.26-2.81-3.38-8.08-3.39z']
  },
  plus: {
    viewBox: '0 0 42 42',
    path: 'M35.91 17.38H24.62V6.1c0-2-1.62-3.62-3.62-3.62S17.38 4.1 17.38 6.1v11.28H6.1c-2 0-3.62 1.62-3.62 3.62s1.62 3.62 3.62 3.62h11.28V35.9c0 2 1.62 3.62 3.62 3.62s3.62-1.62 3.62-3.62V24.62H35.9c2 0 3.62-1.62 3.62-3.62s-1.61-3.62-3.61-3.62'
  },
  minus: {
    viewBox: '0 0 42 42',
    path: 'M35.91 17.38H6.1c-2 0-3.62 1.62-3.62 3.62s1.62 3.62 3.62 3.62h29.81c2 0 3.62-1.62 3.62-3.62s-1.62-3.62-3.62-3.62'
  },
  expand: {
    viewBox: '0 0 42 42',
    path: 'M41 3.5C41 2.1 39.9 1 38.5 1H26c-1.4 0-2.5 1.1-2.5 2.5S24.6 6 26 6h6.4l-8.2 8.2c-1 1-1 2.6 0 3.5 1 1 2.6 1 3.5 0L36 9.6V16c0 1.4 1.1 2.5 2.5 2.5S41 17.4 41 16V3.5zM17.8 24.2c-1-1-2.6-1-3.5 0L6 32.4V26c0-1.4-1.1-2.5-2.5-2.5S1 24.6 1 26v12.5C1 39.9 2.1 41 3.5 41H16c1.4 0 2.5-1.1 2.5-2.5S17.4 36 16 36H9.6l8.2-8.2c1-1 1-2.6 0-3.6z'
  },
  quality: {
    viewBox: '0 0 32 32',
    path: [
      'm19.6 13-4.8 4.5-2.3-2.4c-.3-.3-.7-.3-.9 0-.3.3-.3.7 0 .9l2.8 2.8.5.2.5-.2 5.3-5c.3-.3.3-.7 0-.9-.4-.1-.9-.1-1.1.1z',
      'M16 6C10.5 6 6 10.5 6 16s4.5 10 10 10 10-4.5 10-10S21.5 6 16 6zm0 18.6a8.6 8.6 0 1 1 0-17.2 8.6 8.6 0 0 1 0 17.2z',
      'm31.9 15.6-2.2-3.3.2-4c0-.3-.1-.5-.4-.6L26 6l-1.8-3.5c-.1-.2-.4-.4-.6-.4l-4 .2L16.3.1 16 0l-.4.1-3.3 2.2-4-.2c-.3 0-.5.1-.6.4L6 6 2.4 7.7a1 1 0 0 0-.3.7l.2 4-2.2 3.3v.7l2.2 3.3-.2 4c0 .3.1.5.4.6L6 26.1l1.8 3.5c.1.2.4.4.6.4l4-.2 3.3 2.2h.3l.4-.1 3.3-2.2 4 .2c.3 0 .5-.1.6-.4l1.8-3.5 3.5-1.8c.2-.1.4-.4.4-.6l-.2-4 2.2-3.3-.1-.7zm-3.4 3.5-.1.4.2 3.7-3.3 1.8c-.2 0-.3.1-.3.3l-1.7 3.3-3.7-.2-.4.1-3.2 2-3.1-2.1-.4-.1-3.7.2-1.7-3.3-.3-.2-3.3-1.7.2-3.7-.1-.4L1.5 16l2.1-3.1.1-.4-.3-3.8L6.7 7c.2 0 .3-.1.3-.2l1.7-3.3 3.7.2.4-.1L16 1.5l3.1 2.1.4.1 3.7-.2L25 6.8l.3.3 3.3 1.7-.2 3.7.1.4 2.1 3.1-2.1 3.1z'
    ]
  },
  freeDelivery: {
    viewBox: '0 0 32 32',
    path: [
      'M30.73,16c-1.4-1.5-2.74-3.05-4.11-4.58a3.66,3.66,0,0,0-2.86-1.27h-4c.09-.8.17-1.55.25-2.3,0-.36.09-.73.12-1.09a2.18,2.18,0,0,0-1.85-2.46,2.13,2.13,0,0,0-.44,0H4.09a2.17,2.17,0,0,0-2.2,1.88c0,.2,0,.41-.07.61-.06.63-.13,1.25-.2,1.91h4a.72.72,0,0,1,.8.62h0a.49.49,0,0,1,0,.12.74.74,0,0,1-.74.73H1.45L.63,17.46H10.9a.72.72,0,0,1,.66.78.74.74,0,0,1-.54.64,1.6,1.6,0,0,1-.44,0H.47c0,.47-.11.93-.16,1.38-.1,1-.24,1.93-.3,2.91a2.09,2.09,0,0,0,1.84,2.27,15.54,15.54,0,0,0,1.93,0,.68.68,0,0,1,0-.14,5.23,5.23,0,0,1,4.75-5,3.72,3.72,0,0,1,4.15,3.24,3.62,3.62,0,0,1,0,1c0,.27-.09.55-.14.83h7c0-.28,0-.55.09-.82a5.27,5.27,0,0,1,4.81-4.3,3.66,3.66,0,0,1,4,3.08,12.89,12.89,0,0,1,0,2.06h.87a2.21,2.21,0,0,0,2.25-2.07c.12-1.21.21-2.42.4-3.62A4.36,4.36,0,0,0,30.73,16Zm-23-1.45H2.88A.76.76,0,0,1,2,13.78c0-.44.34-.72.86-.72H7.77c.54,0,.85.27.85.73S8.3,14.51,7.77,14.52Zm4.61,0h0q-.81,0-1.62,0a.73.73,0,0,1,.05-1.45h1.54a.72.72,0,0,1,0,1.44Zm1.26-4.39H8.79c-.56,0-.89-.28-.88-.74s.31-.72.89-.72h4.85c.52,0,.86.3.85.73S14.16,10.11,13.64,10.11Zm14.75,8.37a.71.71,0,0,1-.72.44H21.62c-.63,0-.91-.32-.86-1,.11-1.26.21-2.51.31-3.77,0-.17,0-.35.06-.51a.68.68,0,0,1,.7-.63c.62,0,1.24,0,1.85,0a.92.92,0,0,1,.57.26c1.34,1.43,2.65,2.88,4,4.32A.72.72,0,0,1,28.39,18.48Z',
      'M25.3,21.92h0a2.1,2.1,0,0,0-.61-.05,3.77,3.77,0,0,0-3.57,3,2.29,2.29,0,0,0,1.59,2.81,2.22,2.22,0,0,0,1,.06A3.83,3.83,0,0,0,27,24.59,2.23,2.23,0,0,0,25.3,21.92Z',
      'M9.13,21.88H9a3.77,3.77,0,0,0-3.72,3A2.25,2.25,0,0,0,7,27.65l.18,0a3.73,3.73,0,0,0,4.1-3.3,1.94,1.94,0,0,0,0-.24A2.19,2.19,0,0,0,9.13,21.88Z'
    ]
  },
  SecurePayment: {
    viewBox: '0 0 32 32',
    path: [
      'M16,18.32a.78.78,0,1,0,0,1.55.77.77,0,0,0,.77-.77v0A.78.78,0,0,0,16,18.32Z',
      'M24.61,9.55H22.69V6.69a6.69,6.69,0,0,0-13.38,0V9.55H7.39a3.82,3.82,0,0,0-3.81,3.81h0V28.19A3.82,3.82,0,0,0,7.39,32H24.61a3.82,3.82,0,0,0,3.81-3.81V13.36A3.82,3.82,0,0,0,24.61,9.55Zm-5.87,11h0A3.12,3.12,0,0,1,17.5,21.8a.54.54,0,0,0-.34.58c0,1.07,0,2.13,0,3.2a1.17,1.17,0,1,1-2.32.22.89.89,0,0,1,0-.23c0-.55,0-1.09,0-1.64s0-1.09,0-1.63a.43.43,0,0,0-.28-.47,3.1,3.1,0,1,1,4.18-1.31Zm1.56-11H11.7V6.69a4.3,4.3,0,0,1,8.6,0Z'
    ]
  },
  Satisfied: {
    viewBox: '0 0 32 32',
    path: 'M17.6.6c-.4 0-.7.3-.7.7v2.8c0 .4.3.8.7.8s.7-.3.7-.7V1.4c.1-.6-.3-.8-.7-.8M13 3.4c-.4 0-.7.4-.7.7l.2.5 1.3 1.3c.3.3.8.3 1 0s.3-.7 0-1l-1.3-1.2a.5.5 0 0 0-.5-.3m9.4 0c-.2 0-.4 0-.5.2L20.6 5c-.3.3-.3.7 0 1s.7.3 1 0L23 4.7c.3-.3.3-.8 0-1-.2-.2-.3-.3-.5-.3m-4.7 3c-1.2 0-2 .8-2.4 1.9L15 9.6l-1 2.1c-1 2-2.6 4-5.2 5.6H3.4c-.4 0-.7.3-.7.6 0 .4.2.8.6.8H9l.3-.1A13 13 0 0 0 15 13c.5-1 .8-2 1-2.7l.6-1.5c.1-.6.3-1 1-1 .6 0 1.1.5 1.1 1.1V15c0 .4.3.7.7.7l6.4-.1c.9 0 1.6.8 1.6 2 0 .5-.1 1-.4 1.4-.3.2-.2.7 0 1 .4.4.8 1 .8 1.7 0 .6-.3 1.3-.6 1.6-.3.2-.3.5-.2.8l.2.9c0 .8-.5 1.5-1 1.6-.4 0-.6.4-.5.8v.1l.1.8c0 .9-.6 1.6-1.3 1.6h-6.3c-2.1 0-3.8-.6-5.3-1.3-1.5-.6-3-1.4-4.6-1.4h-5c-.4 0-.7.3-.7.7 0 .4.3.7.6.7h5.3c1.3 0 2.5.6 4 1.3a14 14 0 0 0 6 1.4h6.2c1.5 0 2.7-1.4 2.7-3v-.7a3 3 0 0 0 1.4-2.6l-.1-1.2c.4-.6.8-1.4.8-2.2s-.4-1.6-.8-2.3c.2-.5.5-1 .5-1.7 0-1.8-1.3-3.4-3-3.4h-5.9V8.6a2.6 2.6 0 0 0-2.5-2.2'

  },
  pin: {
    viewBox: '0 -960 960 960',
    path: [
        'M480-301q99-80 149.5-154T680-594q0-90-56-148t-144-58q-88 0-144 58t-56 148q0 65 50.5 139T480-301Zm0 101Q339-304 269.5-402T200-594q0-125 78-205.5T480-880q124 0 202 80.5T760-594q0 94-69.5 192T480-200Zm0-320q33 0 56.5-23.5T560-600q0-33-23.5-56.5T480-680q-33 0-56.5 23.5T400-600q0 33 23.5 56.5T480-520ZM200-80v-80h560v80H200Zm280-520Z'
    ]
  },
  temperature: {
    viewBox: '0 0 32 32',
    path: [
      'M28.13,10.41H25.4a.47.47,0,0,0,0,.94h2.77a.46.46,0,0,0,.44-.49A.47.47,0,0,0,28.13,10.41Z',
      'M26.64,6.61H25.4a.45.45,0,0,0-.47.46.48.48,0,0,0,.43.48h1.28a.47.47,0,0,0,.44-.5A.46.46,0,0,0,26.64,6.61Z',
      'M25.37,3.74h1.71a.47.47,0,0,0,0-.94H25.4a.47.47,0,0,0-.49.45A.49.49,0,0,0,25.37,3.74Z',
      'M27.12,18H25.4a.47.47,0,1,0,0,.94h1.72a.47.47,0,0,0,0-.94Z',
      'M27.11,14.69a.47.47,0,0,0-.47-.47H25.4a.47.47,0,0,0,0,.94h1.24A.47.47,0,0,0,27.11,14.69Z',
      'M12.43,10.63a.47.47,0,0,0,.47-.47.52.52,0,0,0-.24-.41l-.8-.45.59-.24a.48.48,0,0,0,.24-.62.49.49,0,0,0-.6-.25l-1.28.53L9.13,7.78l1.68-.94c1.31.52,1.29.56,1.45.56a.47.47,0,0,0,.47-.47.48.48,0,0,0-.29-.44l-.59-.24.81-.45a.47.47,0,0,0,.2-.63.48.48,0,0,0-.64-.2h0l-.8.45.1-.63a.47.47,0,0,0-.37-.55.48.48,0,0,0-.55.38v0L10.35,6,8.64,7V5l1.08-.86a.47.47,0,0,0,.09-.66.47.47,0,0,0-.66-.09v0l-.5.39V2.89a.47.47,0,1,0-.94,0v1l-.5-.39a.47.47,0,1,0-.58.73L7.7,5V7L6,6,5.76,4.65a.47.47,0,0,0-.54-.39.48.48,0,0,0-.39.54l.11.63L4.13,5a.47.47,0,0,0-.64.17.48.48,0,0,0,.17.65h0l.81.45-.59.24a.48.48,0,0,0-.24.62.47.47,0,0,0,.6.25l1.28-.52,1.68.94-1.68.94L4.25,8.19a.47.47,0,0,0-.36.87l.59.24-.8.45a.48.48,0,0,0-.19.64.49.49,0,0,0,.42.24c.2,0,.25-.09,1-.51l-.1.63a.46.46,0,0,0,.42.51.47.47,0,0,0,.5-.35L6,9.54l1.72-1v2l-1.09.86a.46.46,0,0,0-.05.66.47.47,0,0,0,.64.07l.5-.39v.92a.47.47,0,0,0,.93.07v-1c.44.31.53.49.8.49a.46.46,0,0,0,.46-.47.45.45,0,0,0-.17-.36l-1.08-.86v-2l1.71,1,.22,1.36a.47.47,0,0,0,.93-.15l-.1-.63C12.17,10.54,12.22,10.63,12.43,10.63Z',
      'M23.36,19.33V4.18a4.25,4.25,0,0,0-8.5,0V19.33a7,7,0,1,0,9.88,1.37A7.16,7.16,0,0,0,23.36,19.33Zm.75,9.13a6.12,6.12,0,0,1-5,2.59h0A6.11,6.11,0,0,1,15.61,20a.47.47,0,0,0,.2-.38V4.18a3.31,3.31,0,1,1,6.62,0V19.57a.47.47,0,0,0,.19.38A6.11,6.11,0,0,1,24.11,28.46Z',
      'M21.17,20.26V4.18a2.06,2.06,0,1,0-4.11,0V20.26a5,5,0,1,0,4.11,0Z'
    ]
  },
  burger: {
    viewBox: '0 0 24 24',
    path: [
      'M2 5v2h20V5H2zm0 6v2h20v-2H2zm0 6v2h20v-2H2z'
    ]
  },
  Guadeloupe: {
    viewBox: '0 0 64 64',
    path: 'M51.1 57.8c-1.2 0-2.3-.4-3.1-1.2-.8-.7-1.7-1.1-2.7-1.3-1.3-.4-2.7-.8-2.7-2.4 0-1.8.2-2.3 1.2-2.5.1 0 .2-.1.3-.1.6-.5 1-1.2 1.4-1.8.6-1.2 1.5-2.2 2.5-3.1.3-.2.6-.2.9-.2 1.4 0 2.6 1.7 3 2.3 2.1.1 2.4 4.1 2.4 7.9 0 .8-.4 1.5-1 1.9-.7.3-1.5.5-2.2.5m-38.5-4.7c-1.1 0-1.7-.9-1.9-2.7-.1-1.1 0-2.1.4-3.1.4-1.2.7-2.2-1-5.3-1.7-2.9-2.5-6.2-2.2-9.6v-.8c-.2-1.3-.6-2.6-1.3-3.8-.3-.6-.6-1.3-.8-1.9v-.1c-.3-1.4-1.4-5.5.1-7.6.5-.7 1.3-1.2 2.2-1.2h.7c1.8-.1 3.5.9 4.2 2.5.2.4.4.7.7 1 .9.9 3.6 1.1 4.6 1.1h.2l.1.2s2.4 2.9 4.6 2.9 3.4-1.7 4.8-4.4c.7-1.3 1.5-2.5 2.5-3.7-1.4-.9-4.9-3.7-4.7-6 .1-1 .8-1.8 2.2-2.3 3.5-1.3 3.4-2.2 3.3-2.5 0-.2 0-.5.2-.7s.4-.3.7-.2c1.5 0 4.1 1.7 4.5 3.1.3 1.8.4 3.7.3 5.6 0 1.3 1.6 2.6 2.2 3 1.4.6 2.9.9 4.4.9h1.3c1.7.7 7.1 3.1 7.1 5.1s-.4 3-3 3c-1.1.2-2.2.6-3.1 1.3-1.9 1.2-4.2 2-6.4 2.1-1.7 0-3.5.5-5.1 1.2-1.4.7-2.8 1.1-4.4 1.2-1.2.1-2.4-.6-2.7-1.8-.3-.6-.4-1-1.3-1-1.5 0-2.6.1-3.2.7-.4.5-.5 1.2-.4 1.8.1.8.5 1.6 1.2 2.1 1 .7 1.6 1.9 1.5 3.2 0 .6 0 1.2-.1 1.8-.1 1-.1 1 1.6 3.1 1.6 2 2.3 3.1 2.1 4.2-.3.8-.9 1.5-1.7 1.8-.7.4-1.3 1.1-1.6 1.8-.3 1-1.2 1.8-2.3 1.9-1.7.2-3.3.3-5 .3h-.4l-.1-.4c0-.2-.2-.3-.4-.3-.5.1-.9.3-1.3.6-1 1.1-2.2 1.9-3.3 1.9m45.6-25.8c-.4 1.6-2.6 1.5-2.5 2.7 1.3.3 1.8-.5 2.8-.7.5-.6.6-1.3-.1-1.6m-40 27.8c-1.5.1-2.5.9-2.3 2.5 1.2.4 2.4-.3 2.8-1.5.1-.2.1-.4.1-.7-.3.1-.4-.2-.5-.2m.8 2.5c.2.8-.9 1.1-1.8.9 0-.3 0-.6.2-.9.6-.1 1.2-.1 1.8 0m2.1-.2c-.4.1-.7.2-1.1.4-.1.3-.1.6 0 .9 1 .3 1.9-.1 1.6-1.2h-.7m.8-3.2c-.5.1-1.4.2-2 .4-1.3 2.3 5.1.3 1.8-.2'
  },
  guyane: {
    viewBox: '0 0 64 64',
    path: 'M30.6 59c-2.3 0-2.9-.1-3-.7-.2-.5.3-.8.8-1.2.1-.1.3-.2.4-.3.4-.2.6-.7.5-1.1-.7-.6-1.5-.9-2.4-1.1-.6-.1-1.2-.3-1.7-.5-.3-.1-.6-.2-.9-.2-.1 0-.2 0-.2.1-.3.2-.7.6-1.2 1.1-.5.6-1.1 1.1-1.8 1.6 0 0-.2.2-.2.3-.6.9-1.6 1.5-2.7 1.5H18c-.9 0-1.8-.3-2.5-.8-.2-.2-.6-.4-1-.7-.1-.1-.3-.2-.5-.2-.1.1-.2.1-.3.1-.3 0-.6-.1-1.6-.9-1-.7-1.4-1.4-1.4-2.1 0-.9.9-1.6 1.7-2.2 1.2-1.1 2.1-2.4 2.8-3.8l.1-.1c.2-.5.4-.9.5-1.4.2-1.1.7-2.2 1.3-3.2.9-1.2 1.4-2.7 1.5-4.1-.1-1.2-.3-2.3-.5-3.4v-.2c-.4-.7-1-1.4-1.6-2s-1.3-1.2-2-1.8c-.9-.5-1.5-1.4-1.8-2.3-.2-2-.3-3.9-.2-5.9-1-.7-1.7-1.8-1.7-3 0-1.9.6-3.8 1.7-5.3.6-.6 2.2-2.8 3.6-4.7l.2-.3c1.3-1.8 2.8-3.9 4.3-3.9.6 0 1.3.1 1.9.4.7-.8 1.7-1.4 2.8-1.5 1.4 0 4.5 2.1 4.8 2.3 1.2.6 2.6.9 3.9 1 1.9.1 3.8.6 5.5 1.6.9.7 4.9 4.8 5.3 5.3.2.3 2.9 3 4.6 4.8h.6c1.5 0 2.5.8 2.5 2 .1 1.1.4 2.3.8 3.3s.6 2 .5 3.1c-.7 1.5-1.6 2.8-2.6 4l-.2.3c-1 1.4-2.6 3.1-3.7 4.3l-.8.8c-.9.8-1.5 1.9-1.9 3-.1 1.2-.1 5.7-.1 5.7v.2l-.2.2c-.5.6-.8 1.2-1.1 1.9l-.2.4c-.7 2-1.7 3.9-3 5.5-.9.8-2 1.3-3.1 1.6-.9.3-1.7.6-2.5 1-.6.3-.7.5-.7.7-.5.7-1 .8-3.1.8'
  },
  martinique: {
    viewBox: '0 0 64 64',
    path: 'M46.5 58.8c-1.4.1-2.8-.5-3.7-1.6-.3-.5-.3-1.1 0-1.6.9-1.4 2-2.5 3.3-3.5l.3-.3c.7-.6 1.1-1.4 1.1-2.3v-.4c.1-.6 0-1.2-.2-1.8-.2.1-.3.2-.4.3l-.6.6c-.9.8-1.6 1.4-2.3 1.4-.5 0-1-.3-1.2-.7-.1-.2-.3-.4-.4-.5-.3-.5-.7-1.1-1-1.1-.2 0-.4.1-.5.3-.3.2-.5.5-.7.9-.3.5-.5.8-1.7 1.2-.5.2-1 .3-1.5.3-.6 0-1.1-.2-1.6-.5l-.6-.3c-1.4-.3-2.9-.5-4.3-.4h-.4c-.2.9-.8 2-1.9 2-.3 0-.7-.1-1-.3-.4-.2-.7-.3-.9-.4-1.1-.3-1.8-1.3-1.9-2.4-.2-.6-.6-1-1.1-1.3-.6-.5-1.6-1.2-.7-2.5.2-.2.4-.5.5-.8.9-1.6 2.1-2.9 3.7-3.8.4-.3.9-.4 1.4-.5.9 0 1.3.7 1.9 1.7l.3.4c.5.8.7 1 .8 1 .4-.3.7-.6 1-1l.1-.1.9-.9c.1-.1.3-.2.4-.4-.2-.3-.4-.5-.6-.8-.7-.7-1-1.6-1-2.6 0-.6-.2-1.2-.5-1.7s-.5-1-.5-1.6c-.1-.7-.1-.7-.7-.7-.3 0-.6 0-.8.1-1 .2-1.8.6-2.5 1.3-.5.5-1.2.9-1.9.9-.2 0-.5 0-.7-.1l-.6-.2c-.8-.2-1.5-.6-2-1.2l-.6-.6c-2-1.7-3.5-3.8-4.4-6.3-.2-.8-.6-1.5-1-2.2-.6-.9-.8-1.9-.7-3 .1-1.3.2-2 .2-2.2l-3.8-3.5c-.5-.1-1-.3-1.4-.7-.7-.9-1.1-2-.9-3.2.1-3.5.4-3.8 3.1-4.4l.3-.1c.7-.1 1.3-.4 1.9-.8 1-.6 2.1-.9 3.3-.7 1-.1 1.9 0 2.9.2.4.1 1.2.2 2.8.5 4.4.7 7.3 2 8.2 3.9 1.4 2 3 3.9 4.8 5.6 1.8-.2 3.6-.5 5.3-.8.5-.1.9-.3 1.2-.7.6-.6 1.4-1 2.2-1h.7c1.5 0 2.7.5 3 1.3.2.5 0 1.1-.4 1.4-.5.5-1 .8-1.6 1.1-.6.2-1.1.5-1.6.9-.8.8-1.9 1.2-3.1 1.3-.6 0-1.1-.2-1.5-.6-.6-.5-1.3-.8-2.1-.8-.1 0-.2 0-.4.1-.3.3-.5.6-.5 1 .2.1.5.3.8.4 2.1 1.1 3.9 2.1 4 3.2.1.6-.4 1.2-1 1.4-.1 0-.2.1-.3.1 0 .2.1.4.2.6.4.7 1.1.9 2.3 1 1 0 2 .3 2.8 1 1.1 1 1.6 1.7 1.5 2.4-.1.6-.4 1.1-.9 1.4-.6.4-1 .8-1.3 1.4.1.1.2.1.3.3 1.5 1.4 2.9 3 4.1 4.7 2.6 3.5 2.7 4.6 2.5 6.2-.1 1 1.1 2.7 1.6 3.4.1.2.2.4.3.5.5.9-.9 4-1 4.1 0 .1-4.3 7.8-6.5 8.2-.1-.1-.3-.1-.6-.1'
  },
  mayotte: {
    viewBox: '0 0 64 64',
    path: ['M51.8 27.1c.1-1.4-.4-2.7-1.4-3.6-.6-.4-.8-.2-1.4.3-.3.3-1.1 1-1.1 1.4.5 0 .5.1.9.4-.7 1.1-1.1 1.6-.4 2.8.4.7 1 2.2 1.8 2.2.1-.6-.2-1-.3-1.6.8-.2 2-.8 1.8-1.8M42.7 30c.6 0 1.1.7 1.2 1.2-.6.2-1.2.3-1.8.2-.2-.5 0-1.1.3-1.5M14.9 6.8c-.6-1.7-2.2-.5-2.7.4.1.1.2.1.2.2h1c.1.4.2.9.2 1.3.5-.2 2.4-1.2 1.3-1.9',
    'M24.7 8c-1.3.7-1.9.2-2.2 1.9-1.3.4-1.2 2.6-2.4 2.7-.2 1.8-1.8 3.2-3.6 3 0 .6-.2 1.7-.8 1.9-.1.3-.2.6-.5.8.9.6 2.9-.5 2.5 1.3-.8.3-1.2 1.3-2 1.6.1.4.2.8.4 1.2.3.3.7.5 1 .8.6-.5 1.3-1.1 2-.5.2.1.2.9.4 1.1.4.4.9.8 1.5 1.1.9-.5 1.1.3 1.9.4.5 0 1.6-1 1.6.3-.7.1-.4.6-.5.8-.2.2-.7.6-.8.9-.4 1.5 1.7.3 1.7 1.6-.8.3-1.5 1.7-.3 2 .6.1-.2 2.9-.2 3.5.9 0 1.9-.1 2.4.7-.4.8-1.4.3-2.2.5-.6.3-1.2.6-1.7 1.1.3.7.8 1.3 1.4 1.7 1 .6 1.3-.2 2 .7s2 3 3.3 3.1c.6.5.6 1.8 1 2.6.5 1 .6 2.2.5 3.3-1.6 1.4-4.3-.6-5.7-1.7-1-.8-.6-2-1.4-2.8-.7-.7-2.8-.6-3.9-.5-.2 1.2 1 2.3 1.7 3.2.2.3.6 1.4 1 1.5.6.3 1.2-.5 2-.2s-1 3.1-1.3 3.5c-.4.4-1.3.7-1.2 1.3 0 .4 1 1.1 1.4 1.2.6.1.8-.9 1.6-.5 1.1.5.3 1.3.1 1.9-.3.9 0 1.1.4 1.9.1-.7.8-1.2 1.5-1.1.2 0 .4.1.6.3.2.5 0 1.1.5 1.5.8-1.2 1.1-.1 1.8.2 1.1.5 1.7-.3 2.1-1 .4-.8.6-1.6 1.7-1.6 1.2 0 1.5 1.1 1.5 2.1.2.1.3.1.5 0-.2 0 .1-1.2.3-1.4.4-.3 1-.4 1.5-.2v.2c.5-.2.8-1.2 1.2-1.7-1-.4-2.4-.4-3.1-1.2-.8.2-.9-.5-.8-1.1-.5-.4-.9-.8-1.2-1.3-.2-1 .7-.7 1.6-.8.3-.6-.2-.9-.2-1.4 0-.8.2-.6.7-1.1s1.7-1.9 2.3-.8c.3.7.8-.2.8-.6-.7-.5-.6-1-.5-1.7-.1-.7.3-1.4 1-1.6 1-.6 2.3-1.6 2.1-3-1.4.1-.7-.2-1.2-.9-.4-.5-.8-.5-1.5-.8 0-1.2-1.6-1.8-1.5-3.2.1-1.6 1.1-1.9 1.8-3 1.4-2 2.2-4.6 5-4.7.7 0-.9-1.1-.8-.9-.4-.8.4-.9.7-1.7.3-.6.3-1.3 0-1.9-.5-.3-1-.7-1.5-.9l-1.5-.9c-.7-.4-1.1-1.1-1.8-1.5-1-.3-2-.5-3-.5-1-.3-2-.4-3.1-.3-.9.3-1 1.4-1.9 1.7 0-.3-.2-.4-.2-.7-1.3-.4-2.1-.6-2.7-1.7-.3-.5-.6-1-.7-1.6-.1-.6-.2-1.3-1-1.3-.6-.9-1-2-2.3-2.5 0-1.2-1.6-.9-1.5-2 1.3-.6 1.8-.5 2.1-2.1'
      ]
  },
  nouvellecaledonie: {
    viewBox: '0 0 64 64',
    path: 'M43.1 37.9v-.6c-.5 0-2-.8-2.1-1.3-1.3.1-1.2-1-2.4-1.2-.2-1.7-3-.6-2.7-2.2-1.3-.2-1.2-.6-2.2-1.4-.8-.6-1.6-.5-1.7-1.7-.7-.4-2.5-1-2.9-1.5-.7-.8.4-3-1.5-2.8 0-.3-.2-.5-.1-.8-.5-.2-.5-.6-.8-1-.4-.6-.3-.6-1-1-.9-.5-2-.6-2.8-1.4-.6-.7-1.3-1.3-2.1-1.9-1-.5-1.8-1.3-2.5-2.2-1-.3-1.3-1.4-2.4-1.8-.6-.2-1.3-.6-1.8-.7s-1.1-.6-1.1.4c-.9-.7-1.8-1.5-2.7-2.3-.5-.4-1-.6-1.3-1.1s-.3-1.3-.6-1.9c-1.8-.4.7 3.1.9 3.5.4.8 2.3 3 1.4 3.6.5.7.9 1.6 1.4 2.3.5.7 1.5 1.1 1.8 1.8-.4.6.6 1.4.8 2l-.3.3c1 1.7 2.8 2.2 4.1 3.6.5.7 1.1 1.3 1.7 1.8.8.5 1.3 1.4 1.2 2.4.8.5 1.5 1.1 2 2 .7.9 2 .9 2 2.2.6.2 1.4.4 1.6 1.1.9-.3 1.2.4 1.9 1 .4.4.8.7 1.2 1 .3.1.7-.1.9.1.3.1.4.5.7.7.3.1.6 0 .9.2.7.4.8 1.3 1.7 1.1.5.6.9 1.6 1.9 1.5.3.9 1.2.6 2 .7 1.3.1 1.3.3 1.5 1.5 0 .6.4 1.2.9 1.6.5.5 1.1.8 1.8 1 .4.1.6.2.9.5.2.2 0 .9.2 1 .7.4.9-.7 1.9-.4 1.5.5 2.3 2.7 4.2 1.4.6.7.5 1.3 1.3.8.6-.4.2-1.3 1.4-1.1.2-.7.3-1.5 0-2.2-.3-.5-1.1-.6-1.4-1-.5-.5-.4-1.1-.8-1.7-.5-.9-.7-.7-1.6-.9-.7-.1-1.3-.5-1.7-1.1-.3-.5-.7-.9-1.2-1.3-1-.9-1.5-1.8-2.7-2.4M33.6 14.2c-.5-.1-1 0-1.2.4-1.4.3-.4-1.1.2-1.4.9-.5 2-.5 2.7.2 2 1.5-.8 2.2-.6 4.1.2 0 .3.1.5.1.2 1.8-.7 2.4-1.9 3.4-.4.3-1 1.1-1.5 1.1-.6 0-1.5-.9-1.4-1.5 3.4-.6 3.3-3.7 2.8-6.4M44.1 20.6h-.9v-.4c-1 .2-2.7-.2-2.9 1.2.3 0 1.4.6 1.4 1.2 0 .8-1.2.7-1.8.8-.9 2.6 4 2.5 4.2 4.4.3 0 1.5-2 1.4-2.5-.7-.2-.3-.6-.6-1-.2-.3-.5-.6-.7-.9-.6-.8.1-1.9-.3-2.5M52.7 29.8c-.5 0-1 0-1.5-.1 0 .2-.1.3-.2.4h-.6v-.4c-1.4-.8-.7.7-.7 1.2.2 1-.4 1.7-.2 2.7 1 .4.6 1.4 1.4 1.9 1.2.8 1.6-.4 1.9-1.2.8 0 .8-.8.9-1.4h.2c.8-1.5-1.1-.7-1.6-1.1-.8-.8.7-1.1.4-2h-.2M58.2 52.1c-.4-.3-.8-.5-1-1-.3-.1-.6-.1-.9 0 0 .7-.6.5-.9.9-.4.4-.1.9-.7 1.2 1.1-.2 1.3 1.4 2.5 1.3.7-.5 1-1.3.8-2.1M44.6 51.7c-.8-1-1.4 1.1-.1.8v-.6M49.5 50c-.9-.2-1.6 0-1.1 1.1.8.2 1.2-.5.7-.9M6.4 9.7c-1-.1-.7 1.7.3 1.6.2-.6.3-1.4-.4-1.5M8.1 12.7c-.2-.1-.4-.2-.7-.1-.2.7.2 1.4.9 1.6h.3c.1-.5-.1-1.1-.5-1.5'
  },
  securePayment: {
    viewBox: "0 0 36 36",
    path: [
      "M31.25,7.4a43.79,43.79,0,0,1-6.62-2.35,45,45,0,0,1-6.08-3.21L18,1.5l-.54.35a45,45,0,0,1-6.08,3.21A43.79,43.79,0,0,1,4.75,7.4L4,7.59v8.34c0,13.39,13.53,18.4,13.66,18.45l.34.12.34-.12c.14,0,13.66-5.05,13.66-18.45V7.59ZM30,15.93c0,11-10,15.61-12,16.43-2-.82-12-5.44-12-16.43V9.14a47.54,47.54,0,0,0,6.18-2.25,48.23,48.23,0,0,0,5.82-3,48.23,48.23,0,0,0,5.82,3A47.54,47.54,0,0,0,30,9.14Z",
      "M10.88,16.87a1,1,0,0,0-1.41,1.41l6,6L26.4,13.77A1,1,0,0,0,25,12.33l-9.47,9.19Z"
    ]
  }
};
export default icons;
