<template src="@/components/MenuModal.html"/>
<style lang="scss" scoped src="@/components/MenuModal.scss"/>
<script>

import {
  SfSidebar,
  SfList,
  SfLink,
  SfBar,
  SfIcon,
  SfInput,
  SfButton
} from '@storefront-ui/vue';


import icons from "@/utils/icons";


export default {
  name: 'MenuModal',
  components: {
    SfButton,
    SfInput,

    SfSidebar,
    SfBar,
    SfLink,
    SfList,
    SfIcon
  },
  data() {
    return {
      activeMenu: '',
      icons: icons,
      navAnnexe: [
        {
          label: 'linkedIn',
          link: '',
          icon: 'M23 23v-8.1c0-4-.9-7-5.4-7-2.2 0-3.7 1.2-4.3 2.4h-.1v-2H8.8V23h4.6v-7.3c0-1.9.4-3.8 2.7-3.8s2.4 2.2 2.4 3.9v7.1l4.5.1zM1.4 8.4H6V23H1.4V8.4zM3.6 1C2.2 1 1 2.2 1 3.6s1.2 2.6 2.6 2.6S6.3 5 6.3 3.6 5.1 1 3.6 1z'
        },
        {
          label: 'facebook',
          link: '',
          icon: 'M15.997 3.985h2.191V.169C17.81.117 16.51 0 14.996 0c-3.159 0-5.323 1.987-5.323 5.639V9H6.187v4.266h3.486V24h4.274V13.267h3.345l.531-4.266h-3.877V6.062c.001-1.233.333-2.077 2.051-2.077z'
        }
      ],
      desktopMin: 768,
    }
  },

  computed: {
    locale: {
      get() {
        return this.$root.$i18n.locale
      }
    },
    isMenuModalOpen: {
      get() {
        return this.$store.getters.isMenuModalOpen
      }
    },
    categoryTree: {
      get() {
        return this.$store.getters.getCategoryTree
      }
    },
  },
  watch: {},

  methods: {
    isActive(catSlug, level) {
      return this.$router.history.current.params[level] === catSlug
    },
    isProduct(){
      return this.$route.name==='Category'
    },
    toggleMenuModal() {

      this.$store.dispatch('toggleMenuModal')
    },
    closeMenu(item) {
      //const itemClick = item.target.parentElement.children.item(1).checked = !item.target.parentElement.children.item(1).checked;
      //console.log(item.target.parentElement.querySelector(".menu-checkbox").classList.toggle('toto'));
    },
    checkMenu(item) {
      item.target.parentElement.classList.toggle("active");
    }
  }
};
</script>



