import Vue from "vue";
import Vuex, { createLogger, StoreOptions } from "vuex";
import { RootState } from "~/core/store/type";
import { category } from "~/core/store/category";
import { customer } from "~/core/store/customer";
import { ui } from "~/core/store/ui";
import { client } from "~/client/store/override";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
const store: StoreOptions<RootState> = {
  modules: {
    client,
    category,
    customer,
    ui,
  },
  strict: debug,
  devtools: debug,
  plugins: debug ? [createLogger()] : [],
};

export default new Vuex.Store<RootState>(store);