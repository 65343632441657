<template>
  <div :id="id" :class="selectedRelaisId&&selectedRelaisId === id?'sf-store selected':'sf-store'">
    <!-- @slot Use this slot to change distance element -->
    <slot name="distance">
      <div v-if="distance" class="sf-store__distance">
        <span v-if="distance<1000">{{ distance }}m</span>
        <span v-else>{{ distance / 1000 }}km</span>
      </div>
    </slot>

    <div class="sf-store__info">
      <div class="sf-store__heading">
        <!-- @slot Use this slot to show heading -->
        <slot name="heading">
          <div class="sf-store__name">
            <SfRadio :label="name" :selected="selectedRelaisId" :value="id" @change="chooseRelais(id,latlng)">
              <template #label>
                {{ name }}
              </template>
              <template #details>

              </template>
              <template #description>
                <slot name="address">
                  <div v-if="address" class="sf-store__address">
                    {{ address }}
                  </div>
                </slot>

              </template>

            </SfRadio>
            <slot name="openingTime">

            </slot>
          </div>
        </slot>
      </div>
      <!-- @slot This is the default slot of the component, placed on the right of the picture -->
    </div>
  </div>
</template>
<script>
import {focus} from '@storefront-ui/vue/src/utilities/directives';
import {SfRadio} from '@storefront-ui/vue';

export default {
  name: 'RelaisColissimmo',
  inject: [
    'chooseRelais',
  ],
  components: {
    SfRadio
  },
  directives: {
    focus: focus
  },
  props: {
    selectedRelaisId: {
      type: String,
      default: undefined
    },
    /**
     * Name of the store
     */
    latlng: {
      type: Object,
      default: () => {
      }
    },

    /**
     * Name of the store
     */
    name: {
      type: String,
      default: ''
    },

    /**
     * Url of the picture of the store
     */
    picture: {
      type: String,
      default: null
    },
    /**
     * Distance store to relais
     */
    distance: {
      type: Number,
      default: null
    },
    /**
     * store Id
     */
    id: {
      type: String,
      default: null
    },
    /**
     * Address of the store
     */
    address: {
      type: String,
      default: ''
    },
  },
  computed: {
    /* distance() {
       if (this.locatorData && this.locatorData.userPosition) {
         const dictLatLng = {
           lat: this.latlng[0],
           lng: this.latlng[1]
         };
         return this.getGeoDistance(this.locatorData.userPosition, dictLatLng);
       }
       return null;
     }*/
  },
 
};
</script>
<style lang="scss" scoped>

</style>
