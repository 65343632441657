import { Category } from "@/models/category";

export function filterData(data, predicate): any {

  // if no data is sent in, return null, otherwise transform the data
  return (data || []).reduce((list, entry: Category) => {

    let clone;
    if (predicate(entry)) {
      // if the object matches the filter, clone it as it is
      clone = { ...entry };

    } else if (entry.children !== null) {
      // if the object has childrens, filter the list of children
      const children = filterData(entry.children, predicate);
      if (children.length > 0) {
        // if any of the children matches, clone the parent object, overwrite
        // the children list with the filtered list
        clone = Object.assign({}, ...children);
      }
    }

    // if there's a cloned object, push it to the output list
    clone && list.push(clone);
    return list;
  }, []);
}

