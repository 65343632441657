<template src="@/components/Ork/OrkReassurance.html"></template>
<style lang="scss" scoped src="./OrkReassurance.scss"></style>

<script>
import {
  SfIcon,
  SfImage
} from '@storefront-ui/vue';
import {icons} from "@/utils/icons";
import i18n from "vue-lang-router";


export default {
  name: 'OrkReassurance',
  components: {
    SfIcon,
    SfImage
  },
  data() {
    return {
      icons: icons,
      reassurance:null
    }
  },
  created() {
    this.setReassurance();
  },
  methods: {
    setReassurance(){
      this.reassurance=[
          {
            text: 'components.orkReassurance.icon2',
            icon: '/assets/images/reassurance/Fast deliver.png'
          },
          {
            text:'components.orkReassurance.icon3',
            icon: '/assets/images/reassurance/paiement securise.png'
          },
          {
            text: 'components.orkReassurance.icon4',
            icon: '/assets/images/reassurance/sav.png'
          }]
    }
  }
};

</script>
