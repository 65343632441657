var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasAction)?_c('SfButton',{class:[
'ork-swatch',
'label-'+_vm.value.label,
 { 'label': _vm.value.label },
{ 'is-disabled': _vm.disabled },
{ 'is-active': _vm.selected }],on:{"click":function($event){return _vm.$emit('click', _vm.isSelected)}}},[(_vm.value.image)?_c('SfImage',{staticClass:"ork-swatch__img",attrs:{"alt":_vm.value.label,"src":_vm.value.image}}):(_vm.value.color)?_c('span',{staticClass:"ork-swatch__color",style:('background-color:'+_vm.value.color)}):_c('span',{staticClass:"ork-swatch__label"},[_vm._v(_vm._s(_vm.value.label))]),_c('div',{staticClass:"ork-swatch__tooltip"},[_vm._v(_vm._s(_vm.value.label))])],1):_c('span',{class:[
'ork-swatch',
'attribute',
'label-'+_vm.value.label,
{ 'is-disabled': _vm.disabled },
{ 'is-active': _vm.selected }],on:{"click":function($event){return _vm.$emit('click', _vm.isSelected)}}},[(_vm.value.image)?_c('SfImage',{staticClass:"ork-swatch__img",attrs:{"alt":_vm.value.label,"src":_vm.value.image}}):(_vm.value.color)?_c('span',{staticClass:"ork-swatch__color",style:('background-color:'+_vm.value.color)}):_c('span',{staticClass:"ork-swatch__label"},[_vm._v(_vm._s(_vm.value.label))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }