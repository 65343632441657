<template src="@/components/WelcomeModal.html"/>
<style lang="scss" scoped src="@/components/WelcomeModal.scss"/>
<script>

import {
  SfButton,
  SfModal,
  SfBar
} from '@storefront-ui/vue';


export default {
  name: 'WelcomeModal',
  components: {
    SfButton,
    SfModal,
    SfBar
  },
  computed: {
    isWelcomeModal: {
      get() {
        return this.$store.getters.isWelcomeModal
      }
    },
  },

  methods: {
    toggleWelcomeModal() {
      this.$store.dispatch('toggleWelcomeModal', {customer: null})
    },
    goToAccount() {
      this.$store.dispatch('toggleWelcomeModal', {customer: null})
      this.$router.push(this.$localizePath("/mon-compte")).catch()
    }
  }
};
</script>
