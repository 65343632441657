import { Module } from "vuex";
import { RootState } from "~/core/store/type";
import { ClientState } from "@/store/type";
import { getters } from "@/store/getters";
import { actions } from "@/store/actions";
import { mutations } from "@/store/mutations";

const state: ClientState = {
  isMenuDesktopModalOpen: false,
};

export const client: Module<ClientState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};




