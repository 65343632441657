<template src="./App.html"></template>
<style lang="scss" src="./App.scss"></style>

<script>


import store from "@/store";
import {CustomerActions} from "@/store/customer/actions"

const default_layout = 'default';

import Vue from "vue";

export default Vue.extend({
  name: 'SED',

  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout';
    },
    locale: {
      get() {
        return this.$store.getters.getCurrentLocale
      }
    }
  },  watch: {
    $route : 'getRoute',
  },
  mounted() {
    store.dispatch("getTopPromotional")
    store.dispatch("getFranco")
  },
  methods:{
    getRoute() {
      this.currentRoute = this.$route.path
      if (this.$i18n.locale !== this.locale){
        this.$store.dispatch('setCurrentLocal',{locale:this.$i18n.locale})
        document.getElementsByTagName("html")[0].lang = this.$i18n.locale;

        this.$store.dispatch(CustomerActions.getMe)

        // VSX 2024-07-19 Already done by the router
        //this.$store.dispatch('getRootCategories').then(()=>{
        //    this.$store.dispatch('getCategoryTree')
        //})
      }
    }
  }

});
</script>
