import { Customer } from "@/models/customer";
import { MarketingEventHandler } from "./MarketingEventHandler";
import { OrderMarketingtEventData, ProductMarketingEventData } from "../MarketingEventService";

export default class GtagEventHandler implements MarketingEventHandler {

    handlerName(): string {
        return "gtag";
    }

    private trackEvent(eventName, eventData) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ecommerce: null});
        window.dataLayer.push({
            event: eventName,
            ecommerce: eventData
        });
    }

    viewCart = (cartData: OrderMarketingtEventData): void => {
        this.trackEvent("view_cart", cartData);
    };

    viewItem = (cartData: OrderMarketingtEventData): void => {
        this.trackEvent("view_item", cartData);
    };

    viewCategory = (eventData): void => {
        this.trackEvent("view_category", eventData);
    };

    viewPage = (eventData): void => {
        this.trackEvent("view_item", eventData.title);
    };

    addToCart = (cartData: OrderMarketingtEventData, productData: ProductMarketingEventData): void => {
        this.trackEvent("add_to_cart", cartData);
    };

    removeFromCart = (cartData: OrderMarketingtEventData, productData: ProductMarketingEventData): void => {
        this.trackEvent("remove_from_cart", cartData);
    };

    beginCheckout = (cartData: OrderMarketingtEventData): void => {
        this.trackEvent("begin_checkout", cartData);
    };

    purchase = (orderData: OrderMarketingtEventData): void => {
        this.trackEvent("purchase", orderData);
    };

    search = (searchTerm: string): void => {
        this.trackEvent("search", searchTerm);
    };

    signUp = (customerData: Customer): void => {
        this.trackEvent("sign_up", customerData);
    };

    login = (customerData: Customer): void => {
        this.trackEvent("login", customerData);
    };

    updateProfile = (customerData: Customer): void => {
        this.trackEvent("update_profile", customerData);
    }
}
