<template src="@/components/Ork/OrkHelloBar.html"></template>
<style lang="scss" scoped src="@/components/Ork/OrkHelloBar.scss"></style>

<script>
import {SfButton} from '@storefront-ui/vue';
import Validation from "@/utils/validation";


import {Navigation, Pagination, EffectFade} from 'swiper'
import {SwiperCore, Swiper, SwiperSlide} from 'swiper-vue2'
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination, EffectFade])

// This is how we should import once we are in Vue3 and can use a more recent version of swiper
// import {Swiper as SwiperCore} from 'swiper'
// import {Navigation, Pagination, EffectFade} from 'swiper'
// import {Swiper, SwiperSlide} from 'swiper/vue'
// import "swiper/swiper-bundle.css";
// SwiperCore.use([Navigation, Pagination, EffectFade])

import Api from "@/api/Api";
import {PromotionnalItem} from "@/models/marketingHome";


export default {
  name: 'OrkHelloBar',
  components: {
    SfButton,
      Swiper,
      SwiperSlide
  },
  data() {
    return {
      Validation,
      showPromotionalBar: process.env.VUE_APP_SHOW_PROMOTIONAL_BAR  === 'true',
      error: false,
      errorMesage: null,
      loading: true,
      dateNow: new Date(Date.now()),
      topPromotional:[]

    };
  },
  computed: {
    locale: {
      get() {
        return this.$i18n.locale
      }
    },
  },

    created(){
        this.getPromotionnal()
    },

    methods:{
        getPromotionnal(){
            this.loading=true
            setTimeout(() => {
                    Api.marketing.getTopPromotional().then((data) => {
                        const promotionnalItems = []
                        data.forEach(function (item) {
                            promotionnalItems.push(PromotionnalItem.of(item))
                        })
                        this.topPromotional= promotionnalItems;

                    }).catch((e) => {
                        console.log(e)
                    }).finally(() => {
                        this.loading = false
                    })
                }
,500)
        }
    }
};
</script>
