import { i18n } from 'vue-lang-router';

export class MarketingHome {

  seo?: Seo;
  featured?: unknown;
  slider?: Slider[];
  universes?: Univers[];

  public static of(data: any): MarketingHome {
    const content: MarketingHome = new MarketingHome();

    content.featured = {
      title: data.marketing_featured_title,
      link: data.marketing_featured_link,
      image: data.marketing_featured_image || '',
      description: data.marketing_featured_description
    };
    content.slider = data.marketing_homepage_slider.map(s => {
      return Slider.of(s);
    });
    content.universes = data.marketing_homepage_universes.map(s => {
      return Univers.of(s);
    });

    content.seo = Seo.of(data);
    return content;
  }
}

export class Seo {
  title?: string | null;
  description?: string | null;

  public static of(data: any): Seo {
    const s: Seo = new Seo();
    s.title = data?.cms_seo_title;
    s.description = data?.cms_seo_description;
    return s;
  }
}

export class ContentPage {
  title?: string | null;
  description?: string | null;
  seo?: { title: string | null, description: string | null };
  purchase_guide?: any;

  public static of(data: any): ContentPage {
    const c: ContentPage = new ContentPage();
    c.title = data.cms_title || null;
    c.description = data.cms_description || null;
    c.purchase_guide = data.purchase_guide || null;
    c.seo = {
      title: data.cms_seo_title || null,
      description: data.cms_seo_description || null
    };
    return c;
  }
}

export class BestSellers {
  title?: string | null;
  description?: string | null;
  products?: string | null;

  public static of(data: any): BestSellers {
    const s: BestSellers = new BestSellers();
    s.title = data?.homepage_bestsellers_title || i18n.t('bestseller.title');
    s.description = data?.homepage_bestsellers_description || i18n.t('bestseller.description');
    s.products = data?.homepage_bestsellers?.join(',') || '';
    return s;
  }
}

export class FeaturedHome {
  title?: string | null;
  description?: string | null;
  products?: string | null;

  public static of(data: any): FeaturedHome {
    const s: FeaturedHome = new FeaturedHome();
    s.title = data?.homepage_featured_products_title || i18n.t('featured.title');
    s.description = data?.homepage_featured_products_description || i18n.t('featured.description');
    s.products = data?.homepage_featured_products?.join(',') || '';
    return s;
  }
}

export class MarketingSection {
  title?: string | null;
  description?: string | null;
  image?: string | null;
  imageMobile?: string | null;
  link?: string | null;
  buttonLabel?: string | null;

  public static of(data: any, section: string): MarketingSection {
    const s: MarketingSection = new MarketingSection();

    const image = data?.['homepage_section_photo_' + section];
    const imageMobile = data?.['homepage_section_photo_mobile_' + section];

    s.title = data?.['homepage_section_title_' + section] || null;
    s.buttonLabel = data?.['homepage_section_button_label_' + section] || i18n.t('Discover');
    s.description = data?.['homepage_section_description_' + section] || null;
    s.link = data?.['homepage_section_url_' + section] || null;
    s.image = Array.isArray(image) ? image[0]?.url : null;
    s.imageMobile = Array.isArray(imageMobile) ? imageMobile[0]?.url : null;

    return s;
  }
}

export class Promotions {
  title?: string | null;
  description?: string | null;
  products?: string | null;

  public static of(data: any): Promotions {
    const s: Promotions = new Promotions();
    s.title = data?.homepage_discount_title || i18n.t('discount.title');
    s.description = data?.homepage_discount_description || i18n.t('discount.description');
    s.products = data?.homepage_discount_products.join(',') || '';
    return s;
  }
}

export class Universe {
  universes?: Univers[];
  description?: string | null;
  title?: string | null;
  background?: string | null;

  public static of(data: any): Universe {
    const u: Universe = new Universe();
    let i = 1
    u.title = data?.homepage_universe_title;
    u.description = data?.homepage_universe_description;
    u.background = data?.homepage_universe_image[0]?.url || '/assets/images/placeholder.webp';
    u.universes = data?.homepage_universe_blocks.map(s => {
      if(i===1)
        s.position = "center"
      if(i===2)
        s.position = "topLeft"
      if(i===3)
        s.position = "bottomLeft"
      if(i===4)
        s.position = "right"
      i++
      return Univers.of(s);
    });

    return u;
  }
}

export class Sliders {
  sliders?: Slider[];

  public static of(data: any): Sliders {
    const u: Sliders = new Sliders();
    u.sliders = data.homepage_slider.map(s => {
      return Slider.of(s);
    });
    return u;
  }
}

export class Univers {

  title?: string | null;
  link?: string | null;
  image?: any | null;
  position?: string | null;

  public static of(data: any): Univers {
    const s: Univers = new Univers();

    s.image = Array.isArray(data?.homepage_universe_block_image) ? data?.homepage_universe_block_image[0]?.url : '/assets/images/placeholder.webp';
    s.title = data?.homepage_universe_block_title || '';
    s.link = data?.homepage_universe_block_url || '';
    s.position = data?.position || '';
    return s;
  }
}

export class Slider {
  description?: string | null;
  title?: string | null;
  link?: string | null;
  image?: Record<string, unknown>;
  label?: string | null;

  public static of(data: any): Slider {
    const s: Slider = new Slider();
    s.description = data?.homepage_slider_block_description || null;
    s.title = data?.homepage_slider_block_titre || null;
    s.link = data?.homepage_slider_block_link || null;
    s.label = data?.homepage_slider_block_button_label || null;
    s.image = {
      desktop: {
        url: data?.homepage_slider_block_image[0]?.url || '',
        alt: data?.homepage_slider_block_image[0]?.alt || ''
      }, mobile: {
        url: data?.homepage_slider_block_image_mobile[0]?.url || '',
        alt: data?.homepage_slider_block_image_mobile[0]?.alt || ''
      }
    };
    return s;
  }
}

export class Featured {
  description?: string | null;
  title?: string | null;
  link?: string | null;
  image?: any;
  label?: string | null;

  public static of(data: any): Featured {
    const f: Featured = new Featured();
    f.title = data.homepage_featured_title;
    f.link = data.homepage_featured_link;
    f.image = data.homepage_featured_image || '';
    f.description = data.homepage_featured_description;
    f.label = data.homepage_featured_button_label || i18n.t('Discover');
    return f;
  }
}

export class MarketingCategory {
  footer?: Record<string, unknown>;
  header?: Record<string, unknown>;
  seo?: Record<string, unknown>;

// data?.landing_page_header_image[0]?.url ||
  public static of(data: any): MarketingCategory {
    const c: MarketingCategory = new MarketingCategory();
    c.header = {
      title: data?.landing_page_title || '',
      description: data?.categ_description || '',
      description_short: data?.categ_description || '',
      image: {
        'alt': data?.categ_image_banner && data?.categ_image_banner[0]?.alt || '',
        'url': data?.categ_image_banner && data?.categ_image_banner[0]?.url || '/assets/images/placeholder.webp'
      },
      thumbnail: {
        'alt': data?.categ_image_banner && data?.categ_image_banner[0]?.alt || '',
        'url': data?.categ_image_banner && data?.categ_image_banner[0]?.url || '/assets/images/placeholder.webp'
      }
    };
    c.seo = {
      title: data?.categ_seo_title || '',
      description: data?.categ_seo_description || ''
    };
    return c;
  }
}

export class ListCategory {
  label?: string
  description?:string
  thumbnail?: any;
  path?: string;
  slug?: string;

// data?.landing_page_header_image[0]?.url ||
  public static of(data: any): ListCategory {
    const c: ListCategory = new ListCategory();
    c.label = data?.categ_label || '';
    c.description = data?.categ_description_short || '';
    c.thumbnail = {
      'alt': data?.categ_image_thumbnail && data?.categ_image_thumbnail[0]?.alt || c.label,
      'url': data?.categ_image_thumbnail && data?.categ_image_thumbnail[0]?.url || '/assets/images/placeholder.webp'
    };
    c.path =data?.categ_path || '';
    c.slug =data?.categ_slug || '';

    return c;
  }
}


export class HomeCategories {
  categories?: HomeCategory[];

  public static of(data: any): HomeCategories {
    const u: HomeCategories = new HomeCategories();
    if (data.homepage_categories) {
      u.categories = data.homepage_categories.map(s => {
        return HomeCategory.of(s);
      });
    } else {
      u.categories = [];
    }
    return u;
  }
}

export class HomeCategory {
  category?: string | null;
  title?: string | null;
  link?: string | null;
  image?: Record<string, unknown>;
  label?: string | null;
  blockTitle?: string | null;

  public static of(data: any): HomeCategory {
    const s: HomeCategory = new HomeCategory();
    s.category = data?.homepage_categories_category || null;
    s.title = data?.homepage_categories_title || null;
    s.link = data?.homepage_categories_url || null;
    s.label = data?.homepage_slider_block_button_label || i18n.t('Discover');
    s.image = {
      url: data?.homepage_categories_photo[0]?.url || '/core/assets/images/placeholder.webp',
      alt: data?.homepage_categories_photo[0]?.alt || ''
    };
    s.blockTitle = data?.dataBlockTitle.trimEnd().split(' ').join('-').toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '').replace(/[^a-zA-Z-]+/g, '').replace('--', '-') || '';

    return s;
  }
}

export class PromotionnalItem {
  startDate?: Date;
  endDate?: Date;
  title?: string | null;
  isActive?: boolean;
  btn?: Record<string, unknown>;
  isEvent?: boolean;

  public static of(data: any): PromotionnalItem {
    const s: PromotionnalItem = new PromotionnalItem();

    s.title = data?.title || null;
    if (data.btn) {
      s.btn = {
        link: data?.btn.link || null,
        label: data?.btn.label || i18n.t('Discover')
      };
    }
    s.isActive = data?.isActive || false;
    s.isEvent = data?.isEvent || false;
    s.startDate = data?.startDate || false;
    s.endDate = data?.endDate || false;
    return s;
  }
}
