import "@/assets/css/styles.scss";

import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import Blank from "@/layouts/Blank.vue";
import DefaultCoreLayout from "@/layouts/Default.vue";
import Checkout from "@/layouts/Checkout.vue";
import { i18n } from "vue-lang-router";
import VueI18n from "vue-i18n";
import "@/utils/filters";


let DefaultLayout;
let CheckoutLayout;
let BlankLayout;
// Charger le layout personnalisé s'il existe, sinon utiliser le layout par défaut
try {
    const DefaultClientLayout = require('@/layouts/Default.vue').default;
    DefaultLayout = DefaultClientLayout || DefaultCoreLayout;
} catch (error) {
    DefaultLayout = DefaultCoreLayout;
}

try {
    const BlankClientLayout = require('@/layouts/Blank.vue').default;
    BlankLayout = BlankClientLayout || Blank;
} catch (error) {
    BlankLayout = Blank;
}

try {
    const CheckoutClientLayout = require('@/layouts/Checkout.vue').default;
    CheckoutLayout = CheckoutClientLayout || Checkout;
} catch (error) {
    CheckoutLayout = Checkout;
}

Vue.component('default-layout', DefaultLayout);
Vue.component("blank-layout", BlankLayout);
Vue.component("checkout-layout", CheckoutLayout);

import "@/client-main";

Vue.config.productionTip = false;
Vue.use(VueI18n);
new Vue(
    {
        router,
        i18n,
        store,
        render: h => h(App),
    },
).$mount("#app");
