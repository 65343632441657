import { GetterTree } from "vuex";
import { RootState } from "~/core/store/type";
import { UiState } from "~/core/store/ui/type";

export const getters: GetterTree<UiState, RootState> = {

  isLoginModalOpen: (state): any => {
    return state.isLoginModalOpen;
  },
  isMenuModalOpen: (state): boolean => {
    return state.isMenuModalOpen;
  },
  isProductsActionModal: (state): any => {
    return state.isProductsActionModal;
  },
  isNoStockActionModal: (state): any => {
    return state.isNoStockActionModal;
  },
  isStockAlertModal: (state): any => {
    return state.isStockAlertModal;
  },
  relayModal: (state): any => {
    return state.relayModal;
  },
  isAddressModalOpen: (state): any => {
    return state.isAddressModalOpen;
  },
  isQuickOrderModal: (state): any => {
    return state.isQuickOrderModal;
  },
  isAddressListModalOpen: (state): boolean => {
    return state.isAddressListModalOpen;
  },
  isSearchOpen: (state): boolean => {
    return state.isSearchOpen;
  },
  isWelcomeModal: (state): any => {
    return state.isWelcomeModal;
  },
  isCgvModal: (state): any => {
    return state.isCgvModal;
  },
  isResetModal: (state): any => {
    return state.isResetModal;
  },
  isCartSidebarOpen: (state): boolean => {
    return state.isCartSidebarOpen;
  },
  isProductSidebarOpen: (state): any => {
    return state.isProductSidebarOpen;
  },
  isRootCategoryModalOpen: (state) => {
    return state.rootCategoryModalOpen;
  },
  getNotifications: (state) => {
    return state.notifications;
  },
  getFiltersValues: (state) => {
      return state.filtersValues;
  },
  getProductGallery: (state) => {
    return state.productGallery;
  },
  getIndexGallery: (state) => {
    return state.indexGallery;
  },
  getCurrentLocale: (state): any => {
    return state.currentLocal;
  },
  getFranco: (state): any => {
    return state.franco;
  },
  getTopPromotional: (state): any => {
    return state.topPromotional;
  },
  getSelectedRelais: (state) => {
    return state.relayModal.selectedRelais;
  },
};
