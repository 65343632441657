import { Address } from "./address";
import { Price } from "./price";
import { Shipment, ShipmentProduct } from "./shipment";

export class ShoppingCartItem {
  quantity!: number;
  product!: ShipmentProduct;
  unitPrice!: UnitPrice;
  isGift!: boolean;
  price!: Price;

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(data: any): ShoppingCartItem {
    const s: ShoppingCartItem = new ShoppingCartItem();
    s.quantity = data.quantity;
    s.product = ShipmentProduct.of(data.product);
    s.price = Price.of(data.price);
    s.unitPrice = UnitPrice.of(data.product?.offer);
    s.isGift = data.isGift || false;
    return s;
  }
}

export class UnitPrice {
  regular!: number | null;
  discount!: number | null;

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(data: any): UnitPrice {
    const p: UnitPrice = new UnitPrice();
    p.regular = data?.regularPrice?.taxIncluded || null;
    p.discount = data?.discountPrice?.taxIncluded || null;
    return p;
  }
}

export class ShoppingCart {
  id!: string;
  cartItems!: ShoppingCartItem[];
  coupon!: any;
  billingAddress!: Address | null;
  shippingPrice!: Price;
  shipments!: Shipment[];
  totalPrice!: Price;
  specialPrice!: Price;

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(data: any): ShoppingCart {
    const s: ShoppingCart = new ShoppingCart();

    s.id = data?.id;
    s.cartItems = [];
    data?.cartItems.forEach(ci => s.cartItems?.push(ShoppingCartItem.of(ci)));
    s.coupon = data.coupon;
    s.billingAddress = data.billingAddress ? Address.of(data?.billingAddress) : null;
    s.specialPrice = Price.of(data.specialPrice);
    s.totalPrice = Price.of(data.totalPrice);
    s.shippingPrice = Price.of(data.shippingPrice);
    s.shipments = [];
    data?.shipments.forEach(shipment => s.shipments?.push(Shipment.of(shipment)));

    return s;
  }
}
