<template src="@/components/Ork/OrkHomeGallery.html"></template>

<script>

import {
  SfIcon,
  SfImage,
  SfButton
} from '@storefront-ui/vue';

import icons from "@/utils/icons";

import {Navigation, Pagination} from 'swiper'

import {SwiperCore, Swiper, SwiperSlide} from 'swiper-vue2'

import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination])

import Api from "@/api/Api";
import OrkLoader from "@/components/Ork/OrkLoader";


export default {
  name: 'OrkHomeGallery',
  components: {
    OrkLoader,
    SfImage,
    SfIcon,
    SfButton,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      index: null,
      icons: icons,
      gallery: null,
      loading: false,
      isMobile: false,
      img:null,
    };
  },
  computed:{
    categoryTree: {
      get() {
        return this.$store.getters.getCategoryTree
      }
    },
  },
  watch:{
    categoryTree: {
      handler(catNew) {
        if(catNew) {
          this.getHomeSlider();
        }
      }, immediate: true
    },
  },
  created() {
    this.getHomeSlider();
    this.checkDisplaySize();

  },
  beforeDestroy() {
    window.matchMedia("(max-width :520px)").removeEventListener('change', this.updateDesktop)
  },
  methods: {
    checkDisplaySize() {
      const mql = window.matchMedia("(max-width :520px)");
      if (mql.matches) {
        this.isMobile = true;
        }else{
          this.isMobile = false;
        }

        mql.addEventListener("change", (e) => {
          this.updateImage(e);
        });

    },
    updateImage(event){
      this.isMobile = event.matches;
    },
    getHomeSlider() {
      this.loading = true
      Api.marketing.getHomeSlider().then((data) => {
        this.gallery = data.sliders

      }).catch((e) => {


      }).finally(()=>{
        this.loading = false
      })
    }

  }
};
</script>


<style lang="scss" scoped src="@/components/Ork/OrkHomeGallery.scss"></style>