import QorusClient from "@/api/clients/QorusClient";
import { Category, RootCategory } from "@/models/category";
import { ShoppingCart } from "@/models/shopping";

export const SET_ROOT_CATEGORY_PATH = "shopping-cart/catalog";
/**
 *  Qorus API about categories
 */
export default class CategoryService {
    private qorusClient = QorusClient.of();
    
    static of(): CategoryService {
        return new CategoryService();
    }

    /**
     * Get tree of categories
     * @return CategoryDTO Tree of categories
     */
    getTree(): Promise<Category> {
        return this.qorusClient.get("categories").then(
            (data) => {
                return Category.of(data);
            },
            data => {
                throw data;
            },
        );
    }
    getRootCategories(): Promise<RootCategory[]> {
        return this.qorusClient.get("categories/roots", {}).then(
            (data) => {
                console.log("root categories", data);
                return data?.map(category => RootCategory.of(category)) || [];
            },
            data => {
                throw data;
            },
        );
    }

    setRootCategoryPath(rootCategoryPath: string | null): Promise<ShoppingCart> {
        return this.qorusClient.post(SET_ROOT_CATEGORY_PATH, { "rootCategoryPath": rootCategoryPath })
            .then(
                data => ShoppingCart.of(data),
                data => { throw data; }
            );
    }
}