<template>
  <div class="OrkNoResultGrid">
    <SfNotification type="secondary" class="error-msg" :visible="true" :persistent="true" >
      <template #icon><span></span></template>
      <template #message>
        <div  class="sf-notification__message">{{$t('No result found')}}</div>
        <SfButton class="sf-button color-primary sf-button--small reset" @click="clearFilters">{{$t('components.orkProductGrid.actions.clearFilters')}}</SfButton>

      </template>
    </SfNotification>


  </div>
</template>
<script>

import {SfButton, SfNotification} from '@storefront-ui/vue';

export default {
  name: "OrkNoResultGrid",
  components: {
    SfButton,
    SfNotification
  },

  methods: {
    clearFilters() {
      this.$store.dispatch('setFiltersValues', [])
    },

  },
};
</script>
<style lang="scss" scoped>
::v-deep{

  .error-msg{
    padding-bottom: var(--spacer-xs);
  }
  .reset{
    margin-top: 20px;
    margin-bottom: 0;
  }
  .OrkNoResultGrid{
    background-color: var(--c-secondary);
    font-weight: bold;
    text-align: center;
    margin: 0;
  }
}
</style>

