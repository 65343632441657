var render = function render(_c,_vm){return (_vm.props.useSelect)?_c('div',{class:[_vm.data.class, _vm.data.staticClass, 'sf-quantity-selector'],style:([_vm.data.style, _vm.data.staticStyle])},[_c(_vm.injections.components.SfSelect,{tag:"component",staticClass:"sf-quantity-selector__select",attrs:{"name":_vm.$options.uniqueKey(),"value":_vm.props.qty.toString()},on:{"input":function($event){return _vm.$options.handleSelect($event, _vm.listener)}}},_vm._l((_vm.props.max),function(i){return _c('SfSelectOption',{key:i,attrs:{"value":i.toString()}},[_vm._v(" "+_vm._s(i)+" ")])}),1)],1):_c('div',{class:[_vm.data.class, _vm.data.staticClass, 'sf-quantity-selector'],style:([_vm.data.style, _vm.data.staticStyle])},[(_vm.props.showButtons)?_c(_vm.injections.components.SfButton,{tag:"component",staticClass:"sf-button--pure sf-quantity-selector__button minus",attrs:{"disabled":_vm.props.disabled || Boolean(_vm.props.min !== null && _vm.props.qty <= _vm.props.min),"data-testid":"decrease"},on:{"click":function($event){_vm.$options.handleButton(
          Number(_vm.props.qty)<Number(_vm.props.packSize)?Number(_vm.props.packSize): Number(_vm.props.qty) - Number(_vm.props.packSize),
          _vm.listeners,
          _vm.props.min,
          _vm.props.max,
          _vm.props.packSize,
          _vm.props.ecommerceStyle,
          _vm.props.isInCart,
          _vm.props.gridMode
        )}}},[_c('SfIcon',{attrs:{"icon":"minus","size":"xxs"}})],1):_vm._e(),_c(_vm.injections.components.SfInput,{tag:"component",staticClass:"sf-quantity-selector__input",attrs:{"disabled":_vm.props.disabled,"min":_vm.props.min,"name":_vm.$options.uniqueKey(),"value":Number(_vm.props.qty),"data-testid":"sf-quantity-selector input","type":"number"},on:{"blur":function($event){return _vm.$options.handleBlur(_vm.listeners)},"input":function($event){return _vm.$options.handleInput($event, _vm.listeners, _vm.props.min, _vm.props.max, _vm.props.packSize, _vm.props.ecommerceStyle, _vm.props.isInCart, _vm.props.gridMode)}}}),(_vm.props.showButtons)?_c(_vm.injections.components.SfButton,{tag:"component",staticClass:"sf-button--pure sf-quantity-selector__button plus",attrs:{"disabled":_vm.props.disabled || Boolean(_vm.props.max !== null && _vm.props.qty >= _vm.props.max),"data-testid":"increase"},on:{"click":function($event){_vm.$options.handleButton(
          Number(_vm.props.qty) === 1 ? Number(_vm.props.packSize)=== 1 ?Number(_vm.props.qty)+1:Number(_vm.props.packSize):Number(_vm.props.qty) + Number(_vm.props.packSize),
          _vm.listeners,
          _vm.props.min,
          _vm.props.max,
          _vm.props.packSize,
          _vm.props.ecommerceStyle,
          _vm.props.isInCart,
          _vm.props.gridMode
        )}}},[(_vm.props.max && _vm.props.max > 0 && _vm.props.max <= Number(_vm.props.qty))?_c('span',{staticClass:"maxQtyLabel",domProps:{"innerHTML":_vm._s(_vm.injections.components.i18n.t('components.OrkQuantitySelector.maxQty') )}}):_c('SfIcon',{attrs:{"icon":"plus","size":"xxs"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }