<template src="@/components/AddressModal.html"/>
<style lang="scss" scoped src="@/components/AddressModal.scss"/>

<script>

import {
  SfBar,
  SfButton,
  SfModal,
  SfHeading
} from '@storefront-ui/vue';

import AddressForm from "@/components/MyAccount/AddressForm";
import Api from "@/api/Api";
import Notifications from "@/utils/notifications";
import {CustomerActions} from "~/core/store/customer/actions";


export default {
  name: 'AddressModal',
  components: {
    AddressForm,
    SfHeading,
    SfBar,
    SfButton,
    SfModal
  },
  computed: {
    isAddressModalOpen: {
      get() {
        return this.$store.getters.isAddressModalOpen
      }
    },
    customer: {
      get() {
        return this.$store.getters.getProfile;
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      set() {

      },
    },
  },

  methods: {
    submitAddress({address, onComplete, onError}) {
      address.defaultShipping = this.isAddressModalOpen.isShipping
      address.defaultBilling = this.isAddressModalOpen.isBilling
if(!this.customer.addresses.length===0){

}
      Api.account.addAddress(address)
          .then((data) => {
            onComplete(data)
            this.$store.dispatch('addCustomerAddress', {isNew: !this.isAddressModalOpen.address, address});
            this.toggleAddressModal()
          })
          .catch((e) => {
            switch (e.status) {
              case 401:
                if (e.errorCode === "customer-is-not-logged-in") {
                  Notifications.createNotifications({name: 'errors.not-logged-in'})
                  this.$store.dispatch(CustomerActions.setCustomer, null);
                  this.$store.dispatch('getCategoryTree');
                  this.$router.push(this.$router.app.$localizePath("/mon-compte"));
                } else {
                  Notifications.createNotifications({name:'errors.address.unauthorized'})
                }
                break;
              case 409:
                Notifications.createNotifications({name:'errors.address.allReadyExist'})
                break;
              case 404:
                Notifications.createNotifications({name:'errors.address.notFound'})
                break;

            }
            onError(e)
          })
    },

    toggleAddressModal() {
      this.$store.dispatch('toggleAddressModal',{open:false,address:null})
    },

  }
};
</script>
