import { MutationTree } from "vuex";
import { CustomerState } from "~/core/store/customer/type";
import { Customer } from "@/models/customer";
import { ShoppingCart } from "@/models/shopping";

export enum CustomerMutations {
  SET_CUSTOMER = "SET_CUSTOMER",
  ADD_CUSTOMER_ADDRESS = "ADD_CUSTOMER_ADDRESS",
  REMOVE_CUSTOMER_ADDRESS = "REMOVE_CUSTOMER_ADDRESS",
  SET_CUSTOMER_CART = "SET_CUSTOMER_CART",
}

export const mutations: MutationTree<CustomerState> = {
  [CustomerMutations.SET_CUSTOMER](state, customer: Customer): void {
      state.me = customer;
      state.shoppingCart = customer.shoppingCart || null;
  },

  [CustomerMutations.ADD_CUSTOMER_ADDRESS](state, { isNew, address }): void {
    const currentUser = Customer.of({ ...state.me });

    if (isNew) {
        currentUser.addresses.push(address);
    } else {
        const addressIndex = currentUser.addresses.findIndex(a => a.name === address.name);
        currentUser.addresses[addressIndex] = address;
    }

    if (address.defaultShipping && currentUser.defaultShippingAddress !== address.name) {
        currentUser.defaultShippingAddress = address.name;
    }
    if (!address.defaultShipping && currentUser.defaultShippingAddress === address.name) {
        currentUser.defaultShippingAddress = null;
    }

    if (address.defaultBilling && currentUser.defaultBillingAddress !== address.name) {
        currentUser.defaultBillingAddress = address.name;
    }
    if (!address.defaultBilling && currentUser.defaultBillingAddress === address.name) {
        currentUser.defaultBillingAddress = null;
    }

    currentUser.addresses.sort((a, b) => a.name.localeCompare(b.name));

    state.me = currentUser;
  },

  [CustomerMutations.REMOVE_CUSTOMER_ADDRESS](state, addressName): void {
    const currentUser = Customer.of({ ...state.me });

    if (currentUser?.addresses) {
      currentUser.addresses.forEach((addressWithName, i, addresses) => {
        if (addressWithName.name === addressName) {
          addresses.splice(i, 1);
        }
      });
      state.me = currentUser;
    }
  },

  [CustomerMutations.SET_CUSTOMER_CART](state, cart: ShoppingCart): void {
    state.shoppingCart = cart;
  },

};
