<template src="@/components/AccountForm/LogIn.html"/>
<style lang="scss" scoped src="@/components/AccountForm/LogIn.scss"/>
<script>

import {SfHeading, SfAlert, SfButton, SfInput, SfLoader} from '@storefront-ui/vue';
import OrkRecaptcha from "@/components/Ork/OrkRecaptcha";
import Validation from "@/utils/validation";


export default {
  name: "LogIn",
  components: {
    OrkRecaptcha,
    SfHeading,
    SfAlert,
    SfInput,
    SfButton,
    SfLoader,
  },
  data() {
    return {
      Validation,
      loading: false,
      error: null,
      valid: false,
      submitted: false,
      password: "",
      passwordBlur: true,
      email: "",
      emailBlur: true
    }
  },
  watch: {
    isLogIn() {
      this.email = ""
      this.password = ""
    },
  },
  methods: {
    executeRecaptcha() {
      this.$refs.recaptchaLogin.execute()
    },
    validate() {
      this.emailBlur = false;
      if (
          Validation.validateEmail(this.email)
      ) {
        this.valid = true;
      }else{
        this.valid=false
      }
    },


    submitLoginForm(recaptchaData) {
      const onComplete = () => {
        if (!this.error || this.error.status === 200) {
          this.loading = false;
        } else {
          this.loading = false;
        }
      };

      const onError = (e) => {
        console.log(e)
        this.error = e;
        this.loading = false;
      };
      this.validate()
      if (this.valid) {
        this.loading = true;

          this.$emit('submit', {form: {email: this.email, password: this.password, recaptchaData}, onComplete, onError});

      }
    },
  }
}
</script>


