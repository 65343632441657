import { i18n } from "vue-lang-router";

export class Relais {
  name?: string;
  company?: string;
  street?: any;
  city?: string;
  postCode?: string;
  country?: string;
  latlng?: any;
  distance?: number;
  openingTimes?: any;
  phone?: string | "";
  firstName?: string;
  lastName?: string;

  public static of(data: any): Relais {

    const r: Relais = new Relais();

    r.name = "relay_" + data.identifiant;
    r.company = data.nom;
    r.street = data.adresse1 + " " + data.adresse2 + " " + data.adresse3;
    r.city = data?.localite;
    r.postCode = data?.codePostal;
    r.country = data?.codePays;
    r.latlng = { lat: data.coordGeolocalisationLatitude, lng: data.coordGeolocalisationLongitude };
    r.distance = data.distanceEnMetre;
    const date = new Date();
    const day = date.getDay();
    const dimanche = data.horairesOuvertureDimanche.replace("00:00-00:00", "").replace(" 00:00-00:00", "").replace("  ", "");
    const lundi = data.horairesOuvertureLundi.replace("00:00-00:00", "").replace(" 00:00-00:00", "").replace("  ", "");
    const mardi = data.horairesOuvertureMardi.replace("00:00-00:00", "").replace(" 00:00-00:00", "").replace("  ", "");
    const mercredi = data.horairesOuvertureMercredi.replace("00:00-00:00", "").replace(" 00:00-00:00", "").replace("  ", "");
    const jeudi = data.horairesOuvertureJeudi.replace("00:00-00:00", "").replace(" 00:00-00:00", "").replace("  ", "");
    const vendredi = data.horairesOuvertureVendredi.replace("00:00-00:00", "").replace(" 00:00-00:00", "").replace("  ", "");
    const samedi = data.horairesOuvertureSamedi.replace("00:00-00:00", "").replace(" 00:00-00:00", "").replace("  ", "");
    r.openingTimes = [
      {
        now: (day === 1),
        day: "Monday",
        dayNumber: 1,
        time: lundi ? lundi : i18n.t("Close"),
        open: !!lundi
      },
      {
        now: (day === 2),
        day: "Tuesday",
        dayNumber: 2,
        time: mardi ? mardi : i18n.t("Close"),
        open: !!mardi
      },
      {
        now: (day === 3),
        day: "Wednesday",
        dayNumber: 3,
        time: mercredi ? mercredi : i18n.t("Close"),
        open: !!mercredi
      },
      {
        now: (day === 4),
        day: "Thursday",
        dayNumber: 4,
        time: jeudi ? jeudi : i18n.t("Close"),
        open: !!jeudi
      },
      {
        now: (day === 5),
        day: "Friday",
        dayNumber: 5,
        time: vendredi ? vendredi : i18n.t("Close"),
        open: !!vendredi
      },
      {
        now: (day === 6),
        day: "Saturday",
        dayNumber: 6,
        time: samedi ? samedi : i18n.t("Close"),
        open: !!samedi
      },
      {
        now: (day === 0),
        day: "Sunday",
        dayNumber: 0,
        time: dimanche ? dimanche : i18n.t("Close"),
        open: !!dimanche
      },
    ];

    return r;
  }
}

export class hereAddress {
  street?: string;
  postCode?: string;
  city?: string;
  country?: string;

  public static of(data: any): hereAddress {
    let countries;
    // eslint-disable-next-line prefer-const
    countries = require("@/models/country.json");
    const address: hereAddress = new hereAddress();

    address.street = data.street;
    address.postCode = data.postalCode;
    address.city = data.city;
    address.country = countries.find(country => country.name === data.countryName).code || "";
    return address;
  }

}
