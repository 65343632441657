<template src="@/components/Ork/OrkRootCategoryModal.html"/>
<style lang="scss" scoped src="@/components/Ork/OrkRootCategoryModal.scss"/>

<script>

import {
  SfBar,
  SfButton,
  SfImage,
  SfModal,
  SfSelect,
  SfHeading
} from '@storefront-ui/vue';

export default {
  name: 'OrkRootCategoryModal',
  components: {
    SfHeading,
    SfBar,
    SfSelect,
    SfImage,
    SfButton,
    SfModal
  },
  data() {
    return {
      focusRootCategory: null,
    }
  },
  computed: {
    isRootCategoryModalOpen: {
      get() {
        return this.$store.getters.isRootCategoryModalOpen
      }
    },
    rootCategories: {
      get() {
        return this.$store.getters.getRootCategories
      }
    },
    rootCategory: {
      get() {
        return this.$store.getters.getRootCategoryPath
      }
    },
  },
  watch: {
    rootCategory(newRoot,oldRoot){
      this.focusRootCategory = this.rootCategories.find(category=> category.path === newRoot).label;
    },
  },
  methods: {
    setRootCategory(rootCategory) {
        this.$store.dispatch('setRootCategoryPath', rootCategory).then();

    },
    EnterRootCategory(rootCategoryLabel) {
      this.focusRootCategory = rootCategoryLabel
    },
    LeaveRootCategory() {
      if(this.rootCategory){
        this.focusRootCategory = this.rootCategories.find(category=> category.path === this.rootCategory).label;
      }else {
        this.focusRootCategory = null
      }
    },
    toggleRootCategoryModal(rootCategory) {
      this.$store.dispatch('toggleRootCategoryModal', {open: false, rootCategory: rootCategory})
    },
  }
};
</script>
