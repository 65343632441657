import { Price } from "./price";
import { PickingDelay } from "./shop";

export class Offer {
  id?: string;
  shopId?: string;
  shopName?: string;
  stockQuantity?: number;
  price?: Price;
  logisticClassId?: string;
  discountPrice?: Price;
  discountStart?: string;
  discountEnd?: string;
  pickingDelay?: PickingDelay;
  minimalOrderQuantity?: number;
  maximalOrderQuantity?: number;
  additionalAttributes?: any

  public static of(data: any) {
    const o: Offer = new Offer();
    o.id = data?.id;
    o.shopId = data?.shopId;
    o.shopName = data?.shopName;
    o.stockQuantity = data?.stockQuantity || 0;
    o.price = Price.of(data?.price);
    o.logisticClassId = data?.logisticClassId;
    o.discountPrice = Price.of(data?.discountPrice);
    o.discountStart = data?.discountStart;
    o.discountEnd = data?.discountEnd;
    o.pickingDelay = data?.pickingDelay;
    o.minimalOrderQuantity = data?.minimalOrderQuantity || 0;
    o.maximalOrderQuantity = data?.maximalOrderQuantity || o.stockQuantity;
    o.additionalAttributes = data?.additionalAttributes

    return o;
  }
}
