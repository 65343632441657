<template src="@/components/Ork/OrkSocial.html"></template>
<style lang="scss" src="@/components/Ork/OrkSocial.scss"></style>

<script>
import {SfIcon, SfLink} from '@storefront-ui/vue';

export default {
  name: 'OrkSocial',
  components: {
    SfLink,
    SfIcon
  },
  props: {
    items: {type: Array},
    title: {type: String}
  }
};
</script>