import { render, staticRenderFns } from "@/components/SedRelay/SedRelayModal.html?vue&type=template&id=6424d1a7&scoped=true&external"
import script from "./SedRelayModal.vue?vue&type=script&lang=js"
export * from "./SedRelayModal.vue?vue&type=script&lang=js"
import style0 from "@/components/SedRelay/SedRelayModal.scss?vue&type=style&index=0&id=6424d1a7&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6424d1a7",
  null
  
)

export default component.exports