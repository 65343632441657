<template src="@/components/Ork/OrkStock.html"></template>
<style lang="scss" src="@/components/Ork/OrkStock.scss"></style>

<script>
import {
  SfAlert,
  SfButton,
  SfInput,
  SfNotification
} from '@storefront-ui/vue';
import ProductOptions from "@/utils/product_options";
import Validation from "@/utils/validation";
import Api from "@/api/Api";

export default {
  name: 'OrkStock',
  components: {
    SfAlert,
    SfButton,
    SfInput,
    SfNotification
  },
  props: {
    OfferId: {type: String},
    quantity: {type: Number},
    showStockAlert: {type: Boolean, default: true}
  },
  data() {
    return {
      Validation,
      errorMessage: null,
      loading: false,
      error: false,
      valid: false,
      submitted: false,
      email: "",
      emailBlur: true,
      success: false,
      StockQuantityAlert: process.env.VUE_APP_STOCK_QUANTITY_ALERT,
      minimalQuantity: ProductOptions.getProductOptions.minimalQuantity
    }
  },
  methods: {
    validate() {
      this.emailBlur = false;
      if (
          Validation.validateEmail(this.email)
      ) {
        this.valid = true;
      }else{
        this.valid=false
      }
    },
    submitStockAlert() {
      this.validate()
      if (this.valid) {
        this.loading = true;
        Api.product.restockAlert({email: this.email, offerId: this.offerId}).then((data) => {
          this.error = false
          this.errorMessage = null;
          this.success = true
        }).catch((e) => {
          if (e.status === 409) {
            this.errorMessage = this.$i18n.t("stockAlert.errorMailExist")
          } else {
            this.errorMessage = this.$i18n.t("errors.server.access")
          }
          this.error = true
          this.success = false
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    toggleStockAlertModal() {
      this.$router.push(this.$localizePath('/contact'))
      //this.$store.dispatch('toggleStockAlertModal',{open:true, product: this.OfferId })
    }
  }
};
</script>