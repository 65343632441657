<template src="@/components/BottomNavigation.html"/>
<style lang="scss" scoped src="@/components/BottomNavigation.scss"/>
<script>
import {SfBottomNavigation, SfLink, SfIcon, SfCircleIcon, SfBadge} from '@storefront-ui/vue';
import {icons} from "@/utils/icons";


export default {
  components: {
    SfBottomNavigation,
    SfIcon,
    SfLink,
    SfCircleIcon,
    SfBadge
  },

  data() {
    return {
      showQuickOrder: process.env.VUE_APP_QUICKORDER  === 'true',
      icons: icons
    }
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getProfile
      }
    },
    isAuthenticated: {
      get() {
        return this.$store.getters.isAuthenticated
      }
    },
    cart: {
      get() {
        return this.$store.getters.getCart
      }
    }
  },
  methods: {

    toggleQuickOrder() {
      this.$store.dispatch('toggleQuickOrder', {open: true})
    },

    // mutation de isModalLogin
    handleAccountClick() {
      if (this.isAuthenticated) {
        if (this.$route.name !== 'My-account') {
          return this.$router.push(this.$localizePath('/mon-compte/profil'));
        }
      } else {
        this.$store.dispatch('toggleLoginModal', {open:true})
      }
    },
    toggleCartSidebar() {
      this.$store.dispatch('toggleCartSidebar')

    },
    toggleSearch() {
      // this.$store.dispatch('toggleSearch')
      this.$router.replace({path: '/recherche', query: {}}).catch(() => {
        return null
      });
    },
    toggleMenuModal() {
      this.$store.dispatch('toggleMenuModal')

    },
    goToHome() {
      if (this.$route.name !== 'Home') {
        this.$router.push(this.$localizePath('/'));
      }
    }
  }
};
</script>

