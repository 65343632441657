<template src="@/components/LoginModal.html"></template>
<style lang="scss" scoped src="@/components/LoginModal.scss"></style>

<script>

import {SfHeading, SfModal, SfButton} from '@storefront-ui/vue';
import LogIn from '@/components/AccountForm/LogIn'
import ForgotPassword from '@/components/AccountForm/ForgotPassword'
import SignIn from '@/components/AccountForm/SignIn'
import Api from "@/api/Api";
import icons from "@/utils/icons";
import AuthFactor from "@/components/AccountForm/AuthFactor";
import {CustomerMutations} from "~/core/store/customer/mutations";

export default {
    name: 'LoginModal',
    components: {
        ForgotPassword,
        AuthFactor,
        LogIn,
        SignIn,
        SfModal,
        SfHeading,
        SfButton,
    },
    data() {
        return {
            isLogin: true,
            isAuth: false,
            isForgot: false,
            createAccount: false,
            openTab: 1,
            icons: icons,
            customerForm: null,
        }
    },

    computed: {
        isLoginModalOpen: {
            get() {
                return this.$store.getters.isLoginModalOpen
            }
        },
    },

    methods: {


        toggleModal() {
            this.isLogin = true;
            this.isAuth = false;
            this.isForgot = false;
            this.createAccount = false;
            this.$store.dispatch('toggleLoginModal', {open: !this.isLoginModalOpen.open})
        },

        loginForm({form, onComplete, onError}) {
            this.customerForm = form;
            Api.account.login(form)
                .then((data) => {
                    onComplete(data)
                    if (data.twoFactorAuthenticationEnabled) {
                        this.isAuth = true;
                        this.isLogin = false;
                    } else {
                        this.isLogin = true;

                        this.$store.commit(CustomerMutations.SET_CUSTOMER, data);
                        Api.marketingEvent.signUp();

                        this.$store.dispatch('toggleLoginModal', {open: false});
                        this.$store.dispatch('toggleWelcomeModal', { open: true, customer: data });
                        this.$store.dispatch('getCategoryTree');
                    }
                })
                .catch((e) => {
                    let error = '';
                    console.log(e)
                    switch (e.status) {

                        case 498:
                            error = this.$i18n.t('errors.server.access')
                            this.setNotificationError(error)
                            break;
                        case 401:
                            error = this.$i18n.t('errors.login.unauthorized')
                            this.setNotificationError(error)
                            break;
                        case 409:
                            error = this.$i18n.t('errors.login.allReadyExist')
                            this.setNotificationError(error)
                            break;
                        case 404:
                            error = this.$i18n.t('errors.login.notFound')
                            this.setNotificationError(error)
                            break;
                    }

                    onError(error)
                })

        },

        setNotificationError(error) {
            this.$store.dispatch('sendNotification', {type: 'danger', message: error, icon: 'error'});
        },

        signinForm({form, onComplete, onError}) {
            Api.account.register(form)
                .then((data) => {
                    onComplete(data);

                    this.$store.commit(CustomerMutations.SET_CUSTOMER, data);
                    Api.marketingEvent.signUp();

                    this.$store.dispatch('toggleLoginModal', {open: false});
                    this.$store.dispatch('toggleWelcomeModal', { open: true, customer: data });
                    this.$store.dispatch('getCategoryTree');
                })
                .catch((e) => {
                    let error = '';
                    switch (e.status) {
                        case 400:
                            error = this.$i18n.t('errors.login.unauthorized')
                            break;
                        case 401:
                            error = this.$i18n.t('errors.login.unauthorized')
                            break;
                        case 409:
                            error = this.$i18n.t('errors.login.allReadyExist')
                            break;
                        case 404:
                            error = this.$i18n.t('errors.login.notFound')
                            break;
                    }
                    this.$store.dispatch('sendNotification', {type: 'danger', message: error, icon: 'error'});
                    onError(error)
                })
        },

        forgotForm({form, onComplete, onError}) {
            Api.account.forgotPassword(form)
                .then((data) => {
                    onComplete(data)
                    this.$store.dispatch('toggleLoginModal', {open: false});
                    this.$store.dispatch('toggleResetModal', {
                        open: true,
                        email: form.email
                    });

                })
                .catch((e) => {
                    let error = '';
                    switch (e.status) {
                        case 401:
                            error = this.$i18n.t('errors.login.unauthorized')
                            break;
                        case 409:
                            error = this.$i18n.t('errors.login.allReadyExist')
                            break;
                        case 404:
                            error = this.$i18n.t('errors.login.notFound')
                            break;

                    }
                    this.$store.dispatch('sendNotification', {type: 'danger', message: error, icon: 'error'});
                    onError(error)
                })

        },
        authSubmit({oneTimePassword, onComplete, onError}) {

            this.customerForm.oneTimePassword = oneTimePassword
            Api.account.checkAuth(this.customerForm)
                .then((data) => {
                    console.log("Before oncomplete");
                    onComplete(data);
                    console.log("LoginModal.authSubmit");
                    this.isAuth = false;
                    this.isLogin = true;
                    
                    this.$store.commit(CustomerMutations.SET_CUSTOMER, data);
                    Api.marketingEvent.login();

                    this.$store.dispatch('toggleLoginModal', {open: false});
                    this.$store.dispatch('toggleWelcomeModal', { open: true, customer: data });
                    this.$store.dispatch('getCategoryTree');
                })
                .catch((e) => {
                    let error = '';
                    const messageParts = e.message.split('|')
                    const count = messageParts[1]

                    switch (messageParts[0]) {
                        case "The provided one time password doesn't match":
                            error = this.$i18n.t('errors.auth.unauthorized')
                            if (!count) {
                                this.isAuth = false;
                                this.isLogin = true
                            }
                            break;
                        case "Too much attempts for one time password":
                            error = this.$i18n.t('errors.auth.tooMuch')
                            if (!count) {
                                this.isAuth = false;
                                this.isLogin = true
                            }
                            break;
                        case "One time password is expired":
                            error = this.$i18n.t('errors.auth.timeOut')
                            break;
                    }
                    switch (e.status) {
                        case 404:
                            error = this.$i18n.t('errors.notfound')
                            break;
                    }
                    this.$store.dispatch('sendNotification', {type: 'danger', message: error, icon: 'error'});
                    onError({message: error, count})
                })
        },

        setIsLoginValue(value) {
            this.isLogin = value;
            this.isForgot = !value;
        },
        setIsForgotValue(value) {
            this.isForgot = value;
            this.isLogin = !value;
        },

        setIsRegisterValue(value) {
            this.isForgot = value;
            this.isLogin = value;
        },
    },
}
</script>
