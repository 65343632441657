<template src="@/layouts/Checkout.html"></template>

<script>
import AppHeaderCheckout from '@/components/AppHeaderCheckout.vue';
import AppFooter from '@/components/AppFooter';
import ResetPasswordModal from '@/components/ResetPasswordModal';
import WelcomeModal from '@/components/WelcomeModal';
import Notification from "@/components/Notification";
import AddressModal from "@/components/AddressModal";
import AddressListModal from "@/components/AddressListModal";
import NoStockActionModal from "@/components/NoStockActionModal";
import CgvModal from "@/components/CgvModal";
import {SfIcon} from "@storefront-ui/vue"
import icons from "@/utils/icons";
import SedRelayModal from '@/components/SedRelay/SedRelayModal';
export default {
  components: {
    SfIcon,
    ResetPasswordModal,
    AppFooter,
    AppHeaderCheckout,
    Notification,
    WelcomeModal,
    AddressModal,
    AddressListModal,
    NoStockActionModal,
    SedRelayModal,
    CgvModal
  },
  data() {
    return {
      scTimer: 0,
      scY: 0,
      icons: icons
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  },
};
</script>



