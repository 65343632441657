<template>

  <div class="sf-store-locator">
    <div class="sf-store-locator__wrapper">

      <div class="sf-store-locator__map-wrapper">

        <div ref="map" class="sf-store-locator__map"></div>

        <slot v-if="!mapReady" name="map-loading">
          <OrkLoader :loading="!mapReady" :message="$t('loading.relais')"/>
        </slot>
      </div>
      <div class="sf-store-locator__list">
        <!-- @slot Use this slot to show stores cards -->
        <slot
            v-if="mapReady"
            v-bind="{
            centerOn,
            centerOnMarker,
            chooseRelais,
            registerStore,
            removeStore,
            userPosition,
            getGeoDistance,
            locateUser,
            selectedRelaisId
          }"
        />
      </div>
    </div>
  </div>

</template>
<script>
import Vue from 'vue';
import {focus} from '@storefront-ui/vue/src/utilities/directives';


import Relais from './_internal/Relais.vue';
import Locales from "@/utils/locales";
import OrkLoader from "@/components/Ork/OrkLoader";

Vue.component('Relais', Relais);

export default {
  name: 'SedRelay',
  provide() {

    const locatorData = {};
    Object.defineProperty(locatorData, 'userPosition', {
      enumerable: true,
      get: () => this.userPosition
    });
    return {
      registerStore: this.registerStore,
      removeStore: this.removeStore,
      centerOn: this.centerOn,
      centerOnMarker: this.centerOnMarker,
      updateRelais: this.updateRelais,
      chooseRelais: this.chooseRelais,
      getGeoDistance: this.getGeoDistance,
      locateUser: this.locateUser,
      locatorData,
    };
  },
  components: {
    OrkLoader,
  },
  directives: {
    focus
  },
  props: {
    hasPopup: {
      type: Boolean,
      default: true
    },
    /**
     * Initial center of the map, overridden when the user position is captured, supports sync modifier
     */
    center: {
      type: Object,
      default: () => [{lat: 46.5, lng: 2}]
    },

    /**
     * Initial zoom of the map
     */
    zoom: {
      type: Number,
      default: 12
    },

    /**
     * Max zoom allowed, consider tileserver limitation when setting this
     */
    maxZoom: {
      type: Number,
      default: 16
    },


    /**
     * Zoom to be set when centering map on clicked store
     */
    flyToStoreZoom: {
      type: Number,
      default: 13
    },

    relais: {default: []}

  },
  data() {
    return {
      style: '/assets/images/colissimo/style.yaml',
      clusterOptions: null,
      loaded: false,
      userPosition: null,
      selectedRelaisId: undefined,
      selectedRelais: null,
      mapReady: false,
      map: null,
      hereIsLoaded: false,
    };
  },
  computed: {
    hereIsLoadedComp: {
      get() {
        return this.hereIsLoaded
      }
    },
    computedMapOptions() {
      return {...this.mapOptions, zoomControl: false};
    },
    internalCenter: {
      get() {
        return this.center;
      },
      set(value) {
        this.updateCenter(value);
      }
    },
    internalZoom: {
      get() {
        return this.zoom;
      },
      set(value) {
        this.$emit('update:zoom', value);
      }
    }
  },
  watch: {
    /*internalCenter() {
      if (this.mapReady) {
        this.centerOn(this.center);
      }
    },*/
    relais(newRelais) {
      if (this.mapReady && newRelais && newRelais.length>0) {
        this.updateRelais();
      }
    },

    hereIsLoadedComp(newHere, oldHere) {

      if (newHere || oldHere) {
        const platform = new window.H.service.Platform({
          apikey: process.env.VUE_APP_HERE_API
        });
        this.platform = platform;
        this.initializeHereMap();
      }
    },
  },
  created() {

  },
  mounted() {
    this.loadScript("https://js.api.here.com/v3/3.1/mapsjs-core.js", "hereMap-Core")
        .then(script => this.loadScript("https://js.api.here.com/v3/3.1/mapsjs-service.js", "hereMap-Service"))
        .then(script => this.loadScript("https://js.api.here.com/v3/3.1/mapsjs-mapevents.js", "hereMap-Event"))
        .then(script => this.loadScript("https://js.api.here.com/v3/3.1/mapsjs-ui.js", "hereMap-Ui"))
        .then(script => this.loadScript("https://js.api.here.com/v3/3.1/mapsjs-clustering.js", "hereMap-Cluster"))
        .then(script => this.loadScript("https://js.api.here.com/v3/3.1/mapsjs-data.js", "hereMap-Data"))
        .then(script => this.loadStyle("https://js.api.here.com/v3/3.1/mapsjs-ui.css", "hereMap-Style"))
        .then(data => {
          this.hereIsLoaded = true
        });
  },

  methods: {
    loadScript(src, id) {
      const existingScript = document.getElementById(id);

      return new Promise(function (resolve, reject) {
        if (!existingScript) {
          const script = document.createElement('script');
          script.src = src;
          script.id = id

          script.onload = () => resolve(script);
          script.onerror = () => reject(new Error(`Script load error for ${src}`));

          document.head.append(script);
        } else {
          resolve(id + 'allreadyLoaded');
        }
      });

    },

    loadStyle(src, id) {
      const existingScript = document.getElementById(id);
      return new Promise(function (resolve, reject) {
        if (!existingScript) {
          const element = document.createElement('link')

          element.setAttribute('rel', 'stylesheet')
          element.setAttribute('type', 'text/css')
          element.setAttribute('href', src)
          element.setAttribute('id', id)
          element.onload = () => resolve(src)
          element.onerror = () => reject(src)

          document.head.appendChild(element)
        } else {
          resolve(id + 'allreadyLoaded');
        }
      });
    },

    initializeHereMap() { // rendering map

      const mapContainer = this.$refs.map;
      const H = window.H;
      // Obtain the default map types from the platform object
      const maptypes = this.platform.createDefaultLayers({lg: this.$i18n.locale});

      // Instantiate (and display) a map object:
      this.map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        pixelRatio: window.devicePixelRatio || 1,
        center: this.center,
        zoom: this.internalZoom,

      });
      const provider = this.map.getBaseLayer().getProvider();

      const style = new H.map.Style('/assets/images/colissimo/style.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
      // set the style on the existing layer
      provider.setStyle(style);


      addEventListener("resize", () => this.map.getViewPort().resize());

      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

      // add UI
      const locale = Locales.getLocaleCode().replace('_', '-');

      const ui = H.ui.UI.createDefault(this.map, maptypes, locale);
      // End rendering the initial map
      ui.removeControl('mapsettings');
      ui.getControl('zoom').setAlignment('top-left');

      this.onMapReady()
    },

    addMarker() {
      const H = window.H;

      /*this.relais.forEach((ptRelais) => {
        this.map.addObject(new H.map.Marker(ptRelais.latlng));
      })*/

      const dataPoints = this.relais.map(function (item) {
        return new H.clustering.DataPoint(item.latlng.lat, item.latlng.lng, null, item);
      });

      const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
        clusteringOptions: {
          // Maximum radius of the neighbourhood
          eps: 26,
          // minimum weight of points required to form a cluster
          minWeight: 2
        },
      });

      // const defaultTheme = clusteredDataProvider.getTheme();
      const CUSTOM_THEME = {
        getClusterPresentation: function (cluster) {

          let marker = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50" xml:space="preserve">' +
              ' <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="5.881" y1="36.868" x2="44.119" y2="13.132">' +
              '<stop offset="0" style="stop-color:#e9660d"/>' +
              '<stop offset=".192" style="stop-color:#ea6c0d"/>' +
              '<stop offset=".444" style="stop-color:#ed7c0e"/>' +
              '<stop offset=".726" style="stop-color:#f29710"/>' +
              '<stop offset="1" style="stop-color:#f8b812"/>' +
              '</linearGradient>' +
              '<circle cx="25" cy="25" r="22.5" fill="url(#a)" stroke="#fff" stroke-width="5" stroke-miterlimit="10" stroke-opacity=".6"/>' +
              '<text transform="translate(25 28)" text-anchor="middle" font-size="18px" font-family="Arial" font-weight="bold" fill="#fff">#NB</text>' +
              '</svg>'

          return new H.map.Marker(cluster.getPosition(), {
            icon: new H.map.Icon(marker.replace('#NB', cluster.getWeight()), {
              size: {w: 50, h: 50},
              anchor: {x: 25, y: 25}
            }),
            min: cluster.getMinZoom(),
            max: cluster.getMaxZoom()
          });
          /*   var clusterMarker = defaultTheme.getClusterPresentation.call(defaultTheme, cluster);
             return clusterMarker;*/
        },
        getNoisePresentation: function (noisePoint) {
          // Get a reference to data object our noise points
          const data = noisePoint.getData(),
              // Create a marker for the noisePoint
              noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
                // Use min zoom from a noise point
                // to show it correctly at certain zoom levels:
                min: noisePoint.getMinZoom(),
                icon: new H.map.Icon("/assets/images/relay/marker.svg", {
                  size: {w: 26, h: 42},
                  anchor: {x: 13, y: 37}
                })
              });

          // Link a data from the point to the marker
          // to make it accessible inside onMarkerClick
          noiseMarker.setData(data);

          return noiseMarker;
        }
      };
      // Create a clustering provider with custom options for clusterizing the input

      clusteredDataProvider.setTheme(CUSTOM_THEME)
      clusteredDataProvider.addEventListener('tap', this.onMarkerClick);
      // Create a layer tha will consume objects from our clustering provider
      this.clusterOptions = new H.map.layer.ObjectLayer(clusteredDataProvider);

      // To make objects from clustering provder visible,
      // we need to add our layer to the map
      this.map.addLayer(this.clusterOptions);

    },

    updateRelais() {

      if (this.mapReady) {
        if (this.relais && this.relais.length > 0) {
          if (this.clusterOptions) {
            this.map.removeLayer(this.clusterOptions);
          }
          this.addMarker();
        } else {
          if (this.clusterOptions) {
            this.map.removeLayer(this.clusterOptions);
          }
        }
        this.centerOn(this.internalCenter)
      }
    },

    onMarkerClick(e) {
      const H = window.H;
      const ui = H.ui.UI;
      /*    // Get position of the "clicked" marker
          var position = e.target.getGeometry(),
              // Get the data associated with that marker
              data = e.target.getData(),
              // Merge default template with the data and get HTML
              bubbleContent = this.getBubbleContent(data),
              bubble = this.onMarkerClick.bubble;
    */
      // For all markers create only one bubble, if not created yet

      let position = e.target.getGeometry(),
          // Get the data associated with that marker
          ptRelais = e.target.getData();
      // Move map's center to a clicked marker
      if (ptRelais) {
        this.chooseRelais(ptRelais.name, ptRelais.latlng);

        // event target is the marker itself, group is a parent event target
        // for all objects that it contains


        return
      }
      this.centerOn(position);
    },
    latLngEquality(a, b) {
      return a.latlng.lat === b.latlng.lat && a.latlng.lng === b.latlng.lng;
    },
    registerStore(ptRelais) {
      if (!this.relais.some((s) => this.latLngEquality(ptRelais, s))) {
        this.relais.push(ptRelais);
      }
    },
    removeStore(ptRelais) {
      this.relais = this.relais.filter((s) => !this.latLngEquality(s, ptRelais));
    },
    onMapReady() {

      /**
       * Map ready and displayed event
       *
       * @event 'map:ready'
       * @type {object}
       */
      this.mapReady = true;
      this.$emit('isMapReady', true);
      this.updateRelais();
      this.centerOn(this.center)
      // this.centerOn(this.center);
      // this.locateUser();
    },
    locateUser() {
      if (navigator.geolocation) {
        let userPosition = navigator.geolocation.getCurrentPosition();
        this.centerOn({lat: userPosition.coords.latitude, lng: userPosition.coords.longitude})
      } else {
        this.centerOn(this.userPosition)
      }
      // this.$refs.map.mapObject.locate({timeout: 20000});
    },
    locationFound(location) {
      this.userPosition = {...location.latlng};
      this.$emit('userPosition', this.userPosition)
      /**
       * Update center with user position event,
       *
       * @event 'update:center'
       * @type {object}
       */
      this.updateCenter({...location.latlng});
    },
    locationError(error) {

      /**
       * Location error event.
       *
       * @event 'location:errors'
       * @type {object}
       */
      this.$emit('location:error', error);
    },
    updateCenter(latlng) {
      this.$emit('update:center', {...latlng});
    },

    centerOn(latlng) {

      if (this.relais.length > 0) {
        this.map.getViewModel().setLookAtData(
            {
              position: latlng,
              zoom: this.map.getZoom() >= this.flyToStoreZoom ? this.map.getZoom() + 1 : this.flyToStoreZoom
            },
            true
        );
      } else {
        this.map.getViewModel().setLookAtData(
            {
              position: latlng,
              zoom: 6
            },
            true
        );
      }
    },

    centerOnMarker(latlng) {
      this.map.getViewModel().setLookAtData(
          {
            position: latlng,
            zoom: 16
          },
          true
      );
    },

    chooseRelais(storeId, latlng) {
      this.centerOnMarker(latlng);
      this.selectedRelaisId = storeId;

      this.scrollToElementList(storeId)
      this.$emit('selectedRelais', this.selectedRelaisId)

    },
    scrollToElementList(storeId) {

      const el = document.getElementById(storeId);
      el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    },
    getGeoDistance(start, end) {
      const deg2rad = (deg) => deg * (Math.PI / 180);
      const R = 6371;
      const dLat = deg2rad(end.lat - start.lat);
      const dLng = deg2rad(end.lng - start.lng);
      const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(deg2rad(start.lat)) *
          Math.cos(deg2rad(end.lat)) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let distance = R * c * 1000;
      distance = distance / 1000;
      return Math.round(distance * 100) / 100;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/components/organisms/SfStoreLocator.scss";


.sf-store-locator {
  ::v-deep {
    .sf-store-locator__map {
      width: 100%;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }

  &__map-wrapper {
    flex: 1;
  }

  &__list {
    width: 33%;
    min-width: 320px;
    flex: none;
    @include for-mobile {
      width: 100%;
      min-width: 0;
      flex: 1;
    }
  }
}


</style>
