import { Gender } from "@/enum/gender";
import { Address } from "./address";
import { ShoppingCart } from "@/models/shopping";
import { i18n } from "vue-lang-router";

export class Customer {
    id?: string;
    gender?: Gender;
    isGuest?: boolean;
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    JWT?: string;
    birthDate?: string;
    locale?: string;
    customerGroup?: string;
    shoppingCart?: ShoppingCart | null;
    defaultBillingAddress?: string | null;
    defaultShippingAddress?: string | null;
    addresses: Address[] = [];
    newsLetterSubscribed?: boolean;
    twoFactorAuthenticationEnabled?: boolean;
    oneTimePassword?: string;

    public static of(data: any): Customer {
        const c: Customer = new Customer();
        c.isGuest = data?.isGuest;
        c.id = data?.id;
        c.gender = data?.gender;
        c.firstName = data?.firstName;
        c.lastName = data?.lastName;
        c.email = data?.email;
        c.password = data?.password;
        c.JWT = data?.JWT;
        c.birthDate = data?.birthDate;
        c.locale = data?.locale;
        c.customerGroup = data?.customerGroup;
        c.shoppingCart = data?.shoppingCart ? ShoppingCart.of(data?.shoppingCart) : null;
        c.defaultBillingAddress = data?.defaultBillingAddress || null;
        c.twoFactorAuthenticationEnabled = data?.twoFactorAuthenticationEnabled || false;
        c.defaultShippingAddress = data?.defaultShippingAddress || null;
        c.addresses = data?.addresses?.map(a => Address.of(a)).sort((a, b) => a.name.localeCompare(b.name)) || [];
        c.newsLetterSubscribed = data?.newsLetterSubscribed || false;
        return c;
    }

    public static getAddress(customer: Customer, addressName: string): Address | null {
        if (customer.addresses) {
            const address = customer.addresses.find(address => address.name === addressName);
            if (address) {
                return address;
            }
        }
        return null;
    }

    public static getCustomerGroup(customerGroup: string) {
        switch (customerGroup) {
            case "General public":
                return i18n.t("customerGroup.label.public");
                break;
        }
    }
}
