export class Address {
  firstName?: string;
  lastName?: string;
  company?: string;
  street?: string;
  city?: string;
  postCode?: string;
  country?: string;
  phone?: string;
  email?: string;
  website?: string;
  latitude?: number;
  longitude?: number;
  name: string = '';

  public static of(data: any): Address {
    const a: Address = new Address();
    a.firstName = data?.firstName;
    a.lastName = data?.lastName;
    a.company = data?.company;
    a.street = data?.street;
    a.city = data?.city;
    a.postCode = data?.postCode;
    a.country = data?.country;
    a.phone = data?.phone;
    a.email = data?.email;
    a.website = data?.website;
    a.latitude = data?.latitude;
    a.longitude = data?.longitude;
    a.name = data?.name;

    return a;
  }
}

export class RelaistoAddress {
  firstName?: string;
  lastName?: string;
  company?: string;
  street?: string;
  city?: string;
  postCode?: string;
  country?: string;
  phone?: string;
  email?: string;
  website?: string;
  latitude?: number;
  longitude?: number;
  name?: string;
  openingTimes?: any;

  public static of(data: any): RelaistoAddress {

    const a: RelaistoAddress = new RelaistoAddress();
    a.firstName = data?.customer.firstName;
    a.lastName = data?.customer.lastName;
    a.company = data?.relais.company;
    a.street = data?.relais.street;
    a.city = data?.relais.city;
    a.postCode = data?.relais.postCode;
    a.country = data?.relais.country;
    a.phone = data?.relais.phone;
    a.email = data?.customer.email;
    a.latitude = data?.relais?.latlng?.lat;
    a.longitude = data?.relais?.latlng?.lng;
    a.name = data?.relais.name;
    a.openingTimes = data?.relais?.openingTimes;

    return a;
  }
}
