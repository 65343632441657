import QorusClient from '@/api/clients/QorusClient';

export default class PaymentService {

  static of(): PaymentService {
    return new PaymentService();
  }

  getIP(): string {
    return sessionStorage.getItem("ShopperIP") || "";
  }

  initSystemPayForm(): Promise<any> {
    return QorusClient.of().post("payment/systempay/prepare-checkout")
      .then(data => {
        return {
          formToken: data.formToken,
        };
      }, (data) => {
        throw data;
      },
    );
  }
}
