<script lang="ts">
import {Category} from "@/models/category";
import Component from 'vue-class-component';
import Vue from 'vue';
import icons from "@/utils/icons";
import {SfIcon} from "@storefront-ui/vue";

@Component({
  name: "MenuModalItem",
  computed: {
    icons() {
      return icons
    }
  },
  components: {
    MenuModalLevel : () => import('./MenuModalLevel.vue'), // Needs to be dynamic because recursive
    SfIcon
},
  props: {
    subCategory: Category,
    level: Number,
    parentSlug: String
  },
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    toggleItem() {
      this.isActive = !this.isActive;
      console.log(this.isActive ? "ACTIVE" : "INACTIVE")
    }
  }
})
export default class MenuModalItem extends Vue { }
</script>

<template>
  <li
    class="menu-list-item"
      :class="{active: isActive}"
      @click.stop="toggleItem"
  >
    <label>
      <div>{{subCategory.label}}</div>
      <SfIcon :icon="icons.right.path" :viewBox="icons.right.viewBox" class="icon" size="18px"/>
    </label>
    <MenuModalLevel :category="subCategory" :level="level+1" :parent-slug="`${parentSlug}`"/>
  </li>
</template>


<style lang="scss">
#menu {
  li {
    padding: 0 var(--spacer-sm);
    border-bottom: 1px solid var(--_c-lightgray);

    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 60px;
      color: var(--c-primary);
    }
  }

  .flyout {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transition: left .2s;
    width: 100%;
    z-index: 1;
    //min-height: 100%;
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
  }

  li:not(.active) > .flyout {
    left: 100%;
  }
}</style>
