<template src="@/components/AppHeaderCheckout.html"></template>
<style lang='scss' scoped src="@/components/AppHeaderCheckout.scss"></style>
<script>
import {
  SfImage,
} from '@storefront-ui/vue';

import {icons} from "@/utils/icons";

export default {
  name: 'AppHeaderCheckout',
  components: {
    SfImage,
  },
  data() {
    return {
      icons: icons,
      term: '',
      cartTotalItems: 0,
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getProfile
      }
    },
    isAuthenticated: {
      get() {
        return this.$store.getters.isAuthenticated
      }
    },
    cart: {
      get() {
        return this.$store.getters.getCart
      }
    }
  },

  mounted() {
    this.$store.dispatch('getMe');
  }
};
</script>


