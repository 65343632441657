<template src="@/layouts/Default.html"></template>

<script>
import AppHeader from '@/components/AppHeader';
import TopBar from '@/components/TopBar';
import AppFooter from '@/components/AppFooter';
import BottomNavigation from '@/components/BottomNavigation';
import {SfIcon} from "@storefront-ui/vue"
import icons from "@/utils/icons";
import CartSidebar from '@/components/CartSidebar';
import OrkLightBox from '@/components/Ork/OrkLightBox'
import OrkProductPreview from '@/components/Ork/OrkProductPreview'
import OrkRootCategoryModal from '@/components/Ork/OrkRootCategoryModal'
import AddressModal from '@/components/AddressModal'
import ProductsActionModal from '@/components/ProductsActionModal'
import ResetPasswordModal from '@/components/ResetPasswordModal'
import LoginModal from '@/components/LoginModal'
import Notification from '@/components/Notification'
import MenuModal from '@/components/MenuModal'
import WelcomeModal from '@/components/WelcomeModal'



export default {
    components: {
        OrkLightBox,
        OrkProductPreview,
        OrkRootCategoryModal,
        SfIcon,
        AddressModal,
        ProductsActionModal,
        ResetPasswordModal,
        AppFooter,
        AppHeader,
        TopBar,
        CartSidebar,
        LoginModal,
        Notification,
        MenuModal,
        WelcomeModal,
        BottomNavigation

    },
    data() {
        return {
            showPromotionalBar: false,
            showQuickOrder: false,
            showRootCategory: false,
            scTimer: 0,
            scY: 0,
            icons: icons
        }
    },
    created() {
        this.showRootCategory = process.env.VUE_APP_SHOW_ROOT_CATEGORY_SELECTION === 'true'
        this.showQuickOrder = process.env.VUE_APP_QUICKORDER === 'true'
        this.showPromotionalBar = process.env.VUE_APP_SHOW_PROMOTIONAL_BAR === 'true'
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);

    },
    methods: {
             handleScroll: function () {
            if (this.scTimer) return;
            this.scTimer = setTimeout(() => {
                this.scY = window.scrollY;
                clearTimeout(this.scTimer);
                this.scTimer = 0;
            }, 100);
        },
        toTop: function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
    },
};
</script>

<style lang="scss">
#main {
  transition: 0.3s ease all;
}

.activeMenu {
  overflow: hidden;

  #AFMenu {
    transform: translateX(0);
  }

  #main {
    transform: translateX(300px);
  }
}

</style>
