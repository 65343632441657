export class Picture {
  shoppingCart?: { url: string };
  category?: { url: string };
  desktop?: { url: string };
  mobile?: { url: string };
  zoom?: { url: string };
  alt?: string;

  public static of(p: any, name: string) {
    const picture = new Picture();
    picture.shoppingCart = { url: p?.shoppingCart || p?.small || "" };
    picture.category = { url: p?.category || p?.small || "" };
    picture.desktop = { url: p?.medium || p?.small || "" };
    picture.mobile = { url: p?.small || "" };
    picture.zoom = { url: p?.big || "" };
    picture.alt = name;
    return picture;
  }
}

export class LightPicture {
  srcMedium?: string;
  srcThumb?: string;
  srcLarge?:string
  src?: string;
  title?: string;
  description?: string;

  public static of(p: any, name: string) {
    const picture = new LightPicture();
    picture.srcMedium = p?.medium || p?.small || "";
    picture.srcThumb = p?.shoppingCart || ""
    picture.src = p?.large || p?.medium || "";
    picture.srcLarge = p?.large || p?.medium || "";
    picture.title = name || "";
    picture.description = p?.description || "";
    return picture;
  }
}

export class CartPicture {
  src?: string;
  title?: string;
  description?: string;

  public static of(p: any, name: string) {
    const picture = new CartPicture();
    picture.src = p?.shoppingCart || p?.small || "";
    picture.title = name;
    picture.description = p?.description || "";
    return picture;
  }
}

export class CategoryPicture {
  src?: string;
  title?: string;
  description?: string;

  public static of(p: any, name: string) {
    const picture = new CategoryPicture();
    picture.src = p?.category || p?.small || "";
    picture.title = name;
    return picture;
  }
}

export class Pictures {
  small?: string;
  normal?: string;
  big?: string;
  isCover?: boolean;
  shoppingCart?: string;
  category?: string;
  alt?: string;

  public static of(data: any): Pictures {
    const p: Pictures = new Pictures();

    p.small = data.small || "";
    p.normal = data.medium || "";
    p.big = data.large || "";
    p.isCover = data?.isCover;
    p.shoppingCart = data?.shoppingCart;
    p.category = data?.category;
    p.alt = data?.alt || "";

    return p;
  }
}