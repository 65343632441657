<template src="@/components/Ork/OrkQuickOrder.html"/>
<style lang="scss" scoped src="@/components/Ork/OrkQuickOrder.scss"/>

<script>

import {
  SfModal,
  SfButton,
  SfImage,
  SfNotification,
  SfLink, SfBar, SfHeading,

} from '@storefront-ui/vue';
import Api from "@/api/Api";
import icons from "@/utils/icons";
import OrkPrice from "@/components/Ork/OrkPrice";
import OrkAddToCart from "@/components/Ork/OrkAddToCart";
import OrkStock from "@/components/Ork/OrkStock";
import OrkLoader from "@/components/Ork/OrkLoader";
import {filterData} from "@/utils/categoryFilter";
import OrkQuantitySelector from "@/components/Ork/OrkQuantitySelector";
import Locales from "@/utils/locales";
import {LightProduct} from "@/models/product";
import {StreamBarcodeReader} from "vue-barcode-reader";

import Notifications from "@/utils/notifications";

export default {
  name: 'OrkQuickOrder',
  components: {
    StreamBarcodeReader,
    SfModal,
    SfNotification,
    OrkLoader,
    OrkAddToCart,
    SfBar,
    OrkPrice,
    SfLink,
    SfButton,
    SfHeading,
    OrkQuantitySelector,
    SfImage,
    OrkStock,
  },

  data() {
    return {
      imgHasError:false,
      showPreview: process.env.$VUE_APP_SHOWPRODUCT_PREVIEW === 'true',
      showQty: process.env.VUE_APP_SHOWQTY === 'true',
      showWishlist: process.env.VUE_APP_WISHLIST === 'true',
      showAddToCartModal: process.env.VUE_APP_SHOW_ADD_TO_CART_MODAL === 'true',
      ecommerceStyle: process.env.VUE_APP_ECOMMERCE_STYLE,
      ecommerceMode: process.env.VUE_APP_ECOMMERCE_MODE,
      icons,
      categ : null,
      loading: false,
      quantity: 1,
      AddTocartLoading: false,
      selectedProduct:null,
      locale: Locales.getLocaleCode(),
      index: null,
      showStockAlert: (process.env.VUE_APP_SHOW_STOCK_ALERT === 'true'),
      fromCategory: null,
      urlEncode: encodeURI(window.location.href),
      product: null,
      error: false,
      configurables: null,
      firstLoad: true,
      ProductId: [],
      productIsInCart:null
    };
  },
  computed: {
    isQuickOrderModal: {
      get() {
        return this.$store.getters.isQuickOrderModal.open;
      }
    },

    customer() {
      return this.$store.getters.getProfile;
    },

    sortedAttribute: {
      get() {
        const sort = Object.fromEntries(
            Object.entries({...this.selectedProduct.attributeValuesByAttributeName})
                .sort(([KeyA, a], [KeyB, b]) => {

                  if (!a.value) {
                    delete this[KeyA]
                  }
                  if (!b.value) {
                    delete this.selectedProduct.attributeValuesByAttributeName[KeyB]
                  }
                  return a.label < b.label ? -1 : 1;
                })
        );
        for (const [key, value] of Object.entries(sort)) {
          if (!value.value) {
            delete sort[key]
          }
        }
        return sort
      }
    },
    currentCart: {
      get() {
        return this.$store.getters.getCart;
      }
    },
    categoryTree: {
      get() {
        return this.$store.getters.getCategoryTree
      }
    },
    activeCategory: {
      get() {
        return sessionStorage.getItem('activeCategory')
      }
    },
  },
created() {

},
  beforeDestroy() {

  },
  watch: {
    productSlug(newVal,oldVal){
      if(newVal && newVal!== oldVal) {
        this.selectedProduct=null
        this.product=null
        this.loadProduct(newVal)
      }
    },
    currentCart(newCart) {
      if (newCart && this.selectedProduct) {

        this.productIsInCart = newCart.cartItems.find(item => item.product.offer.id === this.selectedProduct.defaultOfferId)
      } else {
        this.productIsInCart = null
      }
      if (this.productIsInCart) {
        this.quantity = this.productIsInCart.quantity
      }
    }
  },
  methods: {
    imgError(){
      this.imgHasError=true
    },
    playAudio() {
      let audio = this.$refs.audio;

      audio.volume=0.02;
      audio.play();
    },
    onDecode(ProductId) {
      if (this.selectedProduct && this.selectedProduct.reference !== ProductId) {

        this.loadProduct(ProductId)
      } else if (!this.selectedProduct) {
        this.loadProduct(ProductId)
      }


    },
    onError() {
      console.log("error");
    },
    onLoaded(){

/*

*/
    },
    toggleQuickOrder() {
      this.firstLoad = true;
      this.quantity = 1;
      this.selectedProduct = null;
      this.$store.dispatch('toggleQuickOrder', {open: false})
     /*
      const scanner = this.$refs.barcode.$refs.scanner

      const stream = scanner.srcObject;

      const tracks = stream.getTracks();
      console.log(tracks)
      tracks.forEach(function(track) {
        track.stop();
      });

      scanner.srcObject = null;*/
    },
    getProductCategory() {
      const productCategory = this.product.categoryPaths;

      let splitPath = null
      // get only the first category

      if (this.activeCategory) {
        splitPath = this.product.categoryPaths.find(categ => {
          if (categ.includes(this.activeCategory)) {
            return true;
          }
        }) || null;

        if (splitPath) {
          splitPath = splitPath.split('/');
        } else {
          splitPath = productCategory[0].split('/');
        }
      } else {
        splitPath = productCategory[0].split('/');
      }
      const tempArr = [];
      if (splitPath) {
        for (let i of splitPath)
          i && tempArr.push(i); // copy each non-empty value to the 'temp' array

        splitPath = tempArr;

        const categoriesPath = []
// get all path for categories child
        for (let i = 0, n = splitPath.length; i + 1 <= n; i++) {
          if (i === 0)
            categoriesPath.push('/' + splitPath[i]);
          else categoriesPath.push(categoriesPath[i - 1] + '/' + splitPath[i])
        }

//create categories breadcrumbs
        const categoriesBreadcrumbs = [];

        for (let i = 0, n = categoriesPath.length; i + 1 <= n; i++) {
          //get data for the category with categoryTree
          let categ = filterData(this.categoryTree.children, function (item) {
            return item.path === categoriesPath[i]
          })[0];

          if (categ) {

            // add categories to breadcrumbs
            // if is the first entry
            if (i === 0) {
              categoriesBreadcrumbs.push({
                link: '/categorie/' + categ.slug,
                text: categ.label
              })
            }
            //for other
            else {
              categoriesBreadcrumbs.push({
                link: categoriesBreadcrumbs[i - 1]['link'] + '/' + categ.slug,
                text: categ.label
              })
            }
          }
        }
        this.categ= categoriesBreadcrumbs.slice(-1)
      }
    },
    toggleProductSidebar(){
      this.$store.dispatch('toggleProductSidebar',{open:false,productSlug:null})
    },
    getSelectedProduct(product) {
      if (product.children) {
        return product.children.find((p) => {
          if (p.offers && product.defaultOffer) {
            return p.offers.find(o => o.id === product.defaultOffer?.id) !== undefined
          }
        });
      }
      return product
    },
    loadProduct(ProductId) {
      this.loading = true;
      this.playAudio()
      Api.product.getBySkuList(ProductId).then((data) => {
        if (data.length > 0) {
          this.selectedProduct = LightProduct.of(data[0]);
          if (this.currentCart) {
            this.productIsInCart = this.currentCart.cartItems.find(item => item.product.offer.id === this.selectedProduct.defaultOfferId)
          } else {
            this.productIsInCart = null
          }
          if (this.productIsInCart) {
            this.quantity = this.productIsInCart.quantity + 1
          } else {
            this.quantity = 1;
          }


          this.setOfferQuantity({
            method: 'add_one',
            offerId: this.selectedProduct.defaultOfferId,
            quantity: this.quantity,
            stock: this.selectedProduct.stockQuantity,
            currentCart: this.currentCart
          })
        } else {
          this.selectedProduct = null
        }
      }).catch((e) => {
        if (e.status === 404) {
          // this.$router.push(this.$localizePath('/404')).catch();
          this.$store.dispatch('sendNotification', {
            type: 'danger',
            message: this.$i18n.t('errors.notfound'),
            icon: icons.error
          });
        } else {
          this.$store.dispatch('sendNotification', {
            type: 'danger',
            message: this.$i18n.t('errors.server.access'),
            icon: icons.error
          });
          this.error = e;
        }
      })
          .finally(() => {
            this.loading = false;
            this.firstLoad = false
          });
    },
    getConfigurable(product) {
      this.configurables = {};
      if (product.configurableAttributes?.length > 0 && product.children?.length > 0) {
        const defaultChild = product.children.find((child) => child?.defaultOffer?.id === product.defaultOfferId);

        if (!defaultChild) return;

        for (const attr of product.configurableAttributes) {
          const defaultAttr = defaultChild.attributeValuesByAttributeName[attr];
          const attrValue = {
            label: defaultAttr?.label || this.$i18n.t('Choose an option'),
            options: {},
            selected: defaultAttr?.value.toString(),
            type: product.attributeValuesByAttributeName[attr]?.attributeType
          };

          // Find difference between all children for a configurable attribute
          for (const p of product.children) {
            const value = p.attributeValuesByAttributeName[attr]?.value.toString();

            if (Object.keys(attrValue.options).indexOf(value) === -1 && value) {
              attrValue.options[value] = 'enabled';
            }
          }

          // set only if has more than 1 option  in attrValue.options
          if (Object.keys(attrValue.options).length > 1) {
            // Need to set new value with Vue.set method to preserve reactivity
            attrValue.options = Object.keys(attrValue.options).sort().reduce((r, k) => (r[k] = attrValue.options[k], r), {})
            this.$set(this.configurables, attr, attrValue);
          }
        }

        // Foreach key select default option
        Object.keys(this.configurables).forEach(key => {
          this.selectOption(key, this.configurables[key]?.selected);
        });
      }
    },
    selectOption(key, option) {
      // Set new value with Vue.set method to preserve reactivity
      this.$set(this.configurables[key], 'selected', option);

      Object.keys(this.configurables).forEach(k => {
        if (k && k != key) {
          const enabledOptions = [];

          for (const p of this.product.children) {
            // For all children, check if attribute value is same than option
            if (p.attributeValuesByAttributeName[key]?.value.toString() === option) enabledOptions.push(p.attributeValuesByAttributeName[k]?.value.toString());
          }
          Object.keys(this.configurables[k].options).forEach(option => {
            // Disabled all configurables button that don't contain the same option
            if (!enabledOptions.includes(option)) this.configurables[k].options[option] = 'disabled';
          });
        }
      });
    },
    selectConfigurableOption(key, option, remove) {

      const resetAllOptions = () => {
        Object.keys(this.configurables).forEach(k => {
          Object.keys(this.configurables[k].options).forEach(option => {
            this.configurables[k].options[option] = 'enabled';
          });
        });
      };

      const selectDisabledOption = (key, option) => {
        resetAllOptions();

        // Unselect all except current key
        Object.keys(this.configurables).forEach(k => {
          if (k != key) this.$set(this.configurables[k], 'selected', null);
          else if (this.configurables[key].selected != 'selected') this.selectOption(key, option);
        });
      }

      const selectProduct = () => {
        let matchingProducts = [...this.product.children];

        Object.keys(this.configurables).forEach(k => {
          const selected = this.configurables[k].selected;
          if (selected) matchingProducts = matchingProducts.filter(p => p.attributeValuesByAttributeName[k]?.value == selected);
        });

        if (matchingProducts.length !== 0) {
          this.selectedProduct = matchingProducts[0];
        } else {

        }
      };

      const unSelectOption = (key) => {
        this.$set(this.configurables[key], 'selected', null);
        resetAllOptions();

        // Replay all selected options
        Object.keys(this.configurables).forEach(k => {
          if (this.configurables[k].selected && k !== key) this.selectConfigurableOption(k, this.configurables[k].selected, true);
        });
      };

      if (this.configurables[key].options[option] === 'disabled') selectDisabledOption(key, option);
      else if (this.configurables[key].selected == null || remove) this.selectOption(key, option);
      else if (this.configurables[key].selected === option && !remove) unSelectOption(key);
      else if (this.configurables[key].selected !== option) {
        unSelectOption(key);
        this.selectOption(key, option);
      }
      selectProduct();
    },

    changeQty(quantity) {

        this.setOfferQuantity({
          method:"changeQty",
          offerId: this.selectedProduct.defaultOfferId,
          quantity: Number(quantity),
          stock: this.selectedProduct.stockQuantity,
          currentCart: this.currentCart
        })

    },

    setOfferQuantity(params) {

      let cart = this.currentCart,
          itemIsInCart = null,
          qty = Number(params.quantity),
          prevQty = 0,
          stock = params.stock;


      if (cart && cart.cartItems && cart.cartItems.length) {
        itemIsInCart = cart.cartItems.find(item => item.product?.offer?.id === params.offerId);

        if (itemIsInCart) {
          prevQty = itemIsInCart?.quantity || 0;
          stock = itemIsInCart?.product.offer.stockQuantity || 0;
        }
      }

      if(itemIsInCart && (this.ecommerceStyle === 'b2b' || this.ecommerceStyle === 'market')){
        params.method = 'update'
      }
      else if(itemIsInCart && params.method==="changeQty"){
        params.method = 'update'
      }
      else {
        qty = 1;
        params.quantity = 1
        params.method = 'add'
      }
      if(params.remove){
        params.method = 'remove'
        params.quantity = 0;
      }

      if (prevQty + qty >= stock && params.method === 'add') {
        Notifications.createNotifications({
          name: 'addToCart.maxQty',
          value: {stock: stock, productName: this.selectedProduct.name}
        })
        this.loading = true;
        this.AddTocartLoading = true
        params.quantity = stock;
        this.quantity = stock;



        Api.cart.setOfferQuantity(params)
            .then(data => {
              this.$store.dispatch('setCart', data);
            }).catch((e) => {
          console.error(e)
          this.$store.dispatch('sendNotification', {
            type: 'danger',
            message: this.$i18n.t('errors.server.access'),
            icon: icons.error
          });
          this.error = e;
        })
            .finally(() => {
              this.loading = false
              this.AddTocartLoading = false
            });
      }
      else if (qty >= stock && params.method === 'update') {
        Notifications.createNotifications({
          name: 'addToCart.maxQty',
          value: {stock: stock, productName: this.selectedProduct.name}
        })
        params.quantity = stock;
        this.quantity = stock;
        this.loading = true;
        this.AddTocartLoading = true
        Api.cart.setOfferQuantity(params)
            .then(data => {
              this.$store.dispatch('setCart', data);
            }).catch((e) => {
          console.error(e)
          this.$store.dispatch('sendNotification', {
            type: 'danger',
            message: this.$i18n.t('errors.server.access'),
            icon: icons.error
          });
          this.error = e;
        })
            .finally(() => {
              this.loading = false
              this.AddTocartLoading = false
            });
      }
      else {
        this.loading = true;
        this.AddTocartLoading = true
        Api.cart.setOfferQuantity(params)
            .then(data => {

              this.$store.dispatch('setCart', data);

              if (params.method==="update" !== this.showAddToCartModal ) {
                this.$store.dispatch('toggleProductsActionModal', {
                  open: true,
                  type: params.method,
                  product: this.selectedProduct.name
                });
              }

              if(params.method==="remove"){
                Api.marketingEvent.removeFromCart(this.selectedProduct,this.currentCart)
                Notifications.createNotifications({name: 'addToCart.remove', value: {productName: this.selectedProduct.name}})
              } else {
                Api.marketingEvent.addToCartLight(this.selectedProduct, qty, this.currentCart)
                if (this.productIsInCart) {
                  Notifications.createNotifications({name: 'addToCart.update', value: {productName: this.selectedProduct.name}})
                } else {
                  Notifications.createNotifications({name: 'addToCart.add', value: {productName: this.selectedProduct.name}})
                }
              }


            })
            .catch((e) => {
              console.error(e)
              this.$store.dispatch('sendNotification', {
                type: 'danger',
                message: this.$i18n.t('errors.server.access'),
                icon: icons.error
              });
              this.error = e;
            })
            .finally(() => {
              this.loading = false
              this.AddTocartLoading = false
            });
      }
    },


    goToProductPage() {
      if (this.activeCategory) {
        sessionStorage.setItem("activeCategory", this.activeCategory);
      }
      this.toggleProductSidebar();
      this.$router.push(this.$localizePath('/produit/' + this.product.slug));
    },
  }
};
</script>
