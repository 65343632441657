
import { i18n } from "vue-lang-router";

export default class Locales {

  static getLocaleCode(): string {
    const locale = i18n.locale;
    switch (locale) {
      case "fr":
        return "fr_FR";
        break;
      case "en":
        return "en_US";
        break;
      default:
        return "fr_FR";
    }
  }
}
