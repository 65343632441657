<template src="@/components/AddressListModal.html"/>
<style lang="scss" scoped src="@/components/AddressListModal.scss"/>

<script>

import {
  SfBar,
  SfButton,
  SfModal,
  SfHeading,
} from '@storefront-ui/vue';
import UserAddress from '@/components/UserAddress.vue';
import Api from "@/api/Api";
import Notifications from "@/utils/notifications";
import {CustomerActions} from "~/core/store/customer/actions";


export default {
  name: 'AddressListModal',
  components: {
    UserAddress,
    SfHeading,
    SfBar,
    SfButton,
    SfModal
  },
  data() {
    return {
      error: null,
      loadingAddress: false,
      billingAddress: null,
      selectedAddress: null,
      loading: false
    }
  },
  computed: {
    isAddressListModalOpen: {
      get() {
        return this.$store.getters.isAddressListModalOpen
      }
    },
    customer: {
      get() {
        return this.$store.getters.getProfile;
      },
      set() {

      }
    },
    currentCart: {
      get() {
        return this.$store.getters.getCart;
      },
      set() {

      }
    }
  },

  mounted() {

    if (this.customer && this.customer.defaultBillingAddress && this.customer.addresses?.length !== 0) {
      this.selectedAddress = this.customer.defaultBillingAddress;
      if (this.currentCart && this.currentCart.billingAddress) {
        this.selectedAddress = this.currentCart.billingAddress.name;
      }
      /*  if (this.selectedAddress) {
          this.change(this.selectedAddress)
        }*/
    }
  },

  methods: {
    change(address) {
      this.selectedAddress = address;
    },
    setBillingAddress(address) {
      this.selectedAddress = address;
      if (address) {
        this.$emit('changeAddress', {selectedAddress: this.selectedAddress})
        if (this.selectedAddress) {
          Api.cart.setBillingAddress(address)
              .then((data) => {
                this.$store.dispatch('setCart', data)
                this.currentCart = data;
                this.toggleAddressListModal()
              })
              .catch((e) => {
                switch (e.status) {
                  case 401:
                    if (e.errorCode === "customer-is-not-logged-in") {
                      Notifications.createNotifications({name: 'errors.not-logged-in'})
                      this.$store.dispatch(CustomerActions.setCustomer, null);
                      this.$store.dispatch('getCategoryTree');
                      this.$router.push(this.$router.app.$localizePath("/mon-compte"));
                    } else {
                      if (e.errorCode === "customer-is-not-logged-in") {
                        Notifications.createNotifications({name: 'errors.not-logged-in'})
                        this.$store.dispatch(CustomerActions.setCustomer, null);
                        this.$store.dispatch('getCategoryTree');
                        this.$router.push(this.$router.app.$localizePath("/mon-compte"));
                      } else {
                        Notifications.createNotifications({name:'errors.address.unauthorized'})
                      }
                    }
                    break;
                  case 409:
                    Notifications.createNotifications({name:'errors.address.allReadyExist'})
                    break;
                  case 404:
                    Notifications.createNotifications({name:'errors.address.notFound'})
                    break;

                }
              })
              .finally(() => {
                this.loadingAddress = false
              })

        }
      } else {
        this.loadingAddress = false
      }
    },
    toggleAddressListModal() {
      this.$store.dispatch('toggleAddressListModal')
    },

  }
};
</script>
