import Vue from "vue";


/**
 *  Qorus API about user's account
 */

export default class CustomService extends Vue {
  constructor() {
    super();
  }

  static of(): CustomService {
    return new CustomService();
  }

}