<template src="@/components/Ork/OrkReassurance.html"></template>
<style lang="scss" scoped src="@/components/Ork/OrkReassurance.scss"></style>

<script>
import {
  SfIcon,
  SfImage
} from '@storefront-ui/vue';
import {icons} from "@/utils/icons";
import i18n from "vue-lang-router";


export default {
  name: 'OrkReassurance',
  components: {
    SfIcon,
    SfImage
  },
  data() {
    return {
      icons: icons,
      reassurance:null
    }
  },
  created() {
    this.setReassurance();
  },
  methods: {
    setReassurance(){
      this.reassurance=[
          {
            text:  'components.orkReassurance.icon1',
            // icon is path of file ('/assets/images/reassurance/iconName.svg' ou icon in library (icons.name)
            icon: icons.SecurePayment,
            // link is optional
          },
          {
            text: 'components.orkReassurance.icon2',
            icon: icons.freeDelivery,
          },
          {
            text:'components.orkReassurance.icon3',
            icon: icons.pin,
          },
          {
            text: 'components.orkReassurance.icon4',
            icon: icons.temperature,
          }]
    }
  }
};

</script>
