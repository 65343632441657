import {ActionTree} from "vuex";
import {RootState} from "~/core/store/type";
import {UiState} from "~/core/store/ui/type";
import {UiMutations} from "~/core/store/ui/mutations";
import Api from "@/api/Api";
import { PromotionnalItem } from "@/models/marketingHome";

export const actions: ActionTree<UiState, RootState> = {

    toggleLoginModal({commit}, {open, isReloaded}) {
        commit(UiMutations.IS_TOGGLE_LOGIN, {open, isReloaded});
    },
    closeLoginModal({commit}) {
        commit(UiMutations.CLOSE_LOGIN);
    },
    toggleMenuModal({commit}) {
        commit(UiMutations.IS_TOGGLE_MENU);
    },
    toggleAddressModal({commit},{ open, address, isShipping, isBilling}) {
        commit(UiMutations.IS_TOGGLE_ADDRESS_ACTION,{ open, address, isShipping, isBilling} );
    },

    toggleAddressListModal({commit}) {
        commit(UiMutations.IS_TOGGLE_ADDRESSLIST_ACTION);
    },
    closeMenuModal({commit}) {
        commit(UiMutations.CLOSE_MENU);
    },
    toggleSearch({commit}) {
        commit(UiMutations.IS_TOGGLE_SEARCH);
    },
    closeSearch({commit}) {
        commit(UiMutations.CLOSE_SEARCH);
    },
    openSearch({commit}) {
        commit(UiMutations.OPEN_SEARCH);
    },
    toggleCartSidebar({commit}) {
        commit(UiMutations.IS_TOGGLE_CART);
    },
    toggleProductSidebar({commit}, {open, productSlug}) {
        commit(UiMutations.IS_TOGGLE_PRODUCT, {open, productSlug});
    },
    toggleProductsActionModal({commit}, {open, type, product}) {
        commit(UiMutations.IS_TOGGLE_PRODUCTS_ACTION, {open, type, product});
    },
    toggleQuickOrder({commit}, {open}) {
        commit(UiMutations.IS_TOGGLE_QUICK_ORDER, {open});
    },
    toggleCgvModal({commit}, {open, shopName, cgvContent}) {
        commit(UiMutations.IS_TOGGLE_CGV_CONTENT, {open, shopName, cgvContent});
    },
    toggleNoStockActionModal({commit}, {open, products}) {
        commit(UiMutations.IS_TOGGLE_NOSTOCK_ACTION, {open, products});
    },
    toggleStockAlertModal({commit}, {open, product}) {
        commit(UiMutations.IS_TOGGLE_STOCKALERT_ACTION, {open, product});
    },
    setIndexGallery({commit}, {index}) {
        commit(UiMutations.CHANGE_INDEX, {index});
    },

    setProductGallery({commit}, {gallery}) {
        commit(UiMutations.GET_GALLERY, {gallery});
    },
    toggleWelcomeModal({commit}, {open, customer}) {
        commit(UiMutations.IS_TOGGLE_WELCOME_ACTION, {open, customer});
    },
    toggleResetModal({commit}, {open, email, status}) {
        commit(UiMutations.IS_TOGGLE_RESET_ACTION, {open, email, status});
    },
    toggleRootCategoryModal({commit},{ open, rootCategory}) {
        commit(UiMutations.IS_TOGGLE_ROOTCATEGORY_MODAL,{ open, rootCategory} );
    },
    sendNotification({commit, dispatch}, notification) {
        const id = Symbol();
        commit(UiMutations.SEND_NOTIFICATIONS, {notification, id});
        setTimeout(() => {
            dispatch("dismissNotification", id);
        }, 3000);
    },
    dismissNotification({commit}, id) {
        commit(UiMutations.DISMIS_NOTIFICATIONS, id);
    },
    setCurrentLocal({commit}, locale) {
        commit(UiMutations.SET_CURRENT_LOCAL, locale);
    },

     getFranco: ({commit}): Promise<number | null> | null => {

        return  Api.marketing.getFranco()
            .then((data) => {
                commit(UiMutations.SET_FRANCO, data);
                return data;
            })
            .catch((e) => {
                commit(UiMutations.SET_FRANCO, null);
                return null;
            })


    },

    setFiltersValues({commit}, filtersValues) {
        commit(UiMutations.SET_FILTERS_VALUES, filtersValues);
    },

    getTopPromotional: ({commit, state}): [PromotionnalItem] | null | undefined => {

        if (state.topPromotional) {
            return state.topPromotional;
        } else {
            Api.marketing.getTopPromotional()
                .then((data) => {

                    data.map(s => {
                        return PromotionnalItem.of(s);
                    })
                    commit(UiMutations.SET_TOP_PROMOTIONAL, data);
                    return data;

                })
                .catch((e) => {
                    commit(UiMutations.SET_TOP_PROMOTIONAL, null);
                    return null;
                })
        }
    }
};
