<template src="@/components/SedRelay/SedRelayModal.html"/>
<style lang="scss" scoped src="@/components/SedRelay/SedRelayModal.scss"/>

<script>
import {SfAccordion, SfButton, SfCharacteristic, SfInput, SfModal, SfScrollable, SfSelect} from '@storefront-ui/vue';
import Api from "@/api/Api";
import {Relais} from "@/models/relais";
import SedRelay from '@/components/SedRelay/SedRelay.vue';
import countries from "@/models/country.json";
import icons from "@/utils/icons";
import Validation from "@/utils/validation";
import {UiMutations} from "@/store/ui/mutations";
import OrkTelInput from '@/components/Ork/OrkTelInput.vue';

export default {
  name: 'SedRelayModal',
  components: {
    OrkTelInput,
    SfButton,
    SfModal,
    SfScrollable,
    SfCharacteristic,
    SfAccordion,
    SedRelay,
    SfInput,
    SfSelect,
  },
  data() {
    const territorySlug = this.$store.getters.getRootCategoryPath.slice(0, -1);

    return {
      Validation,
      icons: icons,
      countryLang: "label_" + this.$i18n.locale,
      countries: countries,
      authorizedCountryList: process.env.VUE_APP_AUTHORIZED_COUNTRY_LIST,
      countryBlur: true,
      relais: [],
      loading: true,
      streetBlur: true,
      cityBlur: true,
      postCodeBlur: true,
      validRelaisAddress: false,
      validRelaisPhone: false,
      address: '',
      userCenter: Api.sed.getHereMapParamsByTerritorySlug(territorySlug).coords,
      userZoom: Api.sed.getHereMapParamsByTerritorySlug(territorySlug).zoom,
      initZoom: 3,
      UserGeocode: null,
      checkColissimoApi: false,
      selectedRelais: null,
      selectedRelaisId: null,
      phoneNumberBlur: false,
      mapReady: false,
      phoneErrorMessage: null
    }
  },
  mounted() {
    if(this.authorizedCountryList){
      const CountryList = this.authorizedCountryList.split(',')
      this.countries = this.countries.filter((country) => CountryList.includes(country.code));
    }
    if (this.countries.length === 1) {
      //this.addressComp.country= this.countries[0].code
    }
    this.countries.sort((a, b) => {
      return a[this.countryLang] > b[this.countryLang] ? 1 : -1;
    })

    if (window.matchMedia("(max-width: 768px)").matches) {
      let relaisForm = document.getElementById('find-relais-form')
      if (relaisForm) {
        relaisForm.style.display = "none";
      }
    }
  },
  computed: {
    customer: {
      get() {
        return this.$store.getters.getProfile;
      },
    },
    selectedRelaisComp: {
      get() {
        return this.$store.getters.relayModal.selectedRelais
      }
    },
    shipmentWeight: {
      get() {
        return this.$store.getters.relayModal.shipmentWeight
      }
    },
    addressComp: {
      get() {
        return this.$store.getters.relayModal.address
      }
    },
    isRelayModalOpen: {
      get() {
        return this.$store.getters.relayModal.open
      }
    },
  },
  watch: {
    selectedRelaisComp(newRelais) {
      if (newRelais) {
        //   this.setSelectedRelais(newRelais.name)
        this.selectedRelais = newRelais
        this.selectedRelaisId = newRelais.name
      } else {
        this.selectedRelais = null;
        this.selectedRelaisId = null;
      }
    },
    addressComp(newAddress, oldAddress) {
      if (newAddress && this.address !== newAddress && newAddress !== oldAddress) {
        this.address = {...newAddress};

      }
      this.geocode(this.address)
    },
    UserGeocode(geocode) {
      if (geocode) {
        //    this.reverseGeocode(geocode);
      }
    }
  },

  methods: {
    onPhoneValidation({ errorMessage, phoneNumber }) {
      this.phoneErrorMessage = errorMessage;

      if (!errorMessage) {
        this.address.phone = phoneNumber;
      }

      this.validRelaisPhone = !errorMessage;
    },
    getMapReady(event) {
      this.mapReady = event;
    },
    toggleAddressForm() {
      let formElement = document.getElementById('find-relais-form')
      if (window.matchMedia("(max-width: 768px)").matches) {
        if (formElement.style.display === "none") {
          formElement.style.display = "block";
        } else {
          formElement.style.display = "none";
        }
      } else {
        formElement.style.display = "flex";
      }
    },

    validateAddress() {
      this.cityBlur = false;
      this.postCodeBlur = false;
      this.streetBlur = false;
      this.countryBlur = false;

      if (
          Validation.validatePostalCode(this.address.country,this.address.postCode) &&
          Validation.validateText(this.address.city,2) &&
          Validation.validateText(this.address.street,2) &&
          Validation.validateCountry(this.address.country)
      ) {
        this.validRelaisAddress = true;
      } else {
        this.validRelaisAddress = false;
      }
    },

// addressForm
    findRelais() {
      this.validateAddress()
      if (this.validRelaisAddress) {
        this.geocode(this.address)
      }
    },
    getUserGeocode(geocode) {
      this.UserGeocode = geocode
    },
    /*reverseGeocode(geocode) {

      Api.platform.reverseGeocoding(geocode).then((data) => {
        this.address = {...data};
        this.getRelais(data);
      }).catch((e) => {
        console.log(e)
      })
    },*/
    setSelectedRelais(storeId) {
      this.selectedRelaisId = storeId;
      this.selectedRelais = this.relais.find(relais => relais.name === storeId);

    },
    geocode(address) {

      if (address) {
        console.log("updating map address: " + JSON.stringify(address));
        console.trace();

        this.validateAddress()
        Api.platform.geocode(address).then((data) => {
            console.log("coordinates: " + JSON.stringify(data));
          this.userCenter = data;
          this.getRelais(address)
        }).catch((e) => {
          console.error(e)
        })
      }
    },

    getRelais(address) {
        const territorySlug = this.$store.getters.getRootCategoryPath.slice(0, -1);

        const catalogName = process.env.VUE_APP_MOSAIQ_RELAY_CATALOG;
        const catalogPrefix = catalogName + "_";

        this.loading = true;

        Api.relay.getRelaysForTerritory(territorySlug)
        .then((data) => {
            this.relais = data.map(moqRelayData => { return {
                identifiant: moqRelayData[catalogPrefix + "code"],
                nom: moqRelayData[catalogPrefix + "name"],
                adresse1: moqRelayData[catalogPrefix + "address"]["houseNumber"] + " " + moqRelayData[catalogPrefix + "address"]["street"],
                adresse2: moqRelayData[catalogPrefix + "address"]["street2"],
                adresse3: moqRelayData[catalogPrefix + "address"]["street3"],
                localite: moqRelayData[catalogPrefix + "address"]["city"],
                codePostal: moqRelayData[catalogPrefix + "address"]["postalCode"],
                codePays: moqRelayData[catalogPrefix + "address"]["countryName"],
                coordGeolocalisationLatitude: moqRelayData[catalogPrefix + "address"]["latitude"],
                coordGeolocalisationLongitude: moqRelayData[catalogPrefix + "address"]["longitude"],
                distanceEnMetre: 0,
                horairesOuvertureDimanche: "00:00-00:00 00:00-00:00",
                horairesOuvertureLundi: "00:10-12:00 14:10-19:00",
                horairesOuvertureMardi: "00:00-12:00 14:10-19:00",
                horairesOuvertureMercredi: "08:00-12:00 14:00-00:00",
                horairesOuvertureJeudi: "00:00-00:00 00:00-00:00",
                horairesOuvertureVendredi: "00:00-00:00 00:00-00:00",
                horairesOuvertureSamedi: "00:00-00:00 00:00-00:00"
            };})
            .map(data => Relais.of(data));
        }).catch((e) => {
          this.relais = [];
          if (this.$refs.relayMap) {
            // this.$refs.relayMap.updateRelais(this.relais)
          }

          this.$store.dispatch('sendNotification', {
            type: 'danger',
            message: this.$i18n.t('errors.server.relaisAddress'),
            icon: this.icons.error
          });
        }).finally(() => {
            this.loading = false;
        })
    },
    toggleRelayModal() {
      this.$store.commit(UiMutations.CLOSE_RELAY_MODAL, this.selectedRelais);
    },

    validStore() {
      this.selectedRelais.phone = this.address.phone;
      this.$store.commit(UiMutations.CLOSE_RELAY_MODAL, this.selectedRelais);
    },

    opentTime(item) {
      const openingElements = [...document.getElementsByClassName('isOpenTime')];
      item.target.parentElement.classList.toggle("isOpenTime");
      if (openingElements) {
        openingElements.forEach((element) => {
          if (element !== item.target.parentElement) {
            element.classList.remove('isOpenTime')
          }
        })
      }
    }
  }
};
</script>
