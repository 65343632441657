import { render, staticRenderFns } from "@/components/Menu/MenuDesktopModal.html?vue&type=template&id=782b221f&scoped=true&external"
import script from "./MenuDesktopModal.vue?vue&type=script&lang=js"
export * from "./MenuDesktopModal.vue?vue&type=script&lang=js"
import style0 from "@/components/Menu/MenuDesktopModal.scss?vue&type=style&index=0&id=782b221f&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "782b221f",
  null
  
)

export default component.exports