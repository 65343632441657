<template src="@/components/Notification.html"></template>
<style lang="scss" scoped src="@/components/Notification.scss"></style>

<script>
import {SfIcon, SfNotification} from '@storefront-ui/vue';

export default {
  name: 'Notification',
  components: {
    SfNotification,
    SfIcon
  },
  computed: {
    notifications: {
      get() {
        return this.$store.getters.getNotifications;
      }
    },
    isSidebarOpen: {
      get() {
        return this.$store.getters.isCartSidebarOpen;
      }
    }
  }
}
</script>
