<template src="@/components/Ork/OrkLightBox.html"/>
<style lang="scss" scoped src="@/components/Ork/OrkLightBox.scss"/>
<script>


import CoolLightBox from 'vue-cool-lightbox'

export default {
  name: 'OrkLightBox',
  components: {
    CoolLightBox,
  },

  computed: {
    gallery: {
      get() {
        return this.$store.getters.getProductGallery
      },
      set() {
      }
    },
    index: {
      get() {
        return this.$store.getters.getIndexGallery
      },
      set() {
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch('setIndexGallery', {index: null})
    this.$store.dispatch('setProductGallery', {gallery: []})
  },
  methods: {
    resetIndex() {
      this.$store.dispatch('setIndexGallery', {index: null})
    }
  }

};
</script>


