import { render, staticRenderFns } from "@/components/Ork/OrkRootCategoryModal.html?vue&type=template&id=2f359bf4&scoped=true&external"
import script from "./OrkRootCategoryModal.vue?vue&type=script&lang=js"
export * from "./OrkRootCategoryModal.vue?vue&type=script&lang=js"
import style0 from "@/components/Ork/OrkRootCategoryModal.scss?vue&type=style&index=0&id=2f359bf4&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f359bf4",
  null
  
)

export default component.exports