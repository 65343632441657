var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ork-toggle-state",class:{
     'small':_vm.size==='small',
     'medium':_vm.size==='medium',

    'is-disabled': _vm.disabled,
    'has-error': !_vm.valid,
    'is-required': _vm.required,
  }},[_c('div',{staticClass:"switch"},[_c('input',{attrs:{"name":"switch","id":"one","type":"radio","value":"false"},domProps:{"checked":_vm.selected === 'false'},on:{"change":_vm.inputHandler}}),_c('label',{staticClass:"switch__label",attrs:{"for":"one"}}),_c('input',{attrs:{"name":"switch","id":"two","type":"radio","value":"none"},domProps:{"checked":_vm.selected === 'none'},on:{"change":_vm.inputHandler}}),_c('label',{staticClass:"switch__label",attrs:{"for":"two"}}),_c('input',{attrs:{"name":"switch","id":"three","type":"radio","value":"true"},domProps:{"checked":_vm.selected === 'true'},on:{"change":_vm.inputHandler}}),_c('label',{staticClass:"switch__label",attrs:{"for":"three"}}),_c('button',{staticClass:"switch__indicator",on:{"click":_vm.changeSelected}}),_c('div',{staticClass:"switch__background"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }