<template src="@/layouts/Default.html"></template>

<script>
import Default from '~/core/layouts/Default';
import MenuDesktopModal from '@/components/Menu/MenuDesktopModal'
import Vue from 'vue';

export default Vue.extend({
  mixins: [Default],
  components: {
    MenuDesktopModal
  }
})
</script>