<template src="@/components/ResetPasswordModal.html"/>

<style lang="scss" scoped src="@/components/ResetPasswordModal.scss"/>
<script>

import {
  SfModal,
  SfButton,
  SfBar
} from '@storefront-ui/vue';


export default {
  name: 'ResetPasswordModal',
  components: {
    SfModal,
    SfButton,
    SfBar
  },
  computed: {
    isResetModal: {
      get() {
        return this.$store.getters.isResetModal
      }
    },
  },

  methods: {
    toggleResetModal() {
      this.$store.dispatch('toggleResetModal', {email: null, status: null})
    },
    goToAccount() {
      this.$store.dispatch('toggleResetModal', {email: null, status: null})
      this.$router.push(this.$localizePath("/mon-compte/profil"))
    },
    backToHome() {
      this.$store.dispatch('toggleResetModal', {email: null, status: null})
      this.$router.push(this.$localizePath("/"))
    }

  }
};
</script>

