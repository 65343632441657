<template src="@/components/UserAddress.html"/>
<style lang="scss" scoped src="@/components/UserAddress.scss"/>
<script>

import {SfIcon, SfImage, SfButton} from '@storefront-ui/vue';
import icons from "@/utils/icons";
import countries from "@/models/country.json";
import {Address} from "@/models/address";

export default {
  components: {
    SfIcon,
    SfImage,
    SfButton
  },
  data() {
    return {
      countryLang: "label_" + this.$i18n.locale,
      countries: countries,
      icons: icons,
      country: '',
    }
  },
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isInShipping: {
      type: Boolean,
      default: false
    },
    isRelais: {
      type: Boolean,
      default: false
    },
    showAddressName: {
      type: Boolean,
      default: false
    },
    showAddressDetails: {
      type: Boolean,
      default: true
    },
    address: {
      type: Address,
      required: true
    },
    isModifiable: {
      type: Boolean,
      required: false,
      default: true
    },
    isEligible: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  watch: {
    addressComp(newAddress, oldAddress) {
      if (oldAddress !== newAddress)
        this.country = this.countries.find(country => country.code === newAddress.country)
    }
  },
  created() {
    this.country = this.countries.find(country => country.code === this.addressComp.country)
  },
  computed:{
    addressComp() {
      return this.address?this.address:{
        name: '',
        company: '',
        firstName: '',
        lastName: '',
        street: '',
        city: '',
        postCode: '',
        country: '',
        phone: '',
        defaultShipping: true,
        defaultBilling: true
      }
    },
  },
  methods:{
    editingAddress() {
      this.$store.dispatch("toggleAddressModal",{open:true, address:this.addressComp});
    },
  }
};
</script>
