import { render, staticRenderFns } from "@/components/UserAddress.html?vue&type=template&id=1ef67aae&scoped=true&external"
import script from "./UserAddress.vue?vue&type=script&lang=js"
export * from "./UserAddress.vue?vue&type=script&lang=js"
import style0 from "@/components/UserAddress.scss?vue&type=style&index=0&id=1ef67aae&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ef67aae",
  null
  
)

export default component.exports