<template>
  <div class="orkMobileGrid">
    <p class="orkImgGrid--title">{{ product.name }}</p>
    <p class="orkImgGrid--reference">{{ product.reference }}</p>
    <SfScrollable maxContentHeight="170px">
      <div class="scroll-content">
        <div class="orkImgGrid">
          <SfImage :alt="product.name?product.name:''" :src="product.img" class="orkImgGrid--image" height="auto"
                   width="90"/>

        </div>
        <div class="orkImgGrid--content">
          <div class="attributes">
            <SfAccordion
                :firstOpen="false"
                :multiple="false"
                :show-chevron="true"
                open=""
                transition=""
            >
              <SfAccordionItem
                  v-if="Object.keys(attributes).length !== 0 "
                  key="characteristic"
                  :header="$t('pages.products.characteristics.title')"
              >
                <div v-for="(attr,i) in attributes" v-if="attr.value.length>0 && attr.displayType!=='NONE' "
                     v-bind:key="i"
                     class="attribute">
                  <div class="label">{{ attr.name }}</div>
                  <div class="value">
                    <div v-for="(attrVal,a) in attr.value" v-if="attr.value.length>1" :key="a">
                      <div v-html="attr.unit?attrVal+' '+attr.unit:attrVal"></div>
                    </div>
                    <div v-else>
                      <div v-html="attr.unit?attr.value+' '+attr.unit:attr.value[0]"></div>
                    </div>
                  </div>
                </div>
              </SfAccordionItem>
            </SfAccordion>
          </div>
        </div>

      </div>
    </SfScrollable>

    <div class="bottom-content">
      <div class="orkPriceGrid">
        <OrkPrice
            v-if="ecommerceMode==='B2B'"
            :regular="product.regular.taxFree | toCurrencyHT"
            :special="product.discountPrice && product.discountPrice.taxFree | toCurrencyHT"

        />
        <OrkPrice
            v-else
            :regular="product.price.taxIncluded | toCurrency"
            :special="product.discountPrice && product.discountPrice.taxIncluded | toCurrency"
        />
      </div>

      <div class="orkAddToCartGrid">
        <OrkAddToCart
            v-model="quantity"
            :canAddToCart="stockQuantity >= quantity && stockQuantity > 0 && stockQuantity >= packSize "
            :cartQty="productIsInCart? productIsInCart.quantity:0"
            :disabled="loading"
            :gridMode="gridMode"
            :isInCart="!!productIsInCart"
            :isInProductView="false"
            :loading="AddTocartLoading"
            :max="maximalOrderQuantity"
            :min="minimalOrderQuantity"
            :packSize="packSize"
            :showIcon="showIcon"
            :showLabel="showLabel"
            :showQtySelect="showQty"
            :stock="stockQuantity"
            class="grid__add-to-cart"
            @remove="addToCart({remove:true, offerId:defaultOfferId, quantity: 0, stock:stockQuantity, currentCart})"
            @click="addToCart({offerId:defaultOfferId, quantity: quantity, stock:stockQuantity, currentCart})"
            @input="changeQty"
        ></OrkAddToCart>
        <div class="stock">
          <OrkStock :offer-id="product.defaultOfferId" :quantity="product.stockQuantity"
                    :show-stock-alert="false"></OrkStock>
        </div>
      </div>
    </div>

  </div>


</template>
<script>

import {
  SfScrollable,
  SfAccordion,
  SfImage,
} from '@storefront-ui/vue';

import OrkPrice from "@/components/Ork/OrkPrice";

import OrkAddToCart from "@/components/Ork/OrkAddToCart";
import icons from "@/utils/icons";
import Vue from "vue";
import Notifications from "@/utils/notifications";
import Api from "@/api/Api";
import OrkStock from "@/components/Ork/OrkStock";

export default {
  name: "OrkMobileGrid",
  components: {
    OrkStock,
    OrkAddToCart,
    SfScrollable,
    SfAccordion,
    OrkPrice,
    SfImage
  },
  data() {
    return {
      product: null,
      ecommerceMode: process.env.VUE_APP_ECOMMERCE_MODE,
      showQty: process.env.VUE_APP_SHOWQTY === 'true',
      productIsInCart: null,
      showLabel: false,
      showIcon: true,
      minimalOrderQuantity: 0,
      maximalOrderQuantity: 0,
      packSize: 1,
      stockQuantity: 0,
      attributes: [],
      loading: false,
      AddTocartLoading: false,
      showQtySelect: false,
      canAddToCart: false,
      label: "components.okrAddToCart.addLabel",
      isConfigurable: false,
      defaultOfferId: null,
      defaultOffer: null,
      gridMode: true,
      quantity: 1,
      isInProductView: true,
      isInCart: false,
      cartQty: 0,
      ecommerceStyle: process.env.VUE_APP_ECOMMERCE_STYLE,
      icons: icons,
      reference: null,
      name: null
    }
  },
  beforeMount() {
    this.product = this.params.value

    this.maximalOrderQuantity = this.params.value.maximalOrderQuantity
    this.minimalOrderQuantity = this.params.value.minimalOrderQuantity
    this.stockQuantity = this.params.value.stockQuantity
    this.quantity = this.params.value.quantity
    this.defaultOfferId = this.params.value.defaultOfferId
    this.defaultOffer = this.params.value.defaultOffer
    this.packSize = this.params.value.packSize
    this.name = this.params.value.name
    this.reference = this.params.value.reference
    this.getProductIsInCart()
    this.sortedAttributes()
  },
  computed: {
    activeCategory: {
      get() {
        return sessionStorage.getItem('activeCategory')
      }
    },
    currentCart: {
      get() {
        return this.$store.getters.getCart;
      }
    },
  },
  watch: {
    currentCart(NewCart) {
      if (NewCart && this.defaultOfferId) {
        this.productIsInCart = NewCart.cartItems.find(item => item.product.offer.id === this.defaultOfferId)
      } else {
        this.productIsInCart = null
      }
      if (this.productIsInCart) {
        this.quantity = this.productIsInCart.quantity
      }
    },
  },
  methods: {
    sortedAttributes() {
      const sort = Object.fromEntries(
          Object.entries({...this.product.attributes})
              .sort(([KeyA, a], [KeyB, b]) => {

                if (!a.value) {
                  delete this[KeyA]
                }
                if (!b.value) {
                  delete this.product.attributes[KeyB]
                }
                return a.label < b.label ? -1 : 1;
              })
      );
      for (const [key, value] of Object.entries(sort)) {
        if (!value.value) {
          delete sort[key]
        }
      }

      this.attributes = sort
      // return sort
    },
    getProductIsInCart() {
      if (this.currentCart) {
        this.productIsInCart = this.currentCart.cartItems.find(item => item.product.offer.id === this.defaultOfferId)
      }
    },
    changeQty(quantity) {

      if (this.ecommerceStyle === "market" || this.gridMode) {
        this.addToCart({
          offerId: this.defaultOfferId,
          quantity: Number(quantity),
          stock: this.stockQuantity,
          currentCart: this.currentCart
        })
      }

    },
      addToCart(params) {
          let cart = params.currentCart,
              itemIsInCart = null,
              qty = Vue.filter('toInt')(params.quantity),
              prevQty = 0,
              stock = params.stock;

          if (cart && cart.cartItems && cart.cartItems.length) {
              itemIsInCart = cart.cartItems.find(item => item.product?.offer?.id === params.offerId);

              if (itemIsInCart) {
                  prevQty = itemIsInCart?.quantity || 0;
                  stock = itemIsInCart?.product.offer.stockQuantity || 0;
              }
          }

          if (this.productIsInCart) {
              params.method = 'update'
          } else {
              params.method = 'add'
          }
          if(params.remove){
              params.method = 'remove'
              params.quantity = 0;
          }
          if (prevQty + qty >= stock && params.method === 'add') {
              Notifications.createNotifications({
                  name: 'addToCart.maxQty',
                  value: {stock: stock, productName: this.product.name}
              })

              this.AddTocartLoading = true
              params.quantity = stock;
              Api.cart.setOfferQuantity(params)
                  .then(data => {
                      this.$store.dispatch('setCart', data);
                  }).catch((e) => {
                  console.error(e)
                  this.$store.dispatch('sendNotification', {
                      type: 'danger',
                      message: this.$i18n.t('errors.server.access'),
                      icon: icons.error
                  });
                  this.error = e;
              })
                  .finally(() => {
                      this.loading = false
                      this.AddTocartLoading = false
                  });
          }
          else if (qty >= stock && params.method === 'update') {
              Notifications.createNotifications({
                  name: 'addToCart.maxQty',
                  value: {stock: stock, productName: this.product.name}
              })
              params.quantity = stock;

              this.AddTocartLoading = true
              Api.cart.setOfferQuantity(params)
                  .then(data => {
                      this.$store.dispatch('setCart', data);
                  }).catch((e) => {
                  console.error(e)
                  this.$store.dispatch('sendNotification', {
                      type: 'danger',
                      message: this.$i18n.t('errors.server.access'),
                      icon: icons.error
                  });
                  this.error = e;
              })
                  .finally(() => {

                      this.AddTocartLoading = false
                  });
          }
          else {
              this.AddTocartLoading = true;
              Api.cart.setOfferQuantity(params)
                  .then(data => {
                      this.$store.dispatch('setCart', data);

                      Api.marketingEvent.addToCart(this.selectedProduct,qty,this.activeCategory, this.currentCart)

                      if(params.method==="add" && this.showAddToCartModal) {
                          this.$store.dispatch('toggleProductsActionModal', {
                              open: true,
                              type: params.method,
                              product: this.product.name
                          });
                      }
                      if(params.method==="remove"){
                        Api.marketingEvent.removeFromCart(this.selectedProduct,this.currentCart)
                          Notifications.createNotifications({name: 'addToCart.remove', value: {productName: this.selectedProduct.name}})
                      } else {
                          if (this.productIsInCart) {
                              Notifications.createNotifications({name: 'addToCart.update', value: {productName: this.selectedProduct.name}})
                          } else {
                              Notifications.createNotifications({name: 'addToCart.add', value: {productName: this.selectedProduct.name}})
                          }
                      }
                  })
                  .catch((e) => {
                      if(e.status !== 499) {
                          this.$store.dispatch('setCart', null);
                          this.currentCart = null;
                          Notifications.createNotifications({name:'errors.server.access'})
                      }
                  })
                  .finally(() => {
                      this.AddTocartLoading = false;

                      if (!this.productIsInCart) {
                          this.quantity = 1;
                      }
                  });
          }

      },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/components/molecules/SfAddToCart.scss";

.orkMobileGrid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .sf-scrollable {
    ::v-deep {
      .sf-scrollable__view-all {
        display: none;
      }
    }

    .scroll-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  .bottom-content {
    margin: auto 0 0 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
  }

  .attributes {
    margin: 0;
    padding-left: 0;

    .attribute {
      display: table-row;
      font-size: var(--font-size--xs);

      .label {
        font-weight: bold;
        display: table-cell;
        padding-right: var(--spacer-sm);
        text-align: left;
        word-break: keep-all;
        white-space: nowrap;
        text-overflow: ellipsis;

      }

      .value {
        text-align: left;
        flex: 1;

        ::v-deep {
          p {
            margin: 0 !important;
          }
        }
      }

    }
  }
}

::v-deep {
  .grid__add-to-cart {
    margin: var(--spacer-xs) 0 0 auto;
    justify-content: flex-end;

    &.sf-add-to-cart {
      .sf-button {
        &.sf-button-add-to-cart-outline {
          flex: 1;
          width: 52px;
          max-width: 52px;

          &.is-disabled--button {
            flex: 1;
            width: auto;
            max-width: 100%;

            &.is-in-cart, &.loading {
              flex: 1;
              width: 52px;
              max-width: 52px;
            }
          }
        }
      }

      &__select-quantity {

      }
    }


    .sf-quantity-selector {
      max-width: 80px;

      &__button {
        &.minus, &.plus {
          min-width: 20px;
          padding: 0;
        }
      }
    }

    .add-to-cart-btn {
      flex: 1;
      max-width: 100px;
    }
  }
}

.orkImgGrid {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &--content {
    width: 100%;

    ::v-deep {
      .sf-accordion-item {
        &__content {
          padding: var(--spacer-xs) 0;
        }

        &__header {
          font-family: var(--font-family--primary);
          color: var(--c-text);
          font-size: var(--font-size--sm);
          min-height: 32px;
        }
      }
    }
  }

  ::v-deep {
    .sf-image {
      &--wrapper {
        margin: auto 0;
        width: 90px;
        height: auto;
      }

      img {
        width: 90px;
        height: auto;
      }
    }
  }


  &--title {
    margin-bottom: 0;
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    font-size: 1rem;
  }

  &--reference {
    margin-top: 0;
  }
}

.stock {
  .OrkStock {
    margin: var(--spacer-xs) 0 var(--spacer-sm);
  }
}
</style>
