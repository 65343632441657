import QorusClient from "@/api/clients/QorusClient";
import axios, { AxiosRequestConfig } from "axios";
import { ShoppingCart } from "@/models/shopping";
import { Address } from "@/models/address";
import store from "~/core/store";

/**
 *  Qorus API about user's cart
 */
export default class CartService {

  private cancelSetOfferToken;
  private cancelSetShippingAddressToken;
  private cancelSetShippingMethodToken;
  private cancelGetShippingMethodToken;
  private SetOfferController;

  private qorusClient = QorusClient.of();

  static of(): CartService {
    return new CartService();
  }
  abortController(){
    if (this.SetOfferController) this.SetOfferController.abort();
    this.SetOfferController = new AbortController();
    return this.SetOfferController.signal;
  }

  abortControllerSetAddress(){
    if (this.SetOfferController) this.SetOfferController.abort();
    this.SetOfferController = new AbortController();
    return this.SetOfferController.signal;
  }

  getCart(): Promise<ShoppingCart> {
    return this.qorusClient.get("shopping-cart").then(
      (data: ShoppingCart) => {
        return ShoppingCart.of(data);
      },
      (data) => {
        throw data;
      },
    );
  }

  removeCart(shoppingCartId: string): Promise<null> {
    return this.qorusClient.delete("shopping-cart/" + shoppingCartId).then(
      () => {
        store.dispatch("setCart", null).then()
        return null;
      },
      (data) => {
        throw data;
      },
    );
  }

  setCoupons(coupon: string): Promise<ShoppingCart> {
    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        coupon,
      },
    };
    return this.qorusClient.put("shopping-cart/set-coupon", null, axiosRequestConfig).then(
      (data: ShoppingCart) => {
        return ShoppingCart.of(data);
      },
      (data) => {
        throw data;
      },
    );
  }

  removeCoupons(coupon: string): Promise<ShoppingCart> {
    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        coupon,
      },
    };
    return this.qorusClient.delete("shopping-cart/remove-coupon", axiosRequestConfig).then(
      (data: ShoppingCart) => {
        return ShoppingCart.of(data);
      },
      (data) => {
        throw data;
      },
    );
  }

  setOfferQuantity(params): Promise<ShoppingCart | null> {
    let defaultQuantity = 0;
    let prevQty = 0;
    const cart: ShoppingCart | null = params.currentCart;
    if (cart && cart.cartItems && cart.cartItems.length) {
      const itemIsInCart = cart.cartItems.find(item => item.product?.offer?.id === params.offerId);
      prevQty = itemIsInCart?.quantity || 0;
    }
    switch (params.method) {
      case "add_one":
        defaultQuantity = 1 + prevQty;
        break;
      case "add":
        defaultQuantity = Number(params.quantity) + prevQty;
        break;
      case "update" :
        defaultQuantity = Number(params.quantity);
        break;
      case "remove":
        defaultQuantity = 0;
        break;
    }

    const signal = this.abortController();

    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        offerId: params.offerId,
        quantity: defaultQuantity
      },
      signal
    };

    return this.qorusClient.put("shopping-cart/set-offer-quantity", null, axiosRequestConfig)
      .then(
        (data) => {
          if (data) {
            const customerCart = ShoppingCart.of(data);
            store.dispatch('setCart', customerCart);
            return customerCart;
          } else {
            return null;
          }
        },
        (data) => {
          throw data;
        },
      );

  }

  setShippingAddress(shippingAddress: Address): Promise<ShoppingCart> | unknown {
      if (typeof this.cancelSetShippingAddressToken != typeof undefined) {
        this.cancelSetShippingAddressToken.cancel({ cancel: true });
      }
      if (typeof this.cancelSetShippingMethodToken != typeof undefined) {
        this.cancelSetShippingMethodToken.cancel({ cancel: true });
      }

      this.cancelSetShippingAddressToken = axios.CancelToken.source();

      const axiosRequestConfig: AxiosRequestConfig = {
        cancelToken: this.cancelSetShippingAddressToken.token,
          headers: {
              'Content-Type': 'application/json'
          }
      };
      return this.qorusClient.put("shopping-cart/shipping-address", shippingAddress, axiosRequestConfig)
        .then(
          (data: ShoppingCart) => {
            return ShoppingCart.of(data);
          },
          (data) => {
            throw data;
          },
        );
  }

  setBillingAddress(billingAddressName): Promise<ShoppingCart> | unknown {
      const axiosRequestConfig: AxiosRequestConfig = {
        params: {
          billingAddressName,
        },
      };
      return this.qorusClient.put("shopping-cart/billing-address", null, axiosRequestConfig)
        .then(
          (data: ShoppingCart) => {
            return ShoppingCart.of(data);
          },
          (data) => {
            throw data;
          },
        );
  }

  getShipmentMethods(): Promise<Array<unknown>> |unknown {
      if (typeof this.cancelGetShippingMethodToken != typeof undefined) {
        this.cancelGetShippingMethodToken.cancel({ cancel: true });
      }
      if (typeof this.cancelSetShippingMethodToken != typeof undefined) {
        this.cancelSetShippingMethodToken.cancel({ cancel: true });
      }

      this.cancelGetShippingMethodToken = axios.CancelToken.source();
      const axiosRequestConfig: AxiosRequestConfig = {
        cancelToken: this.cancelGetShippingMethodToken.token,
      };
      return this.qorusClient.get("shopping-cart/shipment-methods", axiosRequestConfig)
        .then(
          (data) => {
            return data;
          },
          (data) => {
            throw data;
          },
        );
  }

  setShipmentMethods(selectedShipmentMethods): Promise<ShoppingCart> | unknown {
      if (typeof this.cancelSetShippingMethodToken != typeof undefined) {
        this.cancelSetShippingMethodToken.cancel({ cancel: true });
      }

      this.cancelSetShippingMethodToken = axios.CancelToken.source();

      const axiosRequestConfig: AxiosRequestConfig = {
        cancelToken: this.cancelSetShippingMethodToken.token,
      };
      return this.qorusClient.put("shopping-cart/shipment-methods", { shipmentMethodIds: selectedShipmentMethods }, axiosRequestConfig)
        .then(
          (data: ShoppingCart) => {
            return ShoppingCart.of(data);
          },
          (data) => {
            throw data;
          },
        );
  }
}
