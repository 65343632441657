import { ActionTree } from "vuex";
import { CategoryState } from "~/core/store/category/type";
import { RootState } from "~/core/store/type";
import Api from "@/api/Api";
import { CategoryMutations } from "~/core/store/category/mutations";
import {Category, RootCategory} from "@/models/category";
import Notifications from "@/utils/notifications";
import { CustomerActions } from "../customer/actions";
import { UiMutations } from "../ui/mutations";

export const actions: ActionTree<CategoryState, RootState> = {
  getCategoryTree({ commit }): Promise<Category> | Category {
    return Api.category.getTree()
      .then((data: Category) => {
        if (data.children && data.children.length > 0) {
          data.children.forEach((subcat) => {
            if (subcat.children) {
              subcat.children.sort(function (a, b) {
                return a.label.localeCompare(b.label);
              });
            }
          });
        }
        commit(CategoryMutations.SET_CATEGORY_TREE, data);
        return data;
      }).catch((e) => {

        /*  if (e.status === 404) {
            Router.push("/404");
          }*/
          Notifications.createNotifications({name:'errors.categories'})

        return e;
      });

  },
    getRootCategories({ commit }): Promise<RootCategory[]> {
        return Api.category.getRootCategories()
            .then((data: RootCategory[]) => {
                commit(CategoryMutations.SET_ROOTCATEGORIES, data);
                return data;
            }).catch((e) => {
                /*  if (e.status === 404) {
                    Router.push("/404");
                  }*/
                console.log(e);
                Notifications.createNotifications({name:'errors.rootCategories'})

                return e;
            });

    },
    setRootCategoryPath({dispatch, commit, state}, rootCategoryPath: string) {
        return Api.category.setRootCategoryPath(rootCategoryPath)
        .then((cartData) => {
            const rootCategory = state.rootCategories?.find(c => c.path === rootCategoryPath);
            commit(CategoryMutations.SET_ROOTCATEGORY, rootCategory);
            // The current selected relay address may not be suitable anymore
            commit(UiMutations.RESET_RELAY_DATA);
            return cartData;
        })
        .then((cartData) => dispatch(CustomerActions.setCart, cartData))
        .then(() => dispatch('getCategoryTree'))
        .catch(e => console.log(e))
    },
    getRootCategory: ({dispatch, state}) => {
      const localRootCategoryPath = localStorage.getItem('rootCategory')
      const storeRootCategory = state.rootCategory;

      // Is the root category valid ? We suppose here that the root categories are initialized
      const localRootCategory = state.rootCategories?.find(c => c.path === localRootCategoryPath);

      // no valid local root category
      if (!localRootCategory) {
        return Promise.resolve(null);
      }

      // consider that if the store state is initialized, then it is on server too
      if (localRootCategoryPath === storeRootCategory?.path) return Promise.resolve(localRootCategory);

      // mismatch between the store and the local value, refresh server side
      return dispatch("setRootCategoryPath", localRootCategoryPath).then(() => localRootCategory);
    },
  setPage: ({commit}, page: number) => {
    commit(CategoryMutations.SET_PAGE, page);
    return page;
  }
};
