import { MutationTree } from "vuex";
import { CategoryState } from "~/core/store/category/type";
import {Category, RootCategory} from "@/models/category";

export enum CategoryMutations {
  SET_CATEGORY_TREE = "SET_CATEGORY_TREE",
  SET_ROOTCATEGORIES = "SET_ROOTCATEGORIES",
  GET_ACTIVE_CATEGORY = "GET_ACTIVE_CATEGORY",
  SET_PAGE = "SET_PAGE",
  SET_ROOTCATEGORY ="SET_ROOTCATEGORY"
}

export const mutations: MutationTree<CategoryState> = {
  [CategoryMutations.SET_CATEGORY_TREE](state, categoryTree: Category): void {

    state.categoryTree = categoryTree;
  },
  [CategoryMutations.SET_ROOTCATEGORIES](state, rootCategories: RootCategory[]): void {
    console.log("Setting root cateories to", rootCategories);
    state.rootCategories = rootCategories;
  },
  [CategoryMutations.GET_ACTIVE_CATEGORY](state, activeCategory: string): void {
    state.activeCategory = activeCategory;
  },
  [CategoryMutations.SET_PAGE](state, page: number): void {
    state.page = page;
  },
  [CategoryMutations.SET_ROOTCATEGORY](state, rootCategory: RootCategory | undefined | null): void {
    state.rootCategory = rootCategory || null;
    if (rootCategory) {
      localStorage.setItem('rootCategory', rootCategory.path);
    }
    else {
      localStorage.removeItem('rootCategory');
    }
  }
};
