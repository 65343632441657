<template src="@/components/Ork/grid/OrkAddToCartGrid.html"/>
<style lang="scss" scoped src="@/components/Ork/grid/OrkAddToCartGrid.scss"/>
<script>
import {
  SfButton,
  SfLoader,
  SfIcon
} from '@storefront-ui/vue';
import OrkAddToCart from "@/components/Ork/OrkAddToCart";
import icons from "@/utils/icons";
import Vue from "vue";
import Notifications from "@/utils/notifications";
import Api from "@/api/Api";

export default {
  name: "OrkAddToCartGrid",
  components: {
    SfButton,
    SfIcon,
    SfLoader,
    OrkAddToCart,
  },


  data() {
    return {
      valueofproduct:null,
      showQty : process.env.VUE_APP_SHOWQTY === 'true',
      productIsInCart:null,
      showLabel:false,
      showIcon:true,
      minimalOrderQuantity:0,
      maximalOrderQuantity:0,
      packSize: 1,
      stockQuantity:0,
      loading: false,
      AddTocartLoading: false,
      showQtySelect: false,
      canAddToCart: false,
      label:"components.okrAddToCart.addLabel",
      isConfigurable:false,
      defaultOfferId:null,
      defaultOffer:null,
      gridMode:true,
      quantity: 1,
      isInProductView:true,
      isInCart: false,
      cartQty: 0,
      ecommerceStyle: process.env.VUE_APP_ECOMMERCE_STYLE,
      icons:icons,
      reference:null,
      name:null
    };
  },
  beforeMount() {

    this.selectedProduct = this.params.value
    this.maximalOrderQuantity = this.params.value.maximalOrderQuantity
    this.minimalOrderQuantity = this.params.value.minimalOrderQuantity
    this.stockQuantity = this.params.value.stockQuantity
    this.quantity = this.params.value.quantity
    this.defaultOfferId = this.params.value.defaultOfferId
    this.defaultOffer = this.params.value.defaultOffer
    this.packSize = this.params.value.packSize
    this.name = this.params.value.name
    this.reference = this.params.value.reference
    this.getProductIsInCart()
  },
  computed: {
    activeCategory: {
      get() {
        return sessionStorage.getItem('activeCategory')
      }
    },
    currentCart: {
      get() {
        return this.$store.getters.getCart;
      }
    },
  },
  watch: {
    currentCart(NewCart) {
      if(NewCart && this.defaultOfferId) {
        this.productIsInCart = NewCart.cartItems.find(item => item.product.offer.id === this.defaultOfferId)
      }else{
        this.productIsInCart = null
      }
      if (this.productIsInCart) {
        this.quantity = this.productIsInCart.quantity
      }
    },
  },
methods:{
    getProductIsInCart(){
      if (this.currentCart) {
        this.productIsInCart = this.currentCart.cartItems.find(item => item.product.offer.id === this.defaultOfferId)
      }
    },
  changeQty(quantity) {

    if(this.ecommerceStyle==="market" || this.gridMode){
      this.addToCart({
        offerId: this.defaultOfferId,
        quantity: Number(quantity),
        stock: this.stockQuantity,
        currentCart: this.currentCart
      })
    }

  },
    addToCart(params) {
        let cart = params.currentCart,
            itemIsInCart = null,
            qty = Vue.filter('toInt')(params.quantity),
            prevQty = 0,
            stock = params.stock;

        if (cart && cart.cartItems && cart.cartItems.length) {
            itemIsInCart = cart.cartItems.find(item => item.product?.offer?.id === params.offerId);

            if (itemIsInCart) {
                prevQty = itemIsInCart?.quantity || 0;
                stock = itemIsInCart?.product.offer.stockQuantity || 0;
            }
        }

        if (this.productIsInCart) {
            params.method = 'update'
        } else {
            params.method = 'add'
        }
        if(params.remove){
            params.method = 'remove'
            params.quantity = 0;
        }
        if (prevQty + qty >= stock && params.method === 'add') {
            Notifications.createNotifications({
                name: 'addToCart.maxQty',
                value: {stock: stock, productName: this.product.name}
            })

            this.AddTocartLoading = true
            params.quantity = stock;
            Api.cart.setOfferQuantity(params)
                .then(data => {
                    this.$store.dispatch('setCart', data);
                }).catch((e) => {
                console.error(e)
                this.$store.dispatch('sendNotification', {
                    type: 'danger',
                    message: this.$i18n.t('errors.server.access'),
                    icon: icons.error
                });
                this.error = e;
            })
                .finally(() => {
                    this.loading = false
                    this.AddTocartLoading = false
                });
        }
        else if (qty >= stock && params.method === 'update') {
            Notifications.createNotifications({
                name: 'addToCart.maxQty',
                value: {stock: stock, productName: this.product.name}
            })
            params.quantity = stock;

            this.AddTocartLoading = true
            Api.cart.setOfferQuantity(params)
                .then(data => {
                    this.$store.dispatch('setCart', data);
                }).catch((e) => {
                console.error(e)
                this.$store.dispatch('sendNotification', {
                    type: 'danger',
                    message: this.$i18n.t('errors.server.access'),
                    icon: icons.error
                });
                this.error = e;
            })
                .finally(() => {

                    this.AddTocartLoading = false
                });
        }
        else {
            this.AddTocartLoading = true;
            Api.cart.setOfferQuantity(params)
                .then(data => {
                    this.$store.dispatch('setCart', data);

                    Api.marketingEvent.addToCart(this.selectedProduct,qty,this.activeCategory, this.currentCart)

                    if(params.method==="add" && this.showAddToCartModal) {
                        this.$store.dispatch('toggleProductsActionModal', {
                            open: true,
                            type: params.method,
                            product: this.product.name
                        });
                    }
                    if(params.method==="remove"){
                        Api.marketingEvent.removeFromCart(this.selectedProduct,this.currentCart)
                        Notifications.createNotifications({name: 'addToCart.remove', value: {productName: this.selectedProduct.name}})
                    } else {
                        if (this.productIsInCart) {
                            Notifications.createNotifications({name: 'addToCart.update', value: {productName: this.selectedProduct.name}})
                        } else {
                            Notifications.createNotifications({name: 'addToCart.add', value: {productName: this.selectedProduct.name}})
                        }
                    }
                })
                .catch((e) => {
                    if(e.status !== 499) {
                        this.$store.dispatch('setCart', null);
                        this.currentCart = null;
                        Notifications.createNotifications({name:'errors.server.access'})
                    }
                })
                .finally(() => {
                    this.AddTocartLoading = false;

                    if (!this.productIsInCart) {
                        this.quantity = 1;
                    }
                });
        }

    },
}
};
</script>
