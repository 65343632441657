<template src="@/components/Ork/OrkProductCard.html"/>
<style lang="scss" scoped src="@/components/Ork/OrkProductCard.scss"/>

<script>

import {
  SfIcon,
  SfButton,
  SfImage,
  SfLoader,
  SfLink
} from '@storefront-ui/vue';
import Api from "@/api/Api";
import icons from "@/utils/icons";
import OrkPrice from "@/components/Ork/OrkPrice";
import OrkAddToCart from "@/components/Ork/OrkAddToCart";
import {filterData} from "@/utils/categoryFilter";
import Notifications from "@/utils/notifications";

export default {
  name: 'OrkProductCard',
  components: {
    OrkAddToCart,
    OrkPrice,
    SfButton,
    SfImage,
    SfIcon,
    SfLoader,
    SfLink
  },
  props: {
    activeCategory: {
      default: null,
    },
    product: {
      default: null,
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      imgHasError:false,
      showPreview: process.env.VUE_APP_SHOWPRODUCT_PREVIEW === 'true',
      showCategory: process.env.VUE_APP_SHOWPRODUCT_CATEGORY === 'true',
      showQty: process.env.VUE_APP_SHOWQTY === 'true',
      showWishlist: process.env.VUE_APP_WISHLIST === 'true',
      showAddToCartModal: process.env.VUE_APP_SHOW_ADD_TO_CART_MODAL === 'true',
      ecommerceStyle: process.env.VUE_APP_ECOMMERCE_STYLE,
      ecommerceMode: process.env.VUE_APP_ECOMMERCE_MODE,
      showConfigurablePrice: process.env.VUE_APP_SHOW_LISTING_CONFIGURABLE_PRICE === 'true',
      icons,
      categ: null,
      loading: false,
      quantity: 1,
      productIsInCart: null,
      AddTocartLoading: false
    };
  },
  computed: {
    currentCart: {
      get() {
        return this.$store.getters.getCart;
      }
    },
    currentWishlistIcon() {
      return this.isInWishlist ? icons.wishlistFill : icons.wishlistStroke;
    },
    wishlistIconClasses() {
      const defaultClass = "sf-product-card__wishlist-icon sf-button--pure";
      return `${defaultClass} ${this.isInWishlist ? "on-wishlist" : ""}`;
    },
    categoryTree: {
      get() {
        return this.$store.getters.getCategoryTree
      }
    },

  },

  mounted() {
    if (this.currentCart) {
      this.productIsInCart = this.currentCart.cartItems.find(item => item.product.offer.id === this.product.defaultOfferId)
    } else {
      this.productIsInCart = null
    }
    if (this.productIsInCart) {
      this.quantity = this.productIsInCart.quantity
    }
if(this.showCategory) {
    this.getProductCategory()
}
  },
  watch: {
    currentCart(newCart) {
      if (newCart) {
        this.productIsInCart = newCart.cartItems.find(item => item.product.offer.id === this.product.defaultOfferId)
      } else {
        this.productIsInCart = null
      }
      if (this.productIsInCart) {
        this.quantity = this.productIsInCart.quantity
      }
    }

  },

  methods: {
    imgError(){
      this.imgHasError=true
    },
    changeQty(quantity) {
      if (this.ecommerceStyle === "market") {
        this.setOfferQuantity({
          offerId: this.product.defaultOfferId,
          quantity: Number(quantity),
          stock: this.product.stockQuantity,
          currentCart: this.currentCart
        })
      }
    },
    openProductSideBar() {
      this.$store.dispatch('toggleProductSidebar', {open: true, productSlug: this.product.slug})
    },
    getProductCategory() {
      const productCategory = this.product.categoryPaths;

      let splitPath = null
      // get only the first category

      if (this.activeCategory) {
        splitPath = this.product.categoryPaths.find(categ => {
          if (categ.includes(this.activeCategory)) {
            return true;
          }
        }) || null;

        if (splitPath) {
          splitPath = splitPath.split('/');
        } else {
          splitPath = productCategory.length?productCategory[0].split('/'):null;
        }
      } else {
        splitPath = productCategory.length?productCategory[0].split('/'):null;
      }
      const tempArr = [];
      splitPath.shift()
      if (splitPath) {
        for (let i of splitPath)
          i && tempArr.push(i); // copy each non-empty value to the 'temp' array

        splitPath = tempArr;

        const categoriesPath = []
// get all path for categories child
        for (let i = 0, n = splitPath.length; i + 1 <= n; i++) {
          if (i === 0)
            categoriesPath.push('/' + splitPath[i]);
          else categoriesPath.push(categoriesPath[i - 1] + '/' + splitPath[i])
        }

//create categories breadcrumbs
        // if (this.categoryTree.children) {
        const categoriesBreadcrumbs = [];

        for (let i = 0, n = categoriesPath.length; i + 1 <= n; i++) {
          //get data for the category with categoryTree
          if(this.categoryTree && this.categoryTree.children && this.categoryTree.children.length>0) {
            let categ = filterData(this.categoryTree.children, function (item) {
              const categoryPath = item.path;
              const indexPremierSlash = categoryPath.indexOf('/');
              const categoryPathWithoutRootCategory = categoryPath.substring(indexPremierSlash);
              return categoryPathWithoutRootCategory === categoriesPath[i]
            })[0];


            if (categ) {

              // add categories to breadcrumbs
              // if is the first entry
              if (i === 0) {
                categoriesBreadcrumbs.push({
                  link: '/categorie/' + categ.slug,
                  text: categ.label
                })
              }
              //for other
              else {
                categoriesBreadcrumbs.push({
                  link: categoriesBreadcrumbs[i - 1]['link'] + '/' + categ.slug,
                  text: categ.label
                })
              }
            }
          }
        }
        this.categ = categoriesBreadcrumbs.slice(-1)
        //  }
      }
    },
    goToProductPage() {
      if (this.activeCategory) {
        sessionStorage.setItem("activeCategory", this.activeCategory);
      }

      this.$router.push(this.$localizePath('/produit/' + this.product.slug));
    },
    toggleIsInWishlist() {
      this.isInWishlist=!this.isInWishlist
      this.$emit("click:wishlist", !this.isInWishlist);
    },
    setOfferQuantity(params) {
      if(this.ecommerceStyle === 'standard'){
        this.goToProductPage()
        return
      }
      let cart = this.currentCart,
          itemIsInCart = null,
          qty = Number(params.quantity),
          prevQty = 0,
          stock = params.stock;


      if (cart && cart.cartItems && cart.cartItems.length) {
        itemIsInCart = cart.cartItems.find(item => item.product?.offer?.id === params.offerId);

        if (itemIsInCart) {
          prevQty = itemIsInCart?.quantity || 0;
          stock = itemIsInCart?.product.offer.stockQuantity || 0;
        }
      }

      if(itemIsInCart && (this.ecommerceStyle === 'b2b' || this.ecommerceStyle === 'market')){
        params.method = 'update'
      }
      else {
        qty = 1;
        params.quantity = 1
        params.method = 'add'
      }
      if(params.remove){
        params.method = 'remove'
        params.quantity = 0;
      }

      if (prevQty + qty > stock && params.method === 'add') {
        Notifications.createNotifications({
          name: 'addToCart.maxQty',
          value: {stock: stock, productName: this.product.name}
        })
        this.loading = true;
        this.AddTocartLoading = true
        params.quantity = stock;



        Api.cart.setOfferQuantity(params)
            .then(data => {
              this.$store.dispatch('setCart', data);
            }).catch((e) => {
          console.error(e)
          this.$store.dispatch('sendNotification', {
            type: 'danger',
            message: this.$i18n.t('errors.server.access'),
            icon: icons.error
          });
          this.error = e;
        })
            .finally(() => {
              this.loading = false
              this.AddTocartLoading = false
            });
      }
      else if (qty >= stock && params.method === 'update') {
        Notifications.createNotifications({
          name: 'addToCart.maxQty',
          value: {stock: stock, productName: this.product.name}
        })
        params.quantity = stock;
        this.loading = true;
        this.AddTocartLoading = true
        Api.cart.setOfferQuantity(params)
            .then(data => {
              this.$store.dispatch('setCart', data);
            }).catch((e) => {
          console.error(e)
          this.$store.dispatch('sendNotification', {
            type: 'danger',
            message: this.$i18n.t('errors.server.access'),
            icon: icons.error
          });
          this.error = e;
        })
            .finally(() => {
              this.loading = false
              this.AddTocartLoading = false
            });
      }
      else {
        this.loading = true;
        this.AddTocartLoading = true
        Api.cart.setOfferQuantity(params)
            .then(data => {
              if (params.method==="add" && this.showAddToCartModal ) {
                this.$store.dispatch('toggleProductsActionModal', {
                  open: true,
                  type: params.method,
                  product: this.product.name
                });
              }
              if(params.method==="remove"){
                Api.marketingEvent.removeFromCart(this.product,this.currentCart)
                Notifications.createNotifications({name: 'addToCart.remove', value: {productName: this.product.name}})
              } else {
                Api.marketingEvent.addToCartLight(this.product, qty, this.currentCart)
                if (this.productIsInCart) {
                  Notifications.createNotifications({name: 'addToCart.update', value: {productName: this.product.name}})
                } else {
                  Notifications.createNotifications({name: 'addToCart.add', value: {productName: this.product.name}})
                }
              }

            })
            .catch((e) => {
              console.error(e)
              this.$store.dispatch('sendNotification', {
                type: 'danger',
                message: this.$i18n.t('errors.server.access'),
                icon: icons.error
              });
              this.error = e;
            })
            .finally(() => {
              this.loading = false
              this.AddTocartLoading = false
            });
      }
    },
  }
};
</script>
