import Vue from "vue";
import VueCookies from "vue-cookies";
import store from "~/core/store";
import { CustomerActions } from "../store/customer/actions";

Vue.use(VueCookies);

export default class CookieManager {
    static setTwoFactorAuthenticationToken(twoFactorAuthenticationToken: string | undefined | null): null {
        Vue.$cookies.set("qorus_2fa", twoFactorAuthenticationToken, "10m", undefined, undefined, true, "Strict");
        return null;
    }

    static parseCookie = (rawToken: string | null): string | null => {
        if (rawToken) {
            try {
                return JSON.parse(rawToken);
            } catch (e) {
                return null;
            }
        }
        return null;
    };
    static getCookie = (cookieName: string | null): string | null => {
        if (cookieName) {
            try {
                return Vue.$cookies.get(cookieName) || null;
            } catch (e) {
                return null;
            }
        }
        return null;
    };
    /*
     * Customer Token
     */

    static expireAt = (day: string): Date => {
        const dateNow = new Date();
        dateNow.setDate(dateNow.getDate() + parseInt(day));
        return dateNow;
    };

    static error(): Promise<unknown> {
        store.dispatch("toggleLoginModal", { open: true, isReloaded: true });
        store.dispatch(CustomerActions.setCustomer, null);
        store.dispatch("setCart", null);
        return Promise.reject({ status: 401 });
    }
}
