var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sf-store-locator"},[_c('div',{staticClass:"sf-store-locator__wrapper"},[_c('div',{staticClass:"sf-store-locator__map-wrapper"},[_c('div',{ref:"map",staticClass:"sf-store-locator__map"}),(!_vm.mapReady)?_vm._t("map-loading",function(){return [_c('OrkLoader',{attrs:{"loading":!_vm.mapReady,"message":_vm.$t('loading.relais')}})]}):_vm._e()],2),_c('div',{staticClass:"sf-store-locator__list"},[(_vm.mapReady)?_vm._t("default",null,null,{
          centerOn: _vm.centerOn,
          centerOnMarker: _vm.centerOnMarker,
          chooseRelais: _vm.chooseRelais,
          registerStore: _vm.registerStore,
          removeStore: _vm.removeStore,
          userPosition: _vm.userPosition,
          getGeoDistance: _vm.getGeoDistance,
          locateUser: _vm.locateUser,
          selectedRelaisId: _vm.selectedRelaisId
        }):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }