<template src="@/components/BottomNavigation.html"/>
<style lang="scss" scoped src="@/components/BottomNavigation.scss"/>
<script>
import BottomNavigation from '~/core/components/BottomNavigation.vue';
import Vue from 'vue';


export default Vue.extend({
  mixins: [BottomNavigation],
  methods: {
    toggleMenuModal() {
      this.$store.dispatch('toggleMenuDesktopModal')
    },
  }
})
</script>
