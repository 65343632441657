<template>
  <div class="orkStockGrid">
    <OrkStock :show-stock-alert="false" :offer-id="defaultOfferId" :quantity="stockQuantity" ></OrkStock>

  </div>
</template>
<script>


import OrkStock from "@/components/Ork/OrkStock";
export default {
  name: "OrkStockGrid",
  components: {
    OrkStock
  },
  data() {
    return {
      defaultOfferId:null,
      stockQuantity:0,
      ecommerceMode: process.env.VUE_APP_ECOMMERCE_MODE,
    }
  },
  beforeMount() {
    this.defaultOfferId = this.params.value.defaultOfferId
    this.stockQuantity = this.params.value.stockQuantity
  },
  methods: {
   /* btnClickedHandler() {
      this.params.clicked(this.params.value);
    }*/
  },
};
</script>
<style lang="scss" scoped>
::v-deep{
  .OrkStock{
    margin: 0;
  }
}
</style>

