import { Attribute } from "./attribute";
import { LogisticClass, LogisticPrice } from "./logistic";
import {PickingDelay, Shop} from "./shop";
import { TrackingInfo } from "./tracking";
import { CartPicture } from "@/models/picture";
import { Product } from "@/models/product";
import { Price } from "./price";
import { Address } from "@/models/address";
import { i18n } from "vue-lang-router";
import {Parcel} from "@/models/parcel";
import { UnitPrice } from "./shopping";
export class Shipment {
  id!: string | null;
  trackingInfo!: TrackingInfo | null;
  items!: ShipmentItem[];
  shipmentWeight!: number;
  shipmentMethod!: ShipmentMethods | null;
  address!: Address | null;
  price!: Price;
  shop!:Shop;
  parcels!: Parcel[];

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(data: any): Shipment {
    const s: Shipment = new Shipment();

    s.id = data.id;
    s.items = []
    data?.items.forEach(ci => s.items?.push(ShipmentItem.of(ci)));
    s.shipmentWeight = 0;
    s.price = Price.of(data.price);
    s.trackingInfo = data.trackingInfo;
    s.shipmentMethod = data.shipmentMethod ? ShipmentMethods.of(data.shipmentMethod) : null;
    s.address = data.address;
    s.shop = data.shop;
    s.parcels = [];
    data.parcels?.forEach(parcel => s.parcels?.push(Parcel.of(parcel)));

    return s;
  }
}

export class ShipmentShop {
  id!: string;
  name!: string;
  returnPolicy!: string;
  logo!: string;

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(data: any): ShipmentShop {
    const s: ShipmentShop = new ShipmentShop();

    s.id = data.id;
    s.name = data.name;
    s.returnPolicy = data.returnPolicy;
    s.logo = data.logo;

    return s;
  }
}

export class ShipmentMethods {
  id!: string;
  carrier!: string;
  carrierImg?: string;
  carrierName!: string;
  deliveryTime!: string;
  deliveryTimeUnit!: string;
  name!: string;
  price!: Price;

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(data: any): ShipmentMethods {
    const s: ShipmentMethods = new ShipmentMethods();
    const locale = i18n.locale;
    s.id = data.id;
    s.carrier = data.carrier;
    switch (s.carrier) {
      case "DOS":
        s.carrierImg = locale === "fr" ? "/assets/images/transporteurs/colissimo_domicile.png" : "/assets/images/transporteurs/colissimo_domicile-en.png";
        s.carrierName = "Colissimo";
        break;
      case "CDS":
        s.carrierImg = locale === "fr" ? "/assets/images/transporteurs/colissimo_domicile.png" : "/assets/images/transporteurs/colissimo_domicile-en.png";
        s.carrierName = "Colissimo outre-mer";
        break;
      case "A2P":
        s.carrierImg = locale === "fr" ? "/assets/images/transporteurs/colissimo_relais.png" : "/assets/images/transporteurs/colissimo_relais-en.png";
        s.carrierName = "Colissimo relais";
        break;
      case "CHRONO13H_BtoC":
        s.carrierImg = "/assets/images/transporteurs/chronopost.png";
        s.carrierName = "Chronopost 13";
        break;
      case "CHRONO13H_BtoB":
        s.carrierImg = "/assets/images/transporteurs/chronopost.png";
        s.carrierName = "Chronopost 13";
        break;
      case "DHL_ECX":
        s.carrierImg = "/assets/images/transporteurs/dhl.png";
        s.carrierName = "DHL Express";
        break;
      case "DHL_WPX":
        s.carrierImg = "/assets/images/transporteurs/dhl.png";
        s.carrierName = "DHL Express";
        break;
      case "K+N_EuroLinkClassic":
        s.carrierImg = "/assets/images/transporteurs/Kuhne_Nagel.png";
        s.carrierName = "K+N_EuroLinkClassic";
        break;
      case "DELIVENGO":
        s.carrierImg = "/assets/images/transporteurs/delivengo.png";
        s.carrierName = "Delivengo Europe";
        break;
      case "LETTRE SUIVIE":
        s.carrierImg = "/assets/images/transporteurs/lettre_suivie.png";
        s.carrierName = "Lettre Suivie";
        break;
      default:
        s.carrierName = s.carrier;
        break
    }
    s.name = data.name;
    s.deliveryTime = data.deliveryTime;
    s.deliveryTimeUnit = data.deliveryTimeUnit;
    s.price = Price.of(data.price);

    return s;
  }
}

export class ShipmentOffer {
  id!: string;
  shop!: ShipmentShop;
  pickingDelay!: PickingDelay;
  stockQuantity!: number;
  logisticClass!: LogisticClass;
  regularPrice!: Price;
  discountPrice!: Price;
  minimalOrderQuantity!: number;
  maximalOrderQuantity!: number;

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(data: any): ShipmentOffer {
    const s: ShipmentOffer = new ShipmentOffer();
    s.id = data.id;
    s.shop = data.shop;
    s.pickingDelay = data.pickingDelay;
    s.stockQuantity = data.stockQuantity || 0;
    s.logisticClass = data.logisticClass;
    s.regularPrice = Price.of(data.regularPrice);
    s.discountPrice = Price.of(data.discountPrice);
    s.minimalOrderQuantity = data.minimalOrderQuantity || 0;
    s.maximalOrderQuantity = data.maximalOrderQuantity || s.stockQuantity;

    return s;
  }
}

export class ShipmentProduct {
  slug!: string;
  reference!: string;
  name!: string;
  cover!: CartPicture;
  configurableAttributes!: any;
  attributeValuesByAttributeName!: Record<string, Attribute>;
  selectedAttributes?: any;
  offer!: ShipmentOffer;
  packSize!:number;

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(data: any): ShipmentProduct {
    const s: ShipmentProduct = new ShipmentProduct();

    s.slug = data.slug;
    s.reference = data.reference;
    s.name = data.name;
    s.configurableAttributes = data?.configurableAttributes;
    Object.keys(data.attributeValuesByAttributeName).forEach(function (key, index) {
      data.attributeValuesByAttributeName[key].index = index;
      data.attributeValuesByAttributeName[key] = Attribute.of(data.attributeValuesByAttributeName[key]);
    });
    s.attributeValuesByAttributeName = data.attributeValuesByAttributeName;
    s.offer = ShipmentOffer.of(data.offer);
    s.packSize = Number(data?.packSize) || 1;
    s.cover = data.cover || CartPicture.of(data.pictures.find(p => p.isCover === true) || null, data.name);

    if (data?.configurableAttributes) {
      const selectedAttributes = {};
      for (let attr = 0; attr < data?.configurableAttributes.length; attr++) {
        const label = data?.configurableAttributes[attr];

        if (data?.attributeValuesByAttributeName[label]) {
          selectedAttributes[label] = data?.attributeValuesByAttributeName[label];
        }
      }
      s.selectedAttributes = selectedAttributes;
    }
    return s;
  }
}

export class ShipmentItem {
  quantity!: number;
  product!: ShipmentProduct;
  price!: Price;
  unitPrice!: UnitPrice;

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(data: any): ShipmentItem {
    const s: ShipmentItem = new ShipmentItem();

    s.quantity = data.quantity;
    s.product = ShipmentProduct.of(data.product);
    s.price = Price.of(data.price);
    s.unitPrice = UnitPrice.of(data.product?.offer);
    return s;
  }
}

export class ShipmentsItem {
  price!: Price;
  product!: Product;
  quantity!: number;

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(data: any): ShipmentsItem {
    const s: ShipmentsItem = new ShipmentsItem();
    s.price = Price.of(data.price);
    s.product = data.product;
    s.quantity = data.quantity;

    return s;
  }
}

export class ShipmentOptions {
  shipment!: Shipment[];
  options!: LogisticPrice[];

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(data: any): ShipmentOptions {
    const s: ShipmentOptions = new ShipmentOptions();

    s.shipment = data.shipment;
    s.options = data.options;

    return s;
  }
}
