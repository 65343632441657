let postalRegEx;
postalRegEx = require("@/models/postal-codes.json");

import {parsePhoneNumber} from 'awesome-phonenumber'
let countries;
countries = require("@/models/country.json");
const emailRegEx = new RegExp(/(.+)@(.+){2,}\.(.+){2,}/);
const hasWhiteSpace = /\s/;

export default class Validation {

    static validatePostalCode = (countryCode, postalCode): boolean => {
        const countryRegEx = postalRegEx.find(country => country.countryCode === countryCode);
        if (countryRegEx) {
            if (countryRegEx.regEx) {
                const regEx: RegExp = new RegExp(countryRegEx.regEx) || null;
                return regEx.test(postalCode);
            }
            // valid true if not exist
            return true;
        }
        // valid true if not exist
        return true;
    };

    static validateEmail = (email): boolean => {
        return emailRegEx.test(email.toLowerCase()) && !hasWhiteSpace.test(email.toLowerCase());
    };

    static validateText = (text: string, length: number): boolean => {
        return text.length >= length;
    };

    static validateMessage = (message: string, minLength: number, maxLength: number): boolean => {
        return !!message && message.length > minLength && message.length <= maxLength
    };

    static validatePhoneNumber(phone, country) {

        let pn;
        let regionCode;
        let formatedPhone;
        if (country) {
            regionCode = { 'regionCode':country}
            pn = parsePhoneNumber(phone, regionCode);
        } else {
            pn = parsePhoneNumber(phone);
        }
        if (pn.valid) {
            formatedPhone = pn.number.international;
        }
        return {phoneNumber: formatedPhone?formatedPhone:phone, isValid: pn.valid};
    }

    static validateDateInf(date) {
        const q = new Date();
        const dateNow = new Date(q.getFullYear(), q.getMonth(), q.getDate());
        const currentDate = new Date(date);
        return currentDate < dateNow;
    }

    static validateDateSup(date) {
        const q = new Date();
        const dateNow = new Date(q.getFullYear(), q.getMonth(), q.getDate());
        const currentDate = new Date(date);
        return currentDate > dateNow;
    }

    static validatePassword(password) {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    }

    static validateRepeatPassword(password: string, repeatPassword: string) {
        return password === repeatPassword;
    }

    static validateCountry(country) {
        const findCountry = countries.filter((countryEntry) => countryEntry.code === country)
        return findCountry.length > 0
    }

}

