import { GetterTree } from "vuex";
import { CustomerState } from "~/core/store/customer/type";
import { RootState } from "~/core/store/type";
import { Customer } from "@/models/customer";
import { ShoppingCart } from "@/models/shopping";

export const getters: GetterTree<CustomerState, RootState> = {
    isAuthenticated: (state): boolean => {
        return state.me?.isGuest !== undefined ? !state.me.isGuest : false;
    },
    getProfile: (state): Customer | null => {
        return state.me;
    },
    getCart: (state): ShoppingCart | null => {
        return state.shoppingCart;
    },
};
