<template>
  <div class="filter">
    <div class="filter-label">{{ filter.name }}</div>
    <div class="filter-content"  v-if="filter.values.length > 4">
      <SfScrollable
                    :hide-text="$t('pages.category.filters.scrollElement.hide')"
                    :show-text="$t('pages.category.filters.scrollElement.show')"
                    max-content-height="90px">
      <SfCheckbox v-for="(value,i) in filter.values" :key="filter.name+i" :label="value.value" :name="value.value"
                  :options="value.value"
                  class="filter-content-checkbox"
                  :selected="currentValue.length>0?!!currentValue.find(filteredVal=> filteredVal === value.value):false"
                  @change="changeValue({filter, value})"></SfCheckbox>
      </SfScrollable>

    </div>
    <div class="filter-content" v-else>
      <SfCheckbox v-for="(value,i) in filter.values" :key="filter.name+i" :label="value.value" :name="value.value"
                  :options="value.value"
                  class="filter-content-checkbox"
                  :selected="currentValue.length>0?!!currentValue.find(filteredVal=> filteredVal === value.value):false"
                  @change="changeValue({filter, value})"></SfCheckbox>

    </div>
    <div>
      <SfButton class="sf-button color-primary sf-button--small" @click="clearFilter">{{$t('components.orkProductGrid.actions.clearFilter')}}</SfButton>
    </div>
  </div>

</template>
<script>


import {SfCheckbox, SfButton, SfScrollable} from '@storefront-ui/vue';


export default {
  name: "OrkFilterCheckBoxGrid",
  components: {
    SfCheckbox,
    SfButton,
    SfScrollable
  },
  data() {
    return {
      filter: null,
      currentValue: [],
    }
  },
  computed: {
    filtersValues() {
      return this.$store.getters.getFiltersValues
    },
  },
   watch: {
      '$store.state.ui.filtersValues': {
        handler(newVal) {
          if (newVal && this.filter) {
            this.currentValue = newVal[this.filter.name] || []
          } else{
            this.currentValue = []
          }
        }
      },
    },
  created() {

    this.filter = this.params.filterValue.filter;
    /*this.unwatch = this.$store.watch(
        (state, getters) => getters.filtersValues,
        (newValue, oldValue) => {
          console.log(`Updating from ${oldValue} to ${newValue}`);

          // Do whatever makes sense now
          if (newValue && this.filter) {
            this.currentValue = newValue[this.filter.name] || [];
          }
        }
    );*/
    // this.filtersValues = this.params.filterValue.filtersValues
    this.currentValue = this.filtersValues[this.filter.name] ? this.filtersValues[this.filter.name] : []
  },
  beforeDestroy() {
    // this.unwatch();
  },

  methods: {
    clearFilter() {
      const filterName = this.filter.name
      const filters = this.filtersValues;
      delete filters[filterName]
      this.currentValue =  [];
      this.$store.dispatch('setFiltersValues', filters)
      this.params.filterChangedCallback();
    },

    changeValue(event) {
      const value = event.value.value;
      const filterName = event.filter.name;
      this.filterChange(filterName, value)
    },

    filterChange(filterName, value ){

      const filters = this.filtersValues;
      if (!filters[filterName]) {
        filters[filterName] = [value]
      } else {
        if (filters[filterName].includes(value)) {
          filters[filterName].forEach((valueOf, index) => {
            if (valueOf === value) {
              filters[filterName].splice(index, 1)
            }
          })
        } else {
          filters[filterName].push(value)
        }
      }

      if (filters[filterName] && filters[filterName].length === 0) {
        delete filters[filterName]
        //filters.filter(Boolean)
      }

      this.currentValue = filters[filterName] || [];

      this.$store.dispatch('setFiltersValues', filters)
      this.params.filterChangedCallback();
    }

  },
};
</script>
<style lang="scss" scoped>
.filter{
  width: 200px;
  overflow:hidden;
  padding: var(--spacer-xs);
  &-label{
    font-size: var(--font-size--sm);
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: var(--spacer-sm);
  }
  &-content{
    &-checkbox{
      ::v-deep{
      .sf-checkbox__label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      }
    }
  }
}
</style>

