<template src="@/components/AccountForm/SignIn.html"/>
<style lang="scss" scoped src="@/components/AccountForm/SignIn.scss"/>

<script>

import {SfAlert, SfCheckbox, SfButton, SfInput, SfLoader, SfLink, SfHeading} from '@storefront-ui/vue';
import OrkToggle from "@/components/Ork/OrkToggle";
import Api from "@/api/Api";
import OrkRecaptcha from "@/components/Ork/OrkRecaptcha";
import Validation from "@/utils/validation";

export default {
  name: "SignIn",
  components: {
    OrkRecaptcha,
    SfCheckbox,
    OrkToggle,
    SfHeading,
    SfAlert,
    SfInput,
    SfButton,
    SfLoader,
    SfLink
  },
  props: {
    showLogin: {type: Boolean, default: true}

  },
  data() {
    return {
      Validation,
      loading: false,
      error: null,
      valid: false,
      createAccount: false,
      submitted: false,
      firstName: "",
      firstNameBlur: true,
      newsLetterSubscribed: false,
      twoFactor: process.env.VUE_2FA_ENABLED === 'true',
      twoFactorAuthenticationEnabled: !!this.twoFactor,
      lastName: "",
      lastNameBlur: true,
      password: "",
      passwordBlur: true,
      repeatPassword: "",
      repeatPasswordBlur: true,
      email: "",
      emailBlur: true,
    }
  },
  watch: {
    isLogIn() {
      this.email = ""
      this.password = ""
      this.createAccount = false
      this.firstName = ""
      this.lastName = ""
    },
  },
  methods: {
    executeRecaptcha() {
      this.$refs.recaptchaSignIn.execute()
    },
    validate() {
      this.firstNameBlur = false;
      this.lastNameBlur = false;
      this.emailBlur = false;
      this.passwordBlur = false;
      this.repeatPasswordBlur = false;

      if (
          Validation.validateEmail(this.email) &&
          Validation.validatePassword(this.password) &&
          Validation.validateRepeatPassword(this.password, this.repeatPassword) &&
          Validation.validateText(this.firstName, 2) &&
          Validation.validateText(this.lastName, 2)
      ) {
        this.valid = true;
      }else{
        this.valid=false
      }
    },


    submitSignInForm(recaptchaData) {
      const onComplete = () => {
        if (!this.error || this.error.status === 200) {
          this.loading = false;
        } else {
          this.loading = false;
        }
      };

      const onError = (e) => {
          console.log(e)
        this.error = e;
        this.loading = false;
      };
      this.validate()
      if (this.valid) {
          this.loading = true;
          this.$emit('submit', {
            form: {
              email: this.email,
              firstName: this.firstName,
              lastName: this.lastName,
              password: this.password,
              twoFactorAuthenticationEnabled: this.twoFactorAuthenticationEnabled,
              newsLetterSubscribed: this.newsLetterSubscribed,
              recaptchaData
            }, onComplete, onError
          });
      }
    },
  }
}
</script>


