<template>
  <div id="layout-attente">
    <Notification/>
    <AppHeaderCheckout/>
    <slot/>
  </div>
</template>

<script>
import AppHeaderCheckout from '@/components/AppHeaderCheckout.vue';
import Notification from "@/components/Notification";

export default {
  components: {
    AppHeaderCheckout,
    Notification,
  }
};
</script>
