import { Module } from "vuex";
import { RootState } from "~/core/store/type";
import { CustomerState } from "~/core/store/customer/type";
import { getters } from "~/core/store/customer/getters";
import { actions } from "~/core/store/customer/actions";
import { mutations } from "~/core/store/customer/mutations";

const state: CustomerState = {
    me: null,
    shoppingCart: null
};

export const customer: Module<CustomerState, RootState> = {
    state,
    getters,
    mutations,
    actions,
};
