import { ActionTree } from "vuex";
import { CustomerState } from "~/core/store/customer/type";
import { RootState } from "~/core/store/type";
import Api from "@/api/Api";
import { CustomerMutations } from "~/core/store/customer/mutations";
import { Customer } from "@/models/customer";
import { ShoppingCart } from "@/models/shopping";

export enum CustomerActions {
    getMe = "getMe",
    getCart = "getCart",
    setCart = "setCart",
    setCustomer = "setCustomer",
    removeCustomerAddress = "removeCustomerAddress",
    addCustomerAddress = "addCustomerAddress",
    loginCustomer = "loginCustomer",
    logOutCustomer = "logOutCustomer",
}

export const actions: ActionTree<CustomerState, RootState> = {
    [CustomerActions.getMe]: ({ commit }): Promise<Customer | null> | null => {
        return Api.account.getProfile()
            .then((data: Customer) => {
                commit(CustomerMutations.SET_CUSTOMER, data);
                return data;
            })
            .catch((e) => {
                return null;
            });
    },
    [CustomerActions.getCart]: ({ commit }): Promise<ShoppingCart | null> | null => {
        return Api.cart.getCart()
            .then((data: ShoppingCart) => {
                commit(CustomerMutations.SET_CUSTOMER_CART, data);
                return data;
            })
            .catch((e) => {
                return null;
            });
    },
    [CustomerActions.setCart]: ({ commit }, data: ShoppingCart) => {
        commit(CustomerMutations.SET_CUSTOMER_CART, data);
        return data;
    },

    [CustomerActions.setCustomer]: ({ commit }, data: Customer) => {
        commit(CustomerMutations.SET_CUSTOMER, data);
        return data;
    },

    [CustomerActions.removeCustomerAddress]({ commit }, addressName) {
        commit(CustomerMutations.REMOVE_CUSTOMER_ADDRESS, addressName);

    },

    [CustomerActions.addCustomerAddress]({ commit }, { isNew, address }) {
        commit(CustomerMutations.ADD_CUSTOMER_ADDRESS, { isNew, address });

    },

    [CustomerActions.loginCustomer]: ({ commit }, customer: Customer) => {
        commit(CustomerMutations.SET_CUSTOMER, customer);
    },

    [CustomerActions.logOutCustomer]: ({ commit }) => {
        return Api.account.logout().then((newEmptyCust) => {
            commit(CustomerMutations.SET_CUSTOMER, newEmptyCust);
        })
    },
};
