<template src="@/components/Ork/OrkNewsletter.html"></template>
<style lang="scss" src="@/components/Ork/OrkNewsletter.scss"></style>

<script>
import {SfButton, SfInput, SfLoader} from '@storefront-ui/vue';
import Api from "@/api/Api";

export default {
  name: 'OrkNewsletter',
  components: {
    SfButton,
    SfInput,
    SfLoader
  },
  props: {
    title: {type: String},
    subtitle: {type: String | null}
  },
  data() {
    return {
      errorMessage: null,
      loadingNewsletter: false,
      newsletterEmail: '',
      newsletterEmailBlur: true,
      valid: false,
      emailValid: false,
      isSubmitted: false,
    };
  },
  methods: {
    validate() {
      this.newsletterEmailBlur = false;
      if (
          this.validEmail(this.newsletterEmail)
      ) {
        this.valid = true;
      }
    },
    validEmail(email) {
      const regex = /(.+)@(.+){2,}\.(.+){2,}/
      const hasWhiteSpace = /\s/;
      if (regex.test(email.toLowerCase()) && !hasWhiteSpace.test(email.toLowerCase())) {
        this.emailValid = true

        return true;
      } else {
        this.emailValid = false
        return false;
      }
    },
    submit() {
      this.loadingNewsletter = true
      this.validate();

      if (this.valid) {
        Api.account.subscribeNewsletter(this.newsletterEmail).then((data) => {
          this.isSubmitted = true;
          this.errorMessage = null
        }).catch((e) => {
          if (e.status === 409) {
            this.errorMessage = this.$i18n.t('newsLetter.errorMailExist')
          } else {
            this.errorMessage = this.$i18n.t('errors.server.access')
          }
          this.isSubmitted = false

        }).finally(() => {
          this.loadingNewsletter = false

        });
      } else {
        this.errorMessage = null;
        this.isSubmitted = false
      }
    }
  }
};

</script>
