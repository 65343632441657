<template>
  <div class="orkPriceGrid">
    <OrkPrice
        v-if="ecommerceMode==='B2B'"
        :regular="price.regular.taxFree | toCurrencyHT"
        :special="price.discountPrice && price.discountPrice.taxFree | toCurrencyHT"
        class="sf-product-card__price"
    />
    <OrkPrice
        v-else
        :regular="price.regular.taxIncluded | toCurrency"
        :special="price.discountPrice && price.discountPrice.taxIncluded | toCurrency"
        class="sf-product-card__price"
    />

  </div>
</template>
<script>


import OrkPrice from "@/components/Ork/OrkPrice";
export default {
  name: "OrkPriceGrid",
  components: {
    OrkPrice
  },
  data() {
    return {
      price:null,
      ecommerceMode: process.env.VUE_APP_ECOMMERCE_MODE,
    }
  },
  beforeMount() {
    this.price = this.params.value
  },
  methods: {
   /* btnClickedHandler() {
      this.params.clicked(this.params.value);
    }*/
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .sf-price__regular {
font-size: 1rem;
    font-family: var(--font-family--secondary);
    .currency{
      font-family: var(--font-family--secondary);
    }
  }

}
</style>
