export class Price {
  taxAmount!: number | null;
  taxFree!: number | null;
  taxIncluded!: number | null;
  taxRate!: number | null;
  currency!: string | null;
  symbol!: string | null;

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(data: any): Price {
    const p: Price = new Price();
    p.taxFree = data?.taxFree;
    p.taxIncluded = data?.taxIncluded;
    p.taxRate = data?.taxRate;
    p.taxAmount = data?.taxAmount;
    p.currency = data?.currency;
    p.symbol = data?.symbol;
    return p;
  }
}
