<template src="@/components/AppHeader.html"></template>
<style lang="scss" scoped src="@/components/AppHeader.scss"></style>
<script>
import {
  SfButton,
  SfHeader,
  SfIcon,
  SfImage,
  SfMegaMenu,
  SfSearchBar,
  SfOverlay,
  SfBadge,
  SfLoader,
  SfLink

} from '@storefront-ui/vue';
import {OrkLoader} from "@/components/Ork";
import SearchResults from '@/components/SearchResults';
import SearchAutocomplete from '@/components/SearchAutocomplete';
import {icons} from "@/utils/icons";
import {clickOutside} from '@storefront-ui/vue/src/utilities/directives/click-outside/click-outside-directive.js';
import Api from "@/api/Api";
import MenuInline from '@/components/Menu/MenuDesktopInline.vue';

export default {
  name: 'AppHeader',
  components: {
    MenuInline,
    SfLink,
    OrkLoader,
    SfButton,
    SfHeader,
    SfImage,
    SfIcon,
    SfMegaMenu,
    SfSearchBar,
    SfBadge,
    SfLoader,
    SfOverlay,
    SearchResults,
    SearchAutocomplete,
  },
  data() {
    return {
      icons: icons,
      showQuickOrder: process.env.VUE_APP_QUICKORDER  === 'true',
      term: '',
      firstLoad: true,
      currentRoute: null,
      debounceSearch: null,
      searchValue: '',
      activeMenu: null,
      isShowMenu: false,
      loadingSearch: false,
      accountIcon: 'profile',
      cartTotalItems: 0,
      searchResult: {content: [], totalItems: 0, loading: false},
      searchTerm: '',
      searchTerms: [],
      showAutocomplete: false,
      customerIsActive: false,
    };
  },
  directives: {clickOutside},
  computed: {
    isMenuDesktopModalOpen: {
      get() {
        return this.$store.getters.isMenuDesktopModalOpen
      }
    },
    rootCategory: {
      get() {
        return this.$store.getters.getRootCategoryPath
      }
    },
    rootCategoryName: {
      get() {
        return this.$store.getters.getRootCategoryLabel
      }
    },
    isSearchOpen: {
      get() {
        return this.$store.getters.isSearchOpen
      }
    },
    categoryTree: {
      get() {
        return this.$store.getters.getCategoryTree
      }
    },
    user: {
      get() {
        return this.$store.getters.getProfile
      }
    },
    isAuthenticated: {
      get() {
        return this.$store.getters.isAuthenticated
      }
    },
    cart: {
      get() {
        return this.$store.getters.getCart
      }
    }
  },
  mounted() {
    //this.$store.dispatch('getMe');
    //this.$store.dispatch('getCart');
  },
  watch: {
    $route: 'routeSearchTerm'
  },
  methods: {
    toggleMenuDesktopModal() {
      this.$store.dispatch('toggleMenuDesktopModal')
    },
    getCustomerIsActive() {
      this.customerIsActive = this.$route.name === 'My-account'
    },
    toggleMenuModal() {
      this.$store.dispatch('toggleMenuModal')

    },
    toggleRootCategoryModal() {
      this.$store.dispatch('toggleRootCategoryModal', {open:true, rootCategory:null})

    },
    routeSearchTerm() {
      this.firstLoad = true
      this.getCustomerIsActive();
      this.searchTerm = this.$route.query.searchTerm ? this.$route.query.searchTerm : this.searchTerm;
    },
    closeMenu() {
      this.isShowMenu = false;
    },
    showMenu() {
      if (this.isShowMenu && this.firstLoad) {
        this.isShowMenu = false
      } else {
        this.firstLoad = false
        this.isShowMenu = !this.isShowMenu;
      }
    },
    // mutation de isModalLogin
    handleAccountClick() {
      if (this.isAuthenticated) {
        return this.$router.push(this.$localizePath('/mon-compte/profil'));
      } else {
        this.$store.dispatch('toggleLoginModal', {open: true})
      }
    },
    toggleCartSidebar() {
      this.$store.dispatch('toggleCartSidebar')
    },
    toggleQuickOrder() {
      this.$store.dispatch('toggleQuickOrder', {open: true})
    },
    removeSearchResults() {
      this.searchTerms = [];
      this.searchResult = {content: [], totalItems: 0, loading: false}
    },
    closeSearch() {
      //  this.searchTerm = '';
      this.searchTerms = [];
      this.searchResult = {content: [], totalItems: 0, loading: false}
      this.$store.dispatch('closeSearch')
    },

    toggleSearch() {
      this.$store.dispatch('toggleSearch')
    },

    openSearch() {
      this.$store.dispatch('openSearch')
    },
    closeAutocomplete() {
      this.showAutocomplete = false;
    },
    getLengthTerm(term) {
      let lengthTerm = 0;
      for (let i = 0, len = term.length; i < len; i++) {
        if (term[i] !== ' ')
          lengthTerm++;
      }
      return lengthTerm;
    },
    handleSearchAutoComplete(term) {
      clearTimeout(this.debounceSearch);
      this.searchTerm = term;

      if (this.getLengthTerm(this.searchTerm) >= 3) {

        this.debounceSearch = setTimeout(() => {
          this.loadingSearch = true;

          Api.product.searchAutocomplete(term).then((data) => {
            this.showAutocomplete = true;
            this.searchTerms = data;
          }).catch((e) => {
            console.error(e)
          }).finally(() => {
            this.loadingSearch = false;
          })
        }, 300);
      }


    },

    changeSearchTerm(term) {
      this.searchTerm = term;
      this.showAutocomplete = false;
      this.handleSearch()

    },

    handleSearch() {
      this.goToSearch()
    },

    goToSearch() {
      if (this.getLengthTerm(this.searchTerm) >= 3) {
        this.searchResult = {content: [], totalItems: 0};
        this.$store.dispatch('closeSearch')
        if (this.$route.name === "Search") {
          this.$router.replace({query: {searchTerm: this.searchTerm}}).catch(() => {
          });
        } else {
          this.$router.push(this.$localizePath('/recherche?searchTerm=' + this.searchTerm)).catch(() => {
          })
        }
      }
    }
  }
};
</script>
