<template src="@/components/Ork/OrkTerms.html"></template>
<style lang="scss" src="@/components/Ork/OrkTerms.scss"></style>

<script>
import {SfCheckbox, SfLink} from '@storefront-ui/vue';
import {ShoppingCart} from "@/models/shopping";

export default {
  name: 'OrkTerms',
  components: {
    SfCheckbox,
    SfLink
  },
  props: {
    terms: {type: Boolean},
    currentCart: {type: ShoppingCart}
  },
  methods:{
    toggleCgvModal(e, cgvContent, shopName) {
      e.preventDefault()
      this.$store.dispatch("toggleCgvModal", {open: true, shopName, cgvContent});
    },
  }
};
</script>