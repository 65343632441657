import ProductService from "@/api/services/ProductService";
import CategoryService from "@/api/services/CategoryService";
import OrderService from "@/api/services/OrderService";
import AccountService from "@/api/services/AccountService";
import CartService from "@/api/services/CartService";
import PaymentService from "@/api/services/PaymentService";
import PlatformService from "@/api/services/PlatformService";
import MarketingService from "@/api/services/MarketingService";
import MarketingEventService from "@/api/services/MarketingEventService";
import CustomService from "@/api/services/CustomService";
import RelayService from "./services/RelayService";
import SedService from "./services/SedService";

//TODO change payment service to good provider (adyen ou mangoPay)


export default class Api{

  private static _payment:PaymentService = PaymentService.of();

  static get payment():PaymentService {
    return Api._payment;
  }

  private static _custom:CustomService = CustomService.of();

  static get custom():CustomService {
    return Api._custom;
  }

  private static _platform: PlatformService = PlatformService.of();

  static get platform(): PlatformService {
    return Api._platform;
  }

  private static _category: CategoryService = CategoryService.of();

  static get category(): CategoryService {
    return Api._category;
  }

  private static _cart: CartService = CartService.of();

  static get cart(): CartService {
    return Api._cart;
  }

  private static _order: OrderService = OrderService.of();

  static get order(): OrderService {
    return Api._order;
  }

  private static _product: ProductService = ProductService.of();

  static get product(): ProductService {
    return Api._product;
  }

  private static _account: AccountService = AccountService.of();

  static get account(): AccountService {
    return Api._account;
  }

  private static _marketing: MarketingService = MarketingService.of();

  static get marketing(): MarketingService {
    return Api._marketing;
  }

  private static _relay: RelayService = RelayService.of();

  static get relay(): RelayService {
    return Api._relay;
  }

  private static _marketingEvent: MarketingEventService = MarketingEventService.of();

  static get marketingEvent(): MarketingEventService {
    return Api._marketingEvent;
  }

  private static _sed: SedService = SedService.of();

  static get sed(): SedService {
    return Api._sed;
  }


}
