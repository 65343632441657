<template src="@/components/AppFooter.html"></template>
<style lang="scss" src="@/components/AppFooter.scss"></style>

<script>
import {SfFooter, SfImage, SfList,} from '@storefront-ui/vue';
import {OrkSocial, OrkNewsletter} from "@/components/Ork";
import OrkReassurance from "@/components/Ork/OrkReassurance";
import {icons} from "@/utils/icons";

export default {
  name: 'AppFooter',
  components: {
    SfImage,
    SfFooter,
    SfList,
    OrkSocial,
    OrkReassurance,
    OrkNewsletter
  },

  data() {
    return {
      showNewsletter: process.env.VUE_APP_SHOW_NEWSLETTER === 'true',
      loading: true,
      date: new Date().getFullYear(),
      social: [
        {
          label: 'Instagram',
          link: 'https://www.instagram.com/shopedom_market/',
          icon: icons.instagram
        },
        {
          label: 'LinkedIn',
          link: 'https://www.linkedin.com/company/shop-e-dom-market/about/',
          icon: icons.linkedIn
        },
        {
          label: 'Facebook',
          link: 'https://www.facebook.com/profile.php?id=61564230622113',
          icon: icons.facebook
        },
        {
          label: 'TikTok',
          link: 'https://www.tiktok.com/@shopedom_market',
          icon: icons.tiktok
        },
      ],

      menuFooter1: {
        items: [
          {
            slug: '/qui-sommes-nous',
            target: '_self'
          },
          {
            slug: '/shopedom-business',
            target: '_self'
          },
          {
            slug: '/devenir-fournisseur',
            target: '_self'
          },
          {
            slug: '/contact-presse',
            target: '_self'
          },
        ]
      },
      menuFooter2: {
        items: [
          {
            slug: '/garanties',
            target: '_self'
          },
          {
            slug: '/moyens-de-paiement',
            target: '_self'
          },
          {
            slug: '/securite-de-paiement',
            target: '_self'
          },
          {
            slug: '/livraison',
            target: '_self'
          },
          {
            slug: '/recrutement',
            target: '_self'
          },
          {
            slug: '/service-client',
            target: '_self'
          },
          {
            slug: '/contactez-nous',
            target: '_self'
          }
        ]
      },
      menuFooter3: {
        items: [
          {
            slug: '/conditions-generales-de-ventes',
            target: '_self'
          },
          {
            slug: '/conditions-generales-d-utilisation',
            target: '_self'
          },
          {
            slug: '/mentions-legales',
            target: '_self'
          },
          {
            slug: '/politique-de-protection-des-donnees-personnelles',
            target: '_self'
          },
          {
            slug: '/gestion-des-cookies',
            target: '_blank'
          },
        ]
      },
      menuAnnexe: {
        items: [
        ]
      }
    }
  }
};
</script>
