var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ork-toggle",class:{
     'small':_vm.size==='small',
     'medium':_vm.size==='medium',
    'is-active': _vm.isChecked,
    'is-disabled': _vm.disabled,
    'has-error': !_vm.valid,
    'is-required': _vm.required,
  }},[_c('label',[_c('span',{class:[_vm.inverse?'switch inverse':'switch']},[_c('input',{directives:[{name:"focus",rawName:"v-focus"}],attrs:{"disabled":_vm.disabled,"name":_vm.name,"type":"checkbox"},domProps:{"checked":_vm.isChecked,"value":_vm.value},on:{"change":_vm.inputHandler}}),(_vm.round)?_c('span',{staticClass:"slider round"}):_c('span',{staticClass:"slider"})]),_c('span',{staticClass:"text"},[(_vm.title)?_c('span',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),(_vm.description)?_c('span',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }