import { ActionTree } from "vuex";
import { ClientState } from "@/store/type";
import { RootState } from "~/core/store/type";
import { ClientMutations } from "@/store/mutations";


export const actions: ActionTree<ClientState, RootState> = {
  toggleMenuDesktopModal({commit}) {
    commit(ClientMutations.IS_TOGGLE_MENU_DESKTOP);
  },

};