import MosaiqClient from "@/api/clients/MosaiqClient";

import { AxiosRequestConfig } from "axios";
export default class RelayService {
  static of(): RelayService {
    return new RelayService();
  }

  getRelaysForTerritory(territorySlug) {
    const catalogName = process.env.VUE_APP_MOSAIQ_RELAY_CATALOG;
    const axiosRequestConfig: AxiosRequestConfig = {
      params: {
        columnFilters: JSON.stringify({
            [catalogName + "_type"]: ["PARCEL_PICKUP_POINT"],
            [catalogName + "_territory"]: territorySlug
        })
      },
    };
    return MosaiqClient.of().get("locations/" + catalogName, axiosRequestConfig).then(
        (data) => {
          return data.data;
        },
        err => {
          throw err;
        },
    );
  }
}
