import axios, { AxiosInstance, AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse, CreateAxiosDefaults, InternalAxiosRequestConfig } from "axios";
import Notifications from "@/utils/notifications";
import router from "@/router";
import store from "~/core/store";
import { CustomerActions } from "~/core/store/customer/actions";

const FILENAME_REGEX = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

export interface InterceptorManagers {
    request: AxiosInterceptorManager<InternalAxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
}

export default class ApiClient {
    private instance: AxiosInstance;

    /** Keep the reference of the interceptor if we ever need to remove it later */
    private responseInterceptor: number;

    static of(options: CreateAxiosDefaults): ApiClient {
        return new ApiClient(options);
    }

    constructor(options: CreateAxiosDefaults) {
        this.instance = axios.create(options || {});

        // Add a response interceptor
        this.responseInterceptor = this.instance.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            const disposition = response.headers['content-disposition'] || '';
            const matches = FILENAME_REGEX.exec(disposition);
            if (matches != null && matches[1]) {
                return {
                    fileName: matches[1].replace(/['"]/g, ''),
                    blob: response.data
                }
            }

            return response.data;
        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            switch (error.code) {
                case "ERR_CANCELED":
                    return;
                case "ERR_NETWORK":
                    Notifications.createNotifications({ name: 'errors.server.connexion' })
                    throw { status: null, errorCode: error.code, errorData: error.name, errorMessage: error.message };
            }

            if (error.response) {
                const data: any = error.response.data || {};

                if (data.errorCode === "recaptcha-low-score") {
                    Notifications.createNotifications({ name: 'errors.recaptcha' })
                }

                if (error.response.status === 401
                    && error.response.config.url !== "customers/one-time-password"
                    && error.response.config.url !== "customers/sign-in") {
                    if (data.errorCode === "customer-is-not-logged-in") {
                        Notifications.createNotifications({ name: 'errors.not-logged-in' })
                        store.dispatch(CustomerActions.setCustomer, null);
                        store.dispatch('getCategoryTree');
                        router.push(router.app.$localizePath("/mon-compte"));
                    } else {
                        Notifications.createNotifications({ name: 'errors.unauthorized' })
                    }
                }

                if (error.response.status === 404) {
                    Notifications.createNotifications({ name: 'errors.server.notFound' })
                }

                if (error.response.status.toString().startsWith('50')) {
                    Notifications.createNotifications({ name: 'errors.server.access' })
                }

                throw { status: error.response.status, errorCode: data.errorCode, errorData: data.errorData, errorMessage: data.errorMessage };
            }

            if (error.request) {
                Notifications.createNotifications({ name: 'errors.server.connexion' })
                throw { status: -1, errorCode: error.code };
            }

            return Promise.reject(error);
        });
    }

    get(path: string, config?: AxiosRequestConfig): Promise<any> {
        return this.request().get(path, config);
    }

    delete(path: string, config?: AxiosRequestConfig): Promise<any> {
        return this.request().delete(path, config);
    }

    post(path: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
        return this.request().post(path, data, config);
    }

    put(path: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
        return this.request().put(path, data, config);
    }

    patch(path: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
        return this.request().patch(path, data, config);
    }

    request(): AxiosInstance {
        return this.instance;
    }

}
