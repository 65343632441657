import ApiClient from "@/api/clients/ApiClient";

export default class DefaultClient extends ApiClient {

  constructor() {
    super({
      headers: {
        Accept: "application/json",
        common: {},
      },
      params: {},
    });
  }

  static of(): DefaultClient {
    return new DefaultClient();
  }
}
