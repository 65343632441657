import {MutationTree} from "vuex";
import {UiState} from "~/core/store/ui/type";


export enum UiMutations {
    IS_TOGGLE_LOGIN = "IS_TOGGLE_LOGIN",
    IS_TOGGLE_MENU = "IS_TOGGLE_MENU",
    IS_TOGGLE_SEARCH = "IS_TOGGLE_SEARCH",
    CLOSE_MENU = "CLOSE_MENU",
    CLOSE_SEARCH = "CLOSE_SEARCH",
    OPEN_SEARCH = "OPEN_SEARCH",
    IS_TOGGLE_CART = "IS_TOGGLE_CART",
    IS_TOGGLE_PRODUCTS_ACTION = "IS_TOGGLE_PRODUCTS_ACTION",
    IS_TOGGLE_NOSTOCK_ACTION = "IS_TOGGLE_NOSTOCK_ACTION",
    SEND_NOTIFICATIONS = "SEND_NOTIFICATIONS",
    DISMIS_NOTIFICATIONS = "DISMISS_NOTIFICATIONS",
    IS_TOGGLE_WELCOME_ACTION = "IS_TOGGLE_WELCOME_ACTION",
    IS_TOGGLE_ADDRESS_ACTION = "IS_TOGGLE_ADDRESS_ACTION",
    IS_TOGGLE_ADDRESSLIST_ACTION = "IS_TOGGLE_ADDRESSLIST_ACTION",
    IS_TOGGLE_RESET_ACTION = "IS_TOGGLE_RESET_ACTION",
    IS_TOGGLE_CGV_CONTENT = "IS_TOGGLE_CGV_CONTENT",
    IS_TOGGLE_QUICK_ORDER = "IS_TOGGLE_QUICK_ORDER",
    IS_TOGGLE_ROOTCATEGORY_MODAL = "IS_TOGGLE_ROOTCATEGORY_MODAL",
    CHANGE_INDEX = "CHANGE_INDEX",
    GET_GALLERY = "GET_GALLERY",
    CLOSE_LOGIN = "CLOSE_LOGIN",
    IS_TOGGLE_STOCKALERT_ACTION = "IS_TOGGLE_STOCKALERT_ACTION",
    IS_TOGGLE_PRODUCT = "IS_TOGGLE_PRODUCT",
    SET_CURRENT_LOCAL = "SET_CURRENT_LOCAL",
    SET_FRANCO = "SET_FRANCO",
    SET_TOP_PROMOTIONAL = "SET_TOP_PROMOTIONAL",
    OPEN_RELAY_MODAL = "OPEN_RELAY_MODAL",
    CLOSE_RELAY_MODAL = "CLOSE_RELAY_MODAL",
    RESET_RELAY_DATA = "RESET_RELAY_DATA",
    SET_FILTERS_VALUES = "SET_FILTERS_VALUES",
}

const maxVisibleNotifications = 3;

export const mutations: MutationTree<UiState> = {
    [UiMutations.IS_TOGGLE_ROOTCATEGORY_MODAL](state,{open, rootCategory}): void {
        state.rootCategoryModalOpen.rootCategory = rootCategory;
        state.rootCategoryModalOpen.open = open;
      },

    [UiMutations.OPEN_RELAY_MODAL](state, { address, shipmentWeight }): void {
        state.relayModal.open = true;
        state.relayModal.address = address;
        state.relayModal.shipmentWeight = shipmentWeight;
    },
    [UiMutations.CLOSE_RELAY_MODAL](state, selectedRelais): void {
        state.relayModal.open = false;
        state.relayModal.selectedRelais = selectedRelais;
    },
    [UiMutations.RESET_RELAY_DATA](state): void {
        state.relayModal.open = false;
        state.relayModal.address = null;
        state.relayModal.selectedRelais = null;
        state.relayModal.shipmentWeight = 0;
    },
    [UiMutations.SET_FILTERS_VALUES](state, filtersValues): void {

        state.filtersValues = filtersValues;
    },
    [UiMutations.SET_FRANCO](state, franco): void {
        state.franco = franco;
    },
    [UiMutations.SET_TOP_PROMOTIONAL](state, topPromotional): void {
        state.topPromotional = topPromotional;
    },
    [UiMutations.SET_CURRENT_LOCAL](state, {locale}): void {
        state.currentLocal = locale;
    },
    [UiMutations.IS_TOGGLE_CGV_CONTENT](state, {open, shopName, cgvContent}): void {
        state.isCgvModal.open = open;
        state.isCgvModal.shopName = shopName;
        state.isCgvModal.cgvContent = cgvContent;
    },
    [UiMutations.IS_TOGGLE_PRODUCT](state, {open, productSlug}): void {
        state.isProductSidebarOpen.open = open;
        state.isProductSidebarOpen.productSlug = productSlug;
    },
    [UiMutations.IS_TOGGLE_PRODUCTS_ACTION](state, {type, product}): void {
        state.isProductsActionModal.open = !state.isProductsActionModal.open;
        state.isProductsActionModal.type = type;
        state.isProductsActionModal.product = product;
    },
    [UiMutations.IS_TOGGLE_QUICK_ORDER](state, {open}): void {
        state.isQuickOrderModal.open = open;
    },
    [UiMutations.IS_TOGGLE_NOSTOCK_ACTION](state, {open, products}): void {
        state.isNoStockActionModal.open = !state.isNoStockActionModal.open;
        state.isNoStockActionModal.products = products;
    },
    [UiMutations.IS_TOGGLE_STOCKALERT_ACTION](state, {open, product}): void {
        state.isStockAlertModal.open = open;
        state.isStockAlertModal.product = product;
    },

    [UiMutations.IS_TOGGLE_WELCOME_ACTION](state, {customer}): void {
        state.isWelcomeModal.open = !state.isWelcomeModal.open;
        state.isWelcomeModal.customer = customer;
    },
    [UiMutations.IS_TOGGLE_RESET_ACTION](state, {email, status}): void {
        state.isResetModal.open = !state.isResetModal.open;
        state.isResetModal.email = email;
        state.isResetModal.status = status;
    },
    [UiMutations.IS_TOGGLE_LOGIN](state, {open, isReloaded}): void {
        state.isLoginModalOpen.open = open;
        state.isLoginModalOpen.isReloaded = isReloaded
    },
    [UiMutations.CLOSE_LOGIN](state): void {
        state.isLoginModalOpen.open = false;
    },
    [UiMutations.IS_TOGGLE_SEARCH](state): void {
        state.isSearchOpen = !state.isSearchOpen;
    },
    [UiMutations.CLOSE_SEARCH](state): void {
        state.isSearchOpen = false;
    },
    [UiMutations.OPEN_SEARCH](state): void {
        state.isSearchOpen = true;
    },
    [UiMutations.IS_TOGGLE_ADDRESS_ACTION](state, {open,address,isShipping, isBilling}): void {
        state.isAddressModalOpen.open = open;
        state.isAddressModalOpen.address = address;
        state.isAddressModalOpen.isShipping = isShipping;
        state.isAddressModalOpen.isBilling = isBilling;
    },
    [UiMutations.IS_TOGGLE_ADDRESSLIST_ACTION](state): void {
        state.isAddressListModalOpen = !state.isAddressListModalOpen;
    },
    [UiMutations.IS_TOGGLE_MENU](state): void {
        state.isMenuModalOpen = !state.isMenuModalOpen;
    },
    [UiMutations.CLOSE_MENU](state): void {
        state.isMenuModalOpen = false;
    },
    [UiMutations.IS_TOGGLE_CART](state): void {
        state.isCartSidebarOpen = !state.isCartSidebarOpen;
    },
    [UiMutations.GET_GALLERY](state, {gallery}): void {
        state.productGallery = gallery;
    },
    [UiMutations.CHANGE_INDEX](state, {index}): void {
        state.indexGallery = index;
    },

    [UiMutations.SEND_NOTIFICATIONS](state, {notification, id}): void {
        const dismiss = () => {
            const index = state.notifications.findIndex(notification => notification.id === id);
            if (index !== -1) {
                state.notifications.splice(index, 1);
            }
        };

        const newNotification = {
            ...notification,
            id,
            dismiss,
        };

        state.notifications.push(newNotification);

        if (state.notifications.length > maxVisibleNotifications) {
            state.notifications.shift();
        }

    },
    [UiMutations.DISMIS_NOTIFICATIONS](state, id): void {

        const index = state.notifications.findIndex(notification => notification.id === id);
        if (index !== -1) {
            state.notifications.splice(index, 1);
        }

    },
};
