<template src="@/components/NoStockActionModal.html"/>
<style lang="scss" scoped src="@/components/NoStockActionModal.scss"/>

<script>

import {
  SfButton,
  SfModal,
  SfBar
} from '@storefront-ui/vue';


export default {
  name: 'NoStockActionModal',
  components: {
    SfButton,
    SfModal
  },

  data() {
    return {};
  },
  computed: {
    isNoStockActionModal: {
      get() {
        return this.$store.getters.isNoStockActionModal
      }
    },
  },


  methods: {
    toggleNoStockActionModal() {
      this.$store.dispatch('toggleNoStockActionModal', {open: false, products: null})
    },
    backToCart() {
      this.$store.dispatch('toggleNoStockActionModal', {open: false, products: null})
      this.$router.push(this.$localizePath('/mon-panier'));
    }
  }
};
</script>


