<template functional src="@/components/Ork/OrkPrice.html"/>
<style lang="scss" src="@/components/Ork/OrkPrice.scss"></style>
<script>
export default {
  name: "OrkPrice",
  props: {
    regular: {
      type: [Number,String],
      default: null,
    },
    special: {
      type: [Number,String],
      default: null,
    },
  },
};
</script>
