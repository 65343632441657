import Router from "@/router";

export default class ProductOptions {

  static getSelectedOptionOrDefault(defaultOption: string): string {
    return Router.history.current.query.sort || defaultOption;
}

  static getProductOptions = {
    itemPerPage: [12, 24, 36],
    sortAttributes: [
      { id: "score.desc", value: "Relevance" },
      { id: "price.asc", value: "Price ascending" },
      { id: "price.desc", value: "Price descending" }
    ],
    allowEmptyStock: false,
    minimalQuantity:1,
  };

  static changeItemsPerPage(itemsPerPage: string): void {
    const { query } = Router.history.current;
    delete query.page;
    Router.replace({ query: { ...query, itemsPerPage } }).catch(() => {
      return;
    });
  }

  static changeSorting(sort: string): void {
    const { query } = Router.history.current;
    Router.replace({ query: { ...query, sort } }).catch(() => {
      return;
    });
  }

}
