<template>
  <div
      :class="{
       'small':size==='small',
       'medium':size==='medium',

      'is-disabled': disabled,
      'has-error': !valid,
      'is-required': required,
    }"
      class="ork-toggle-filter"
  >


    <div class="switch">
      <input name="switch" id="one" type="radio" value="false" @change="inputHandler"  :checked="selected === 'false'"/>
      <label for="one" class="switch__label"></label>
       <input name="switch" id="three" type="radio" value="true" @change="inputHandler"  :checked="selected === 'true'"/>
      <label for="three" class="switch__label" ></label>
      <button class="switch__indicator"  @click="changeSelected"></button>
    <div class="switch__background" />
  </div>


  </div>
</template>
<script>

import {focus} from "@storefront-ui/vue/src/utilities/directives";

export default {
  name: "OrkToggleFilter",
  directives: {
    focus,
  },
  components: {},
  model: {
    prop: "selected",
    event: "change",
  },
  props: {

    size: {
      type: String,
      default: 'medium'
    },
    round: {
      type: Boolean,
      default: true,
    },
    inverse: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Boolean],
      default: "false",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    hintMessage: {
      type: String,
      default: "Required.",
    },
    required: {
      type: Boolean,
      default: false,
    },
    infoMessage: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "This field is not correct.",
    },
    valid: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: String,
      default: "false",
    },
  },
  computed: {
    isChecked() {
      if (typeof this.selected === "boolean") {
        return this.selected;
      } else {
        return this.selected.includes(this.value);
      }
    },

    computedMessageSlotName() {
      return this.messagesHandler(
          "show-error-message",
          "show-info-message",
          this.required ? "show-hint-message" : ""
      );
    },
    computedMessage() {
      return this.messagesHandler(
          this.errorMessage,
          this.infoMessage,
          this.required ? this.hintMessage : ""
      );
    },
    computedMessageClass() {
      return this.messagesHandler(
          "sf-checkbox__message--error",
          "sf-checkbox__message--info",
          this.required ? "sf-checkbox__message--hint" : ""
      );
    },
  },
  methods: {
    changeSelected(){
      switch (this.selected) {
        case 'true':
          this.$emit("change", "false")
          break;
        case 'false':
          this.$emit("change", "true")
          break;
      }
    },
    inputHandler(value) {
        this.$emit("change", value.target.value);

    },
    messagesHandler(error, info, hint) {
      if (this.errorMessage && !this.valid) {
        return error;
      } else if (this.infoMessage && this.valid) {
        return info;
      } else if (this.hintMessage) {
        return hint;
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>


.ork-toggle-filter {
  .switch {
    position: relative;
    width: 64px;
    padding: 0 0;
    display: flex;
    align-items: center;

    &:before {
      content: '  ';
      position: absolute;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 28px;
      background-color: var(--_c-primary-background);
      box-shadow: 0 0 10px -5px inset rgba(0, 0, 0, 0.15);
      border-radius: 30px;
    }
    &:hover{
      &::before{
        box-shadow: 0 0 10px -5px inset rgba(0, 0, 0, 0.5);
      }
    }
    &__label {
      z-index: 2;
      display: inline-block;
      width: 50%;
      height: 28px;
      border-radius: 50%;
      text-align: center;
      cursor: pointer;
      transition: color 200ms ease-out;
      &:hover {
        color: white;

          ~ .switch__background{
            box-shadow: 0 0 10px -5px inset rgba(0, 0, 0, 0.5);
          }

      }
    }
    &__background{
      z-index: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(90deg, var(--toggle-off) 0%, var(--toggle-default) 50%, var(--toggle-on) 100%);
      border-radius: 32px;
      transition: background 300ms cubic-bezier(.17,.67,.14,1.03), box-shadow 250ms ease;
      box-shadow: 0 0 10px -5px inset rgba(0, 0, 0, 0.15);

      &:hover{
          box-shadow: 0 0 10px -5px inset rgba(0, 0, 0, 0.5);
      }
    }
    &__indicator {
      z-index: 4;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 2px;
      left: 2px;
      border:none;
      cursor: pointer;
      background: var(--c-white);
      border-radius: 50%;
      transition: transform 600ms cubic-bezier(.02,.94,.09,.97),
      background 300ms cubic-bezier(.17,.67,.14,1.03);
      transform: translatex(72px);
      &:hover{
        ~ .switch__background{
          box-shadow: 0 0 10px -5px inset rgba(0, 0, 0, 0.5);
        }
      }
    }
    input#one:checked ~ .switch__background {
      background: var(--toggle-off);
    }
    input#one:checked ~ .switch__indicator {
      transform: translateX(0);
      box-shadow: 5px 0 10px -5px rgba(0,0,0,0.5);
    }
    input#three:checked ~ .switch__background {
      background: var(--toggle-on);
    }
    input#three:checked ~ .switch__indicator {
box-shadow: -5px 0 10px -5px rgba(0,0,0,0.5);
      transform: translateX(36px);
    }
    input[type="radio"] {
      &:not(:checked),
      &:checked {
        display: none;
      }
    }
  }
}
</style>

