<template src="@/components/CartSidebar.html"></template>
<style lang="scss" scoped src="@/components/CartSidebar.scss"></style>

<script>
import {
  SfBar,
  SfSidebar,
  SfHeading,
  SfButton,
  SfIcon,
  SfProperty,
  SfPrice,
  SfCircleIcon,
  SfCollectedProduct,
  SfImage,
  SfLink,
  SfDivider
} from '@storefront-ui/vue';
import Api from "@/api/Api";
import icons from "@/utils/icons";
import OrkPrice from "@/components/Ork/OrkPrice";
import OrkQuantitySelector from "@/components/Ork/OrkQuantitySelector";
import Notifications from "@/utils/notifications";
import OrderSummary from '@/components/Cart/OrderSummary.vue';
export default {
  name: 'Cart',
  components: {
    OrderSummary,
    SfBar,
    SfDivider,
    OrkPrice,
    SfSidebar,
    SfCircleIcon,
    SfLink,
    SfButton,
    SfHeading,
    SfIcon,
    SfProperty,
    SfPrice,
    SfCollectedProduct,
    OrkQuantitySelector,
    SfImage
  },
  data() {
    return {
      loading: {
        cart: null,
      },
      icons: icons,
      totalItems: 0,
      isLogin: true,
    }
  },

  computed: {
    isCartSidebarOpen: {
      get() {
        if(this.currentCart && this.$store.getters.isCartSidebarOpen ){
            Api.marketingEvent.viewCart(this.currentCart)
        }
        return this.$store.getters.isCartSidebarOpen
      }
    },
    currentCart: {
      get() {
        return this.$store.getters.getCart
      },
      set() {
      }
    },
  },

  methods: {
    clearCart() {
      Api.cart.removeCart(this.currentCart.id).then((data) => {
        this.$store.dispatch('setCart', data)

      }).catch((e) => {
        this.$store.dispatch('setCart', null);
        this.currentCart = null;
      })
    },
    backToHome() {
      this.toggleCartSidebar()
    },
    toggleCartSidebar() {
      this.$store.dispatch('toggleCartSidebar')
    },
    openProduct(link) {
      this.toggleCartSidebar()
      this.$router.push(this.$localizePath(link))
    },
    removeCoupon() {
      Api.cart.removeCoupons()
          .then((data) => {
            this.$store.dispatch('setCart', data);
            Notifications.createNotifications({name:'coupon.removed'})
          })
          .catch((e) => {
            switch (e.status) {
              case 409:
                Notifications.createNotifications({name:'coupon.allReady'})
                break;
              case 404:
                Notifications.createNotifications({name:'coupon.not'})
                break;
            }

          })
          .finally();

    },
    setOfferQuantity(params) {
      this.loading = true;

      let cart = params.currentCart,
          itemIsInCart = null,
          qty = params.quantity,
          prevQty = 0,
          stock = 0;

      if (cart && cart.cartItems && cart.cartItems.length) {
        itemIsInCart = cart.cartItems.find(item => item.product?.offer?.id === params.offerId);

        if (itemIsInCart) {
          prevQty = itemIsInCart?.quantity || 0;
          stock = itemIsInCart?.product.offer.stockQuantity || 0;
        }
      }


        if (qty > stock && params.method === 'update') {
          Notifications.createNotifications({name:'addToCart.maxQty',value:{stock:stock, productName:params.product.name}})
          params.quantity = stock

        }
        Api.cart.setOfferQuantity(params)
            .then(data => {
              switch (params.method) {
                case 'add':
                Api.marketingEvent.addToCartShipment(params.product, qty, this.currentCart)
                  Notifications.createNotifications({name:'addToCart.add',value:{productName:params.product.name}})
                  break;
                case 'remove':
                Api.marketingEvent.removeFromCart(params.product,  this.currentCart)
                  Notifications.createNotifications({name:'addToCart.remove',value:{productName:params.product.name}})
                  break;
                case 'update':
                Api.marketingEvent.addToCartShipment(params.product, qty, this.currentCart)
                  Notifications.createNotifications({name:'addToCart.update',value:{productName:params.product.name}})
                  break;
              }
            })
            .catch((e) => {
              if(e.status) {
                Notifications.createNotifications({name:'errors.server.access'})
              }
            })
            .finally(this.loading = false);

    },
    goCart() {
      this.toggleCartSidebar();
      if (this.$route.name !== 'Cart') {
        this.$router.push(this.$localizePath('/mon-panier'));
      }
    },
    goCheckout() {
      this.toggleCartSidebar();
      this.$router.push(this.$localizePath('/commande/livraison'));
    }
  },
}
</script>
