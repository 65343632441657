<template src="@/components/CgvModal.html"/>
<style lang="scss" scoped src="@/components/CgvModal.scss"/>
<script>

import {
  SfButton,
  SfModal,
  SfBar,
    SfHeading
} from '@storefront-ui/vue';


export default {
  name: 'CgvModal',
  components: {
    SfButton,
    SfHeading,
    SfModal,
    SfBar
  },
  computed: {
    isCgvModal: {
      get() {
        return this.$store.getters.isCgvModal
      }
    },
  },

  methods: {
    toggleCgvModal() {
      this.$store.dispatch('toggleCgvModal', {open:false,shopName: null,cgvContent:null })
    },
  }
};
</script>
