import { Module } from "vuex";
import { RootState } from "~/core/store/type";
import { CategoryState } from "~/core/store/category/type";
import { getters } from "~/core/store/category/getters";
import { actions } from "~/core/store/category/actions";
import { mutations } from "~/core/store/category/mutations";

const state: CategoryState = {
  categoryTree: undefined,
  rootCategories:null,
  activeCategory: null,
  page : null,
  rootCategory: null,
};

export const category: Module<CategoryState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
