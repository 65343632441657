<template src="@/components/Ork/OrkCmsPage.html"/>
<style lang="scss" src="@/components/Ork/OrkCmsPage.scss"></style>
<script>
import {
  SfHeading, SfNotification
} from '@storefront-ui/vue';
import OrkLoader from "@/components/Ork/OrkLoader";

import Api from "@/api/Api";
import {ContentPage} from "@/models/marketingHome";

export default {
  name: "OrkCmsPage",
  components: {
    SfNotification,
    SfHeading,
    OrkLoader,

  },

  props: {
    pageId: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      content: ContentPage,
      error:false
    };
  },
  mounted() {
    if (this.pageId) {
        this.loading = true;
        Api.marketing.getContentPage(this.pageId)
            .then((data) => {
              this.content = ContentPage.of(data);
              Api.marketing.setSeo(this.content)
              this.error=false;
            })
            .catch((err) => {
              console.error(err);
              this.error = true;
            }).finally(this.loading = false)
      }
  },
  methods: {
  }
};
</script>
