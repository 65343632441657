import { i18n } from "vue-lang-router";

export class Category {
  label;
  path?: string;
  slug?: string;
  children?: Category[] | null;
  optionalAttributes?: any[];
  requiredAttributes?: any[];
  parentSlugs?: [];
  isCurrent?: boolean;
  level?: number;

  public static of(data: any): Category {
    const c: Category = new Category();

    c.label = data.label;
    c.path = data.path;
    c.slug = data.slug;
    c.children = data.children;
    c.optionalAttributes = data?.optionalAttributes;
    c.requiredAttributes = data?.requiredAttributes;
    c.parentSlugs = data?.parentSlugs;
    c.isCurrent = data?.isCurrent;

    return c;
  }
}

export class RootCategory {
  label!:string;
  path!: string;
  slug!: string;

  public static of(data: any): RootCategory {
    const r: RootCategory = new RootCategory();

    r.label = data.label;
    r.path = data.path;
    r.slug = data.slug;

    return r;
  }
}
