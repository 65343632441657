<template src="@/components/SearchResults.html"/>
<style lang="scss" scoped src="@/components/SearchResults.scss"/>

<script>
import {
  SfLoader,
  SfBar,
  SfIcon,
  SfHeading,
  SfScrollable,
  SfButton,
  SfImage,
  SfSearchBar
} from '@storefront-ui/vue';

import SearchAutocomplete from '@/components/SearchAutocomplete';
import {OrkProductCard} from "@/components/Ork";
import {icons} from "@/utils/icons";
import Api from "@/api/Api";
import {clickOutside} from '@storefront-ui/vue/src/utilities/directives/click-outside/click-outside-directive.js';

import OrkLoader from "@/components/Ork/OrkLoader";

export default {
  name: 'SearchResults',
  components: {
    SfBar,
    SfLoader,
    SearchAutocomplete,
    SfSearchBar,
    OrkProductCard: OrkProductCard,
    SfScrollable,
    SfButton,
    SfImage,
    SfHeading,
    SfIcon,
    OrkLoader
  },
  directives: {clickOutside},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    result: {
      type: Object
    }
  },
  data() {
    return {
      loadingSearch: false,
      icons: icons,
      term: '',
      searchValue: '',
      debounceSearch: null,
      searchTerm: '',
      searchTerms: [],
      showAutocomplete: false,

    }
  },
  computed: {
    searchtermComp:{
      get(){
        return this.$route.query.searchTerm || null
      }
    },
    isSearchOpen: {
      get() {
        return this.$store.getters.isSearchOpen
      }
    },
    propsResult: {
      get() {
        return this.result
      }
    }
  },
  watch: {
    searchtermComp(newval,oldval){
      this.searchTerm=newval;
    }
  },
    methods: {
    removeSearchResults() {
      this.searchTerms = [];
      this.searchResult = {content: [], totalItems: 0}
    },
    closeSearch() {
      this.$store.dispatch('closeSearch')
    },

    toggleSearch() {
      this.$store.dispatch('toggleSearch')
    },

    openSearch() {
      this.$store.dispatch('openSearch')
    },
    getLengthTerm(term) {
      let lengthTerm = 0;
      for (let i = 0, len = term.length; i < len; i++) {
        if (term[i] !== ' ')
          lengthTerm++;
      }
      return lengthTerm;
    },
    handleSearchAutoComplete(term) {

      clearTimeout(this.debounceSearch);
      this.searchTerm = term;
      if (this.getLengthTerm(this.searchTerm) >= 3) {

        this.debounceSearch = setTimeout(() => {
          this.loadingSearch = true;

          Api.product.searchAutocomplete(term).then((data) => {
            this.showAutocomplete = true;
            this.searchTerms = data;
          }).catch((e) => {
            this.$store.dispatch('sendNotification', {
              type: 'danger',
              message: this.$i18n.t('server error'),
              icon: icons.error
            });
          }).finally(() => {
            this.loadingSearch = false;
          })
        }, 300);
      }
    },

    changeSearchTerm(term) {
      this.searchTerm = term;
      this.showAutocomplete = false;
      this.handleSearch(term)

    },

    handleSearch(term) {

      if (this.searchTerm.length > 0) {
        this.loadingSearch = true;
        Api.product.search(term).then((data) => {
            Api.marketingEvent.search(this.searchTerm)
          this.$set(this.propsResult, 'content', data.products.content)
          this.$set(this.propsResult, 'totalItems', data.products.totalElements)
          this.openSearch()
        }).finally(
            this.loadingSearch = false
        )
      }
    },

    goToSearchMobile() {
      this.$store.dispatch('closeSearch');
      this.$router.push(this.$localizePath('/recherche?searchTerm=' + this.searchTerm)).catch(() => {
      })
    },
    closeAutocomplete() {
      this.showAutocomplete = false;
    },
  }
};
</script>
