<template src="@/components/Ork/OrkProductGrid.html"/>
<style lang="scss" scoped src="@/components/Ork/OrkProductGrid.scss"/>
<script>

import {AgGridVue} from "ag-grid-vue";
import OrkFilterSelectGrid from "@/components/Ork/grid/OrkFilterSelectGrid";
import OrkStockGrid from "@/components/Ork/grid/OrkStockGrid";
import OrkFilterCheckBoxGrid from "@/components/Ork/grid/OrkFilterCheckBoxGrid";
import OrkImgGrid from "@/components/Ork/grid/OrkImgGrid";
import OrkPriceGrid from "@/components/Ork/grid/OrkPriceGrid";
import OrkAddToCartGrid from "@/components/Ork/grid/OrkAddToCartGrid";
import OrkMobileGrid from "@/components/Ork/grid/OrkMobileGrid";
import OrkNoResultGrid from "@/components/Ork/grid/OrkNoResultGrid";
import OrkMultiselect from "@/components/Ork/OrkMultiSelect";
import {SfButton, SfImage, SfHeading} from '@storefront-ui/vue';

export default {
  name: "OrkProductGrid",

  components: {
    SfHeading,
    OrkFilterCheckBoxGrid,
    OrkFilterSelectGrid,
    OrkNoResultGrid,
    OrkMobileGrid,
    OrkStockGrid,
    OrkMultiselect,
    SfButton,
    SfImage,
    AgGridVue,
    OrkImgGrid,
    OrkPriceGrid,
    OrkAddToCartGrid
  },
  data() {
    return {
      gridApi: null,
      columnApi: null,
      loadingSelect: true,
      rowHeight: 150,
      desktopWidth: 1360,
      isMobile: false,
      defaultColDef: {
        flex: 1,
        sortable: true,
        resizable: true,
        filter: true,
        /* floatingFilter: true,
         floatingFilterComponentParams: {
           suppressFilterButton: true,
         }*/
      },
      noRowsOverlayComponent: null,
      columnDefs: null,
      rowData: null,
      mobileColumn: [
        "mobile",
      ],
      desktopColumn: [],

    };
  },
  props: {
    products: {
      default: null
    },
  },
  computed: {
    filtersValues(){
        return this.$store.getters.getFiltersValues
    }
  },
  watch: {
    filtersValues: {
      handler(newVal) {
        this.gridApi.onFilterChanged();
      }
    },
  },
created(){
  this.noRowsOverlayComponent = 'OrkNoResultGrid';
},
  beforeDestroy() {
    window.matchMedia("(max-width :" + this.desktopWidth + "px)").removeEventListener('change', this.updateDesktop)
  },
  methods: {


    onGridReady(params) {
      if (this.products) {
        this.loadingSelect = false
        this.rowData = this.products.rowData
        this.columnDefs = this.products.columnDefs
        const products = {...this.products}
        this.columnDefs.map((col) => {

          col.floatingFilterComponentParams = {
            suppressFilterButton: true,
            refreshValuesOnOpen: true,
            filterValue: {
              filter: products.filters.find(filter => filter.name === col.field),
            //  filtersValues:this.filtersValues
            }
          }
          col.filterParams = {
            suppressFilterButton: true,
            refreshValuesOnOpen: true,
            filterValue: {
              filter: products.filters.find(filter => filter.name === col.field),
            }
          }

          if (col.filterType && col.filterType !== "NONE") {
            col.filter = 'OrkFilterCheckBoxGrid'
          } else {
            col.filter = 'OrkFilterCheckBoxGrid'
          }
          if (col.field === "reference") {
            col.cellRenderer = 'OrkImgGrid'
            col.filter = false
            col.minWidth = 150
            col.maxWidth = 150
            col.width = 150
            col.comparator = (valueA, valueB) => {
              if (valueA.reference === valueB.reference) return 0;
              return (valueA.reference > valueB.reference) ? 1 : -1;
            }
            col.cellRendererParams = {}
          }
          if (col.field === "mobile") {
            col.cellRenderer = 'OrkMobileGrid'
            col.filter = false
            col.comparator = (valueA, valueB) => {
              if (valueA.reference === valueB.reference) return 0;
              return (valueA.reference > valueB.reference) ? 1 : -1;
            }
            col.cellRendererParams = {}
          }
          if (col.field === "price") {
            col.cellRenderer = 'OrkPriceGrid'
            col.minWidth = 120
            col.maxWidth = 120
            col.width = 120
            col.cellRendererParams = {}
            col.filter = false
            col.comparator = (valueA, valueB) => {
              return valueA.regular.taxFree - valueB.regular.taxFree
            }
          }
          if (col.field === "stock") {
            col.cellRenderer = 'OrkStockGrid'
            col.minWidth = 120
            col.maxWidth = 120
            col.width = 120
            col.cellRendererParams = {}
            col.filter = false
            col.comparator = (valueA, valueB) => {
              return valueA.stockQuantity - valueB.stockQuantity
            }
          }
          if (col.field === "addToCart") {
            col.minWidth = 180
            col.maxWidth = 180
            col.width = 180
            col.cellRenderer = 'OrkAddToCartGrid'
          }
        })

        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridColumnApi.autoSizeAllColumns(true)
        this.gridApi.setColumnDefs(this.columnDefs)
        this.gridApi.setRowData(this.rowData);
        const desktopColumn = this.desktopColumn
        this.columnDefs.forEach(function (col) {
          desktopColumn.push(col.field)

        })


        this.desktopColumn = desktopColumn
        if (this.gridApi) {
          this.checkDisplaySize();
          /*
            window.addEventListener('resize', function () {

              setTimeout(function () {
                if (window.innerWidth <= 768) {
                  this.gridApi.setColumnDefs(mobileColumn);
                  this.gridApi.sizeColumnsToFit();
                } else {
                  this.gridApi.setColumnDefs(this.columnDefs);
                  this.gridApi.sizeColumnsToFit();
                }
              })
            })*/
        }
      }
    },
    clearFilters(){

      this.$store.dispatch('setFiltersValues',[])

      this.gridApi.onFilterChanged();
    },
    externalFilterChanged(newValue) {

    const filters = {...this.filtersValues}
    filters[newValue.name] = newValue.value;
      if (filters[newValue.name].length === 0) {
        delete filters[newValue.name]

      }

      this.$store.dispatch('setFiltersValues',{...filters})
      this.gridApi.onFilterChanged();

    },



    isExternalFilterPresent() {
      const filters = {...this.filtersValues}

      return filters.length!==0;
    },
    onFilterChanged(event) {

        if(this.gridApi && this.gridApi.rowModel.rowsToDisplay.length == 0) {
          this.gridApi.showNoRowsOverlay();
        }
        if(this.gridApi && this.gridApi.rowModel.rowsToDisplay.length > 0) {
          this.gridApi.hideOverlay();
        }


    },


    doesExternalFilterPass(node) {
      if (node) {
        const data = {...this.filtersValues}
        const validRow = [];
        Object.keys(data).forEach(function (key, index) {
          if(data[key].length>0) {
            validRow.push(data[key].includes(node.data[key]))
          }
          validRow.push(true)
        })
        return !validRow.includes(false)
      }
      return true;
    },


    checkDisplaySize() {
      const mql = window.matchMedia("(max-width :" + this.desktopWidth + "px)");

      this.updateDesktop(mql);

      mql.addEventListener("change", (e) => {
        this.updateDesktop(e);
      })

    },
    changeRowHeight(height) {
      this.gridApi.forEachNode(function (rowNode) {

        if (rowNode.data) {
          rowNode.setRowHeight(height);
        }
      });
      this.gridApi.onRowHeightChanged();
    },
    updateDesktop(event) {

      this.isMobile = event.matches;

      if (this.isMobile) {
        this.rowHeight = 350
        this.gridColumnApi.setColumnsVisible(this.desktopColumn, false);
        this.gridColumnApi.setColumnsVisible(this.mobileColumn, true);
        this.changeRowHeight(350);
      } else {
        this.rowHeight = 170
        this.gridColumnApi.setColumnsVisible(this.desktopColumn, true);
        this.gridColumnApi.setColumnsVisible(this.mobileColumn, false)
        this.changeRowHeight(170);
      }
    },
  },
};
</script>