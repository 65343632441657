<template src="@/components/TopBar.html"></template>
<style lang="scss" scoped src="./TopBar.scss"></style>

<script>
import {SfButton, SfSelect, SfLink} from '@storefront-ui/vue';

export default {
  name: 'TopBar',
  components: {
    SfButton,
    SfSelect,
    SfLink
  },
  computed: {
    rootCategoryLabel: {
      get() {
        if(this.rootCategories && this.rootCategory){
          const root = [...this.rootCategories]
        return root.find(category => category.path === this.rootCategory).label;
        }
        return null
      }
    },
    rootCategories: {
      get() {
        return this.$store.getters.getRootCategories
      }
    },
    rootCategory: {
      get() {
        return this.$store.getters.getRootCategoryPath
      }
    },
    locale: {
      get() {
        return this.$i18n.locale;
      }
    }
  },
  methods:{
    toggleRootCategoryModal(rootCategory) {
      this.$store.dispatch('toggleRootCategoryModal', {open: true, rootCategory: rootCategory})
    },
  }
};
</script>
