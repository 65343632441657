import {RouteConfig} from "vue-router/types/router";

const clientRoute: RouteConfig[]=[
    
    {
        path: "/categorie",
        name: "Categorie",
        beforeEnter: (to, from, next) => {
            next({name: "Notfound"});
        }
    },
    {
        path: "/devenir-fournisseur",
        name: "BecomeSupplier",
        component: () => import(  "@/pages/CMS/BecomeSupplier.vue"
          ),
    },
    {
        path: "/contact-presse",
        name: "PressContact",
        component: () => import(  "@/pages/CMS/PressContact.vue"
          ),
    },
    {
        path: "/garanties",
        name: "Warranty",
        component: () => import(  "@/pages/CMS/Warranty.vue"
          ),
    },
    {
        path: "/qui-sommes-nous",
        name: "WhoAreWe",
        component: () => import(  "@/pages/CMS/WhoAreWe.vue"
          ),
    },
    {
        path: "/Shopedom-business",
        name: "SedBusiness",
        component: () => import(  "@/pages/CMS/SedBusiness.vue"
          ),
    },
    {
        path: "/securite-de-paiement",
        name: "PaiementSecurity",
        component: () => import(  "@/pages/CMS/PaiementSecurity.vue"
          ),
    },
    {
        path: "/moyens-de-paiement",
        name: "PaiementMethods",
        component: () => import(  "@/pages/CMS/PaiementMethods.vue"
          ),
    },
    {
        path: "/service-client",
        name: "CustomerSupport",
        component: () => import(  "@/pages/CMS/CustomerSupport.vue"
          ),
    },
    {
        path: "/livraison",
        name: "Shipping",
        component: () => import(  "@/pages/CMS/Shipping.vue"
          ),
    },
    {
        path: "/contactez-nous",
        name: "Contact",
        component: () => import(/* webpackPrefetch: true */  "@/pages/Contact.vue"),
    },
    {
        path: "/conditions-generales-de-ventes",
        name: "CGV",
        component: () => import(  "@/pages/CMS/CGV.vue"
          ),
    },
    {
        path: "/conditions-generales-d-utilisation",
        name: "CGU",
        component: () => import(/* webpackPrefetch: true */  "@/pages/CMS/CGU.vue"),
    },
    {
        path: "/promotions",
        name: "Promotions",
        component: () => import("@/pages/Promotions.vue"),
    },
    {
        path: "/meilleurs-ventes",
        name: "Bestsellers",
        component: () => import("@/pages/Bestseller.vue"),
    }
];

export default clientRoute