import { Attribute } from "./attribute";
import { LogisticClass, LogisticPrice } from "./logistic";
import {PickingDelay, Shop} from "./shop";
import { TrackingInfo } from "./tracking";
import { CartPicture } from "@/models/picture";
import { Product } from "@/models/product";
import { Price } from "./price";
import { Address } from "@/models/address";
import {ShoppingCartItem, UnitPrice} from "@/models/shopping";
import { i18n } from "vue-lang-router";
export class Parcel {
  parcelId?: string | null;
  supplierParcelId?: string | null;
  creationDate?: Date | null;
  parcelStatus?: string | null;
  trackingUrl?: string | null;
  trackingNumber?: string | null;
  pickupCode?: string | null;
  shippedItems?: ParcelShippedItemQuantity[] | null;
  events?: ParcelEvent[] | null;

  public static of(data: any): Parcel {
    const parcel: Parcel = new Parcel();

    parcel.parcelId = data.parcelId;
    parcel.supplierParcelId = data.supplierParcelId;
    parcel.creationDate = data.creationDate;
    parcel.parcelStatus = data.parcelStatus;
    parcel.trackingUrl = data.trackingUrl;
    parcel.trackingNumber = data.trackingNumber;
    parcel.pickupCode = data.pickupCode;
    parcel.shippedItems = [];
    data.shippedItems?.forEach(item => parcel.shippedItems?.push(ParcelShippedItemQuantity.of(item)));
    parcel.events = [];
    data.events?.forEach(event => parcel.events?.push(ParcelEvent.of(event)));

    return parcel;
  }
}

export class ParcelShippedItemQuantity {
  productReference ?: string | null;
  quantity?: number | null;

  public static of(data: any): ParcelShippedItemQuantity {
    const item: ParcelShippedItemQuantity = new ParcelShippedItemQuantity();

    item.productReference = data.productReference;
    item.quantity = data.quantity;

    return item;
  }
}

export class ParcelEvent {
  date?: Date | null;
  type?: string | null;
  message?: string | null;

  public static of(data: any): ParcelEvent {
    const event: ParcelEvent = new ParcelEvent();

    event.date = data.date;
    event.type = data.type;
    event.message = data.message;

    return event;
  }
}
