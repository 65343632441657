<template src="@/components/Ork/OrkRootCategoryModal.html"/>
<style lang="scss" scoped src="@/components/Ork/OrkRootCategoryModal.scss"/>

<script>

import {
  SfBar,
  SfIcon,
  SfButton,
  SfImage,
  SfModal,
  SfSelect,
  SfHeading
} from '@storefront-ui/vue';

import Api from "@/api/Api";
import icons from '@/utils/icons';


export default {
  name: 'OrkRootCategoryModal',
  components: {
    SfHeading,
    SfBar,
    SfSelect,
    SfImage,
    SfButton,
    SfModal,
    SfIcon
  },
  data() {
    return {
      icons:icons,
      focusRootCategory: null,
    }
  },
  computed: {
    isRootCategoryModalOpen: {
      get() {
        return this.$store.getters.isRootCategoryModalOpen
      }
    },
    rootCategories: {
      get() {
        return this.$store.getters.getRootCategories
      }
    },
    rootCategory: {
      get() {
        return this.$store.getters.getRootCategoryPath
      }
    },
    rootCategoryName: {
      get() {
        return this.$store.getters.getRootCategoryLabel
      }
    },
  },
  watch: {
    rootCategory(newRoot,oldRoot){
      this.focusRootCategory = this.rootCategories.find(category=> category.path === newRoot).label;
    },
  },
  methods: {
    setRootCategory(rootCategory) {
        this.$store.dispatch('setRootCategoryPath', rootCategory.path).then(data => {
            this.toggleRootCategoryModal(rootCategory.path);
        })
    },
    EnterRootCategory(rootCategoryLabel) {
      this.focusRootCategory = rootCategoryLabel
    },
    LeaveRootCategory() {
      if (this.rootCategory) {
        this.focusRootCategory = this.rootCategories.find(category=> category.path === this.rootCategory).label;
      } else {
        this.focusRootCategory = null
      }
    },
    getCurrentRootCategory() {
        return this.rootCategories.find(category=> category.path === this.rootCategory);
    },
    toggleRootCategoryModal(rootCategory) {
      this.$store.dispatch('toggleRootCategoryModal', {open: false, rootCategory: rootCategory})
    },
  }
};
</script>
