<template src="@/components/Ork/OrkRecaptcha.html"/>
<style lang="scss" scoped src="@/components/Ork/OrkRecaptcha.scss"/>

<script>

export default {
  name: 'OrkRecaptcha',
  props: {
    formId: {
      default: "form",
      type: String
    }
  },

  data() {
    return {
      //your google sitekey
   loading:true,
      sitekey: process.env.VUE_APP_RECAPTCHA,
      widgetId: this.formId
    }
  },

  methods: {
    //some vuejs methods
    execute() {
      window.grecaptcha.execute(this.widgetId)
    },
    reset() {
      //reset using vuejs

      window.grecaptcha.reset(this.widgetId)
    },
    render() {
      //render Functions using vuejs

      this.widgetId = window.grecaptcha.render(this.widgetId, {
        sitekey: this.sitekey, //get your sitekey
        size: 'invisible',
        action: this.formId,
        callback: (results) => {

          this.$emit('verify', results)
          this.reset()
        }
      })

    },
    renderWait() {
      setTimeout(() => {
        if (typeof window.grecaptcha !== "undefined" && typeof window.grecaptcha.render !== "undefined"){
          this.loading=false;
          this.render()
        }
        else this.renderWait();
      }, 200);
    },
  },
  mounted() {
    if (typeof window.grecaptcha === "undefined") {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js?render=explicit";
      script.onload = this.renderWait;
      document.head.appendChild(script);
    } else this.render();
  }
}
</script>
