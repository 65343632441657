import QorusClient from "@/api/clients/QorusClient";
import CookieManager from "@/utils/cookieManager";
import { Customer } from "@/models/customer";
import { AxiosRequestConfig } from "axios";
import Locales from "@/utils/locales";
import store from "~/core/store";
import { CustomerActions } from "~/core/store/customer/actions";

export const GET_PROFILE_PATH = "customers/me";

/**
 *  Qorus API about user's account
 */

export default class AccountService {

    private qorusClient = QorusClient.of();

    static of(): AccountService {
        return new AccountService();
    }

    login(form): Promise<Customer> {
        return this.qorusClient.post("customers/sign-in", form).then(
            (data: Customer) => {
                if (!data.twoFactorAuthenticationEnabled) {
                    CookieManager.setTwoFactorAuthenticationToken(data.JWT);
                }
                return Customer.of(data);
            },
            data => {
                throw data;
            },
        );
    }


    paramsToObject(entries) {
        const result = {};
        for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
            result[key] = value;
        }
        return result;
    }


    checkAuth(customer: Customer): Promise<Customer> {
        return this.qorusClient.post("customers/one-time-password", {
            email: customer.email,
            password: customer.password,
            oneTimePassword: customer.oneTimePassword,
        }).then(
            (customerDTO) => {
                CookieManager.setTwoFactorAuthenticationToken(null);
                const customer = Customer.of(customerDTO);
                store.dispatch(CustomerActions.setCustomer, customer);
                return customer;
            },
            data => {
                CookieManager.setTwoFactorAuthenticationToken(null);
                throw data;
            },
        );
    }

    register(form): Promise<Customer> {
        form.locale = Locales.getLocaleCode();

        return this.qorusClient.post("customers/register", form).then(
            (customerDTO: any) => {
                const customer = Customer.of(customerDTO);
                store.dispatch(CustomerActions.setCustomer, customer);
                return customer;
            },
            data => {
                throw data;
            },
        );
    }

    forgotPassword(form): Promise<void> {
        const email = form.email ? form.email : "";
        const recaptchaData = form.recaptchaData ? form.recaptchaData : "";
        return this.qorusClient.post("customers/reset-password", { email, recaptchaData }).then(
            (data: void) => {
                return data;
            },
            data => {
                throw data;
            },
        );
    }

    logout(): Promise<Customer> {
        return this.qorusClient.post("customers/sign-out").then(
            (data) => {
                return Customer.of(data);
            },
            data => {
                throw data;
            },
        );
    }

    getProfile(): Promise<Customer> {
        return this.qorusClient.get(GET_PROFILE_PATH).then(
            (data) => {
                return Customer.of(data);
            },
            data => {
                throw data;
            },
        );

    }

    updateProfile(customer: Customer): Promise<Customer> {
        return this.qorusClient.patch("customers", customer).then(
            (data) => {
                return Customer.of(data);
            },
            err => {
                throw err;
            },
        );
    }

    updatePassword(passwords: { oldPassword: string, newPassword: string }): Promise<void> {
        return this.qorusClient.post("customers/change-password", passwords).then(
            () => {
                return;
            },
            err => {
                throw err;
            },
        );
    }

    addAddress(address): Promise<void> {
        const axiosRequestConfig: AxiosRequestConfig = {

            params: {
                defaultShipping: address.defaultShipping,
                defaultBilling: address.defaultBilling,
            },
        };
        return this.qorusClient.post("customers/address", address, axiosRequestConfig).then(
            (data) => {
                return data;
            },
            data => {
                throw data;
            },
        );
    }

    removeAddress(addressName: string): Promise<void> {

        return this.qorusClient.delete("customers/address/" + addressName).then(
            (data) => {
                return data;
            },
            data => {
                throw data;
            },
        );
    }

    resetPassword(form): Promise<void> {
        return this.qorusClient.post("customers/reset-password/" + form.resetToken, { newPassword: form.newPassword, recaptchaData: form.recaptchaData }).then(
            (data) => {
                return data;
            },
            data => {
                throw data;
            },
        );
    }

    subscribeNewsletter(customerEmail: string): Promise<Customer> {
        const axiosRequestConfig: AxiosRequestConfig = {
            params: {
                customerEmail
            },
        };
        return this.qorusClient.put("customers/newsletter/subscribe", null, axiosRequestConfig).then(
            (data) => {
                return Customer.of(data);
            },
            data => {
                throw data;
            },
        );
    }

    unSubscribeNewsletter(customerEmail: string): Promise<Customer> {
        const axiosRequestConfig: AxiosRequestConfig = {
            params: {
                customerEmail
            },
        };
        return this.qorusClient.put("customers/newsletter/unsubscribe", null, axiosRequestConfig).then(
            (data) => {
                return Customer.of(data);
            },
            data => {
                throw data;
            },
        );
    }
}
