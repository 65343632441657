<template src="@/components/Ork/OrkProductGallery.html"/>
<style lang="scss" scoped src="@/components/Ork/OrkProductGallery.scss"/>

<script>

import {
  SfIcon,
  SfImage
} from '@storefront-ui/vue';

import icons from "@/utils/icons";
import OrkLoader from "@/components/Ork/OrkLoader";

import {Navigation, Pagination, Thumbs} from 'swiper'

import {SwiperCore, Swiper, SwiperSlide} from 'swiper-vue2'
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination,Thumbs])

export default {
  name: 'OrkProductGallery',
  components: {
    SfImage,
    SfIcon,
    Swiper,
    SwiperSlide,
    OrkLoader

  },
  props: {
    loading: {default: true},
    gallery: null,

  },
  data() {
    return {
      imgHasError:[],
      index: null,
      icons: icons,
      thumbsGallery:null
    }
  },
  computed: {
    swiperSlides: {
      get() {
        return this.gallery
      }
    },
  },
  watch: {
    swiperSlides(newgal, oldgal){
      if(this.swiperSlides.length>1) {
        this.thumbsGallery = document.getElementById('thumbs').swiper
      }
      this.$store.dispatch('setProductGallery', {gallery: newgal});
    }
  },
  mounted() {
    if(this.swiperSlides.length>1) {
      this.thumbsGallery = document.getElementById('thumbs').swiper
    }
    this.swiperSlides.forEach((item,index)=>{
      this.imgHasError[index]=false;
    })
    this.$store.dispatch('setProductGallery', {gallery: this.gallery});
  },
  beforeDestroy() {
    this.$store.dispatch('setIndexGallery', {index: null})
    this.$store.dispatch('setProductGallery', {gallery: []})
  },
  methods: {
    imgError(imgIndex){
      this.imgHasError[imgIndex]=true
    },
    openLightBox(index) {
      this.$store.dispatch('setIndexGallery', {index: index})
    },

  }
};
</script>


