<script lang="ts">
import 'intl-tel-input/build/css/intlTelInput.css';

import intlTelInput from "intl-tel-input/intlTelInputWithUtils"
import { fr } from "intl-tel-input/i18n";
import { SfInput } from '@storefront-ui/vue';

export default {
  name: 'OrkTelInput',
  components: {
    SfInput
  },
  props: {
    inputId: String,
    phoneNumber: String,
    country: String
  },
  data() {
    return {
      phoneInput: null,
      errorMessage: null,
      errorMap: []
    }
  },
  mounted() {
    this.initTelInput();
  },
  methods: {
    initTelInput() {
      this.phoneInput = intlTelInput(
        document.getElementById(this.inputId),
        {
          i18n: fr,
          separateDialCode: true,
          initialCountry: this.country
        }
      );

      this.phoneInput.setNumber(this.phoneNumber);
    },
    onInput() {
      if (!this.phoneInput.isValidNumberPrecise()) {
        this.errorMessage = this.$t('phoneValidation.error.' + this.phoneInput.getValidationError());
      } else {
        this.errorMessage = null;
      }

      this.$emit("phone-validation", {errorMessage: this.errorMessage, phoneNumber: this.phoneInput.getNumber()});
    }
  },
  watch: {
    phoneNumber(newNumber) {
      const dialCode = '+' + this.phoneInput.getSelectedCountryData().dialCode;
      this.phoneInput.setNumber(newNumber.replace(dialCode, '').trim());
    }
  }
}
</script>

<template>
  <SfInput :name="inputId" @input="onInput" />
</template>

<style scoped lang="scss">

</style>
