<script lang="ts">
import {Category} from "@/models/category";
import MenuModalItem from "@/components/Menu/MenuModalItem.vue";
import Component from "vue-class-component";
import Vue from "vue";
import icons from "@/utils/icons";
import {SfIcon} from "@storefront-ui/vue";

@Component({
  name: "MenuModalLevel",
  components: {
    MenuModalItem,
    SfIcon
  },
  props: {
    category: [Category],
    level: Number,
    parentSlug: String
  },
  data() {
    return {
      isActive: false
    }
  },
  computed: {
    icons() {
      return icons;
    },
    slugChain() {
      if (this.category.path.endsWith("/")) {
        return "";
      }

      return `${this.parentSlug ? this.parentSlug + '/' : ''}${this.category.slug}`.replaceAll('//', "/");
    },
    hasSubLevel() {
      return this.category.children?.length;
    },
    isSubLevel() {
      return this.level > 1;
    }
  },
  methods: {
    toggleMenuDesktopModal() {
      this.$store.dispatch('toggleMenuDesktopModal')
    }
  }
})
export default class MenuModalLevel extends Vue {
}
</script>

<template>
  <ul
    :class="{flyout: isSubLevel}">
    <li v-if="isSubLevel" class="menu-subtitle">
      <label>
        <div>{{category.label}}</div>
        <SfIcon :icon="icons.left.path" :viewBox="icons.left.viewBox" class="icon" size="18px"/>
      </label>
    </li>
    <MenuModalItem v-for="subCategory in category.children"
                   :key="subCategory.slug"
                   :level="level"
                   :parent-slug="slugChain"
                   :sub-category="subCategory"
    />
    <li v-if="isSubLevel">
      <localized-link :to="`/categorie/${slugChain}`" class="menu-list-item-link">
        <label @click.stop="toggleMenuDesktopModal">
          Voir tout l'univers "{{category.label}}"
        </label>
      </localized-link>
    </li>
  </ul>
</template>

<style lang="scss">
#menu {
  overflow-x: hidden;

  &.sub-level-open {
    overflow-y: hidden;
  }

  ul {
    margin-top: 0;
    padding-left: 0;
    list-style-type: none;
  }

  .menu-list-item-link {
    font-weight: bold;
  }

  .menu-subtitle {
    background: #00000005;
    border-bottom: thick solid #0001;
  }

  > ul {
    position: relative;
  }
}
</style>
