<template src="@/components/AccountForm/AuthFactor.html"/>
<style lang="scss" scoped src="@/components/AccountForm/AuthFactor.scss"/>
<script>

import {SfHeading, SfAlert, SfButton, SfInput, SfLoader, SfNotification} from '@storefront-ui/vue';
import icons from "@/utils/icons";

const numericKey = Array.from({length: 10}, (v, i) => i + "");
export default {
  name: "AuthFactor",
  components: {
    SfHeading,
    SfAlert,
    SfInput,
    SfButton,
    SfLoader,
    SfNotification
  },
  data() {
    return {
      icons: icons,
      isCompleted: false,
      loading: false,
      error: null,
      valid: false,
      submitted: false,
      codeLength: 6,
      count: 3
    }
  },
  computed: {
    codeArray() {
      return Array.from({length: this.codeLength}, () => "");
    }
  },
  mounted() {
      this.initPasteEvent()
  },
  methods: {
    initPasteEvent() {
      const elements = [...document.getElementsByClassName('auth-code_input')];

      elements.forEach((element) => {
            element.addEventListener('paste', this.handlePaste);
          }
      )
    },
    handlePaste(e) {
      let clipboardData, pastedData;

      // Stop data actually being pasted into div
      e.stopPropagation();
      e.preventDefault();
      // Get pasted data via clipboard API
      clipboardData = e.clipboardData || window.clipboardData;
      pastedData = clipboardData.getData('Text');
      let data = pastedData
      // split clipboard text into single characters
      data = data.split('');
      const reg = new RegExp('^[0-9]$');
      if (data.length === 6) {
        const inputEntries = document.querySelectorAll("input[type=number]");
        [].forEach.call(inputEntries, (node, index) => {
          if (reg.test(data[index])) {
            this.updateCodeArray(index, data[index])
          }
        });
      }
    },

    handlePress(e, index) {

      e.stopPropagation();
      e.preventDefault();
      if (this.$refs["authcode_input"][index].value.length > 1) {
        this.$refs["authcode_input"][index].value = this.$refs["authcode_input"][index].value.substr(0, 1);
      }

      const content = this.$refs["authcode_input"][index].value;
      const reg = new RegExp('^[0-9]$');
      if (reg.test(e.target.value)) {
        this.updateCodeArray(index, e.target.value);
        this.switchFocusAndSelect(index, 1);
      } else if (["ArrowRight", "Tab", "Enter"].indexOf(e.key) > -1) {
        this.switchFocusAndSelect(index, 1);
      } else if (["ArrowLeft"].indexOf(e.key) > -1) {
        this.switchFocusAndSelect(index, -1);
      } else if (["Backspace"].indexOf(e.key) > -1) {
        if (content) {
          this.updateCodeArray(index, "");
        } else {
          this.switchFocusAndClear(index, -1);
        }
      } else {
        /* console.log(e.key)
         if (reg.test(e.key)) {

           this.updateCodeArray(index, '');
         }*/
      }
    },
    updateCodeArray(index, value) {
      this.$refs["authcode_input"][index].value = value;
      this.codeArray.splice(index, 1, value);
      if (this.codeArray.every(Boolean)) {
        this.isCompleted = true;
      } else {
        this.isCompleted = false;
      }
    },
    switchFocusAndSelect(index, direction = 1) {
      if (this.$refs["authcode_input"][index + direction]) {
        this.$refs["authcode_input"][index + direction].select();
      }
    },
    switchFocusAndClear(index, direction = 1) {
      if (this.$refs["authcode_input"][index + direction]) {
        this.$refs["authcode_input"][index + direction].value = "";
        this.codeArray.splice(index + direction, 1, "");
        this.$refs["authcode_input"][index + direction].focus();
        this.isCompleted = false;
      }
    },
    submitAuth() {
      const onComplete = () => {
        if (!this.error || this.error.status === 200) {
          this.loading = false;
        } else {
          this.loading = false;
        }
      };

      const onError = (e) => {

        this.error = e.message;
        this.count = e.count
        this.loading = false;
      };
      const authCode = this.codeArray.join('');
      this.loading = true;
      this.$emit('submit', {oneTimePassword: authCode, onComplete, onError});
    }
  }
}
</script>


