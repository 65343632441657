<template src="@/components/Ork/OrkAddToCart.html"/>
<style lang="scss" scoped src="@/components/Ork/OrkAddToCart.scss"/>

<script>
import {
  SfButton,
  SfLoader,
  SfIcon,
  SfCircleIcon
} from '@storefront-ui/vue';
import OrkQuantitySelector from "@/components/Ork/OrkQuantitySelector";
import icons from "@/utils/icons";

export default {
  name: "OrkAddToCart",
  components: {
    SfButton,
    SfCircleIcon,
    SfIcon,
    SfLoader,
    OrkQuantitySelector,
  },
  model: {
    prop: "qty",
  },
  props: {
    cartQty: {
      type: [Number, String],
      default: 0,
    },
    gridMode: {
      type: Boolean,
      default: false,
    },
    isInProductView: {
      type: Boolean,
      default: false,
    },
    isConfigurable: {
      type: Boolean,
      default: false,
    },
    showQtySelect: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    stock: {
      type: [Number, String],
      default: 1,
    },
    min: {
      type: [Number, String],
      default: 0,
    },
    max: {
      type: [Number, String],
      default: 0,
    },
    packSize: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canAddToCart: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "components.okrAddToCart.addLabel"
    },
    qty: {
      type: [Number, String, null],
      default: 1,
    },
    isInCart: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showRemove : process.env.VUE_APP_SHOW_REMOVE === 'true',
      ecommerceStyle: process.env.VUE_APP_ECOMMERCE_STYLE,
      icons: icons
    };
  },

};
</script>
