<template src="@/components/SearchAutocomplete.html"/>
<style lang="scss" scoped src="@/components/SearchAutocomplete.scss"/>
<script>
import {
  SfList,
  SfScrollable
} from '@storefront-ui/vue';
import {icons} from '@/utils/icons'

export default {
  name: 'SearchAutocomplete',
  components: {
    SfList,
    SfScrollable
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    result: {
      type: Array
    }
  },
  data() {
    return {
      icons: icons
    }
  },
  methods: {
    emitSearchTerm(term) {
      this.$emit('changeSearchTerm', term)
    },
    closeAutocomplete() {
      this.$emit('closeAutocomplete')
    }
  }
};
</script>

