import ApiClient from "@/api/clients/ApiClient";
import { AxiosRequestConfig } from "axios";
import Locales from "@/utils/locales";
import store from "@/store";
import { CustomerActions } from "@/store/customer/actions";
import { SET_ROOT_CATEGORY_PATH } from "../services/CategoryService";
import { GET_PROFILE_PATH } from "../services/AccountService";

interface QorusResponse {
    mode: 'authenticated' | 'anonymous',
    rootCategory: string,
    data: any
}

export default class QorusClient extends ApiClient {

    constructor() {
        const QORUS_PORT = process.env.VUE_APP_QORUS_PORT ? ":" + process.env.VUE_APP_QORUS_PORT : "";
        super({
            baseURL: process.env.VUE_APP_QORUS_HTTP_VERSION + "://" + process.env.VUE_APP_QORUS_HOST + QORUS_PORT + process.env.VUE_APP_QORUS_API_ROUTE,
            headers: {
                Accept: "application/json",
                common: {
                    "Authorization": "Bearer " + process.env.VUE_APP_QORUS_API_KEY,
                },
            },
            params: {
                locale: Locales.getLocaleCode(),
            },
        });
    }

    private getQorusConfig(config?: AxiosRequestConfig): AxiosRequestConfig {
        const res = {
            ...config,
            headers: {
                ...config?.headers,
                "Qorus-Root-Category": store.state.category.rootCategory?.path || null
            },
            withCredentials: true };
        console.log("config", res);
        return res;
    }

    get(path: string, config?: AxiosRequestConfig): Promise<any> {
        return super.get(path, this.getQorusConfig(config)).then(responseData => this.handleResponse(path, responseData));
    }

    delete(path: string, config?: AxiosRequestConfig): Promise<any> {
        return super.delete(path, this.getQorusConfig(config)).then(responseData => this.handleResponse(path, responseData));
    }

    post(path: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
        return super.post(path, data, this.getQorusConfig(config)).then(responseData => this.handleResponse(path, responseData));
    }

    put(path: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
        return super.put(path, data, this.getQorusConfig(config)).then(responseData => this.handleResponse(path, responseData));
    }

    patch(path: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
        return super.patch(path, data, this.getQorusConfig(config)).then(responseData => this.handleResponse(path, responseData));
    }

    async handleResponse(path: string, responseData: any): Promise<any> {
        // Exception for binary data
        if (responseData.blob) return responseData;

        const { mode, rootCategory, data } = responseData;

        // Reset customer data if it was either disconnected or session reached timeout
        if (mode === "anonymous" && !store.state.customer.me?.isGuest) {
            // Don't do this if it was a request to get customer details, as it would be useless
            if (GET_PROFILE_PATH !== path) {
                store.dispatch(CustomerActions.getMe);
            }
        }

        // Be sure we are in sync with the server about the root category
        // This should always be the case, as the root category is sent with every Qorus request (see getQorusConfig).
        // But never knows...
        if (store.state.category.rootCategory?.path && rootCategory !== store.state.category.rootCategory?.path) {
            // Don't do this if it was a request to set the root category, otherwise we will get a cycle,
            // we're setting a different root category, it was set on the server, and the response contains the new root category,
            // but our store state is not yet updated.
            if (SET_ROOT_CATEGORY_PATH !== path) {
                store.dispatch('setRootCategoryPath', store.state.category.rootCategory.path);
            }
        }

        return data;
    }

    static of(): QorusClient {
        return new QorusClient();
    }
}