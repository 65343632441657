import { Customer } from "@/models/customer";
import BrevoEventHandler from "./event-handlers/BrevoEventHandler";
import GtagEventHandler from "./event-handlers/GtagEventHandler";
import { MarketingEventHandler, ViewPageData } from "./event-handlers/MarketingEventHandler";
import store from "@/store";
import { Order } from "@/models/order";
import { ShoppingCart } from "@/models/shopping";
import { Shipment, ShipmentItem } from "@/models/shipment";
import { Product } from "@/models/product";
import MosaiqEventHandler from "./event-handlers/MosaiqEventHandler";

export interface ProductMarketingEventData {
    item_id: string;
    item_name: string;
    item_brand: string;
    item_picture: string;
    category?: string;
    affiliation: string;
    currency: string | null;
    unit_regular_price: number | null;
    unit_discount_price: number | null;
    unit_price: number | null;
    price?: number | null;
    quantity: number;
}

export interface OrderMarketingtEventData {
    id: string;
    value: number;
    currency: string;
    items: ProductMarketingEventData[];
    transaction_id?: string;
    tax?: number;
    shipping?: number;
    coupon?: string;
}

export default class MarketingEventService {

    static of(): MarketingEventService {
      return new MarketingEventService();
    }

    private static AVAILABLE_HANDLERS = [
        new GtagEventHandler(),
        new BrevoEventHandler(),
        new MosaiqEventHandler()
    ];

    private handlers: MarketingEventHandler[];

    constructor() {
        const handlerNames = process.env.VUE_APP_MARKETING_EVENT_HANDLERS?.split(',') || [];

        const availableHandlersByName = MarketingEventService.AVAILABLE_HANDLERS.reduce((acc, val) => { acc[val.handlerName()] = val; return acc;}, {});

        this.handlers = handlerNames.filter(name => {
            const handler = availableHandlersByName[name];
            if (!handler) {
                console.error("unknwon marketing event handler of name '" + name + "'");
            }
            return !!handler;
        }).map(name => availableHandlersByName[name]);
    }

    private getCustomerData(): Customer | null {
        return store.state.customer.me;
    }

    private mapProductData(product: Product, qty: number, activeCategory?: string): ProductMarketingEventData {
        return {
            item_id: product.reference,
            item_name: product.name,
            item_brand: product.brand?.value?.toString() || "",
            item_picture: product.pictureCover?.src || "",
            category: activeCategory || "",
            affiliation: product.shopName || "",
            currency: product.currency,
            unit_regular_price: product.taxIncludedPrice,
            unit_discount_price: product.discountPrice,
            unit_price: product.discountPrice || product.taxIncludedPrice,
            // no total price in this case as we don't have the computed value,
            // and I don't think we should do any computation here, we could calculate som
            //price: product.defaultOffer.discountPrice.taxIncluded || product.defaultOffer.price.taxIncluded,
            quantity: qty
        };
    }

    private mapSingleProductCartData(product, cart, qty, activeCategory?): OrderMarketingtEventData {
        return {
            id: cart?.id,
            value: cart?.totalPrice.taxIncluded || 0,
            currency:  cart?.totalPrice.currency || "EUR",
            items: [this.mapProductData(product, qty, activeCategory)]
        };
    }

    private mapShipmentItem(item: ShipmentItem): ProductMarketingEventData {
        return {
            item_id: item.product.reference,
            item_name: item.product.name,
            item_brand:item.product?.['brand'] || "",
            item_picture: item.product.cover?.src || "",
            affiliation: item.product.offer.shop.name || "",
            currency: item.price.currency,
            unit_regular_price: item.unitPrice.regular,
            unit_discount_price: item.unitPrice.discount,
            unit_price: item.unitPrice.discount || item.unitPrice.regular,
            price: item.price.taxIncluded,
            quantity: item.quantity,
        };
    }

    private mapShipmentItems(shipments: Shipment[]): ProductMarketingEventData[] {
        return shipments
            .flatMap(s => s.items)
            .map(item => this.mapShipmentItem(item));
    }

    private mapCartData(cart: ShoppingCart): OrderMarketingtEventData {
        return {
            id: cart.id,
            value: cart?.totalPrice.taxIncluded || 0,
            currency:  cart?.totalPrice.currency || "EUR",
            items: this.mapShipmentItems(cart.shipments)
        };
    }

    private mapOrderData(order: Order): OrderMarketingtEventData {
        return {
            id: order.id,
            transaction_id: order.reference,
            value: order.totalPrice.taxIncluded || 0,
            tax: order.totalPrice.taxAmount || 0,
            shipping: order.shippingPrice.taxIncluded || 0,
            currency: order.shippingPrice.currency || 'EUR',
            coupon: order.coupon || "",
            items: this.mapShipmentItems(order.shipments)
        };
    }

    handlerName(): string {
        return "dispatcher";
    }

    viewCart = (cart): void => {
        const eventData = this.mapCartData(cart);
        this.handlers.forEach(handler => handler.viewCart(eventData));
    };

    viewItem = (product, activeCategory, cart): void => {
        const eventData = this.mapSingleProductCartData(product, cart, 1, activeCategory);
        this.handlers.forEach(handler => handler.viewItem(eventData));
    };

    viewCategory = (eventData): void => {
        this.handlers.forEach(handler => handler.viewCategory(eventData));
    };

    viewPage = (eventData: ViewPageData): void => {
        this.handlers.forEach(handler => handler.viewPage(eventData));
    };

    addToCart = (product, qty, activeCategory, cart): void => {
        const productData = this.mapProductData(product, qty, activeCategory);
        const cartData = this.mapCartData(cart);
        this.handlers.forEach(handler => handler.addToCart(cartData, productData));
    };

    removeFromCart = (product, cart, qty): void => {
        const productData = this.mapProductData(product, qty);
        const cartData = this.mapCartData(cart);
        this.handlers.forEach(handler => handler.removeFromCart(cartData, productData));
    };

    addToCartShipment = (product, qty, cart): void => {
        const productData = this.mapProductData(product, qty);
        const cartData = this.mapCartData(cart);
        this.handlers.forEach(handler => handler.addToCart(cartData, productData));
    };

    addToCartLight = (product, qty, cart): void => {
        const productData = this.mapProductData(product, qty);
        const cartData = this.mapCartData(cart);
        this.handlers.forEach(handler => handler.addToCart(cartData, productData));
    };

    beginCheckout = (cart: ShoppingCart): void => {
        const eventData = this.mapCartData(cart);
        this.handlers.forEach(handler => handler.beginCheckout(eventData));
    };

    purchase = (order: Order): void => {
        const eventData = this.mapOrderData(order);
        this.handlers.forEach(handler => handler.purchase(eventData));
    };

    search = (searchTerm): void => {
        this.handlers.forEach(handler => handler.search(searchTerm));
    };

    signUp = (): void => {
        const customerData = this.getCustomerData();
        if (customerData)
            this.handlers.forEach(handler => handler.signUp(customerData));
    }

    login = (): void => {
        const customerData = this.getCustomerData();
        if (customerData)
            this.handlers.forEach(handler => handler.login(customerData));
    }

    updateProfile = (): void => {
        const customerData = this.getCustomerData();
        if (customerData)
            this.handlers.forEach(handler => handler.updateProfile(customerData));
    };

}
