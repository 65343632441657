<template functional src="@/components/Ork/OrkQuantitySelector.html"></template>
<style lang="scss" src="@/components/Ork/OrkQuantitySelector.scss"></style>
<script>

import {
  SfButton,
  SfInput,
  SfIcon,
  SfSelect
} from '@storefront-ui/vue';
import {i18n} from "vue-lang-router";
import icons from "@/utils/icons";
import Vue from "vue";

export default {
  name: "OrkQuantitySelector",
  inject: {
    components: {
      default: {
        i18n,
        icons,
        SfSelect,
        SfInput,
        SfButton,
        SfIcon
      },
    },
  },
  model: {
    prop: "qty",
  },
  props: {
    packSize: {
      type: [Number, String],
      default: 1,
    },
    ecommerceStyle: {
      type: String,
      default: "standard",
    },
    isInCart: {
      type: Boolean,
      default: false,
    },
    gridMode:{
      type: Boolean,
      default: false,
    },
    useSelect: {
      type: Boolean,
      default: false,
    },
    qty: {
      type: [Number, String, null],
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    stock: {
      type: Number,
      default: null,
    },
    showButtons: {
      type: Boolean,
      default: true
    }
  },

  checkModulo(qty, packSize) {
    const divider = qty / packSize;
    if (divider !== ~~divider) {
      return (Math.floor(qty / packSize) + 1) * packSize;
    }
    return qty
  },

  verifyQty(qty, min, max, packSize, ecommerceStyle, isInCart, gridMode) {
    qty = Vue.filter('toInt')(qty)
    if (qty) {
      const minimum = min ? min < packSize ? min : packSize : packSize ? packSize : 1;
      qty = this.checkModulo(qty, packSize)

      if (qty < minimum || isNaN(qty) ) {
        if(ecommerceStyle !== 'market' || (ecommerceStyle !== 'market' && !isInCart)) {
          qty = this.checkModulo(Number(minimum), packSize)
        }
      } else if (max !== null && qty > max) {
        qty = this.checkModulo(max, packSize)
        if (qty > max) {
          qty = qty - packSize
        }
      }
      return qty
    } else {
      if (ecommerceStyle === 'market' && isInCart || gridMode ) {
        return 0
      } else {
        return packSize
      }
    }

  },
  handleInput(qty, listeners, min, max, packSize, ecommerceStyle, isInCart,gridMode) {
    const quantity = this.verifyQty(qty, min, max, packSize, ecommerceStyle, isInCart, gridMode)

    if (quantity || ecommerceStyle==='market' || gridMode) {

      clearTimeout(this.debounceInput);
      this.debounceInput = setTimeout(() => {
        listeners.input && listeners.input(quantity);
      }, 350);
    }

  },
  handleButton(qty, listeners, min, max, packSize, ecommerceStyle, isInCart, gridMode) {
    const quantity = this.verifyQty(qty, min, max, packSize, ecommerceStyle, isInCart, gridMode)
    if (quantity || ecommerceStyle==='market' || gridMode) {
      return listeners.input && listeners.input(quantity);
    }

  },
  handleBlur(listeners) {
    return listeners.blur && listeners.blur();
  },
  uniqueKey() {
    const key = Math.random().toString(16).slice(2);
    return "quantitySelector" + key;
  },
  handleSelect(qty, listeners) {

  },
};
</script>
